import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import jwt_decode from "jwt-decode";
import { employeModel } from 'src/app/models/employe.model';
import { employeservice } from 'src/app/services/employe.service';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
@Component({
  selector: 'app-employee-review-detail',
  templateUrl: './employee-review-detail.component.html',
  styleUrls: ['./employee-review-detail.component.css']
})
export class EmployeeReviewDetailComponent implements OnInit {

  id : number;
  imgFoto : any;
  usuarioId : any;
  employeeId : any;
  dataSourceEmployee : MatTableDataSource<employeModel>;
  pageInfo : any;

  constructor(private _employeservice : employeservice
      , private readonly _uploadFileService: UploadFileService
      , @Inject(MAT_DIALOG_DATA) public data) { 
      this.pageInfo = data.arrayData;
    }

  ngOnInit(): void {
    this.decode();
  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeProveedorId = jwt_decode(token)["proveedor_id"];
    this.usuarioId = decodeUser;
    this.employeeId = decodeProveedorId;
    this.getemployeeDocuments();
  }

  getemployeeDocuments(){
    let arrayEmployee : any;

        this._uploadFileService.getemployeeDocumentsAll().subscribe(
          doc=> {
    
            let arrayDoc = new MatTableDataSource(doc);
            let urlDocumento = arrayDoc.filteredData.filter(docx => docx.idEmpleado == this.pageInfo.id && docx.categoriaDocumento == '1007');
            this.imgFoto = urlDocumento[0].urlDocumento.toString();
         
          },
          error => console.log("error consulta regiones",error)
        )
  }

  descargarExcel(){}

}
