<div fxLayout="row">
    <h4>REPSE Captura de proveedores</h4>
</div>
<br>
<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Documentos del mes</span>
        </ng-template>
        <br><span class="tabsEncabezado">Documentos del mes</span><br>

        <app-supplier-defeated></app-supplier-defeated>
    </mat-tab>
    
    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Generales</span>
        </ng-template>
        <br><span class="tabsEncabezado">Datos Generales</span><br>

        <app-repse-capture-general></app-repse-capture-general>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Mensuales</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Datos Mensuales </span><br>
        <app-repse-capture-mensual></app-repse-capture-mensual>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Bimestrales</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Datos Bimestrales </span><br>
        <app-repse-capture-bimestral></app-repse-capture-bimestral>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Cuatrimestrales</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Datos Cuatrimestrales </span><br>
        <app-repse-capture-cuatrimestral></app-repse-capture-cuatrimestral>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Papelería CCM</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Papelería CCM </span><br>
        <app-repse-capture-papeleria-ccm></app-repse-capture-papeleria-ccm>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Perfil</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Perfil proveedor </span><br>
        <app-supply-profile></app-supply-profile>
    </mat-tab>
</mat-tab-group>