import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-capture-detail',
  templateUrl: './employee-capture-detail.component.html',
  styleUrls: ['./employee-capture-detail.component.css']
})
export class EmployeeCaptureDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  descargarExcel(){}

}
