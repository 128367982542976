import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { employeModel } from 'src/app/models/employe.model';
import { employeservice } from '../../services/employe.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.css']
})
export class EmployeeDetailComponent implements OnInit {

// =========================
// DECLARACIONES
// =========================
nombre : string;
appaterno : string;
apmaterno : string;
direccion : string;
rfc : string;
contacto : string;
ciudad : string;
numeroEmpleado : string;
estado : string;
employeeDataSource: any;
userProfile : any;

projectInfo : any;
estadoPantalla : string;

public newProject: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<employeModel>
    , private _employeservice : employeservice
    , private formBuilder: FormBuilder
    , @Inject(MAT_DIALOG_DATA) public data
    , private _snackBar : MatSnackBar
  ) {

    this.projectInfo = data.arrayData;
    this.estadoPantalla = data.estadoPantalla;

    this.newProject = this.formBuilder.group({
      nombre :  new FormControl('', [Validators.required]),
      appaterno: new FormControl('', [Validators.required]),
      apmaterno: new FormControl('', [Validators.required]),
      rfc: new FormControl('', [Validators.required]),
      numeroEmpleado: new FormControl('', [Validators.required]),
      // contacto: new FormControl('', [Validators.required]),
      // ciudad: new FormControl('', [Validators.required]),
      // estado: new FormControl('', [Validators.required]),
      // repse: new FormControl('', [Validators.required]),
    });
   }

// =========================
// PROCEDIMIENTOS
// =========================

  ngOnInit(): void {

    this.userProfile = this.data.userProfile;
    // let repse : string = '1';

    // repse = (this.projectInfo.repse == true) ? '1' : '0';

    this.getEmployee();
    if(this.estadoPantalla == 'Edit'){
      this.newProject.controls['numeroEmpleado'].setValue(this.projectInfo['numeroEmpleado']);
      this.newProject.controls['nombre'].setValue(this.projectInfo['nombre']);
      this.newProject.controls['appaterno'].setValue(this.projectInfo['aPaterno']);
      this.newProject.controls['apmaterno'].setValue(this.projectInfo['aMaterno']);
      this.newProject.controls['rfc'].setValue(this.projectInfo['rfc']);
    }
  }

  cancel(event){
    this.dialogRef.close();
  }

  save(form, event){

    if(this.estadoPantalla != 'Edit'){
      if(this.employeeDataSource.filter(e => e.rfc.trim() == form.controls["rfc"].value.trim()).length > 0){
        this.openSnackBar('Este RFC ya esta registrado', '');
        return
      }
    }

    let arrayToDb : any;

    arrayToDb = ({ 
      id : (this.estadoPantalla == 'Edit') ? this.projectInfo.id : 0
        , numeroEmpleado : form.controls["numeroEmpleado"].value
        , nombre : form.controls["nombre"].value
        , aPaterno : form.controls["appaterno"].value
        , aMaterno : form.controls["apmaterno"].value
        , estadoCivil : 0
        , nss : 0 //form.controls["nss"].value 
        , rfc : form.controls["rfc"].value 
        , curp: ''
        , sexo : 1
        , fechaNacimiento : '1980-01-01'
        , lugarNacimientoEstado : ''
        , nacionalidad : 1
        , numeroLicencia : 0
        , creditoInfonavit : 'NO'
        , creditoFonacot : 'NO'
        , telefonoFijo : '0'
        , celular : '0'
        , correo : ''
        , nivelEstudios : ''
        , estudiosActuales : ''
        , estatus : 1
        , institucion : ''
      });

console.log('array to db', arrayToDb)

      if(this.estadoPantalla == 'Edit'){
        this.updateEmployee(arrayToDb);
      }else{
        this.insertEmployee(arrayToDb);
      }
    
  }

  onChangeRFC(event){

    console.log('employee', this.employeeDataSource)

    if(this.employeeDataSource.filter(e => e.rfc.trim() == this.newProject.controls["rfc"].value.trim()).length > 0){
      this.openSnackBar('Este RFC ya esta registrado', '');
    }
  }

// =========================
// UTILERIAS
// =========================
openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {duration : 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: 'alert-snackbar'});
}

// =========================
// SERVICIOS
// =========================

insertEmployee(arrayToDb : any){

  // Inserta Empleados
  this._employeservice.insertemployee(arrayToDb).subscribe(
    res=> {
      this.openSnackBar('El registro se actualizó con éxito', '');  
      this.dialogRef.close();
    },
    error => console.log("error al insertar proveedores",error)
  )

}

updateEmployee(arrayToDb : any){

  // Inserta Empleados
  this._employeservice.updateemployee(arrayToDb).subscribe(
    res=> {
      this.dialogRef.close();
    },
    error => console.log("error al actualizar proveedores",error)
  )

}

getEmployee(){

  // Inserta Empleados
  this._employeservice.getemployeAll().subscribe(
    res=> {
      this.employeeDataSource = res;
    },
    error => console.log("error al actualizar proveedores",error)
  )

}

}
