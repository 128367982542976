import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { poModel } from 'src/app/models/po.model';
import { invoiceDetailModel } from 'src/app/models/invoice-detail.model';
import { environment } from 'src/environments/environment';
import { supplierActivityModel } from '../models/supplier-activity.model';

@Injectable({
    providedIn: 'root'
})

export class ponotinvoiceservice {

    constructor(protected http: HttpClient) { }

    getpoNotInvoiceAll(proyecto_id: number, ordendecompra_id:number, fecha:string) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<poModel[]>(environment.urlapi + environment.apiContextDrivers + 'PurchaseOrder/Lista/sinfactura?proyectoId=' + proyecto_id + '&categoriaId=' + ordendecompra_id + '&fecha=' + fecha, {headers});
    }
    
    getpoNotInvoiceQty() {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<poModel[]>(environment.urlapi + environment.apiContextDrivers + 'PurchaseOrder/notificaciones', {headers});
    }

}