import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import jwt_decode from "jwt-decode";
import { employeModel } from 'src/app/models/employe.model';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { employeservice } from '../../services/employe.service';
import { interval, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-employe-capture',
  templateUrl: './employe-capture.component.html',
  styleUrls: ['./employe-capture.component.css']
})
export class EmployeCaptureComponent implements OnInit {

  id : number;
  dataSourceEmployee : MatTableDataSource<employeModel>;
  subscription: Subscription;

  imgFoto : any; // = 'https://firebasestorage.googleapis.com/v0/b/sap-comercial.appspot.com/o/rh%2Fempleados%2FdatosDomicilio%2F3%2Flicencia_ordenCompraCCM_dos.PNG?alt=media&token=a0ab56a4-9fe5-4740-8e0f-3b036c8bdb1b';
  usuarioId : any;
  employeeId : any;
  constructor(private _employeservice : employeservice
      , private readonly _uploadFileService: UploadFileService
      , private _http: HttpClient) { 
      }

  ngOnInit(): void {
    //emit value in sequence every 10 second
    const source = interval(10000);
    const text = 'Your Text Here';
    this.subscription = source.subscribe(val => this.opensnack());

    this.decode();
  }

  opensnack(){
    // let header : string = 'ALERTA!!!';
    // let icon: any
    // let message : string = 'Suspensión próxima, comuniquese con su proveedor del sistema';
    // let buttonCaption: string = 'OK'
    
    // Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeProveedorId = jwt_decode(token)["proveedor_id"];
    this.usuarioId = decodeUser;
    this.employeeId = decodeProveedorId;
    this.getemployeeAll(decodeUser);
  }

  getemployeeAll(rfc : string){

    this._employeservice.getemployeAll().subscribe(
      res=> {
        let arrayUserId : any[] = res.filter(e => e.rfc == rfc);
        let arrayEmployee : any;
        this.employeeId = arrayUserId[0].id

        this.id = arrayUserId[0].id;

        if(arrayUserId.length > 0 ){
          this._employeservice.getemployeeById(arrayUserId[0].id).subscribe(
            ex=> {
      
              arrayEmployee = new MatTableDataSource(ex);
              
              this.dataSourceEmployee = arrayEmployee;

              this._uploadFileService.getemployeeDocumentsAll().subscribe(
                doc=> {
          
                  let arrayDoc = new MatTableDataSource(doc);
                  let urlDocumento = arrayDoc.filteredData.filter(docx => docx.idEmpleado == arrayUserId[0].id && docx.categoriaDocumento == '1007');

                  this.imgFoto = urlDocumento[0].urlDocumento.toString();

                  console.log('documentacion', urlDocumento[0].urlDocumento)

                  // // this.dataSourceEmployee = arrayEmployee;
                  // console.log('Y Y Y Y Y Y Y Y Y Y Y Y Y Y ', arrayDoc);
    
                  
                },
                error => console.log("error consulta regiones",error)
              )

              // this.newProject.controls["estadoCivil"].setValue(arrayUserId[0].estadoCivil.toString());
            },
            error => console.log("error consulta regiones",error)
          )    
        }
      },
      error => console.log("error consulta regiones",error)
      )
  }

  descargarExcel(){}

  
  
}
