<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card shadow" >
                <div class="card-header border-0">
                    <h3 class="mb-0"></h3>
                    <div class="center" style="width: 100%;"><ng-container *ngIf="isloading == true"><mat-spinner color="primary"></mat-spinner></ng-container></div>
                </div>

                <!-- <form> -->

                <h6 class="heading-small text-muted mb-4"></h6>
                <hr class="my-4" />
                <!-- </form> -->
                <div class="card">
                    <table>
                        <tr>
                            <td class="col20"><input type="text" class="form-control form-control-line"
                                    placeholder="BUSCAR" (keyup)="filtrar($event)">
                            </td>
                            <td class="col70"></td>
                            <td class="col10"><button mat-raised-button color="primary"
                                    (click)="descargarExcel()">Descargar</button></td>
                            <td class="col10">
                                </td>
                            <td class="col10"></td>
                        </tr>
                    </table>
                    <br>
                </div>
                
                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                        <div class="card-body card-body-table">
                            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8" style="width: 95%;">
                                
                                <ng-container matColumnDef="fracc_Id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" >Fracción Arancelaria </th>
                                    <td mat-cell *matCellDef="let element" > {{element.fracc_Id}} </td>
                                </ng-container>

                                <ng-container matColumnDef="uoM_Id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col70">UoM </th>
                                    <td mat-cell *matCellDef="let element"> {{element.uoM_Id}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </mat-table>

                        </div>
                        <!-- <mat-paginator [pageSizeOptions]="[20, 100, 150]" [showFirstLastButtons]="true"
                            [pageSize]="pageSize" [length]="totalSize" (page)="handlePage($event)" style="width: 95%;">
                        </mat-paginator> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>