import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { supplierModel } from 'src/app/models/supplier.model';
import { supplyservice } from 'src/app/services/supplier.service';
import { SupplierDetailComponent } from 'src/app/components/supplier-detail/supplier-detail.component';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
// import { RepseSupplierReviewDetailComponent } from '../../../components/repse-supplier-review-detail/repse-supplier-review-detail.component';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { RepseReviewAproveComponent } from '../repse-review-aprove/repse-review-aprove.component';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee-review-load-documents',
  templateUrl: './employee-review-load-documents.component.html',
  styleUrls: ['./employee-review-load-documents.component.css']
})
export class EmployeeReviewLoadDocumentsComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<supplierModel>
dataSourceEmployee : any;
arrayEmployeeGlobal : any = [];
providerId : number;
pageInfo : any;
arraySupplierGlobal : any = [];
usuarioId : string;
employeeId : number = 0;
urlDocumento : any;
tipoImagenFile : any = [];
txtDescripcion : any = '';

nombreArchivo: string = " (archivo nuevo) ";
loading: boolean;
valido: boolean
errorFileUpload : boolean = true; // true = valido, false = invalido
estadoEmployee : number;

public frmEmployee: FormGroup;

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<supplierModel>
    , private formBuilder: FormBuilder
    , private readonly _uploadFileService: UploadFileService
    , @Inject(MAT_DIALOG_DATA) public data) 
    { 
      this.pageInfo = data.arrayData;
      this.frmEmployee = this.formBuilder.group({
      //   txtDescripcion :  new FormControl('', [Validators.required]),
      });
  }

  displayedColumns = ['employee_id', 'documento', 'ver']; // , 'eliminar'

  ngOnInit(): void {
    this.employeeId = this.pageInfo.id;
    this.tipoImagenFile = [{id : 9001, categoria : 'documentos'}]
    this.getemployeeDocuments();
  }

  cancel(){
    this.dialogRef.close();
  }

  // openFile(documentShow : number){
  //   this.getDocumentsAll(documentShow);
  // }

  // getDocumentsAll(documentShow : number){
  //   console.log('aqui estamos', this.txtDescripcion);
  //   let arrayDocumentos : any
  //   this._uploadFileService.getemployeeDocumentsAll().subscribe(
  //     res=> {
  //       arrayDocumentos = res;
  //       this.arrayDocumentosEmployee = null;

  //       if(documentShow == 0)
  //         this.arrayDocumentosEmployee = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId)
  //       else
  //         this.arrayDocumentosEmployee = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento == documentShow)

  //       if(documentShow == 0){
  //         this.arrayDocumentosEmployee.forEach(element => {
  //           switch(element.categoriaDocumento){
  //             case(2001): this.estadoLicencia = element.estado; break;
  //             case(2002): this.estadoCredito = element.estado; break;
  //             case(2003): this.estadoCredito2 = element.estado; break;
  //             case(2004): this.estadoComprobanteDom = element.estado; break;
  //           }
  //         });
  //       }

  //       if(this.arrayDocumentosEmployeeOrigen == undefined)
  //       this.arrayDocumentosEmployeeOrigen = this.arrayDocumentosEmployee;

  //       if(documentShow != 0){
  //         if(this.arrayDocumentosEmployee != undefined && this.arrayDocumentosEmployee.length > 0)
  //           window.open(this.arrayDocumentosEmployee[0]["urlDocumento"]);
  //         else
  //           this.openSnackBar('Documento no encontrado', '');
  //       }

  //     },
  //     error => console.log("error consulta regiones",error)
  //   )
  // }

  // opencomments(documentShow : number, element, event){
    // let arrayDocuemtnoEnviar : any;
    // arrayDocuemtnoEnviar = this.arrayDocumentosEmployeeOrigen.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento == documentShow);
    // this.showMessage(1, 'Comentario', 'info', arrayDocuemtnoEnviar[0]["comentarios"], 'Cerrar');
  // }


// =========================
// UTILERIAS
// =========================
  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  showDocument(arrayDocumentosFiltrados : any, categoriaDocumento : number, anio: number, mes: number, titulo : string, idDocumento : any){
    let arraySupplier: any[] = [];
      let arrayDocumentos : any;
      let arrayDocumentoPorEmpleado : any;
      let urlShow : any = '';
      let estadoShow : number = 3;
      let idDocumentShow : number = 0;
    
    urlShow = '';
    estadoShow = 4;
    idDocumentShow = 0;
    arrayDocumentoPorEmpleado = arrayDocumentosFiltrados.find(e => e.categoriaDocumento == categoriaDocumento && e.idDocumento == idDocumento)

    if(arrayDocumentoPorEmpleado != undefined){ 
      urlShow = (arrayDocumentoPorEmpleado.urlDocumento.length > 0) ? arrayDocumentoPorEmpleado.urlDocumento : '';
      estadoShow = (arrayDocumentoPorEmpleado.estado != undefined) ? arrayDocumentoPorEmpleado.estado : 0;
      idDocumentShow = (arrayDocumentoPorEmpleado.idDocumento != undefined) ? arrayDocumentoPorEmpleado.idDocumento : 0;
    }
    this.arrayEmployeeGlobal.push({employee_id : this.employeeId, documento : titulo, estatus : estadoShow, aprobacion : true, url : urlShow, idDocumento : idDocumentShow})
  }
  
  // =========================
  // SERVICIOS
  // =========================
  getemployeeDocuments(){
    let arrayDocumentos : any;
    let arrayDocumentosFiltrados : any;

    // Empleado registrado
    this._uploadFileService.getemployeeDocumentsAll().subscribe(
      res=> {
        arrayDocumentos = res;

        arrayDocumentosFiltrados = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento == 9001);
        
        this.arrayEmployeeGlobal = [];
        arrayDocumentosFiltrados.forEach(element => {
          
          this.showDocument(arrayDocumentosFiltrados, 9001, 2023, 1, element.descripcion, element.idDocumento)
        });

        this.dataSourceShow = new MatTableDataSource(this.arrayEmployeeGlobal);

      });
  }

  imagenes: any[] = [];
  cargarImagen(event: any, tipoImagen : string, grupoImagen : string, estado : any, categoriaImagen : any) {

    let archivos = event.target.files;
    let nombre = event.target.files.name;
    let arrayToDb : any;
    let year : number = 2022;
    let month : number = 1;

    if (this.txtDescripcion == '' || this.txtDescripcion == undefined){
      this.showMessage(2, 'Error', 'error', 'favor de capturar una descripción', 'Cerrar');
      return;
    }

    for (let i = 0; i < archivos.length; i++) {

      let reader = new FileReader();
      reader.readAsDataURL(archivos[0]);
      reader.onloadend = () => {
        this.imagenes.push(reader.result);

        this._uploadFileService.subirImagenEmpleados(tipoImagen + '_' + event.target.files[0]["name"], reader.result, grupoImagen, this.employeeId, year , month).then(urlImagen => {

          let arrayCategoriaFiltrado = this.tipoImagenFile.filter(e => e.categoria == tipoImagen);
          let idDocumento : number = 0;

          console.log('mes', arrayCategoriaFiltrado);

          arrayToDb = {
                        idDocumento : idDocumento
                        , idProveedor : this.employeeId
                        , idEmpleado : this.employeeId
                        , idFamiliar : 0
                        , categoriaDocumento : arrayCategoriaFiltrado[0]["id"]
                        , urlDocumento : urlImagen.toString()
                        , anno : 2022
                        , mes : 1
                        , estado : 0
                        , descripcion : this.txtDescripcion};

          this._uploadFileService.postUploadDocumentsEmployeeToDb(arrayToDb).subscribe(
            res=> {
              this.getemployeeDocuments();
            },
            error => console.log("error al actualizar proveedores",error)
          );

            this.urlDocumento = urlImagen.toString();
          
          this.showMessage(2, 'Exitoso', 'success', 'Se cargo exitosamente el archivo', 'Cerrar');

        });
      }
    }
  }

  save(form, event){

  }

  deleteDocument(element, event){
    // Elimina Archivos en base de datos
    // this._UploadFileService.postDocumentosEmpleadosAprobarRechazar(arrayToDb).subscribe(
    //   res=> {
    //     console.log('APROBAR DOCUMENTO', res);
    //     this.getemployeeDocuments();
    //     this.showMessage(2, 'Guardardo', 'success', 'Se actualizo el registro correctamente', 'Cerrar');
    //   },
    //   error => console.log("error al aprobar el ocumento",error)
    // )
  }

  view(element, event){
    console.log('elemeto', element)
    window.open(element.url);
  }
  
}
