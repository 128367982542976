import { Component, Inject, OnInit } from '@angular/core';


@Component({
  selector: 'app-catalogs-list',
  templateUrl: './catalogs-list.component.html',
  styleUrls: ['./catalogs-list.component.css']
})
export class CatalogsListComponent implements OnInit {


  ngOnInit(): void {
  }

  

}
