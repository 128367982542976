<div mat-dialog-content-example [formGroup]="newProject">
    
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Código Postal</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="cp" matInput class="form-control form-control-line control"
                        formControlName="cp" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('cp').valid && newProject.get('cp').touched" class="help-block"
                    style="color: red;"> El código postal es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Nombre de la Vialidad</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="vialidad" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="vialidad">
                </mat-form-field>
                <span *ngIf="!newProject.get('vialidad').valid && newProject.get('vialidad').touched"
                    class="help-block" style="color: red;"> La vialidad es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Número exterior</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="numeroExterior" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="numeroExterior">
                </mat-form-field>
                <span *ngIf="!newProject.get('numeroExterior').valid && newProject.get('numeroExterior').touched" class="help-block"
                    style="color: red;"> El número exterior es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Número Interior</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="numeroInterior" matInput class="form-control form-control-line control" formControlName="numeroInterior" disabled>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Nombre de la Colonia</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="colonia" matInput class="form-control form-control-line control" formControlName="colonia" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('colonia').valid && newProject.get('colonia').touched" class="help-block"
                    style="color: red;"> La colonia es requerida</span>
            </div>
            <div fxFlex.gt-sm="90px">
                <div><br>
                </div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Nombre de la Localidad</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="localidad" matInput class="form-control form-control-line control" placeholder="" formControlName="localidad">
                </mat-form-field>
                <span *ngIf="!newProject.get('localidad').valid && newProject.get('localidad').touched" class="help-block"
                    style="color: red;"> La localidad es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Municipio</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="municipio" matInput class="form-control form-control-line control" formControlName="municipio" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('municipio').valid && newProject.get('municipio').touched" class="help-block"
                    style="color: red;"> El municipio es requerido</span>
            </div>
            <div fxFlex.gt-sm="90px">
                <div><br>
                </div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Entidad federativa</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="entidadFederativa" matInput class="form-control form-control-line control" placeholder="" formControlName="entidadFederativa">
                </mat-form-field>
                <span *ngIf="!newProject.get('entidadFederativa').valid && newProject.get('entidadFederativa').touched" class="help-block"
                    style="color: red;"> La entidad federativa es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Licencia</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>
                <ng-container *ngIf="estadoLicencia == undefined"><input type="file" name="fullicencia" id="fullicencia" (change)="cargarImagen($event, 'licencia', 'domicilio', estadoLicencia, 2001)" hidden><label for="fullicencia"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoLicencia == 2"><input type="file" name="fullicencia" id="fullicencia" (change)="cargarImagen($event, 'licencia', 'domicilio', estadoLicencia, 2001)" hidden><label for="fullicencia"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoLicencia == undefined"><button class="button" (click)="openFile(1001)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoLicencia == 0"><button class="button" (click)="openFile(2001)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoLicencia == 1"><button class="button" (click)="openFile(2001)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoLicencia == 2">
                    <button class="button" (click)="openFile(2001)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(2001)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Crédito INFONAVIT</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div><br>
                <ng-container *ngIf="estadoCredito == undefined"><input type="file" name="fucredito" id="fucredito" (change)="cargarImagen($event, 'credito', 'domicilio', estadoCredito, 2002)" hidden><label for="fucredito"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoCredito == 2"><input type="file" name="fucredito" id="fucredito" (change)="cargarImagen($event, 'credito', 'domicilio', estadoCredito, 2002)" hidden><label for="fucredito"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoCredito == undefined"><button class="button" (click)="openFile(2002)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoCredito == 0"><button class="button" (click)="openFile(2002)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoCredito == 1"><button class="button" (click)="openFile(2002)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoCredito == 2">
                    <button class="button" (click)="openFile(2002)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(2002)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Crédito FONACOT</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>
                <ng-container *ngIf="estadoCredito2 == undefined"><input type="file" name="fucredito2" id="fucredito2" (change)="cargarImagen($event, 'credito2', 'domicilio', estadoCredito2, 2003)" hidden><label for="fucredito2"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                    <ng-container *ngIf="estadoCredito2 == 2"><input type="file" name="fucredito2" id="fucredito2" (change)="cargarImagen($event, 'credito2', 'domicilio', estadoCredito2, 2003)" hidden><label for="fucredito2"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                    <ng-container *ngIf="estadoCredito2 == undefined"><button class="button" (click)="openFile(2003)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                    <ng-container *ngIf="estadoCredito2 == 0"><button class="button" (click)="openFile(2003)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                    <ng-container *ngIf="estadoCredito2 == 1"><button class="button" (click)="openFile(2003)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                    <ng-container *ngIf="estadoCredito2 == 2">
                        <button class="button" (click)="openFile(2003)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                        <button class="button" (click)="opencomments(2003)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                    </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="150px">
            <div><br>Comprobante de Domicilio</div>
        </div>
        <div fxFlex.gt-sm="350px">
            <div><br>
                <ng-container *ngIf="estadoComprobanteDom == undefined"><input type="file" name="fucomprobanteDom" id="fucomprobanteDom" (change)="cargarImagen($event, 'comprobanteDom', 'domicilio', estadoComprobanteDom, 2004)" hidden><label for="fucomprobanteDom"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                    <ng-container *ngIf="estadoComprobanteDom == 2"><input type="file" name="fucomprobanteDom" id="fucomprobanteDom" (change)="cargarImagen($event, 'comprobanteDom', 'domicilio', estadoComprobanteDom, 2004)" hidden><label for="fucomprobanteDom"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                    <ng-container *ngIf="estadoComprobanteDom == undefined"><button class="button" (click)="openFile(2004)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                    <ng-container *ngIf="estadoComprobanteDom == 0"><button class="button" (click)="openFile(2004)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                    <ng-container *ngIf="estadoComprobanteDom == 1"><button class="button" (click)="openFile(2004)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                    <ng-container *ngIf="estadoComprobanteDom == 2">
                        <button class="button" (click)="openFile(2004)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                        <button class="button" (click)="opencomments(2004)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                    </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>


<br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="accent" (click)="save(newProject, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <!-- <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div> -->
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 
