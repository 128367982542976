<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card shadow">
                <div class="card-header border-0">
                    <h3 class="mb-0">Proveedores</h3>
                    <div class="center" style="width: 100%;"><ng-container *ngIf="isloading == true"><mat-spinner color="primary"></mat-spinner></ng-container></div>
                    <!-- <h3><p style="color: red; background-color: greenyellow;">Es necesario que se comunique son su proveedor de sistemas</p></h3> -->
                </div>

                <!-- <form> -->

                <h6 class="heading-small text-muted mb-4"></h6>
                <hr class="my-4" />
                <!-- </form> -->
                <div class="card">
                    <table>
                        <tr>
                            <td class="col20"><input type="text" class="form-control form-control-line"
                                    placeholder="BUSCAR" (keyup)="filtrar($event)">
                            </td>
                            <td class="col70"></td>
                            <td class="col10"><button mat-raised-button color="primary"
                                    (click)="descargarExcel()">Descargar</button></td>
                            <td class="col10">
                                <ng-container *ngIf="decodeUserProfile != 5">
                                    <button mat-raised-button color="accent" (click)="newsupplier($event)">Nuevo Proveedor</button>
                                </ng-container>
                                </td>
                            <td class="col10"></td>
                        </tr>
                    </table>
                    <br>
                </div>
                
                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                        <div class="card-body card-body-table">
                            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8"
                                style="width: 95%;">

                                
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>id </th>
                                    <td mat-cell *matCellDef="let element" hidden> {{element.proveedorid}} </td>
                                </ng-container>

                                
                                <ng-container matColumnDef="nombre">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30">Nombre </th>
                                    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                                </ng-container>

                                
                                <ng-container matColumnDef="direccion">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> Dirección</th>
                                    <td mat-cell *matCellDef="let element"> {{element.direccion}} </td>
                                </ng-container>

                                
                                <ng-container matColumnDef="rfc">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> rfc </th>
                                    <td mat-cell *matCellDef="let element"> {{element.rfc}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="estatus">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"><ng-container *ngIf="decodeUserProfile == 4 || decodeUserProfile == 1"> Estatus </ng-container></th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container  *ngIf="decodeUserProfile == 4 || decodeUserProfile == 1">
                                            <ng-container *ngIf="element.estatus == true"><p style="color: green;">ACTIVO</p></ng-container>
                                            <ng-container *ngIf="element.estatus == false"><p style="color: red;">INACTIVO</p></ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="estatusVencimiento">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Vencimiento </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.estatusVencimiento == 'EN TIEMPO'"><p style="color: green;">{{element.estatusVencimiento}}</p></ng-container>
                                        <ng-container *ngIf="element.estatusVencimiento == 'VENCIDO'"><p style="color: red;">{{element.estatusVencimiento}}</p></ng-container>
                                        <ng-container *ngIf="element.estatusVencimiento == 'NO APLICA'"><p style="color: rgb(131, 124, 124);">{{element.estatusVencimiento}}</p></ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="autorizado">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Aut / NoAut </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.autorizado == 1"><p style="color: green;">AUTORIZADO</p></ng-container>
                                        <ng-container *ngIf="element.autorizado == 0"><p style="color: red;">NO AUTORIZADO</p></ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="servicioEspecializado">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Serv Esp </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.servicioEspecializado == 1"><p style="color: green;"><i class="fa fa-check fa-lg icon-cog-blue"></i></p></ng-container>
                                        <ng-container *ngIf="element.servicioEspecializado == 0"><p style="color: red;"><i class="fa fa-ban fa-lg icon-cog-cancel"></i></p></ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="servicioGeneral">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Serv Gen </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.servicioGeneral == 1"><p style="color: green;"><i class="fa fa-check fa-lg icon-cog-blue"></i></p></ng-container>
                                        <ng-container *ngIf="element.servicioGeneral == 0"><p style="color: red;"><i class="fa fa-ban fa-lg icon-cog-cancel"></i></p></ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="productoDeMateriales">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Prod Mat </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.productoDeMateriales == 1"><p style="color: green;"><i class="fa fa-check fa-lg icon-cog-blue"></i></p></ng-container>
                                        <ng-container *ngIf="element.productoDeMateriales == 0"><p style="color: red;"><i class="fa fa-ban fa-lg icon-cog-cancel"></i></p></ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="repse">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> REPSE </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.repse == 1"><p style="color: green;">SI</p></ng-container>
                                        <ng-container *ngIf="element.repse == 0"><p style="color: red;">NO</p></ng-container>
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons">
                                        <ng-container *ngIf="decodeUserProfile != 5">Editar</ng-container> 
                                        <ng-container *ngIf="decodeUserProfile == 5">Ver</ng-container> 
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="decodeUserProfile != 5"> <button mat-button color="primary" (click)="edit(element, $event)"><i class="fa fa-pencil fa-lg icon-cog-edit"></i></button> </ng-container>
                                        <ng-container *ngIf="decodeUserProfile == 5"> <button mat-button color="primary" (click)="edit(element, $event)"><i class="fa fa-eye fa-lg icon-cog-edit"></i></button> </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="users">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"><ng-container *ngIf="decodeUserProfile != 5">Usuario </ng-container>  </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <ng-container *ngIf="decodeUserProfile != 5"><button mat-button color="primary" (click)="users(element, $event)"><i class="fa fa-user fa-lg icon-cog-user"></i></button> </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="activeInactive">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"><ng-container *ngIf="decodeUserProfile != 5 && (decodeUserProfile == 4 || decodeUserProfile == 1)"><ng-container *ngIf="decodeUserProfile != 5 && (decodeUserProfile == 4 || decodeUserProfile == 1)"> Activo/Inactivo </ng-container> </ng-container>  </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <ng-container *ngIf="decodeUserProfile != 5 && (decodeUserProfile == 4 || decodeUserProfile == 1)">
                                            <ng-container *ngIf="element.estatus == 1"><button mat-button color="primary" (click)="inactive(element, $event)"><i class="fa fa-check fa-lg icon-cog-check"></i></button></ng-container>
                                            <ng-container *ngIf="element.estatus == 0"><button mat-button color="primary" (click)="active(element, $event)"><i class="fa fa-ban fa-lg icon-cog-cancel"></i></button></ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="aprove">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"><ng-container *ngIf="decodeUserProfile != 5">Autorizar/Denegar </ng-container> </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <ng-container *ngIf="decodeUserProfile != 5">
                                            <ng-container *ngIf="element.autorizado == 1"><button mat-button color="primary" (click)="deny(element, $event)"><i class="fa fa-play fa-lg icon-cog-play"></i></button></ng-container>
                                            <ng-container *ngIf="element.autorizado == 0"><button mat-button color="primary" (click)="aprove(element, $event)"><i class="fa fa-pause fa-lg icon-cog-pause"></i></button></ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </mat-table>

                        </div>
                        <!-- <mat-paginator [pageSizeOptions]="[20, 100, 150]" [showFirstLastButtons]="true"
                            [pageSize]="pageSize" [length]="totalSize" (page)="handlePage($event)" style="width: 95%;">
                        </mat-paginator> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>