import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { countryModel } from 'src/app/models/country.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})


export class countryservice {

    constructor(protected http: HttpClient) { }
  
      getcountryAll() {
          const headers : HttpHeaders = new HttpHeaders()
              .set('Content-Type', 'application/json')
            //   .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
              
          return this.http.get<countryModel[]>(environment.urlapi + environment.apiContextDrivers + 'Paise', {headers});
      }
  
  }