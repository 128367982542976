<div fxLayout="row">
    <h4>Reporte de catálogos</h4>
</div>
<br>
<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Paises</span>
        </ng-template>
        <br><span class="tabsEncabezado">Paises</span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-country-report></app-country-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
        
    </mat-tab>
    
    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Estados</span>
        </ng-template>
        <br><span class="tabsEncabezado">Estados</span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-state-report></app-state-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Partes</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Partes </span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-part-report></app-part-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Fraccion Arancelaria</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Fraccion Arancelaria </span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-frac-aran-report></app-frac-aran-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Unidad de Medida</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Unidad de Medida </span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-uom-report></app-uom-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Proveedores</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Proveedores </span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-supplier-report></app-supplier-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Clientes</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Clientes </span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-part-report></app-part-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Agente Aduanal</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Agente Aduanal </span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-part-report></app-part-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label>
            <span class="tabsEncabezado">Compañías</span>
        </ng-template> 
        <br><span class="tabsEncabezado">Compañías </span><br>
        <table style="width: 100%;">
            <tr>
                <td style="width: 20%;"></td>
                <td style="width: 60%;"><app-part-report></app-part-report></td>
                <td style="width: 20%;"></td>
            </tr>
        </table>
    </mat-tab>
</mat-tab-group>