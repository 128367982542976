import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { supplierModel } from 'src/app/models/supplier.model';
import { environment } from 'src/environments/environment';
import { supplierActivityModel } from '../models/supplier-activity.model';
import { documentModel } from '../models/document.model';
    
@Injectable({
    providedIn: 'root'
})

export class supplyservice {

  constructor(protected http: HttpClient) { }

    getsupplyAll() {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<supplierModel[]>(environment.urlapi + environment.apiContextDrivers + 'Provider/lista', {headers});
    }

    getsupplyById(id : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<supplierModel[]>(environment.urlapi + environment.apiContextDrivers + 'Provider/' + id, {headers});
    }

    insertsupply(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.post(environment.urlapi+environment.apiContextDrivers+'Provider/registrar',arrayToDb, {headers});
    }

    updatesupply(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Provider/actualizar', arrayToDb, {headers});
    }

    putsupplyStatus(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Provider/activoinactivo', arrayToDb, {headers});
    }

    postDocument(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Document/subir', arrayToDb, {headers});
    }

    getsupplyActivityByYear(supplier_id: any, year : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<supplierActivityModel[]>(environment.urlapi + environment.apiContextDrivers + 'Provider/actividad/' + supplier_id + '/' + year, {headers});
    }

    insertsupplyActivity(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.post(environment.urlapi+environment.apiContextDrivers+'Provider/registrar/actividad',arrayToDb, {headers});
    }

    updatesupplyActivity(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Provider/actualizar/actividad', arrayToDb, {headers});
    }

    getsupplyOutOfDate(proveedor_Id:number, year:number, month: number) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<supplierModel[]>(environment.urlapi + environment.apiContextDrivers + 'Report/providers/' + proveedor_Id + '/' + year + '/' + month, {headers});
    }

    getreadPDF(urldocument: any) {
        // urldocument = 'https://gsctechstorageaccount.blob.core.windows.net/cccnetwork/Test/Constancia%20de%20SituacionFiscal%20-%20SAT.pdf%3Fsp%3Dr%26st%3D2024-07-03T20%3A32%3A35Z%26se%3D2030-07-04T04%3A32%3A35Z%26spr%3Dhttps%26sv%3D2022-11-02%26sr%3Dc%26sig%3DcCduvjjXPgeWi93Jz06BPx9iHNi0pm%252FAh68IpuS%252FFew%253D'
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<documentModel[]>(environment.urlapi + environment.apiContextDrivers + 'Document/readPDF?urlDocument=' + urldocument, {headers});
    }
}