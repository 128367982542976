<div mat-dialog-content-example [formGroup]="newProject">
    
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>¿Ha sido Afianzado?</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>
                <mat-radio-group aria-label="Select an option" formControlName="afianzado">
                    <mat-radio-button value="1">Sí</mat-radio-button>&nbsp;
                    <mat-radio-button value="0">No</mat-radio-button>
                  </mat-radio-group>
                  <ng-container><input [type]="nombreCia" matInput class="form-control form-control-line control" formControlName="nombreCia"></ng-container>
                <span *ngIf="!newProject.get('afianzado').valid && newProject.get('afianzado').touched" class="help-block" style="color: red;"> campo requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>¿Podría Viajar?</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div><br>
                <mat-radio-group aria-label="Select an option" formControlName="puedeViajar">
                    <mat-radio-button value="1">Sí</mat-radio-button>&nbsp;
                    <mat-radio-button value="0">No</mat-radio-button>
                  </mat-radio-group>
                <span *ngIf="!newProject.get('puedeViajar').valid && newProject.get('puedeViajar').touched" class="help-block" style="color: red;"> campo requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>¿Estaría Dispuesto a Cambiar de Residencia?</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>
                <mat-radio-group aria-label="Select an option" formControlName="cambioResidencia">
                    <mat-radio-button value="1">Sí</mat-radio-button>&nbsp;
                    <mat-radio-button value="0">No</mat-radio-button>
                  </mat-radio-group>
                <span *ngIf="!newProject.get('cambioResidencia').valid && newProject.get('cambioResidencia').touched" class="help-block" style="color: red;"> campo requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>¿Posee Automóvil Propio?</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div><br>
                <mat-radio-group aria-label="Select an option" formControlName="automovil">
                    <mat-radio-button value="1">Sí</mat-radio-button>&nbsp;
                    <mat-radio-button value="0">No</mat-radio-button>
                  </mat-radio-group>
                <span *ngIf="!newProject.get('automovil').valid && newProject.get('automovil').touched" class="help-block" style="color: red;"> campo requerido</span>
            </div>
            <div fxFlex.gt-sm="90px">
                <div><br>
                </div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Observaciones</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="observaciones" matInput class="form-control form-control-line control" formControlName="observaciones">
                </mat-form-field>
            </div>
            <div fxFlex.gt-sm="90px">
                <div><br>
                </div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

<br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="accent" (click)="save(newProject, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 
