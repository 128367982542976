<div fxLayout="row">
  <h4>Nuevo Proyecto</h4>
</div>
<br>
<div mat-dialog-content-example [formGroup]="newProject">
  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>Código</div>
    </div>
    <div fxFlex.gt-sm="10%" >
        <div>
          <mat-form-field>
          <input [type]="codigo_proyecto" matInput class="form-control form-control-line control" formControlName="codigo_proyecto" disabled >
        </mat-form-field>
        <span *ngIf="!newProject.get('codigo_proyecto').valid && newProject.get('codigo_proyecto').touched" class="help-block" style="color: red;"> Capture un código</span>
        </div>
    </div>
    <div fxFlex.gt-sm="25%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><br>Nombre</div>
    </div>
    <div fxFlex.gt-sm="25%" >
      <div>
        <mat-form-field>
          <input [type]="nombre_proyecto" matInput class="form-control form-control-line control" placeholder="" formControlName="nombre_proyecto" >
        </mat-form-field>
        <span *ngIf="!newProject.get('nombre_proyecto').valid && newProject.get('nombre_proyecto').touched" class="help-block" style="color: red;"> Debe capturar un nombre</span>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>Cliente</div>
    </div>
    <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <mat-select class="form-control control" formControlName="cliente" (selectionChange)="selectcustommer($event)">
              <mat-option *ngFor="let op of datasourceCustomers" [value]="op.clienteid">
                {{op.nombre}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span *ngIf="!newProject.get('cliente').valid && newProject.get('cliente').touched" class="help-block" style="color: red;"> Seleccione un cliente</span>
        </div>
        <button mat-raised-button color="primary" (click)="addClient(newProject, $event)"><i class="fa fa-plus icon-cog-wite"></i></button>
        <button mat-raised-button color="accent" (click)="reloadClient(newProject, $event)"><i class="fa fa-repeat fa-lg icon-cog-wite"></i></button>
    </div>
    <div fxFlex.gt-sm="10%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><br>Presupuesto</div>
    </div>
    <div fxFlex.gt-sm="25%" >
      <div>
        <mat-form-field>
          <pre>{{presupuestoFormatoLable | currency}}</pre>
          <input [type]="presupuesto_proyecto" id="presupuesto_proyecto" matInput class="form-control form-control-line control" formControlName="presupuesto_proyecto" step="any" onclick="this.value=null" (change)="onChange($event)" min="0" max="999999999" step="0.01" value="{{presupuestoFormato | currency}}" > 
        </mat-form-field>
        <span *ngIf="!newProject.get('presupuesto_proyecto').valid && newProject.get('presupuesto_proyecto').touched" class="help-block" style="color: red;"> capture  un presupuesto</span>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>Fecha Inicial</div>
    </div>
    <div fxFlex.gt-sm="25%" >
        <div><mat-form-field appearance="fill">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="fecha_inicial_proyecto" (dateChange)="fechaInicial($event)" class="control">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <span *ngIf="!newProject.get('fecha_inicial_proyecto').valid && newProject.get('fecha_inicial_proyecto').touched" class="help-block" style="color: red;"> Seleccione una fecha inicial</span>
      </div>
    </div>
    <div fxFlex.gt-sm="10%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><br>Fecha Final</div>
    </div>
    <div fxFlex.gt-sm="25%" >
      <div><mat-form-field appearance="fill">
        <mat-label>Choose a date</mat-label>
        <input matInput [matDatepicker]="pickerf" formControlName="fecha_final_proyecto" class="control">
        <mat-datepicker-toggle matSuffix [for]="pickerf"></mat-datepicker-toggle>
        <mat-datepicker #pickerf></mat-datepicker>
      </mat-form-field>
      <span *ngIf="!newProject.get('fecha_final_proyecto').valid && newProject.get('fecha_final_proyecto').touched" class="help-block" style="color: red;"> Seleccione una fecha final</span>
    </div>
    </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>Responsable</div>
    </div>
    <div fxFlex.gt-sm="25%">
        <div>
          <mat-form-field>
            <input [type]="responsable_proyecto" matInput class="form-control form-control-line control" placeholder="Responsable" formControlName="responsable_proyecto" >
          </mat-form-field>
          <span *ngIf="!newProject.get('responsable_proyecto').valid && newProject.get('responsable_proyecto').touched" class="help-block" style="color: red;"> Capture un responsable</span>
        </div>
    </div>
    <div fxFlex.gt-sm="10%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><br>FO</div>
    </div>
    <div fxFlex.gt-sm="25%">
      <div>
        <mat-form-field>
          <input [type]="centro_de_costo_proyecto" matInput class="form-control form-control-line control" placeholder="FO" formControlName="centroDeCostos" (blur)="onBlurFO($event)">
        </mat-form-field>
        <span *ngIf="!newProject.get('centroDeCostos').valid && newProject.get('centroDeCostos').touched" class="help-block" style="color: red;"> Capture un centro de costos</span>
      </div>
    </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>Almacén</div>
    </div>
    <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <mat-select class="form-control"  formControlName="almacen">
              <mat-option value= "0" selected="selected">SELECCIONA</mat-option>
              <mat-option value= "1">California</mat-option>
              <mat-option value= "2">Norte</mat-option>
              <mat-option value= "3">Centro</mat-option>
            </mat-select>
          </mat-form-field>
          <span *ngIf="!newProject.get('almacen').valid && newProject.get('almacen').touched" class="help-block" style="color: red;"> Asigne un almacén</span>
        </div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div></div>
    </div>
  </div>
  
  <!-- DATOS DE ENVIO -->
  <!-- ENVIAR A -->
  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>Nombre Destino</div>
    </div>
    <div fxFlex.gt-sm="25%" >
      <div>
        <mat-form-field>
          <input [type]="enviaANombre" matInput class="form-control form-control-line control" formControlName="enviaANombre" placeholder="Nombre Destino">
        </mat-form-field>
      </div>
    </div>
    <div fxFlex.gt-sm="10%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div>
        <br>Dirección destino
      </div>
    </div>
    <div fxFlex.gt-sm="25%" >
          <div>
          <mat-form-field>
            <input [type]="enviaADireccion" matInput class="form-control form-control-line control" formControlName="enviaADireccion" placeholder="Dirección Destino">
          </mat-form-field>
          </div>
    </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>Ciudad destino</div>
    </div>
    <div fxFlex.gt-sm="25%" >
      <div>
        <mat-form-field>
          <input [type]="enviaACd" matInput class="form-control form-control-line control" formControlName="enviaACd" placeholder="Ciudad destino">
        </mat-form-field>
      </div>
    </div>
    <div fxFlex.gt-sm="10%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div>
        <br>Estado destino
      </div>
    </div>
    <div fxFlex.gt-sm="25%" >
          <div>
          <mat-form-field>
            <input [type]="enviaAEstado" matInput class="form-control form-control-line control" formControlName="enviaAEstado" placeholder="Estado destino">
          </mat-form-field>
          </div>
    </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>CP destino</div>
    </div>
    <div fxFlex.gt-sm="25%" >
      <div>
        <mat-form-field>
          <input [type]="destinoCP" matInput class="form-control form-control-line control" formControlName="destinoCP" placeholder="cp destino">
        </mat-form-field>
      </div>
    </div>
    <div fxFlex.gt-sm="10%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><br>Teléfono destino
      </div>
    </div>
    <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <input [type]="enviaATelefono" matInput class="form-control form-control-line control" formControlName="enviaATelefono" placeholder="Teléfono">
            </mat-form-field>
          </div>
    </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div><br>Requisitor destino</div>
    </div>
    <div fxFlex.gt-sm="25%" >
      <div>
        <mat-form-field>
          <input [type]="enviaARequisitor" matInput class="form-control form-control-line control" formControlName="enviaARequisitor" placeholder="Requisitor">
      </mat-form-field>
      </div>
    </div>
    <div fxFlex.gt-sm="10%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><br>
      </div>
    </div>
    <div fxFlex.gt-sm="25%" >
          <div>
          </div>
    </div>
  </div>

</div>
  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><button mat-raised-button color="accent" (click)="save(newProject, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><button mat-raised-button class="btn-bg-red" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
    </div>
  </div>


  