import { ElementRef, EventEmitter, Injectable, Output, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { poModel } from 'src/app/models/po.model';
import jsPDF from 'jspdf';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { purchaseOrderservice } from '../../services/PurchaseOrder.service';
import { codigoDeCostosModel } from 'src/app/models/codigo-de-costos.model';
import { codigoDeCostosService } from 'src/app/services/requisition/codigo-de-costos.service';
import { ReturnStatement } from '@angular/compiler';
import { supplyservice } from '../../services/supplier.service';
import { numeroALetra } from '../numero-a-letra/numero-a-letra';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

@Injectable({
    providedIn: 'root'
})

export class poPdfPrints {

    @ViewChild('pdfTable') pdfTable: ElementRef;
    @Output() filterChange = new EventEmitter();

    firmaRequisicion : any;
    firmaAutorizacion : any;
    firmaCompras : any;
    requisitionDetailDataSource : any;
    CDCDescripcion: string;
    datasourceCodigoDeCostos : MatTableDataSource<codigoDeCostosModel>;
    logoDataUrl : string;
    logoDataCompras : string;
    logoDataControlProy : string;
    logoDataBlanco : string;
    logoDataBlancoODC : string;
    logoCCC: any;
    logoDataGerencia: any;
    decodedSign : any;
    datasourcesupplier : any;

    constructor(protected http: HttpClient
        , private _purchaseOrderService : purchaseOrderservice
        , private _codigoDeCostosService : codigoDeCostosService
        , private _supplyservice : supplyservice
        , private _numeroALetra : numeroALetra
      ) { }

      ngAfterViewInit() {
        console.log("afterinit");
        setTimeout(() => {
          console.log(this.pdfTable.nativeElement.innerText);
    
        //   this.pdfRequisitionPrint();
    
        }, 1000);
      }

    // =======================
    // Ordenes de compra
    // =======================

    pdfPurchaseOrderPrintOnInit(element : MatTableDataSource<poModel>){
        console.log('elementos', element["usuarioGerenciaAutNombre"]);
        switch(element["usuarioCrea"]){
          case('pablo'):  this.decodedSign = this.decodedSign + 'c5a8f192-5cb8-4025-8d30-31918abfa5be';
                          this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaPablo.PNG').then(
                            result => this.logoDataUrl = result
                          )
            break;
          case('alejandro_fuentes'): this.decodedSign = this.decodedSign + '36189034-32e5-4e28-b44c-43dec58e9999' 
                          this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaAlejandro.PNG').then(
                            result => this.logoDataUrl = result
                          )
            break;
          case('bernardo_tamez'): this.decodedSign = this.decodedSign + '611d133a-d14a-45ab-a26a-f6e0dd570636' 
                          this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBernardo.PNG').then(
                            result => this.logoDataUrl = result
                          )        
            break;
          case('fernando_chavez'): this.decodedSign = this.decodedSign + 'be146605-1624-48e9-b646-cf9dbfd4f7a8' 
                          this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaFernando.PNG').then(
                            result => this.logoDataUrl = result
                          )   
            break;
          // case('lauro_araujo'): this.decodedSign = this.decodedSign + '79a31698-9239-4e8c-ba29-90b07692136a' // lauro_araujo
          //                 this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaLauroAraujo.png').then(
          //                   result => this.logoDataCompras = result
          //                 )   
          //   break;
          // case('marisela_jaime'): this.decodedSign = this.decodedSign + '5f8925a7-bd65-40cc-a11d-b61dad2a9fad' 
          //                 this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaMariselaJaime.png').then(
          //                   result => this.logoDataCompras = result
          //                 )   
          // break;
          default: this.decodedSign = this.decodedSign + 'c5a8f192-5cb8-4025-8d30-31918abfa5be';
                        this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlanco.PNG').then(
                          result => this.logoDataUrl = result
                        )
            break;
        }

        switch(element["usuarioGerenciaAutNombre"]){
          case("bernardo_tamez"):
            this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaAutorizacion_bernardo_tamez.jpg' ).then(
              result => this.logoDataGerencia = result
            )
            break;
          case("fernando_chavez"):
            this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaFernando.jpg' ).then(
              result => this.logoDataGerencia = result
            )
            break;
          case("carlos_palos"):
            this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaAutorizacion_carlos_palos.jpg' ).then(
              result => this.logoDataGerencia = result
            )
            break;
          case("alberto_ramirez"):
            this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaAlbertoRamirez.png' ).then(
              result => this.logoDataGerencia = result
            )
            break;
          default:
            this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaAlbertoRamirez.png' ).then(
              result => this.logoDataGerencia = result
            )
            break; 
        }

        this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlanco.PNG').then(
          result => this.logoDataUrl = result
        )
    
        console.log('usuario crea', element["nombre_usuario"])
        switch(element["nombre_usuario"]){
          case('pablo'): this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaPablo.PNG').then(
                            result => this.logoDataCompras = result
                          )
            break;
          case('alejandro_fuentes'): this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaAlejandro.PNG').then(
                            result => this.logoDataCompras = result
                          )
            break;
          case('bernardo_tamez'): this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBernardo.PNG').then(
                            result => this.logoDataCompras = result
                          )        
            break;
          case('fernando_chavez'): this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaFernando.PNG').then(
                            result => this.logoDataCompras = result
                          )   
            break;
          case('lauro_araujo'): this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaLauroAraujo.png').then(
                            result => this.logoDataCompras = result
                          )   
            break;
          case('marisela_jaime'): this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaMariselaJaime.png').then(
                            result => this.logoDataCompras = result
                          )   
          break;
          case('roberto_guzman'): this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaRoberto.PNG').then(
                            result => this.logoDataCompras = result
                          )   
          break;
          default: this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlanco.PNG').then(
                          result => this.logoDataCompras = result
                        )
            break;
        }

        // this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaRoberto.PNG').then(
        //   result => this.logoDataCompras = result
        // )
    
        this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/imgFirmaEnBlanco.jpg').then(
          result => this.logoDataControlProy = result
        )
    
        this.getImageDataUrlFromLocalPath1('../../../assets/images/background/logoCCC.jpg').then(
          result => this.logoCCC = result
        )
    
        this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/imgBlanco.png' ).then(
          result => this.logoDataBlanco = result
        )
    
        this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlancoOrdenesDeCompra.png' ).then(
          result => this.logoDataBlancoODC = result
        )

        this.getImageDataUrlFromLocalPath1('../../../assets/images/background/logoCCC.jpg').then(
          result => this.logoCCC = result
        )
    
        this.getsupplierAll(element);

        console.log('* * * DATOS de la tabla * * *', element);
    }

    // =======================
    // SERVICIOS
    // =======================

    datsourcePoDetail : any;

    getPO_Det(element){
        this._purchaseOrderService.getPODetail(element["ordendecompra_id"]).subscribe(
        res=> {
            console.log('ORDEN DE COMPRA DETALLE', res);

            // Ordena los PO DETAIL
            res.sort(function (a, b) {
            if (a.linea > b.linea) {
                return 1;
            }
            if (a.linea < b.linea) {
                return -1;
            }
            // a must be equal to b
            return 0;
            });

            this.datsourcePoDetail = res;
            this.downloadAsPDF(element);
    
        },
        error => console.log("error consulta proyectos",error)
        )
    }

    getsupplierAll(element){
      // Selecciona todos los proveedores
      this._supplyservice.getsupplyAll().subscribe(
        res=> {
          this.datasourcesupplier = res;
          this.getPO_Det(element);
        },
        error => console.log("error consulta categorias",error)
      )
    }

    // ====================
    // GENERA REPORTE PDF
    // ====================

  getImageDataUrlFromLocalPath1(localPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement('canvas');
        let img = new Image();
        img.onload = () => {
            canvas.height = img.height;
            canvas.width = img.width;
            canvas.getContext("2d").drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png'));
        }
        img.onerror = () => reject('Imagen no disponible')
        img.src = localPath;
    })

  }

  public async downloadAsPDF(element) {


    console.log('element', element);

    let subtotalPDF : number = Number(element.sub_total);
    let ivaPDF : number = Number(element.iva_moneda);
    let totalPDF : number = Number(Number(element.sub_total) + Number(element.iva_moneda));
    let isrRetencion125PDF : number = Number(element.isrRetencion125);
    let totalPDFLetra : string = (element.tipo_moneda == 'MXN') ? this._numeroALetra.numeroALetras(totalPDF, 'PESOS MEXICANOS') : this._numeroALetra.numeroALetras(totalPDF, 'DOLARES AMERICANOS');
    let ciudadEstado : string;
    let terminosYCondiciones : string = element.terminos_condiciones
    let descuentoPDF : number = Number(element.descuento_global);
    let retencionMonto : number = Number(element.retencion_monto);
    let fechaODC : any = element.ordendecompra_fecha;
    let retencionIVA2 : any = element.retencionIVA2;
    let retencionIVA3 : any = element.retencionIVA3;

    // ivaPDF = (retencionMonto == 0) ? ivaPDF : ivaPDF - retencionMonto;
    totalPDF = (retencionMonto == 0) ? Number(Number(element.sub_total) + Number(element.iva_moneda)) : Number(Number(element.sub_total) + Number(ivaPDF) - Number(retencionMonto));
    totalPDF = (isrRetencion125PDF == 0) ? Number(totalPDF) : Number(Number(totalPDF) - Number(isrRetencion125PDF));
    totalPDF = totalPDF - Number(element.retencionIVA2) - Number(element.retencionIVA3);
    
    const doc = new jsPDF();

    // Buscar de donde obtener el proveedor
    let arrayProveedor = this.datasourcesupplier.filter(e => e.proveedorid == element.proveedor_id) 

    var headers = {
      0:{
          col_1:{ text: 'Cantidad', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 20 },
          col_2:{ text: 'Unidad', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 20 },
          col_3:{ text: 'Descripción', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 40 },
          col_4:{ text: 'Precio Unitario', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 10  },
          col_5:{ text: 'Importe', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 10  },
          // col_6:{ text: 'SKU', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 20 }
      },
      1:{
          col_1:{ text: 'Header 1', style: 'tableHeader', alignment: 'center' },
          col_2:{ text: 'Header 2', style: 'tableHeader', alignment: 'center' }, 
          col_3:{ text: 'Header 3', style: 'tableHeader', alignment: 'center' },
          col_4:{ text: 'Citación', style: 'tableHeader', alignment: 'center' },
          col_5:{ text: 'Cumplimiento', style: 'tableHeader', alignment: 'center'},
          col_6:{ text: 'Cumplimiento', style: 'tableHeader', alignment: 'center'}
      }
    }

    let bodyx = [];
    var body = [];
    for (var key in headers){
        if (headers.hasOwnProperty(key)){
            var header = headers[key];
            var row = new Array();
            row.push( header.col_1 );
            row.push( header.col_2 );
            row.push( header.col_3 );
            row.push( header.col_4 );
            row.push( header.col_5 );
            // row.push( header.col_6 );
            body.push(row);
        }
    }
    
    if(retencionIVA2 != 0){
      this.datsourcePoDetail[0]["descripcion"] = this.datsourcePoDetail[0]["descripcion"] + '\nClave Prod. Serv. - 80121603 Derecho societario'
      + '\nImpuestos:'
              + '\nTraslados:'
              + '\n 002 IVA Base - ' + subtotalPDF + ' Tasa - 0.160000 Importe - $ ' + ivaPDF
              + '\nRetenciones:'
              + '\n 001 ISR Base - ' + subtotalPDF + ' Tasa - 0.100000 Importe - $ ' + retencionIVA2
              + '\n 002 IVA Base - ' + subtotalPDF + ' Tasa - 0.106700 Importe - $ ' + retencionIVA3
    }

    for (var key in this.datsourcePoDetail) {
        if (this.datsourcePoDetail.hasOwnProperty(key))
        {
            var data = this.datsourcePoDetail[key];
            var row = new Array();
            row.push( data.cantidad.toString() );
            row.push( data.unidad_medida.toString()  );
            row.push( data.descripcion.toString() + ' ' + data.medida.toString() + ' ' + data.color.toString() );
            row.push( formatter.format(data.precio_unitario).toString()  );
            row.push( formatter.format((data.precio_unitario * data.cantidad)).toString() );
            body.push(row);
            bodyx.push(row);            
        }
    }
    
    ciudadEstado = (element.destino_ciudad.length > 0 && element.destino_estado.length > 0) ? element.destino_ciudad + ' ' + element.destino_estado : ''

    const documentDefinition = {
      content: [
        {
          columns: [
            { 
              // text: '', fontSize:8, width: 20
              // image: this.getBase64ImageFromURL('https://firebasestorage.googleapis.com/v0/b/sap-comercial.appspot.com/o/firmas%2FFirmaPablo.PNG?alt=media&token=c5a8f192-5cb8-4025-8d30-31918abfa5be'),
              image: this.logoCCC,
              width: 100,
              height: 50,
            },
            {
              text: '', fontSize:8, width: 20
            },
            {
              text: 'COMMERCIAL CONTRACTING DE MEXICO, S DE RL DE CV      Carretera a Saltillo-Monterrey Km. 18                                       Parque Industrial Santa María 25903 Ramos Arízpe, Coahuila Telefono: (844) 866 9030 RFC: CCM-950330-P1A', fontSize:8, width: 230
            },
            {
              text: '', fontSize:8, width: 20
            },
            {
              text: ' ', fontSize:8 // ORDEN DE COMPRA No. 1791-SUB232
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Proveedor:', fontSize:8, bold:true
            },
            {
              text: '', fontSize:8, width: 20
            },
            {
              text: '', fontSize:8
            },
            {
              text: '', fontSize:8, width: 20
            },
            {
              text: 'Enviar a:', fontSize:8, bold:true
            }
          ]
        },
        {
          columns: [
            {
              text: 'Nombre: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (arrayProveedor.length > 0) ? arrayProveedor[0]["nombre"] : 'NA', fontSize:8, width: '*'
            },
            {
              text: 'Nombre: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.destino_nombre.length > 0) ? element.destino_nombre : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Dirección: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (arrayProveedor.length > 0) ? arrayProveedor[0]["direccion"] : 'NA', fontSize:8, width: '*'
            },
            {
              text: 'Dirección: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.destino_direccion.length > 0) ? element.destino_direccion : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'R.F.C. ', fontSize:8, bold:true, width: 90
            },
            {
              text: (arrayProveedor.length > 0) ? arrayProveedor[0]["rfc"] : 'NA', fontSize:8, width: '*'
            },
            {
              text: 'Cd./Edo: ', fontSize:8, bold:true, width: 90
            },
            {
              text: ciudadEstado, fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Cd./Edo: ', fontSize:8, bold:true, width: 90
            },
            {
              text: ((arrayProveedor.length > 0) ? arrayProveedor[0]["ciudad"] : 'NA') + ', ' + ((arrayProveedor.length > 0) ? arrayProveedor[0]["estado"] : ''), fontSize:8, width: '*'
            },
            {
              text: 'Requisitor: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.reqIntNombreSolicitante.length > 0) ? element.reqIntNombreSolicitante : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 90
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: 'Autoriza: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.destino_requisitor.length > 0) ? element.destino_requisitor : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Contacto ', fontSize:8, bold:true, width: 90
            },
            {
              text: (arrayProveedor.length > 0) ? arrayProveedor[0]["contacto"] : 'NA', fontSize:8, width: '*'
            },
            {
              text: 'Teléfono: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.destino_telefono.toString().length > 0 && element.destino_telefono.toString() != '0') ? element.destino_telefono : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true, width: 90
            },
            {
              text: '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Orden de compra ', fontSize:8, bold:true, width: 90
            },
            {
              text: element.ordendecompra_codigo, fontSize:8, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Cotización ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.cotizacion_codigo.length > 0) ? element.cotizacion_codigo : '', fontSize:8, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Requisición ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.reqIntCodigo.length > 0) ? element.reqIntCodigo : '', fontSize:8, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'OC ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.reqIntNoODC.length > 0) ? element.reqIntNoODC : '', fontSize:8, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'FO ', fontSize:8, bold:true, width: 90
            },
            {
              text: element.fo, fontSize:8, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Proyecto ', fontSize:8, bold:true, width: 90
            },
            {
              text: element.ordendecompra_codigo.substring(0, element.ordendecompra_codigo.indexOf('-')), fontSize:8, width: '*'
            }
          ]
        },
        {
          style: 'tablebody',
          table: {
              widths: [ '12%', '10%', '48%', '15%', '15%'],
              heights: [10, 10, 10, 10, 10],
              body: [ 
                [{text: 'FECHA ODC', style: 'tableHeader', alignment: 'left', fontSize:8, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}
                  , {text: 'TERMINOS Y CONDICIONES', style: 'tableHeader', colSpan: 4, alignment: 'left', fontSize:8, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}, {}, {}, {}
                ],
                [{text: moment(fechaODC, 'YYYY-MM-DD').format('DD/MMM/YYYY'), style: 'tableHeader', alignment: 'left', fontSize:8, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}
                  , {text: terminosYCondiciones, style: 'tableHeader', colSpan: 4, alignment: 'left', fontSize:8, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}, {}, {}, {}
                ],
              ]
            },
        },
        {
          columns: [
                {
                  text: ' ', fontSize:8, bold:true, width: 90
                }
              ]
        },
        {
          columns: [
                {
                  text: ' ', fontSize:8, bold:true, width: 90
                }
              ]
        },
        {
          fontSize:8, width:'100%',
          table: {
            widths: [ '12%', '10%', '48%', '15%', '15%' ],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: body
          }
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: totalPDFLetra, fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*' // CIEN MIL PESOS 00/100 MN
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Subtotal', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(subtotalPDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Descuento', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(descuentoPDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'IVA', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(ivaPDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Retención', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(retencionMonto) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 300
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Retención 1.25%', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(isrRetencion125PDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 300
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Retención IVA', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(retencionIVA2) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 300
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Retención ISR', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(retencionIVA3) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Total', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(totalPDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, width: 20
            },
            { 
              image: this.logoDataBlanco,
              width: 50,
              height: 50,
            },
            {
              text: ' ', fontSize:8, width: 20
            },
            { 
              image: this.logoDataCompras,
              width: 100,
              height: 50,
            },
            {
              text: '', fontSize:8, width: 20
            },
            { 
              image: this.logoDataControlProy,
              width: 100,
              height: 50,
            },
            {
              text: '', fontSize:8, width: 20
            },
            { 
              // image: this.getBase64ImageFromURL('https://firebasestorage.googleapis.com/v0/b/sap-comercial.appspot.com/o/firmas%2FFirmaPablo.PNG?alt=media&token=c5a8f192-5cb8-4025-8d30-31918abfa5be'),
              // image: this.logoDataGerencia,
              image: (element.estatusGerencia == 2) ? this.logoDataGerencia : this.logoDataUrl,
              //image: (element.estado == 1 || element.estado == 2) ? this.logoDataBlancoODC : this.logoDataUrl,
              width: 100,
              height: 50,
            }
          ]
        },
      
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
       
        {
          columns: [
            {
              text: '1. Datos de Facturación:', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  COMMERCIAL CONTRACTING DE MEXICO', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Régimen General (601)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Método de Pago PPD (Pago Parcial Diferido)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Forma de pago    99   (Por definir)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Uso del CFDI        G3 ( Gastos Generales)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '2. Entrega factura anexando O.C y soporte firmado de autorización y/o almacén:', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  (Incluyendo archivos XML y PDF)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '3. Para el envió de facturas será:', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Facturas.ccm@cccnetwork.mx', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: ' 4. No se pagaran facturas que no cumplan con estos requisitos', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        tableFooter: {
          bold: true,
          fontSize: 13,
          color: 'black',
          border: false
        }
      },
      defaultStyle: {
        // alignment: 'justify'
      }
    };

    pdfMake.createPdf(documentDefinition).open();
    pdfMake.createPdf(documentDefinition).download(element.ordendecompra_codigo + '.pdf');
     
  }
}