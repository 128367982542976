
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';

import { FlexLayoutModule } from '@angular/flex-layout';
import { FullComponent } from './layouts/full/full.component';
import { AppHeaderComponent } from './layouts/full/header/header.component';
import { AppSidebarComponent } from './layouts/full/sidebar/sidebar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DemoMaterialModule } from './demo-material-module';
// import { LoginComponent } from './pages/login/login.component';  

import { SharedModule } from './shared/shared.module';
import { SpinnerComponent } from './shared/spinner.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';

import { MatFormFieldModule} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

import { MatInputModule } from '@angular/material/input';
import { CategoriesComponent } from './components/categories/categories/categories.component';
import { ProjectCaptureDetailComponent } from '../app/components/project-capture-detail/project-capture-detail/project-capture-detail.component';
import { ReactiveFormsModule} from '@angular/forms';
import { RequisitionListComponent } from './pages/requisition/requisition-list/requisition-list.component';
import { RequisitionDetailComponent } from './components/requisitions/requisition-detail/requisition-detail.component';
import { PoListComponent } from './pages/purchaseOrder/po-list/po-list.component';
import { StorageInvoiceListComponent } from './pages/storageInvoice/storage-invoice-list/storage-invoice-list.component';
import { AuthComponent } from './components/auth/auth.component';
import { WarehouseReviewComponent } from './pages/warehouse-review/warehouse-review.component';
import { PoDetailComponent } from './components/po-detail/po-detail.component';
import { AuthSigninComponent } from './components/auth-signin/auth-signin.component';

import {MatDatepickerModule} from '@angular/material/datepicker';
import { CustomerDetailComponent } from './components/customer-detail/customer-detail.component';
import { SupplierDetailComponent } from './components/supplier-detail/supplier-detail.component';
import { SupplierComponent } from './pages/supplier/supplier.component';
import { RepseSupplierCaptureComponent } from './pages/repse-supplier-capture/repse-supplier-capture/repse-supplier-capture.component';
import { RepseSupplierReviewComponent } from './pages/repse-supplier-review/repse-supplier-review/repse-supplier-review.component';
import { RepseSupplierReviewDetailComponent } from './components/repse-supplier-review-detail/repse-supplier-review-detail.component';
import { RepseCaptureGeneralComponent } from './components/repse-supplier-capture-detail/repse-capture-general/repse-capture-general.component';
import { RepseCaptureMensualComponent } from './components/repse-supplier-capture-detail/repse-capture-mensual/repse-capture-mensual.component';
import { RepseCaptureBimestralComponent } from './components/repse-supplier-capture-detail/repse-capture-bimestral/repse-capture-bimestral.component';
import { RepseCaptureCuatrimestralComponent } from './components/repse-supplier-capture-detail/repse-capture-cuatrimestral/repse-capture-cuatrimestral.component';
import { RepseReviewGeneralComponent } from './components/repse-supplier-review-detail/repse-review-general/repse-review-general.component';
import { RepseReviewMensualComponent } from './components/repse-supplier-review-detail/repse-review-mensual/repse-review-mensual.component';
import { RepseReviewBimestralComponent } from './components/repse-supplier-review-detail/repse-review-bimestral/repse-review-bimestral.component';
import { RepseReviewCuatrimestralComponent } from './components/repse-supplier-review-detail/repse-review-cuatrimestral/repse-review-cuatrimestral.component';
import { RepseReviewAproveComponent } from './components/repse-supplier-review-detail/repse-review-aprove/repse-review-aprove.component';
import { RepseCapturePapeleriaCCMComponent } from './components/repse-supplier-capture-detail/repse-capture-papeleriaCCM/repse-capture-papeleria-ccm.component';
import { SupplierUserComponent } from './components/supplier-user/supplier-user.component';
import { RepseReviewCcmComponent } from './components/repse-supplier-review-detail/repse-review-ccm/repse-review-ccm.component';
import { RepseCommentsComponent } from './components/repse-supplier-capture-detail/repse-comments/repse-comments.component';
import { RepseSupplierReviewValidosComponent } from './components/repse-supplier-review-detail/repse-supplier-review-validos/repse-supplier-review-validos.component';
import { RepseReportComponent } from './components/repse-supplier-review-detail/repse-report/repse-report.component';
import { LoaderGlobalComponent } from './components/loader-global/loader-global.component';
import { InvoiceComponent } from './pages/invoice/invoice.component';
import { InvoiceDetailComponent } from './components/invoice/invoice-detail/invoice-detail.component';
import { InvoiceDetailCaptureComponent } from './components/invoice/invoice-detail-capture/invoice-detail-capture.component';
import { SupplyProfileComponent } from './components/supply-profile/supply-profile.component';
import { ReportMasterComponent } from './pages/report-master/report-master/report-master.component';
import { BinnacleDownloadComponent } from './components/reports/binnacle-download/binnacle-download.component';
import { PoDownloadComponent } from './components/reports/po-download/po-download.component';
import { PoNotInvoiceReportComponent } from './components/reports/po-not-invoice-report/po-not-invoice-report.component';
import { ReportMasterDetailComponent } from './components/reports/report-master-detail/report-master-detail.component';
import { ReportMasterGenComponent } from './components/reports/report-master-gen/report-master-gen.component';
import { SuppliersOutToDateComponent } from './components/reports/suppliers-out-to-date/suppliers-out-to-date.component';
import { EmployeeComponent } from './pages/employee/employee.component';
import { EmployeReviewComponent } from './pages/employe-review/employe-review.component';
import { EmployeCaptureComponent } from './pages/employe-capture/employe-capture.component';
import { EmployeeReviewDetailComponent } from './components/employee/employee-review-detail/employee-review-detail.component';
import { EmployeeAproveComponent } from './components/employee/employee-aprove/employee-aprove.component';
import { EmployeeUserComponent } from './components/employee/employee-user/employee-user.component';
import { EmployeeReviewAddressDataComponent } from './components/employee/employee-review-detail/employee-review-address-data/employee-review-address-data.component';
import { EmployeeReviewFamilyDataComponent } from './components/employee/employee-review-detail/employee-review-family-data/employee-review-family-data.component';
import { EmployeeReviewLastWorkDataComponent } from './components/employee/employee-review-detail/employee-review-last-work-data/employee-review-last-work-data.component';
import { EmployeeReviewGeneralDataComponent } from './components/employee/employee-review-detail/employee-review-general-data/employee-review-general-data.component';
import { EmployeeReviewLoadDocumentsComponent } from './components/employee/employee-review-detail/employee-review-load-documents/employee-review-load-documents.component';
import { EmployeeReviewPersonalDataComponent } from './components/employee/employee-review-detail/employee-review-personal-data/employee-review-personal-data.component';
import { EmployeeCaptureDetailComponent } from './components/employee-capture/employee-capture-detail/employee-capture-detail.component';
import { EmployeeCapturePersonalComponent } from './components/employee-capture/employee-capture-detail/employee-capture-personal/employee-capture-personal.component';
import { EmployeeCaptureAddressComponent } from './components/employee-capture/employee-capture-detail/employee-capture-address/employee-capture-address.component';
import { EmployeeCaptureFamilyComponent } from './components/employee-capture/employee-capture-detail/employee-capture-family/employee-capture-family.component';
import { EmployeeCaptureLastWorkComponent } from './components/employee-capture/employee-capture-detail/employee-capture-last-work/employee-capture-last-work.component';
import { EmployeeCaptureGeneralComponent } from './components/employee-capture/employee-capture-detail/employee-capture-general/employee-capture-general.component';
import { EmployeeCaptureLoadDocumentsComponent } from './components/employee-capture/employee-capture-detail/employee-capture-load-documents/employee-capture-load-documents.component';
import { EmployeeDetailComponent } from './components/employee-detail/employee-detail.component';
import { EmployeeCaptureDocumentsComponent } from './components/employee-capture/employee-capture-detail/employee-capture-documents/employee-capture-documents.component'
import { PoRequisitionDetailComponent } from './components/po-requisition-detail/po-requisition-detail.component';
import { RequisitionDocumentComponent } from './components/requisitions/requisition-document/requisition-document.component';
import { RequsitionSendMailComponent } from './components/requisitions/requsition-send-mail/requsition-send-mail.component';
import { InvoiceListComponent } from './components/invoice/invoice-list/invoice-list.component';
import { ManagementPoListComponent } from './pages/management/management-po-list/management-po-list.component';
import { ReportRhComponent } from './pages/report-rh/report-rh.component';
import { SupplierDefeatedComponent } from './components/supplier-defeated/supplier-defeated.component';
import { UserKeyAccessComponent } from './pages/user-key-access/user-key-access.component';
import { UserManageComponent } from './pages/user-key-access/user-manage/user-manage.component';
import { PoRequisitionListComponent } from './pages/purchaseOrder/po-requisition-list/po-requisition-list.component';
import { PoNotInvoiceComponent } from './pages/purchaseOrder/po-not-invoice/po-not-invoice.component';
import { PoDocumentComponent } from './components/po-document/po-document.component';
import { CatalogsListComponent } from './pages/catalogs-list/catalogs-list.component';
import { CountryReportComponent } from './components/country-report/country-report.component';
import { StateReportComponent } from './components/state-report/state-report.component';
import { PartReportComponent } from './components/part-report/part-report.component';
import { FracAranReportComponent } from './components/frac-aran-report/frac-aran-report.component';
import { UomReportComponent } from './components/uom-report/uom-report.component';
import { SupplierReportComponent } from './components/supplier-report/supplier-report.component';
import { CustomerReportComponent } from './components/customer-report/customer-report.component';
import { CustomAgentReportComponent } from './components/custom-agent-report/custom-agent-report.component';
import { CiasReportComponent } from './components/cias-report/cias-report.component';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    AppHeaderComponent,
    SpinnerComponent,
    AppSidebarComponent,
    CategoriesComponent,
    ProjectCaptureDetailComponent,
    RequisitionListComponent,
    RequisitionDetailComponent,
    PoListComponent,
    StorageInvoiceListComponent,
    AuthComponent,
    WarehouseReviewComponent,
    PoDetailComponent,
    AuthSigninComponent,
    CustomerDetailComponent,
    SupplierDetailComponent,
    SupplierComponent,
    RepseSupplierCaptureComponent,
    RepseSupplierReviewComponent,
    RepseSupplierReviewDetailComponent,
    RepseCaptureGeneralComponent,
    RepseCaptureMensualComponent,
    RepseCaptureBimestralComponent,
    RepseCaptureCuatrimestralComponent,
    RepseReviewMensualComponent,
    RepseReviewBimestralComponent,
    RepseReviewGeneralComponent,
    RepseReviewCuatrimestralComponent,
    RepseReviewAproveComponent,
    RepseCapturePapeleriaCCMComponent,
    SupplierUserComponent,
    RepseReviewCcmComponent,
    RepseCommentsComponent,
    RepseSupplierReviewValidosComponent,
    LoaderGlobalComponent,
    InvoiceComponent,
    InvoiceDetailComponent,
    InvoiceDetailCaptureComponent,
    SupplyProfileComponent,
    RepseReportComponent,
    ReportMasterComponent,
    BinnacleDownloadComponent,
    PoDownloadComponent,
    PoNotInvoiceReportComponent,
    ReportMasterDetailComponent,
    ReportMasterGenComponent,
    SuppliersOutToDateComponent,
    EmployeCaptureComponent,
    EmployeReviewComponent,
    EmployeeComponent,
    EmployeeReviewDetailComponent,
    EmployeeAproveComponent,
    EmployeeUserComponent,
    EmployeeReviewAddressDataComponent,
    EmployeeReviewFamilyDataComponent,
    EmployeeReviewGeneralDataComponent,
    EmployeeReviewLastWorkDataComponent,
    EmployeeReviewLoadDocumentsComponent,
    EmployeeReviewPersonalDataComponent,
    EmployeeCaptureDetailComponent,
    EmployeeCapturePersonalComponent, 		
    EmployeeCaptureAddressComponent, 		
    EmployeeCaptureFamilyComponent, 		
    EmployeeCaptureLastWorkComponent,		
    EmployeeCaptureGeneralComponent,	
    EmployeeCaptureLoadDocumentsComponent,	
    EmployeeDetailComponent,
    EmployeeCaptureDocumentsComponent,
    PoRequisitionDetailComponent,
    RequisitionDocumentComponent,
    RequsitionSendMailComponent,
    InvoiceListComponent,
    ManagementPoListComponent,
    ReportRhComponent,
    SupplierDefeatedComponent,
    UserKeyAccessComponent,
    UserManageComponent,
    PoRequisitionListComponent,
    PoNotInvoiceComponent,
    PoDocumentComponent,
    CatalogsListComponent,
    CountryReportComponent,
    StateReportComponent,
    PartReportComponent,
    FracAranReportComponent,
    UomReportComponent,
    SupplierReportComponent,
    CustomerReportComponent,
    CustomAgentReportComponent,
    CiasReportComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FormsModule,
    FlexLayoutModule,
    HttpClientModule,
    SharedModule,
    MatTableModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    ReactiveFormsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    })
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
