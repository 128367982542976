<div mat-dialog-content-example [formGroup]="newProject">
    
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Estado Civil</div>
        </div>
        <div fxFlex.gt-sm="390px">
            <div><br>
                <mat-radio-group aria-label="Select an option" formControlName="estadoCivil">
                    <mat-radio-button value="0">Casado</mat-radio-button>&nbsp;
                    <mat-radio-button value="1">Soltero</mat-radio-button>&nbsp;
                    <mat-radio-button value="2">Divorciado</mat-radio-button>&nbsp;
                  </mat-radio-group>
                <span *ngIf="!newProject.get('estadoCivil').valid && newProject.get('estadoCivil').touched" class="help-block"
                    style="color: red;"> El estado civil es requerido</span>
            </div>
            <button mat-raised-button color="accent" (click)="saveEstadoCivil(newProject, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>
    <br><br>
    <!-- SECCION CAPTURA MANUAL -->
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="200px">
            <div><br>Nombre</div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Primer apellido
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>
                Segundo apellido
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Fecha de nacimiento (dd/mm/yyyy)
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div></div><br>Sexo
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="200px">
            <div><br>
                <mat-form-field>
                    <input [type]="nombre" matInput class="form-control form-control-line control" formControlName="nombre" disabled>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>
                <mat-form-field>
                    <input [type]="primerApellido" matInput class="form-control form-control-line control" formControlName="primerApellido" disabled>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>
                <mat-form-field>
                    <input [type]="segundoApellido" matInput class="form-control form-control-line control" formControlName="segundoApellido" disabled>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="fechaNacimiento" (dateChange)="fechaInicial($event)" class="control">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                <span *ngIf="!newProject.get('fechaNacimiento').valid && newProject.get('fechaNacimiento').touched"
                    class="help-block" style="color: red;"> La nacionalidad es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>
                <mat-radio-group aria-label="Select an option" formControlName="sexo">
                    <mat-radio-button value="1">Masculino</mat-radio-button>&nbsp;
                    <mat-radio-button value="0">Femenino</mat-radio-button>
                  </mat-radio-group>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>
                <mat-radio-group aria-label="Select an option" formControlName="parentesco">
                    <mat-radio-button value="1">Esposo</mat-radio-button><br>
                    <mat-radio-button value="0">Hijo</mat-radio-button>
                  </mat-radio-group>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <button mat-raised-button color="primary" (click)="add(newProject, $event)"><i class="fa fa-plus fa-lg icon-cog-wite"></i></button>
            </div>
        </div>
    </div>
<!-- TERMINA SECCION CAPTURA MANUAL -->
<!--CUERPO DE LA PÁGINA -->
<!-- INICIA CUERPO DE LA PÁGINA-->
<div class="col-12 col-md-12 p-0">
    <div class="card">
      <div class="card-body card-body-table">
        <mat-table [dataSource]="dataSourceEmployee" matSort class="mat-elevation-z8" style="width: 95%;" #tabla1>
            
          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> <span>Id</span> </th>
            <td mat-cell *matCellDef="let element">{{element.id}}
              <!-- <input type='nombre' [(ngModel)]="element.nombre" class="input-short"  class="form-control form-control-line control input-xshort"> -->
            </td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="nombre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> <span>Nombre</span> </th>
            <td mat-cell *matCellDef="let element">{{element.nombre}}
              <!-- <input type='nombre' [(ngModel)]="element.nombre" class="input-short"  class="form-control form-control-line control input-xshort"> -->
            </td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="aPaterno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> <span>Primer Apellido</span> </th>
            <td mat-cell *matCellDef="let element">{{element.aPaterno}}
              <!-- <input type='aPaterno' [(ngModel)]="element.primerApellido" class="form-control form-control-line control input-xshort"> -->
            </td>
          </ng-container>
          
          <!-- Name Column -->
          <ng-container matColumnDef="aMaterno">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" matInput style="width: 300px;"> Segundo Apellido </th>
            <td mat-cell *matCellDef="let element"> {{element.aMaterno}}
              <!-- <input type='aMaterno' [(ngModel)]="element.segundoApellido" class="form-control form-control-line control input-xshort"> -->
            </td>
          </ng-container>
        
          <!-- Email Column -->
            <ng-container matColumnDef="fechaNacimiento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Fecha de Nacimiento </th>
                <td mat-cell *matCellDef="let element"> {{element.fechaNacimiento | date}}
                <!-- <input type='fechaNacimiento' [(ngModel)]="element.fechaNacimiento" class="form-control form-control-line control input-xlarge"> -->
                </td>
            </ng-container>

          <!-- Email Column -->
          <ng-container matColumnDef="sexo">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Sexo </th>
            <td mat-cell *matCellDef="let element"> 
                <ng-container *ngIf= "element.sexo == 0">Femenino</ng-container>
                <ng-container *ngIf= "element.sexo == 1">Masculino</ng-container>
            </td>
          </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="parentesco">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Parentesco </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.parentesco == 0">Hijo</ng-container>
                    <ng-container *ngIf="element.parentesco == 1">Esposo</ng-container>
                </td>
                </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="addDocument">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Documentos </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>
                        <input type="file" name="fuDocumento" id="fuDocumento" (change)="cargarImagen(element, $event, 'acta', 'familiares', estadoActa, 3001, element.id)" style="color: dodgerblue;">
                        <label *ngIf="element.parentesco == 0" style="color: red;">Acta de nacimientos</label>
                        <label *ngIf="element.parentesco == 1" style="color: red;">Acta de matrimonio</label>
                    </ng-container>
                <!-- <ng-container><button class="button" (click)="openFile(3001)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container> -->
                <ng-container>
                    <!-- <button class="button" (click)="opencomments(3001)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button> -->
                </ng-container>
                </td>
            </ng-container>

            <!-- Email Column -->
            <!-- <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Editar </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container ><button mat-button color="primary"(click)="edit(element, $event)"><i class="fa fa-pencil fa-lg icon-cog-orange"></i> </button></ng-container>
                </td>
            </ng-container> -->

            <!-- Email Column -->
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Eliminar </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container ><button mat-button color="primary" (click)="delete(element, $event)"><i class="fa fa-ban fa-lg icon-cog-red"></i> </button></ng-container>
                </td>
            </ng-container>

             <!-- Email Column -->
             <ng-container matColumnDef="linea">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" hidden> Línea </th>
                <td mat-cell *matCellDef="let element" hidden>
                <!-- <input type='linea' [(ngModel)]="element.linea" class="form-control form-control-line control input-xlarge"> -->
                </td>
            </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </mat-table>
      </div>
    </div>
<!-- TERMINA CUERPO DE LA PÁGINA -->


<br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div hidden><button mat-raised-button color="accent" (click)="save(newProject, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <!-- <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div> -->
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 
