import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { supplierModel } from 'src/app/models/supplier.model';
import { supplyservice } from '../../services/supplier.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { supplyusersservice } from '../../services/supplier.users.service';
import { supplierUsersModel } from '../../models/supplier-users.model';
import { AESEncryptService } from '../../services/aesencrypt.service';
import { UserService } from '../../services/user.service';
import { I } from '@angular/cdk/keycodes';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-key-access',
  templateUrl: './user-key-access.component.html',
  styleUrls: ['./user-key-access.component.css']
})
export class UserKeyAccessComponent implements OnInit {

// =========================
// DECLARACIONES
// =========================
nombre : string = '';
usuario : string;
clave : string;
isEdit : boolean = false;
proveedorId : number = 0;
userId : number = 0;
usuarioId : any;
employeeId : any;
PerfilUsuarioId : any;
correo : any;

// pageInfo : any;
estadoPantalla : string;

public newPage: FormGroup;

  constructor(
    // public dialogRef: MatDialogRef<supplierModel>
    private formBuilder: FormBuilder
    // , @Inject(MAT_DIALOG_DATA) public data
    , private _snackBar : MatSnackBar
    , private _supplyusersservice : supplyusersservice
    , private cryptojs: AESEncryptService
    , private _UserService : UserService
  ) { 

    // this.pageInfo = data.arrayData;
    // this.estadoPantalla = data.estadoPantalla;

    this.newPage = this.formBuilder.group({
      clave: new FormControl('', [Validators.required]),
    });
  }

  // =========================
  // PROCEDIMIENTOS
  // =========================

  ngOnInit(): void {
    this.decode();
  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeUsuarioId = jwt_decode(token)["usuarioid"];
    let decodePerfilUsuarioId = jwt_decode(token)["perfil_usuarioid"];
    let decodeCorreo = jwt_decode(token)["correo"];
    this.usuarioId = decodeUser;
    this.employeeId = decodeUsuarioId;
    this.nombre = decodeUser;
    this.usuario = decodeUser;
    this.PerfilUsuarioId = decodePerfilUsuarioId;
    this.correo = decodeCorreo;

    console.log('informacion', jwt_decode(token));
    
    this.getUser(this.usuario);
  }

  cancel(event){
    // this.dialogRef.close();
  }

  save(form, event){
    let arrayToDb : any;

    let pswdEncryp = this.cryptojs.encrypt(this.newPage.controls["clave"].value);

    arrayToDb = ({
      usuarioId : this.userId
      , nombreUsuario : this.nombre
      , contrasegnna : pswdEncryp
      , correo : this.correo
      , perfilId : this.PerfilUsuarioId
    })

    console.log('guardado', arrayToDb);
    this.updateUser(arrayToDb);
  }

  getUser(rfc : string){
    let arrayUsers : any;
    let arrayUserFilter : any;

    this._UserService.getUsersAll().subscribe(
      res=> {
        console.log('user', res);
        arrayUsers = res;
        arrayUserFilter = arrayUsers.filter(e => e.nombreUsuario == rfc)
        if (arrayUserFilter.length > 0){
          this.userId = arrayUserFilter[0]["usuarioId"];
        }
      },
      error => console.log("error consulta regiones",error)
    )
  }

  // =========================
  // UTILERIAS
  // =========================
  // openSnackBar(message: string, action: string) {
  //   this._snackBar.open(message, action, {duration : 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: 'alert-snackbar'});
  // }

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  // =========================
  // SERVICIOS
  // =========================
  // insertUser(arrayToDb){
  //   console.log('INSERTA USUARIO');

  //   // Inserta usuarios
  //   this._supplyusersservice.insertsupplyUser(arrayToDb).subscribe(
  //     res=> {
  //       console.log('USUARIO', res);
  //       this.openSnackBar('El registro se actualizó con éxito', '');  
  //       // this.dialogRef.close();
  //     },
  //     error => console.log("error al insertar proveedores",error)
  //   )

  //   this.openSnackBar('Se creo el usuario correctamente', '');
  //   // this.dialogRef.close();
  // }

  updateUser(arrayToDb){
    console.log('ACTUALIZA USUARIO');
    // Actualiza usuarios
    this._supplyusersservice.updatesupplyUser(arrayToDb).subscribe(
      res=> {
        console.log('USUARIOS', res);
        this.showMessage(2, 'Comentario', 'success', 'El registro se actualizó con éxito', 'Cerrar');
        // this.dialogRef.close();
      },
      error => console.log("error al insertar proveedores",error)
    )

    this.showMessage(2, 'Comentario', 'success', 'Se actualizó el usuario correctamente', 'Cerrar');
    // this.dialogRef.close();
  }

}
