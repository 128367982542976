import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { documentPoModel } from '../../models/document-po.model';
import { UploadFileService } from '../../services/upload-file/upload-file.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-po-document',
  templateUrl: './po-document.component.html',
  styleUrls: ['./po-document.component.css']
})
export class PoDocumentComponent implements OnInit {

  projectInfo : any;
  purchaseOrderId : any = 0;
  estadoPantalla : string;
  purchaseOrderDatasource: any;
  fuDocumento : any;
  urlDocumento : any;

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;

  displayedColumns = ['id', 'descripcion', 'urlDocumento'];

  public newProject: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<documentPoModel>
    , @Inject(MAT_DIALOG_DATA) public data
    , private formBuilder: FormBuilder
    , private _UploadFileService : UploadFileService
  ) {
    this.newProject = this.formBuilder.group({
      comentarios: new FormControl('', [Validators.required])
    });
    this.projectInfo = data.arrayData;
    this.purchaseOrderId = data.purchaseOrderId;
    this.estadoPantalla = data.estadoPantalla;
   }

   ngOnInit(): void {

    this.getPODocumentById();
    
  }

  cancel(){
    this.dialogRef.close();
  }

  
openFile(urlDireccion : string){
  window.open(urlDireccion);
}

deleteFile(id: number){
  this.changeStatus(id, 'delete');
}

  // ====================
  // LLAMADAS A SERVICIOS
  // ====================
  getPODocumentById(){
    console.log('this.PO', this.purchaseOrderId);
    
    this._UploadFileService.getPODocumentsById(this.purchaseOrderId).subscribe(
      res=> {

        this.purchaseOrderDatasource = new MatTableDataSource(res);
        
        console.log('ORDENES DE COMPRA', this.purchaseOrderDatasource);

      },
      error => console.log("error consulta usuarios",error)
    )

  }

  imagenes: any[] = [];
  cargarDocumento(event: any) {

    if(this.newProject.controls["comentarios"].value == '' || this.newProject.controls["comentarios"].value == undefined){
      this.showMessage(2, 'Error', 'error', 'debe capturar un comentario', 'Cerrar');
    }else{

      let archivos = event.target.files;
      let nombre = event.target.files.name;
      let arrayToDb : any;

      for (let i = 0; i < archivos.length; i++) {

        let reader = new FileReader();
        reader.readAsDataURL(archivos[0]);
        reader.onloadend = () => {
          console.log(reader.result);
          this.imagenes.push(reader.result);

          console.log('PO SUBIR IMAGEN', this.purchaseOrderId)
          console.log('NOMBRE ARCHIVO', event.target.files[0]["name"])
          console.log('COMENTARIOS', this.newProject.controls["comentarios"].value)
       
          this._UploadFileService.subirImagenPO(event.target.files[0]["name"], reader.result, this.purchaseOrderId, 2022, 1).then(urlImagen => {

            let idDocumento : number = 0;

            arrayToDb = {
                          idDocumento : idDocumento, 
                          id_purchaseOrder : this.purchaseOrderId
                          , descripcion : this.newProject.controls["comentarios"].value
                          , urlDocumento : urlImagen.toString()
                        };

            this._UploadFileService.postUploadDocumentsPOToDb(arrayToDb).subscribe(
              res=> {
                this.getPODocumentById();
              },
              error => console.log("error al actualizar proveedores",error)
            );

            this.urlDocumento = urlImagen.toString();

            this.showMessage(2, 'Exitoso', 'success', 'Se cargo exitosamente el archivo', 'Cerrar');

          });
        }
      }
    }
  }

  changeStatus(id: number, status: string){

    let arrayToDb: any;

    arrayToDb = {
                  id: id
                  ,id_purchaseOrder: this.purchaseOrderId
                  ,descripcion: ''
                  ,urlDocumento: ''
                  ,fechaCreacion: '2021-01-01'
                  ,fechaActualizacion: ''
                  ,estatus: 4
                };

    this._UploadFileService.postDocumentosPOChangeStatus(arrayToDb).subscribe(
      res=> {
        this.getPODocumentById();
        this.showMessage(2, 'Exitoso', 'success', 'Se elimino el documento', 'Cerrar');
      },
      error => console.log("error consulta usuarios",error)
    )
  }

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }
}
