import { ElementRef, EventEmitter, Injectable, Output, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { requisitionModel } from 'src/app/models/requisition.model';
import jsPDF from 'jspdf';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { requisitionservice } from '../../services/requisition/requisition.service';
import { codigoDeCostosModel } from 'src/app/models/codigo-de-costos.model';
import { codigoDeCostosService } from 'src/app/services/requisition/codigo-de-costos.service';

@Injectable({
    providedIn: 'root'
})

export class pdfPrints {

    @ViewChild('pdfTable') pdfTable: ElementRef;
    @Output() filterChange = new EventEmitter();

    logoCCC: any;
    firmaRequisicion : any;
    firmaAutorizacion : any;
    firmaCompras : any;
    requisitionDetailDataSource : any;
    CDCDescripcion: string;
    datasourceCodigoDeCostos : MatTableDataSource<codigoDeCostosModel>;

  constructor(protected http: HttpClient
    , private _requisitionservice : requisitionservice
    , private _codigoDeCostosService : codigoDeCostosService
  ) { }

  ngAfterViewInit() {
    console.log("afterinit");
    setTimeout(() => {
      console.log(this.pdfTable.nativeElement.innerText);

    //   this.pdfRequisitionPrint();

    }, 1000);
  }

    // =======================
    // Requisiciones
    // =======================
    pdfRequisitionPrintOnInit(element : MatTableDataSource<requisitionModel>){
      
        this.getImageDataUrlFromLocalPath1('../../assets/images/background/logoCCC.jpg').then(
            result => this.logoCCC = result
          )

          this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/firmaRequisicionesGrande.jpg').then(
            result => this.firmaRequisicion = result
          )

          switch(element["usuarioCrea"]){
            case("alfredo_hernandez"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_alfredo_hernandez.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("bernardo_tamez"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_bernardo_tamez.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("carlos_palos"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_carlos_palos.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("carlos palos"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_carlos_palos.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("cesar_jaime"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_cesar_jaime.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("fernando_elizalde"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_fernando_elizalde.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("juan_cruz"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_juan_cruz.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("martin_saldaña"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_martin_saldaña.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("rodolfo_castro"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_rodolfo_castro.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("sergio_castillo"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_sergio_castillo.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("sergio_garcia"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_sergio_garcia.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("sergio_terrazas"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_sergio_terrazas.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("victor_morales"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_victor_morales.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("roberto_guzman"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_roberto_guzman.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            case("juan_figueroa"):
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaAutorizacion_juan_figueroa.jpg').then(
                result => this.firmaAutorizacion = result
              )
              break;
            default:
              this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaDeAtorizacion.jpg').then(
                result => this.firmaAutorizacion = result
              )
            break;
          }
  
          this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaCompras.jpg').then(
            result => this.firmaCompras = result
          )

        this.getCodigoDeCostos();

        this.getRequisitionDetail(element["requisicioninterna_id"], element);
    }

    pdfRequisitionPrint(element : MatTableDataSource<requisitionModel>) {

      // Obtiene descripción de codigo proyecto-categoria
      let arrayCDC : any = [];
      let CDCDescripcionCodigo : string;

      CDCDescripcionCodigo = (element['codigo_proyectocategoria'].indexOf('-') > 0) ? element['codigo_proyectocategoria'].substring(element['codigo_proyectocategoria'].indexOf('-') + 1, element['codigo_proyectocategoria'].indexOf('-').length) : element['codigo_proyectocategoria'];
      arrayCDC = this.datasourceCodigoDeCostos.filteredData.filter(e => e.codigoCC == CDCDescripcionCodigo);
      console.log("DATASOURCE PROYECTO CATEGORIA", this.datasourceCodigoDeCostos);
      console.log("ELEMENTOS PARA OBTENER LA DESCRIPCION DE CODIOG CATEGORIA", CDCDescripcionCodigo);

      if(arrayCDC.length > 0)
        this.CDCDescripcion = ' ( '  + arrayCDC[0]['descripcion'] + ' )';
       else
        this.CDCDescripcion = '';
      // Termina Obtiene descripción de codigo proyecto-categoria

      let arrayProyectoCategoria : any = element['codigo_proyectocategoria'].split('-');
      let arrayProyectoCodigo : any = element['codigo'].split('-');
      let noODC: string = element['noODC'];

      // switch(element["nombreSolicitante"]){
      //   case("fernando_chavez"): 
      //     this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaFernando.jpg').then(
      //       result => this.firmaRequisicion = result
      //     )
      //     break;
      //   case("lauro_araujo"):
      //     this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/FirmaLauroAraujo.jpg').then(
      //       result => this.firmaRequisicion = result
      //     )
      //     break;
      //   case("pablo_castillo"):
      //     this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/firmaRequisiciones.png').then(
      //       result => this.firmaRequisicion = result
      //     )
      //     break;
      //   case("alejandro_fuentes"):
      //     this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/firmaRequisiciones.jpg').then(
      //       result => this.firmaRequisicion = result
      //     )
      //     break;
      //   case("bernardo_tamez"):
      //     this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/firmaRequisiciones.jpg').then(
      //       result => this.firmaRequisicion = result
      //     )
      //     break;
      //   case("roberto_guzman"):
      //     this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/firmaRequisiciones.jpg').then(
      //       result => this.firmaRequisicion = result
      //     )
      //     break;
      //   default:
          this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/firmaRequisiciones.jpg').then(
            result => this.firmaRequisicion = result
          )
      //     break;
      // }
      
      this.getImageDataUrlFromLocalPath1('../../assets/images/Signs/firmaRequisiciones.jpg').then(
        result => this.firmaRequisicion = result
      )

        arrayProyectoCategoria[0]

        const doc = new jsPDF();

        var headers = {
            0:{
                col_1:{ text: 'sku', style: 'tableHeader',rowSpan: 2, alignment: 'center', fontSize:5.5, fontFamily: 'Arial', fillColor:'#F2F2F2' ,margin: [0, 8, 0, 0], width: 10 },
                col_2:{ text: 'Cantidad', style: 'tableHeader',rowSpan: 2, alignment: 'center', fontSize:5.5, fontFamily: 'Arial', fillColor:'#F2F2F2',margin: [0, 8, 0, 0], width: 10 },
                col_3:{ text: 'Unidad', style: 'tableHeader',rowSpan: 2, alignment: 'center', fontSize:5.5, fontFamily: 'Arial', fillColor:'#F2F2F2',margin: [0, 8, 0, 0], width: 10 },
                col_4:{ text: 'Descripción', style: 'tableHeader',rowSpan: 2, alignment: 'center', fontSize:5.5, fontFamily: 'Arial', fillColor:'#F2F2F2',margin: [0, 8, 0, 0], width: 40 },
                col_5:{ text: 'Medida', style: 'tableHeader',rowSpan: 2, alignment: 'center', fontSize:5.5, fontFamily: 'Arial', fillColor:'#F2F2F2',margin: [0, 8, 0, 0], width: 10  },
                col_6:{ text: 'Otras Especificaciones (color, acabado, marca, numero de parte, etc.)', style: 'tableHeader',rowSpan: 2, alignment: 'center', fontSize:5.5, fontFamily: 'Arial', fillColor:'#F2F2F2', margin: [0, 8, 0, 0], width: 20  },
                // col_7:{ text: 'Otras Especificaciones', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 10  },
            },
            1:{
                col_1:{ text: 'Header 1', style: 'tableHeader', alignment: 'center' },
                col_2:{ text: 'Header 2', style: 'tableHeader', alignment: 'center' }, 
                col_3:{ text: 'Header 3', style: 'tableHeader', alignment: 'center' },
                col_4:{ text: 'Citación', style: 'tableHeader', alignment: 'center' },
                col_5:{ text: 'Cumplimiento', style: 'tableHeader', alignment: 'center'},
                col_6:{ text: 'Cumplimiento', style: 'tableHeader', alignment: 'center'},
                // col_7:{ text: 'Cumplimiento', style: 'tableHeader', alignment: 'center'},
            }
          }

        let bodyx = [];
        var body = [];

        for (var key in headers){
            if (headers.hasOwnProperty(key)){
                var header = headers[key];
                var row = new Array();
                row.push( header.col_1 );
                row.push( header.col_2 );
                row.push( header.col_3 );
                row.push( header.col_4 );
                row.push( header.col_5 );
                row.push( header.col_6 );
                // row.push( header.col_7 );
                body.push(row);
            }
        }

        this.requisitionDetailDataSource.filteredData.forEach(data => {
            
            var row = new Array();
            row.push( data.SKU.toString() );
            row.push( data.cantidad.toString()  );
            row.push( data.um.toString());
            row.push( data.descripcion.toString());
            row.push( data.medida.toString()  );
            row.push( data.color.toString() + data.otras_especificaciones.toString()  );
            // row.push( data.otras_especificaciones.toString()  );
            body.push(row);
            bodyx.push(row);            

        });

        const documentDefinition = {
            content: [
              {
                columns: [
                  { 
                    image: this.logoCCC, // text: '',  //
                    width: 200,
                    height: 50,
                  },
                  {
                    text: '', fontSize:8, width: 20
                  },
                  {
                    text: '', fontSize:8, width: 25
                  },
                  {
                    text: '', fontSize:8, width: 25
                  },
                  {
                    text: 'COMMERCIAL CONTRACTING DE MEXICO, S DE RL DE CV' + '\n' + 'Carretera a Saltillo-Monterrey Km. 18' + '\n' + 'Parque Industrial Santa María 25903 ' + '\n' + 'Telefono: (844) 866 9030' + '\n' + ' Ramos Arízpe, Coahuila  RFC: CCM-950330-P1A' + '\n' + 'www.cccnetwork.com' + '\n' + ' Un miembro mas de COMMERCIAL CONTRACTING GROUP', fontSize:6, width: 230, alignment:'right'
                  }
                ]
              },
              {
                  style: 'tablebody',
                  table: {
                      widths: [ '20%', '5%', '5%', '10%', '30%', '10%', '6%', '6%', '8%'],
                      heights: [15, 15, 15, 15, 15, 15, 15, 15, 15],
                    body: [
                      [{text: 'FORMATO DE REQUISICIONES', style: 'tableHeader', colSpan: 9, alignment: 'center', fontSize:10, color:'white', fillColor: 'orange', fontFamily:'Arial', bold: true}, {}, {}, {}, {}, {}, {}, {}, {}],
                      [{text: '', colSpan: 6, fillColor: '#FFDDAB'},{},{},{},{},{}
                        , {text:'Folio', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}
                        , {text: (arrayProyectoCodigo[0] == 'ADMON') ? element['codigo'] : arrayProyectoCodigo[0] + '-' + arrayProyectoCodigo[1] + '-' + arrayProyectoCodigo[arrayProyectoCodigo.length - 1], style: 'tableHeader', colSpan: 2, alignment: 'center', fontSize:5, color:'red', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}, {}
                      ],
                      [{text: 'Nombre Solicitante', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}
                        , {text: 'Especificar Cargo', style: 'tableHeader', colSpan: 3, alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}, {}, {}
                        , {text: 'Código de costos', style: 'tableHeader', rowSpan: 2, alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}
                        , {text: 'Fecha:', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}
                        , {text: moment(element['fecha'], 'YYYY-MM-DD').format('DD/MM/YYYY'), style: 'tableHeader', colSpan: 3, alignment: 'center', fontSize:5, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}, {}, {}
                      ],
                      [{text: element["nombreSolicitante"], style: 'tableHeader', rowSpan: 2}
                        , {text: 'Contrato original', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}
                        // , {text: '', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}
                        , {text: 'T&M/FO/RFI/DCR', style: 'tableHeader', colSpan:2, alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true},{}
                        , ''
                        , {text: 'No. de Proyecto', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}
                        , {text: arrayProyectoCategoria[0], style: 'tableHeader', alignment: 'center', fontSize:5, color:'red', fillColor: 'white', fontFamily:'Arial', bold: true}
                        , {text: 'No O. C.', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}
                        , {text: noODC, style: 'tableHeader', alignment: 'center', fontSize:5, color:'red', fillColor: 'white', fontFamily:'Arial', bold: true}
                      ],
                      [''
                        , {text: (element["contratoOriginal"] == true) ? 'Sí' : 'No', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}
                        // , {text: (element["codigoDeContrato"] == true) ? 'Sí' : 'No', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}
                        , {text: (element["contratoOriginal"] == true) ? element["tAndMDescription"] : '', style: 'tableHeader', colSpan:2, alignment: 'center', fontSize:5, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true},{}
                        , {text: element["codigo_proyectocategoria"] + this.CDCDescripcion, style: 'tableHeader', colSpan: 5, alignment: 'left', fontSize:5, color:'red', fillColor: 'white', fontFamily:'Arial', bold: true},{},{},{},{}
                      ],
                      [{text: '¿Con Cargo al Contratista?', style: 'tableHeader', alignment: 'center', fontSize:5, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}
                        , {text: (element["cargoAlContratista"] == true) ? 'Sí' : 'No', style: 'tableHeader', colSpan: 2, alignment: 'center', fontSize:5, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}, {}
                        , {text: '', style: 'tableHeader', colSpan: 6, alignment: 'left', fontSize:5, color:'red', fillColor: 'white', fontFamily:'Arial', bold: true},{},{},{},{},{}
                      ],
                    ]
                  }
                },
              {
                columns: [
                  {
                    text: ' ', fontSize:8, bold:true, width: 90
                  }
                ]
              },
              {
                fontSize:8, width:'100%',
                table: {
                  widths: [ '10%', '10%', '10%', '40%', '10%', '20%' ],
                  headerRows: 2,
                  // keepWithHeaderRows: 1,
                  body: body
              }
              },
              {
                style: 'tablebody',
                table: {
                    widths: [ '20%', '5%', '5%', '10%', '30%', '10%', '6%', '6%', '8%'],
                    heights: [15, 40],
                  body: [
                    [{text: 'Observaciones', style: 'tableHeader', colSpan: 9, alignment: 'left', fontSize:6, color:'black', fillColor: '#FFDDAB', fontFamily:'Arial', bold: true}, {}, {}, {}, {}, {}, {}, {}, {}],
                    [{text: '', style: 'tableHeader', colSpan: 9, height: 80, alignment: 'left', fontSize:6, color:'black', fillColor: 'white', fontFamily:'Arial', bold: true}, {}, {}, {}, {}, {}, {}, {}, {}],
                  ]
                }
              },
              {
                columns: [
                  {
                    text: ' ', fontSize:8, bold:true, width: '*'
                  }
                ]
              },
              {
                columns: [
                  {
                    text: ' ', fontSize:8, bold:true, width: 90
                  }
                ]
              },
              {
                columns: [
                  {
                    text: ' ', fontSize:8, bold:true, width: 90
                  }
                ]
              },
              ,
              {
                columns: [
                  {
                    text: '', fontSize:8, width: 20, height: 30
                  },
                  { 
                    text: '', fontSize:8, width: 100, height: 30
                  },
                  {
                    text: '', fontSize:8, width: 20, height: 30
                  },
                  {
                    text: '', fontSize:8, width: 100, height: 30
                  },
                  {
                    text: '', fontSize:8, width: 20, height: 30
                  },
                  {
                    text: '', fontSize:8, width: 100, height: 30
                  }
                ]
              },
              {
                columns: [
                  {
                    text: '', fontSize:8, width: 20
                  },
                  { 
                    text: element["nombreSolicitante"], fontSize:8, width: 100 // element["usuarioCrea"]
                  },
                  {
                    text: '', fontSize:8, width: 20
                  },
                  {
                    text: '', fontSize:8, width: 100
                  },
                  {
                    text: '', fontSize:8, width: 20
                  },
                  {
                    text: '', fontSize:8, width: 100
                  }
                ]
              },
              {
                columns: [
                  {
                    text: '', fontSize:8, width: 20
                  },
                  { 
                    image: this.firmaRequisicion,
                    width: 100,
                    height: 40,
                  },
                  {
                    text: '', fontSize:8, width: 20
                  },
                  {
                    image: this.firmaAutorizacion,
                    width: 100,
                    height: 40,
                  },
                  {
                    text: '', fontSize:8, width: 20
                  },
                  {
                    image: this.firmaCompras,
                    width: 100,
                    height: 40,
                  }
                ]
              }
            ],
            styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10]
                },
                subheader: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 10, 0, 5]
                },
                tableExample: {
                  margin: [0, 5, 0, 15]
                },
                tableHeader: {
                  bold: true,
                  fontSize: 11,
                  color: 'black'
                },
                tableFooter: {
                  bold: true,
                  fontSize: 13,
                  color: 'black',
                  border: false
                }
              },
              defaultStyle: {
                // alignment: 'justify'
              }
            };
            pdfMake.createPdf(documentDefinition).open();
            pdfMake.createPdf(documentDefinition).download('Requisicion' + '.pdf');
    }

    getRequisitionDetail(arrayTodb, element : MatTableDataSource<requisitionModel>){

        let arrayRequsitionDetail : any;
        let arrayRequsitionToTable : any[] = [];
    
        this._requisitionservice.getRequisitionDetail(arrayTodb).subscribe(
          res=> {
            arrayRequsitionDetail = res;
    
            console.log('arrayTodb', arrayTodb);
    
            arrayRequsitionDetail.forEach(element => {
              arrayRequsitionToTable.push({ //requisition_Id: 0
                                    SKU : element.sku
                                    , cantidad : element.cantidad
                                    , um : element.unidad_medida
                                    , descripcion : element.descripcion
                                    , medida : element.medida
                                    , color : element.color
                                    , otras_especificaciones : element.otras_especificaciones
                                    , linea : element.linea})
            });
    
            // Ordena los proyectos
            arrayRequsitionToTable.sort(function (a, b) {
              if (a.linea > b.linea) {
                return 1;
              }
              if (a.linea < b.linea) {
                return -1;
              }
              // a must be equal to b
              return 0;
            });
    
            this.requisitionDetailDataSource = new MatTableDataSource(arrayRequsitionToTable);

            console.log('this.requisitionDetailDataSource', this.requisitionDetailDataSource)

            this.pdfRequisitionPrint(element);
          },
          error => console.log("error alta de proyectos",error)
        )
      }

    getImageDataUrlFromLocalPath1(localPath: string): Promise<string> {
        return new Promise((resolve, reject) => {
            let canvas = document.createElement('canvas');
            let img = new Image();
            img.onload = () => {
                canvas.height = img.height;
                canvas.width = img.width;
                canvas.getContext("2d").drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/png'));
            }
            img.onerror = () => reject('Imagen no disponible')
            img.src = localPath;
        })
    
      }

      // =======================
      // Orden de Compra
      // =======================
      pdfPurchaseOrderPrint() {

      }

      getCodigoDeCostos(){
        let arraySort: any;
    
        let codigoCostosArray : any = [];
    
        // Proyectos registrados
        this._codigoDeCostosService.getCodigoDeCostosAll().subscribe(
          res=> {
            console.log('Codigo de costos', res)      
            this.datasourceCodigoDeCostos = new MatTableDataSource(res);
          },
          error => console.log("error consulta regiones",error)
        )
      }     
}