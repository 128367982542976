import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { fracAranModel } from 'src/app/models/frac-aran.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})


export class fracAranservice {

    constructor(protected http: HttpClient) { }
  
      getfracAranAll() {
          const headers : HttpHeaders = new HttpHeaders()
              .set('Content-Type', 'application/json')
            //   .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
              
          return this.http.get<fracAranModel[]>(environment.urlapi + environment.apiContextDrivers + 'FraccAran', {headers});
      }
  
  }