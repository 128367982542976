import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { EmployeReviewModel } from '../models/employe-review.model';
import { environment } from '../../environments/environment';
import { supplierActivityModel } from '../models/supplier-activity.model';
import { employeModel } from '../models/employe.model';


@Injectable({
    providedIn: 'root'
})

export class employeservice {

  constructor(protected http: HttpClient) { }

    // Listado de empleados
    getemployeAll() {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<employeModel[]>(environment.urlapi + environment.apiContextDrivers + 'Employee/lista', {headers});
    }

    getemployeReviewAll() {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<EmployeReviewModel[]>(environment.urlapi + environment.apiContextDrivers + 'Employee/lista', {headers});
    }

    putemployeeStatus(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.post(environment.urlapi + environment.apiContextDrivers + 'Employee/cambio_estatus', arrayToDb, {headers});
    }

    updateemployee(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Employee/actualizar', arrayToDb, {headers});
    }

    insertemployee(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.post(environment.urlapi+environment.apiContextDrivers+'Employee/registrar',arrayToDb, {headers});
    }

    getemployeeById(id : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<employeModel[]>(environment.urlapi + environment.apiContextDrivers + 'Employee/' + id, {headers});
    }

    postDocument(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Document/subir', arrayToDb, {headers});
    }

    // * * * DIRECCION * * * //
    getemployeeDireccionById(id : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<employeModel[]>(environment.urlapi + environment.apiContextDrivers + 'Employee/direccion/' + id, {headers});
    }

    updateemployeeDirection(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Employee/direccion/actualizar', arrayToDb, {headers});
    }

    // * * * EMPLEO ANTERIOR * * * //
    getemployeeLastWorkById(id : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<employeModel[]>(environment.urlapi + environment.apiContextDrivers + 'Employee/empleoanterior/' + id, {headers});
    }

    updateemployeeLastWork(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Employee/empleoanterior/actualizar', arrayToDb, {headers});
    }

    // * * * DATOS GENERALES * * * //
    getemployeeGeneralById(id : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<employeModel[]>(environment.urlapi + environment.apiContextDrivers + 'Employee/datosgenerales/' + id, {headers});
    }

    updateemployeeGeneral(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Employee/datosgenerales/actualizar', arrayToDb, {headers});
    }

    // * * * FAMILIARES * * *
    getemployeeFammilyById(id : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<employeModel[]>(environment.urlapi + environment.apiContextDrivers + 'Employee/familia/' + id, {headers});
    }

    updateemployeeFammily(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Employee/familia/actualizar', arrayToDb, {headers});
    }

    deleteemployeeFammily(id : any) {
        const headers : HttpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.delete(environment.urlapi + environment.apiContextDrivers + 'Employee/familia/eliminar/' + id, {headers});
    }

    insertemployeeFammily(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
        .set('Content-Type', 'application/json')
        .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
    return this.http.post(environment.urlapi + environment.apiContextDrivers + 'Employee/familia/registrar', arrayToDb, {headers});
    }
}