import { Component, OnInit } from '@angular/core';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-user-manage',
  templateUrl: './user-manage.component.html',
  styleUrls: ['./user-manage.component.css']
})
export class UserManageComponent implements OnInit {

  usuarioId : any;
  profile : any;

  constructor() { }

  ngOnInit(): void {
    this.decode();
  }

  
  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    let perfil_usuarioid = jwt_decode(token)["perfil_usuarioid"];
    this.usuarioId = decodeUser;
    this.profile = perfil_usuarioid;

    console.warn('usuario para bloquear', this.profile)
  }

}
