<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div class="main-container"> 
    <!-- ============================================================== -->
    <!-- Topbar - style you can find in header.scss -->
    <!-- ============================================================== -->
    <mat-toolbar style="background-color: rgb(58, 29, 173)" class="topbar telative"> <!--color="primary"-->
        <!-- ============================================================== -->
        <!-- Logo - style you can find in header.scss -->
        <!-- ============================================================== -->
        <div class="navbar-header">
            <a class="navbar-brand" href="index.html">
                <!-- Logo icon --><b>
                    <!--You can put here icon as well // <i class="wi wi-sunset"></i> //-->
                    <!-- Dark Logo icon -->
                    <img src="assets/images/logo-icon.png" alt="homepage" class="dark-logo">
                    <!-- Light Logo icon -->
                    <!-- <img src="assets/images/logo-light-icon.png" alt="homepage" class="light-logo"> -->
                    <img src="assets/images/logo-banner_new.jpg" alt="homepage" class="light-logo">
                    <!-- <img src="assets/images/logo-comercial20.jpeg" alt="homepage" class="light-logo"> -->
                </b>
                <!--End Logo icon -->
                <!-- Logo text -->
                <span fxShow="false" fxShow.gt-xs>
                 <!-- dark Logo text -->
                 <img src="assets/images/logo-text.png" alt="homepage" class="dark-logo">
                 <!-- Light Logo text -->    
                 <!-- <img src="assets/images/logo-light-text.png" class="light-logo" alt="homepage"> -->
                 <img src="assets/images/LogoTextComercial.png" class="light-logo" alt="homepage">
                </span> </a>
        </div>
        <!-- ============================================================== -->
        <!-- sidebar toggle -->
        <!-- ============================================================== -->
        
        <button mat-icon-button (click)="snav.toggle()" value="sidebarclosed">
            <mat-icon>menu</mat-icon>
        </button>
        <span fxFlex>
            <div style="text-align: right;" class="example-button-container">
                <p style="color: white;">Hola&nbsp; {{this.userName}}&nbsp;&nbsp;&nbsp;
                <ng-container>
                    <!-- <button mat-mini-fab style="background-color: rgb(255, 2, 49);" aria-label="Example icon button with a plus one icon"> -->
                        <!-- {{this.QtyPONotInvoice}} -->
                          <!-- <mat-icon>plus_one</mat-icon> -->
                      <!-- </button> -->
                </ng-container>
                </p>
              </div>

            <!-- <button mat-raised-button color="primary" matBadge="8" matBadgePosition="before" matBadgeColor="accent">ODC</button> -->
    </span>
        <!-- ============================================================== -->
        <!-- app header component - style you can find in header.scss / header.component.ts-->
        <!-- ============================================================== -->
        <app-header></app-header>
    </mat-toolbar>
    <!-- ============================================================== -->
    <!-- End Topbar - style you can find in pages.scss -->
    <!-- ============================================================== -->
    <mat-sidenav-container class="example-sidenav-container" [style.marginTop.px]="mobileQuery.matches ? 0 : 0">
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        <mat-sidenav #snav id="snav" class="dark-sidebar pl-xs" [mode]="mobileQuery.matches ? 'side' : 'over'" fixedTopGap="0" [opened]="mobileQuery.matches" [disableClose]="mobileQuery.matches"  >
            
                <app-sidebar></app-sidebar>
               
            
        </mat-sidenav>
        <!-- ============================================================== -->
        <!-- Sidebar - style you can find in sidebar.scss -->
        <!-- ============================================================== -->
        
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
        <mat-sidenav-content class="page-wrapper">
                 
                <div class="page-content">
                   
                        <router-outlet><app-spinner></app-spinner></router-outlet>
                    
                </div>    
               
        </mat-sidenav-content>
        <!-- ============================================================== -->
        <!-- Page container - style you can find in pages.scss -->
        <!-- ============================================================== -->
    </mat-sidenav-container>
</div>