<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
      <div class="header-body">
        <!-- Card stats -->
      </div>
    </div>
  </div>
  <!-- Page content -->
  <div class="container-fluid mt--7">
  
    <!-- Table -->
    <div class="row">
      <div class="col">
        <div class="card shadow">
          <div class="card-header border-0">
            <h3 class="mb-0">Revisión en Almacén</h3>
          </div>
          
          <!-- <form> -->
            
            <h6 class="heading-small text-muted mb-4"></h6>
            <hr class="my-4" />
          <!-- </form> -->
          <!-- <div class="card">
            <table>
              <tr>
                <td class="col20"><input type="text" class="form-control form-control-line" placeholder="BUSCAR" (keyup)="filtrar($event)"></td>
                <td class="col70"></td>
                <td class="col10"><button mat-raised-button color="primary" (click)="descargarExcel()">Descargar</button></td>
                <td class="col10"><button mat-raised-button color="accent" (click)="nuevaRequisicion($event)">Nueva Requisición</button></td>
                <td class="col10"></td>
              </tr>
            </table>
            <br>
          </div> -->

          <!-- INICIA CUERPO DE LA PÁGINA-->

<div class="col-12 col-md-12 p-0">
          <div class="card">
            <div class="card-body card-body-table">
              <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8" style="width: 95%;">
      
                <!-- ID Column -->
                <ng-container matColumnDef="requisicion_Codigo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> <span>Requisición</span> </th>
                  <td mat-cell *matCellDef="let element"> {{element.requisicion_Codigo}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="descripcion">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30"> Descripción </th>
                  <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="Estatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Estatus </th>
                  <td mat-cell *matCellDef="let element"> {{element.estado}} </td>
                </ng-container>

                <!-- Email Column -->
                <ng-container matColumnDef="editar">
                  <th mat-header-cell *matHeaderCellDef class=".colbuttons"> Editar </th>
                  <td mat-cell *matCellDef="let element"> <button mat-button color="primary" (click)="editRequisicion(element, $event)">Editar</button> </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </mat-table>
        
            </div>
            <!-- <mat-paginator [pageSizeOptions]="[20, 100, 150]" [showFirstLastButtons]="true" [pageSize]="pageSize" [length]="totalSize"  (page)="handlePage($event)" style="width: 95%;"></mat-paginator> -->
          </div>
</div>
</div>
</div>
</div>
</div>