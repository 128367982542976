import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { employeModel } from 'src/app/models/employe.model';
import { employeservice } from 'src/app/services/employe.service';
import { SupplierDetailComponent } from 'src/app/components/supplier-detail/supplier-detail.component';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
// import { RepseSupplierReviewDetailComponent } from '../../../components/repse-supplier-review-detail/repse-supplier-review-detail.component';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { RepseReviewAproveComponent } from '../repse-review-aprove/repse-review-aprove.component';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee-review-last-work-data',
  templateUrl: './employee-review-last-work-data.component.html',
  styleUrls: ['./employee-review-last-work-data.component.css']
})
export class EmployeeReviewLastWorkDataComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<employeModel>
employeeId : number;
pageInfo : any;
arraySupplierGlobal : any = [];
usuarioId : string;
dataSourceEmployee : any;
arrayEmployeeGlobal : any = [];

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<employeModel>
      , private _employeservice : employeservice
      , @Inject(MAT_DIALOG_DATA) public data
      , private formBuilder: FormBuilder
    ) { 
      this.pageInfo = data.arrayData;
      this.newForm = this.formBuilder.group({
        
      })
    }

  displayedColumns = ['employee_id', 'documento', 'estatus', 'ver', 'revision'];

  public newForm: FormGroup;
  fileDownload : any;

  ngOnInit(): void {
    this.decode();
    this.employeeId = this.pageInfo.id;
    this.getemployeeDocuments();
  }

  cancel(){
    this.dialogRef.close();
  }

  descargarArchivos(){
  
    let posicion : number;
    let posicionInicial : number;

    this.fileDownload = ''
    this.dataSourceShow.filteredData.forEach(element => {

      let binaryData : any = []
      let dataType = 'png';
      let fileName = 'Nombre'
      let fileNameCompleto : string;
      let filePath : string;

      if(element.url != ''){

        posicionInicial = element.url.indexOf("6%2")
        posicion = element.url.indexOf("?")

        fileNameCompleto = element.url.substring(posicion, posicionInicial + 3)
        dataType = fileNameCompleto.substring(fileNameCompleto.indexOf(".") + 1, fileNameCompleto.length)
        fileName = fileNameCompleto.substring(0, fileNameCompleto.indexOf("."))

        // DESCARGAR ARCHIVO
        // ==================
        binaryData.push(element.url)
        filePath = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        
        let downloadLink = document.createElement('a');
        downloadLink.href = filePath;
        downloadLink.setAttribute('download', fileNameCompleto);
        downloadLink.setAttribute('src', fileNameCompleto);
        document.body.appendChild(downloadLink);
        downloadLink.click();

      }
    
    });

  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    this.usuarioId = decodeUser;
  
  }

  // -------------
  // SERVICIOS
  // -------------

  getemployeeDocuments(){
    let arrayDocumentos : any;

    // Empleado registrado
    this._employeservice.getemployeeLastWorkById(this.employeeId).subscribe(
      emp=> {
        arrayDocumentos = emp;
        this.dataSourceEmployee = new MatTableDataSource(emp);

        this.arrayEmployeeGlobal = [];
        this.arrayEmployeeGlobal.push({employee_id : 1, documento : 'Nombre compañía: ' + this.dataSourceEmployee.filteredData.empresa, estatus : 3, aprobacion : false, url : '', idDocumento : 0}
        , {employee_id : 1, documento : 'Jefe directo: ' + this.dataSourceEmployee.filteredData.jefeDirecto, estatus : 3, aprobacion : false, url : '', idDocumento : 0}
        , {employee_id : 1, documento : 'Teléfono: ' + this.dataSourceEmployee.filteredData.telefono, estatus : 3, aprobacion : false, url : '', idDocumento : 0}
        , {employee_id : 1, documento : 'correo electrónico: ' + this.dataSourceEmployee.filteredData.correo, estatus : 3, aprobacion : false, url : '', idDocumento : 0}
        , {employee_id : 1, documento : 'puesto que desempeñaba: ' + this.dataSourceEmployee.filteredData.puesto, estatus : 3, aprobacion : false, url : '', idDocumento : 0}
        , {employee_id : 1, documento : 'Motivo de separación: ' + this.dataSourceEmployee.filteredData.motivoSeparacion, estatus : 3, aprobacion : false, url : '', idDocumento : 0}
        )

        this.dataSourceShow = new MatTableDataSource(this.arrayEmployeeGlobal);
      }
    
    )}

}
