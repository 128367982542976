
<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row" [formGroup]="newForm">
        <div style="width: 200px;"></div>
        <div class="col">
            <div class="card shadow">
                <br>
                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div>
                        <table>
                            <tr>
                                <td class="col20"></td>
                                <td class="col70"></td>
                                <td class="col10"></td>
                                <td class="col10" hidden><button mat-raised-button color="primary"
                                    (click)="descargarArchivos()">Descargar</button></td>
                                <td class="col10"></td>
                            </tr>
                        </table>
                        <br>
                    </div>
                    <div class="card">
                        <div class="card-body card-body-table">
                            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8" style="width: 80%;">
                                
                                <ng-container matColumnDef="employee_id" style="width: 300px;">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>Id </th>
                                    <td mat-cell *matCellDef="let element" hidden> {{element.employee_id}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="documento" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Documento</th>
                                    <td mat-cell *matCellDef="let element" class="col300"> {{element.documento}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="estatus" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Estatus </th>
                                    <td mat-cell *matCellDef="let element" class="col300"> 
                                        <ng-container *ngIf="element.estatus == 0">POR REVISAR</ng-container>
                                        <ng-container *ngIf="element.estatus == 1">APROBADO</ng-container>
                                        <ng-container *ngIf="element.estatus == 2">RECHAZADO</ng-container>
                                        <ng-container *ngIf="element.estatus == 3">NO APLICA</ng-container>
                                        <ng-container *ngIf="element.estatus == 4">NO ENVIADA</ng-container>
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="ver">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Ver </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 0" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-orange"></i> </button> </ng-container> 
                                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 1" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-green"></i> </button> </ng-container> 
                                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 2" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-red"></i> </button> </ng-container> 
                                        <ng-container><button *ngIf="element.aprobacion == true && (element.estatus == 2 || element.estatus == 1)" mat-button color="primary" (click)="reactive(element, $event)"><i class="fa fa-retweet fa-lg icon-cog-reload"></i> </button> </ng-container> 
                                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 4" mat-button color="primary"><i class="fa fa-eye fa-lg icon-cog-gray"></i> </button> </ng-container> 
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="revision">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Revisión </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <ng-container *ngIf="usuarioId != 'revisor'"><button *ngIf="element.aprobacion == true && element.estatus == 0" mat-button color="primary"(click)="review(element, $event)"><i class="fa fa-arrow-right fa-lg icon-cog-blue"></i> </button></ng-container>
                                        <ng-container *ngIf="usuarioId != 'revisor'"><button *ngIf="element.aprobacion == true && element.estatus == 2" mat-button color="primary"(click)="review(element, $event)"><i class="fa fa-arrow-right fa-lg icon-cog-red"></i> </button></ng-container>
                                    </td>
                                </ng-container>
<!-- 
                                <ng-container matColumnDef="descarga">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Descarga </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.aprobacion == true && element.estatus != 4">
                                            <a href="{{ element.url }}" src="{{ element.url }}" download target="_blank"><i class="fa fa-download fa-lg icon-cog-orange"></i></a>
                                        </ng-container> 
                                        
                                    </td>
                                </ng-container> -->

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </mat-table>
                            <br><br><br>

                            <!-- INICIA TABLA DE FAMILIARES -->
                            <div class="col-12 col-md-12 p-0">
                                <div class="card">
                                  <div class="card-body card-body-table">
                                    <mat-table [dataSource]="dataSourceEmployee" matSort class="mat-elevation-z8" style="width: 95%;">
                                        
                                      <!-- ID Column -->
                                      <ng-container matColumnDef="id">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;" hidden> <span>Id</span> </th>
                                        <td mat-cell *matCellDef="let element" hidden>{{element.id}}
                                        </td>
                                      </ng-container>

                                      <!-- ID Column -->
                                      <ng-container matColumnDef="url">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;" hidden> <span>url</span> </th>
                                        <td mat-cell *matCellDef="let element" hidden>{{element.url}}
                                        </td>
                                      </ng-container>
                            
                                      <!-- ID Column -->
                                      <ng-container matColumnDef="nombre">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> <span>Nombre</span> </th>
                                        <td mat-cell *matCellDef="let element">{{element.nombre}}
                                        </td>
                                      </ng-container>
                            
                                      <!-- ID Column -->
                                      <ng-container matColumnDef="aPaterno">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> <span>Primer Apellido</span> </th>
                                        <td mat-cell *matCellDef="let element">{{element.aPaterno}}
                                        </td>
                                      </ng-container>
                                      
                                      <!-- Name Column -->
                                      <ng-container matColumnDef="aMaterno">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" matInput style="width: 300px;"> Segundo Apellido </th>
                                        <td mat-cell *matCellDef="let element"> {{element.aMaterno}}
                                        </td>
                                      </ng-container>
                                    
                                      <!-- Email Column -->
                                        <ng-container matColumnDef="fechaNacimiento">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Fecha de Nacimiento </th>
                                            <td mat-cell *matCellDef="let element"> {{element.fechaNacimiento | date: 'dd-MM-yyyy'}}
                                            </td>
                                        </ng-container>
                                    
                                      <!-- Email Column -->
                                      <ng-container matColumnDef="sexo">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Sexo </th>
                                        <td mat-cell *matCellDef="let element"> 
                                            <ng-container *ngIf= "element.sexo == 0">Femenino</ng-container>
                                            <ng-container *ngIf= "element.sexo == 1">Masculino</ng-container>
                                        </td>
                                      </ng-container>
                            
                                        <!-- Email Column -->
                                        <ng-container matColumnDef="parentesco">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Parentesco </th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container *ngIf="element.parentesco == 0">Hijo</ng-container>
                                                <ng-container *ngIf="element.parentesco == 1">Esposo</ng-container>
                                            </td>
                                        </ng-container>
                            
                                            <ng-container matColumnDef="ver">
                                                <th mat-header-cell *matHeaderCellDef class="colbuttons"> Ver </th>
                                                <td mat-cell *matCellDef="let element">
                                                    <ng-container><button *ngIf="element.estatus == 0" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-orange"></i> </button> </ng-container> 
                                                    <ng-container><button *ngIf="element.estatus == 1" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-green"></i> </button> </ng-container> 
                                                    <ng-container><button *ngIf="element.estatus == 2" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-red"></i> </button> </ng-container> 
                                                    <ng-container><button *ngIf="(element.estatus == 2 || element.estatus == 1)" mat-button color="primary" (click)="reactive(element, $event)"><i class="fa fa-retweet fa-lg icon-cog-reload"></i> </button> </ng-container> 
                                                    <ng-container><button *ngIf="element.estatus == 4" mat-button color="primary"><i class="fa fa-eye fa-lg icon-cog-gray"></i> </button> </ng-container> 
                                                </td>
                                            </ng-container>
                                            
                                            <ng-container matColumnDef="revision"> 
                                                <th mat-header-cell *matHeaderCellDef class="colbuttons"> Revisión </th>
                                                <td mat-cell *matCellDef="let element"> 
                                                    <ng-container *ngIf="usuarioId != 'revisor'"><button *ngIf="element.estatus == 0" mat-button color="primary"(click)="review(element, $event)"><i class="fa fa-arrow-right fa-lg icon-cog-blue"></i> </button></ng-container>
                                                    <ng-container *ngIf="usuarioId != 'revisor'"><button *ngIf="element.estatus == 2" mat-button color="primary"(click)="review(element, $event)"><i class="fa fa-arrow-right fa-lg icon-cog-red"></i> </button></ng-container>
                                                </td>
                                            </ng-container>
                            
                                        <!-- Email Column -->
                                        <!-- <ng-container matColumnDef="edit">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Editar </th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container ><button mat-button color="primary"(click)="edit(element, $event)"><i class="fa fa-pencil fa-lg icon-cog-orange"></i> </button></ng-container>
                                            </td>
                                        </ng-container> -->
                            
                                        <!-- Email Column -->
                                        <!-- <ng-container matColumnDef="delete">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> Eliminar </th>
                                            <td mat-cell *matCellDef="let element">
                                                <ng-container ><button mat-button color="primary"(click)="delete(element, $event)"><i class="fa fa-ban fa-lg icon-cog-red"></i> </button></ng-container>
                                            </td>
                                        </ng-container> -->
                            
                                         <!-- Email Column -->
                                         <!-- <ng-container matColumnDef="linea">
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" hidden> Línea </th>
                                            <td mat-cell *matCellDef="let element" hidden>
                                            </td>
                                        </ng-container> -->
                                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsFammily"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsFammily;"></tr>
                                    </mat-table>
                                  </div>
                                </div>
                                <!-- TERMINA TABLA DE FAMILIARES -->

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<br><br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <!-- <div><button mat-raised-button color="accent" (click)="save($event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div> -->
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="warn" (click)="cancel()"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 


