<div fxLayout="row">
  <h4>Catálogo de clientes</h4>
</div>
<br>
<div mat-dialog-content-example [formGroup]="newProject">
  <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Nombre</div>
      </div>
      <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
            <input [type]="nombre" matInput class="form-control form-control-line control" formControlName="nombre" disabled >
          </mat-form-field>
          <span *ngIf="!newProject.get('nombre').valid && newProject.get('nombre').touched" class="help-block" style="color: red;"> El nombre es requerido</span>
          </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>Dirección</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <input [type]="direccion" matInput class="form-control form-control-line control" placeholder="" formControlName="direccion">
          </mat-form-field>
          <span *ngIf="!newProject.get('direccion').valid && newProject.get('direccion').touched" class="help-block" style="color: red;"> La dirección es requerida</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Ciudad</div>
      </div>
      <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
            <input [type]="ciudad" matInput class="form-control form-control-line control" formControlName="ciudad" disabled >
          </mat-form-field>
          <span *ngIf="!newProject.get('ciudad').valid && newProject.get('ciudad').touched" class="help-block" style="color: red;"> La ciudad es requerida</span>
          </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>Estado</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <input [type]="estado" matInput class="form-control form-control-line control" placeholder="" formControlName="estado">
          </mat-form-field>
          <span *ngIf="!newProject.get('estado').valid && newProject.get('estado').touched" class="help-block" style="color: red;"> El Estado es requerido</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Responsable</div>
      </div>
      <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
            <input [type]="responsable" matInput class="form-control form-control-line control" formControlName="responsable" >
          </mat-form-field>
          <span *ngIf="!newProject.get('responsable').valid && newProject.get('responsable').touched" class="help-block" style="color: red;"> El responsable es requerido</span>
          </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>Teléfono</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <input [type]="telefono" matInput class="form-control form-control-line control" placeholder="" formControlName="telefono">
          </mat-form-field>
          <span *ngIf="!newProject.get('telefono').valid && newProject.get('telefono').touched" class="help-block" style="color: red;"> El telefono es requerido</span>
        </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>RFC</div>
      </div>
      <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <input [type]="rfc" matInput class="form-control form-control-line control" placeholder="" formControlName="rfc" (change)="onChangeRFC($event)">
          </mat-form-field>
          <span *ngIf="!newProject.get('rfc').valid && newProject.get('rfc').touched" class="help-block" style="color: red;"> El RFC es requerido</span>
          </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br></div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>

        </div>
      </div>
    </div>
</div>

<div fxLayout="row" class="division">
  <div fxFlex.gt-sm="20%">
      <div></div>
  </div>
  <div fxFlex.gt-sm="20%" >
      <div></div>
  </div>
  <div fxFlex.gt-sm="20%" >
    <div></div>
  </div>
  <div fxFlex.gt-sm="20%" >
    <div><button mat-raised-button color="accent" (click)="save(newProject, $event)">Guardar</button></div>
  </div>
  <div fxFlex.gt-sm="20%" >
    <div><button mat-raised-button color="warn" (click)="cancel($event)">Cancelar</button></div>
  </div>
</div>
