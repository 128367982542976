<p>Revisión de documentos Empleados</p>
<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
        </div>
    </div>
</div>
 <div mat-dialog-content-example [formGroup]="newProject">
   <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="100%">
            <div><textarea type="[comentarios]" placeholder="Comentarios" formControlName="comentarios" style="height: 100px;"></textarea> </div>
        </div>
    </div>
    <br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><button mat-raised-button color="primary" (click)="aprove($event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
        </div>
        <div fxFlex.gt-sm="20%">
            <div><button mat-raised-button color="primary" (click)="deny($event)"><i class="fa fa-ban fa-lg icon-cog-wite"></i></button></div>
        </div>
        <div fxFlex.gt-sm="20%">
            <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
        </div>
        <div fxFlex.gt-sm="20%">
            <div></div>
        </div>
        <div fxFlex.gt-sm="20%">
            <div></div>
        </div>
    </div> 
</div>
