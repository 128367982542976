<div class="login-register" style="background-image:url(assets/images/background/logoBackground06.jpg);">
    <!-- <div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);"></div>  -->
    <!-- <img src="assets/images/background/logoBackground.png" style="filter: opacity(40%); position: fixed; vertical-align: middle; align-self: center;"> -->
    <div class="login-register-box">
        <!-- <div class="bg-info text-white m-b-10 text-center p-t-10 p-b-10 alert-box">
            <span class="m-r-10">Username : admin</span> |
            <span class="m-l-10">Password : admin123</span>
        </div> -->
        <mat-card class="m-t-10">
        <mat-card-content>
        <form id="loginform" [formGroup]="loginform" (ngSubmit)="loginReq()">
            <div class="text-center">
                <img alt="homepage" src="assets/images/logo-login.jpg">
                <!-- <h4 class="m-t-0">Login to App</h4> -->
            </div>
            <div *ngIf="msg" class="bg-danger p-10 text-white">{{ msg }}</div>
            <div fxLayout="row wrap">
                <!-- col full-->
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                    <input matInput placeholder="Usuario" formControlName="nombreusuario" required="" #u1>
                    </mat-form-field>
                </div>
                <!-- col full-->
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                    <mat-form-field>
                    <input matInput type="password" placeholder="Clave" formControlName="contrasegna" required="" #p2>
                    </mat-form-field>
                </div>
                <!-- col half-->
                <!-- <div fxFlex.gt-sm="50" fxFlex.gt-xs="50">
                    <mat-checkbox color="warn">Remember me</mat-checkbox>
                </div> -->
                <!-- col half-->
                <!-- <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" class="text-right">
                    <a [routerLink]="['/authentication/forgot']" class="link">Forgot pwd?</a>
                </div> -->
                <!-- col full-->
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                    <!-- <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" (click)="check(u1.value, p2.value)" type="button">Acceder</button> -->
                    <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" (click)="loginReq()" type="button">Acceder</button>
                    <p style="font-family: Arial; font-size: x-small;">V1.00.00 INTEGRA</p>
                </div>
                <!-- col full-->
                <!-- <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="text-center">
                    <span>Don't have an account?
                        <a [routerLink]="['/authentication/register']" class="text-info link">Register</a>
                    </span>
                </div> -->
            </div>
        </form>
        </mat-card-content>
        </mat-card>
    </div>
</div>Designed by Freepik