<div class="row">
    <div style="width: 200px;"></div>
        <div class="col">
            <div class="card shadow">
                <br>
                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div fxLayout="row" class="division">
                        <div fxFlex.gt-sm="10%"></div>
                        <div fxFlex.gt-sm="30%">
                            <div>Año<br>
                                <mat-form-field>
                                    <mat-select class="form-control control" [(ngModel)]="year" (selectionChange)="yearChange($event)">
                                    <mat-option *ngFor="let yr of arrayYear" [value]="yr.year_id">
                                        {{yr.year}}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex.gt-sm="5%"></div>
                        <div fxFlex.gt-sm="65%">
                        <div><br><br>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-12 p-0">
                    <div fxLayout="row" class="division">
                        <div fxFlex.gt-sm="10%"></div>
                        <div fxFlex.gt-sm="30%">
                            <div>Mes<br>
                                <mat-form-field>
                                    <mat-select class="form-control control" [(ngModel)]="month" (selectionChange)="monthChange($event)">
                                    <mat-option *ngFor="let mt of arrayMonth" [value]="mt.month_id">
                                        {{mt.month}}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex.gt-sm="5%"></div>
                        <div fxFlex.gt-sm="65%">
                        <div><br><br>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12 p-0" [formGroup]="formSupplier">
                    <div fxLayout="row" class="division">
                        <div fxFlex.gt-sm="10%"></div>
                        <div fxFlex.gt-sm="30%">
                            <div>Proveedor<br>
                                <mat-form-field class="example-full-width" appearance="fill">
                                    <mat-label>Provedor</mat-label>
                                    <input
                                      type="text"
                                      matInput
                                      [formControl]="proveedor_id"
                                      [matAutocomplete]="auto"/>    
                        
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                                      <mat-option *ngFor="let op of filteredOptions | async" [value]="op" (selectionChange)="selectsupplier($event)">
                                        <ng-container *ngIf="op.autorizado == true"> <p style="color: navy;">{{op.nombre}}</p></ng-container>
                                        <ng-container *ngIf="op.autorizado == false"> <p style="color: red;">{{op.nombre}}</p></ng-container>
                                      </mat-option>
                                    </mat-autocomplete>
                                  </mat-form-field>
                                  <span *ngIf="!formSupplier.get('proveedor_id').valid && formSupplier.get('proveedor_id').touched" class="help-block" style="color: red;"> seleccione un proveedor</span>
                            </div>
                        </div>
                        <div fxFlex.gt-sm="5%"></div>
                        <div fxFlex.gt-sm="65%">
                        <div><br><br>
                            <td class="col10"><button mat-raised-button color="accent" (click)="search()">Genera reporte</button></td>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
