<div class="row">
    <div style="width: 200px;"></div>
    <div class="col">
        <div class="card shadow">
            <br>
            <!-- INICIA CUERPO DE LA PÁGINA-->
            <div class="col-12 col-md-12 p-0">
                <div fxLayout="row" class="division">
                <div fxFlex.gt-sm="10%"></div>
                <div fxFlex.gt-sm="70%">

                    <div>Fecha inicial<br>
                        <mat-form-field appearance="fill" style="width: 30%;">
                            <mat-label>{{fechaIni}}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="fechaIni" (dateChange)="fechaInicial($event)" class="control" >
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker [startAt]="fecha" #picker></mat-datepicker>
                          </mat-form-field>
                    </div>

                    <div>Fecha final<br>
                        <mat-form-field appearance="fill" style="width: 30%;">
                            <mat-label>{{fechaFin}}</mat-label><!-- | date: 'dd-MM-yyyy'-->
                            <input matInput [matDatepicker]="pickerFin" [(ngModel)]="fechaFin" (dateChange)="fechaFinal($event)" class="control" >
                            <mat-datepicker-toggle matSuffix [for]="pickerFin"></mat-datepicker-toggle>
                            <mat-datepicker [startAt]="fecha" #pickerFin></mat-datepicker>
                          </mat-form-field>
                    </div>

                </div>
                <div fxFlex.gt-sm="5%"></div>
                <div fxFlex.gt-sm="15%">
                    <div><br><br>
                        <td class="col10"><button mat-raised-button color="accent" (click)="search()">Buscar</button></td>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>