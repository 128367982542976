<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card shadow">
                <div class="card-header border-0">
                    <h3 class="mb-0">Empleados</h3>
                </div>
                <!-- <form> -->

                <h6 class="heading-small text-muted mb-4"></h6>
                <hr class="my-4" />
                <!-- </form> -->
                <div class="card">
                    <br>
                </div>

                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                        <div class="card-body card-body-table">
                            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8"
                                style="width: 95%;">

                                
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>id </th>
                                    <td mat-cell *matCellDef="let element" hidden> {{element.id}} </td>
                                </ng-container>

                                
                                <ng-container matColumnDef="nombre">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30">Nombre </th>
                                    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                                </ng-container>

                                <ng-container matColumnDef="aPaterno">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30">Apellido paterno </th>
                                    <td mat-cell *matCellDef="let element"> {{element.aPaterno}} </td>
                                </ng-container>

                                <ng-container matColumnDef="aMaterno">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30">Apellido materno </th>
                                    <td mat-cell *matCellDef="let element"> {{element.aMaterno}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="rfc">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> rfc </th>
                                    <td mat-cell *matCellDef="let element"> {{element.rfc}}
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="estatus">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Estatus </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.estatus == true">ACTIVO</ng-container>
                                        <ng-container *ngIf="element.estatus == false">INACTIVO</ng-container>
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="autorize">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Examinar </th>
                                    <td mat-cell *matCellDef="let element"> <button mat-button color="primary"
                                            (click)="edit(element, $event)" class="colbuttons"><i class="fa fa-arrow-right fa-lg icon-cog"></i> </button> </td>
                                </ng-container>

                                <ng-container matColumnDef="download">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Descargar </th>
                                    <td mat-cell *matCellDef="let element"> <button mat-button color="primary"
                                            (click)="downloadInfo(element, $event)" class="colbuttons"><i class="fa fa-download fa-lg icon-cog-green"></i> </button> </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </mat-table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>