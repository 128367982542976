<div class="card">
    <table>
        <tr>
            <td class="col20">{{nombreProveedor}}</td>
            <td class="col70">{{usuarioId}}</td>
            <td class="col10"></td>
            <td class="col10"></td>
            <td class="col10"></td>
        </tr><br>
        <tr>
            <td class="col20"></td>
            <td class="col70"><button mat-raised-button color="accent" (click)="changeAccess($event)">Cambiar clave de acceso</button></td>
            <td class="col10"></td>
            <td class="col10"></td>
            <td class="col10"></td>
        </tr>
    </table>
    <br>
</div>
