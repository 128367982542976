import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { employeModel } from 'src/app/models/employe.model';
import { employeservice } from 'src/app/services/employe.service';
import { SupplierDetailComponent } from 'src/app/components/supplier-detail/supplier-detail.component';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EmployeeAproveComponent } from '../../employee-aprove/employee-aprove.component';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
// import { RepseSupplierReviewDetailComponent } from '../../../components/repse-supplier-review-detail/repse-supplier-review-detail.component';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { RepseReviewAproveComponent } from '../repse-review-aprove/repse-review-aprove.component';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';


@Component({
  selector: 'app-employee-review-address-data',
  templateUrl: './employee-review-address-data.component.html',
  styleUrls: ['./employee-review-address-data.component.css']
})
export class EmployeeReviewAddressDataComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<employeModel>
employeeId : number;
pageInfo : any;
arraySupplierGlobal : any = [];
usuarioId : string;
dataSourceEmployee : any;
arrayEmployeeGlobal : any = [];

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<employeModel>
      , private _employeservice : employeservice
      , @Inject(MAT_DIALOG_DATA) public data
      , private formBuilder: FormBuilder
      , public dialog: MatDialog
      , private _UploadFileService : UploadFileService
    ) { 
      this.pageInfo = data.arrayData;
      this.newForm = this.formBuilder.group({
        
      })
    }

  displayedColumns = ['employee_id', 'documento', 'estatus', 'ver', 'revision'];

  public newForm: FormGroup;
  fileDownload : any;

  ngOnInit(): void {
    this.decode();
    this.employeeId = this.pageInfo.id;
    this.getemployeeDocuments();
  }

  cancel(){
    this.dialogRef.close();
  }

  descargarArchivos(){
  
    let posicion : number;
    let posicionInicial : number;

    this.fileDownload = ''
    this.dataSourceShow.filteredData.forEach(element => {

      let binaryData : any = []
      let dataType = 'png';
      let fileName = 'Nombre'
      let fileNameCompleto : string;
      let filePath : string;

      if(element.url != ''){

        posicionInicial = element.url.indexOf("6%2")
        posicion = element.url.indexOf("?")

        fileNameCompleto = element.url.substring(posicion, posicionInicial + 3)
        dataType = fileNameCompleto.substring(fileNameCompleto.indexOf(".") + 1, fileNameCompleto.length)
        fileName = fileNameCompleto.substring(0, fileNameCompleto.indexOf("."))

        // DESCARGAR ARCHIVO
        // ==================
        binaryData.push(element.url)
        filePath = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        
        let downloadLink = document.createElement('a');
        downloadLink.href = filePath;
        downloadLink.setAttribute('download', fileNameCompleto);
        downloadLink.setAttribute('src', fileNameCompleto);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    
    });

  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    this.usuarioId = decodeUser;
  }

  showDocument(arrayDocumentosFiltrados : any, categoriaDocumento : number, anio: number, mes: number, titulo : string){
    let arraySupplier: any[] = [];
      let arrayDocumentos : any;
      let arrayDocumentoPorEmpleado : any;
      let urlShow : any = '';
      let estadoShow : number = 3;
      let idDocumentShow : number = 0;
    
    urlShow = '';
    estadoShow = 4;
    idDocumentShow = 0;
    arrayDocumentoPorEmpleado = arrayDocumentosFiltrados.find(e => e.categoriaDocumento == categoriaDocumento)

    if(arrayDocumentoPorEmpleado != undefined){ 
      urlShow = (arrayDocumentoPorEmpleado.urlDocumento.length > 0) ? arrayDocumentoPorEmpleado.urlDocumento : '';
      estadoShow = (arrayDocumentoPorEmpleado.estado != undefined) ? arrayDocumentoPorEmpleado.estado : 0;
      idDocumentShow = (arrayDocumentoPorEmpleado.idDocumento != undefined) ? arrayDocumentoPorEmpleado.idDocumento : 0;
    }
    this.arrayEmployeeGlobal.push({employee_id : this.employeeId, documento : titulo, estatus : estadoShow, aprobacion : true, url : urlShow, idDocumento : idDocumentShow})
  }

  review(element, event){
    // AUTORIZA O RECHAZA
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'APROBAR/DENEGAR ARCHIVO',
      arrayData : element,
      employee_id: element.employee_id,
      idDocumento: element.idDocumento,
      estadoPantalla: 'Edit'
     
    }
    
    dialogConfig.width = '550px';
    dialogConfig.height = '300px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(EmployeeAproveComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // window.location.reload();
      this.getemployeeDocuments();
    });
  }

  reactive(element, event){
    let arrayToDb : any;

    arrayToDb = ({idDocumento: element.idDocumento
                , estado: 0
               , comentarios: '' })

    this.aproveRejectDocument(arrayToDb);
  }

  aproveRejectDocument(arrayToDb : any){
    // Inserta Archivos en base de datos
    this._UploadFileService.postDocumentosEmpleadosAprobarRechazar(arrayToDb).subscribe(
      res=> {
        this.getemployeeDocuments();
        this.showMessage(2, 'Guardardo', 'success', 'Se actualizo el registro correctamente', 'Cerrar');
      },
      error => console.log("error al aprobar el ocumento",error)
    )
  }

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  view(element, event){
    window.open(element.url);
    // this.getImage();
  }

  // -------------
  // SERVICIOS
  // -------------

  getemployeeDocuments(){
    let arrayDocumentos : any;
    let arrayDocumentosFiltrados : any;

    // Empleado registrado
    this._employeservice.getemployeeDireccionById(this.employeeId).subscribe(
      emp=> {
        arrayDocumentos = emp;
        this.dataSourceEmployee = new MatTableDataSource(emp);
        
        // Lista de documentos
        this._UploadFileService.getemployeeDocumentsAll().subscribe(
          res=> {
            arrayDocumentos = res;

            arrayDocumentosFiltrados = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento >2000 && e.categoriaDocumento < 3000);

            this.arrayEmployeeGlobal = [];
            this.arrayEmployeeGlobal.push({employee_id : 1, documento : 'Código postal: ' + this.dataSourceEmployee.filteredData.codigoPostal, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'nombreVialidad: ' + this.dataSourceEmployee.filteredData.vialidad, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'numeroExterior: ' + this.dataSourceEmployee.filteredData.numeroExterior, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'numeroInterior: ' + this.dataSourceEmployee.filteredData.numeroInterior, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'colonia: ' + this.dataSourceEmployee.filteredData.colonia, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'localidad: ' + this.dataSourceEmployee.filteredData.localidad, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'municipio: ' + this.dataSourceEmployee.filteredData.municipio, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'entidadFederativa: ' + this.dataSourceEmployee.filteredData.entidadFederativa, estatus : 0, aprobacion : false, url : '', idDocumento : 0})

            this.showDocument(arrayDocumentosFiltrados, 2001, 2022, 1, 'Licencia: ')
            this.showDocument(arrayDocumentosFiltrados, 2002, 2022, 1, 'Crédito INFONAVIT: ')
            this.showDocument(arrayDocumentosFiltrados, 2003, 2022, 1, 'Crédito FONACOT: ')
            this.showDocument(arrayDocumentosFiltrados, 2004, 2022, 1, 'Comprobante de domicilio: ')

            this.dataSourceShow = new MatTableDataSource(this.arrayEmployeeGlobal);
          });
      }
    
    )}

}

