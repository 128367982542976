import { Component, EventEmitter, LOCALE_ID, OnInit, Output, ViewChild, NgModule, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { reportMasterModel } from 'src/app/models/reportMaster.model';
import { reportMasterservice } from 'src/app/services/reportMaster.service';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { projectservice } from '../../../services/projects/project.service';
import { formatCurrency } from '@angular/common';
import * as moment from 'moment';
import { CompileShallowModuleMetadata, tokenName } from '@angular/compiler';
import { categoryservice } from '../../../services/category/category.service';
import { projectCategoryservice } from 'src/app/services/projectCtegory/projectCateogry.service';
@Component({
  selector: 'app-report-master-detail',
  templateUrl: './report-master-detail.component.html',
  styleUrls: ['./report-master-detail.component.css']
})
export class ReportMasterDetailComponent implements OnInit {

// =================
// DECLARACIONES
// =================
curr = formatCurrency(10,this.locale, 'MXP');

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<reportMasterModel>
dataSourceShowX : any = [];
datasourcePorjects : any;
datasourceCategories : any[] = [];
proyecto_id : number
categoria_id : number;
fecha:any = moment(new Date, 'DD-MM-YYYY hh:mm').format('DD-MM-YYYY');
fechaIni:any = moment(new Date, 'DD-MM-YYYY hh:mm').format('DD-MM-YYYY');
fechaFin:any = moment(new Date, 'DD-MM-YYYY hh:mm').format('DD-MM-YYYY');

  constructor(public dialog: MatDialog
          , private _excelService : ExcelServiceService
          , private _projectService : projectservice
          , private _reportMasterservice : reportMasterservice
          , @Inject(LOCALE_ID) public locale: string
          , private _categoryService : categoryservice
          , private _projectCategoryservice : projectCategoryservice) { }

  ngOnInit(): void {

    this.getProyectos();
  }

  search(){
    this.getReporteMaestro();
  }

  descargarDetalleExcel(arrayToExcel : any){
    console.log('ENVIAR A EXCEL', arrayToExcel);
    this._excelService.exportAsExcelFile(arrayToExcel, 'Reporte Maestro');  
  }

  cancel(event){}

  getReporteMaestro(){

    let arrayRPM : any;
    let nombreAnterior : string = '';
    let odc_Anterior : string = '';
    let actual_Cost_Anterior : number = 0;
    let original_Estimate_Anterior : number = 0;
    let contador : number = 1;
    let arrayReport : any[] = [];
    let actual_Costs_Calculated : number = 0;
    let original_Estimate_Calculated : number = 0;
    let arrayDataFinal : any;
    let arraySegundoNivel : any;
    let arrayTercerNivel : any;
    let contadorNivel2 : number = 1;
    let proyecto_Actual : string = '';
    let categoria : number;
    let proyecto_id_Local : number;

    proyecto_id_Local = (this.proyecto_id == 999999) ? 0 : this.proyecto_id;

    categoria = (this.categoria_id == undefined) ? 0 : this.categoria_id

    // Obtiene reporte maestro
    this._reportMasterservice.getReportMasterDetailByProject(proyecto_id_Local, categoria, moment(this.fechaIni, 'YYYY-MM-DD').format('YYYY-MM-DD'), moment(this.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD')).subscribe(
      res=> {
        arrayRPM = res;
        arraySegundoNivel = res;
        arrayTercerNivel = res;

        res.forEach(element => {

          if(contador == 1){
            nombreAnterior = element.nombre;
            proyecto_Actual = element.proyecto_Codigo;
          }else{    
            if(element.nombre == nombreAnterior){
              actual_Costs_Calculated = actual_Costs_Calculated + actual_Cost_Anterior;
              original_Estimate_Calculated = original_Estimate_Calculated + original_Estimate_Anterior;

              }else{
                actual_Costs_Calculated = actual_Costs_Calculated + actual_Cost_Anterior;
                original_Estimate_Calculated = original_Estimate_Calculated + original_Estimate_Anterior;
                
                console.log('Actualcost calculate nuevo', original_Estimate_Anterior)

                arrayReport.push({fecha_inicial :  moment(this.fechaIni, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                , fecha_final : moment(this.fechaFin, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                , nombre : nombreAnterior
                                , original_Estimate : original_Estimate_Calculated //formatCurrency(original_Estimate_Calculated, this.locale, '$')
                                , proyecto : ''
                                , actual_Costs : actual_Costs_Calculated //formatCurrency(actual_Costs_Calculated, this.locale, '$')
                                , odc_Codigo : ''
                                , proveedor : ''
                                , odc_fecha : ''
                                , odc_SubTotal : ''
                                , surtido : ''
                                , factura : ''
                                , moneda : ''
                                , factura_Fecha : ''
                                , monto_Factura : ''});

                // Reinicializa variables
                actual_Costs_Calculated = 0;
                original_Estimate_Calculated = 0;

                // ---------------------
                // Para el segundo nivel
                // ---------------------
                arraySegundoNivel.forEach(el => {
                  if(el.nombre == nombreAnterior){
                    arrayReport.push({fecha_inicial :  moment(this.fechaIni, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                    , fecha_final : moment(this.fechaFin, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                    , nombre : ''
                                    , original_Estimate : ''
                                    , actual_Costs : ''
                                    , proyecto : el.proyecto_Codigo
                                    , odc_Codigo : el.odc_Codigo
                                    , proveedor : el.proveedor_Nombre
                                    , odc_fecha : el.odc_Fecha
                                    , odc_SubTotal : el.odc_Subtotal //formatCurrency(el.odc_Subtotal, this.locale, '$')
                                    , surtido : (el.surtido == 1) ? 'SÍ' : 'NO'
                                    , factura : ''
                                    , moneda : ''
                                    , factura_Fecha : ''
                                    , monto_Factura : ''}) 

                    // ---------------------
                    // Para el tercer nivel
                    // ---------------------                
                    arrayTercerNivel.forEach(tn => {
                      if(tn.odc_Codigo == odc_Anterior && tn.factura_Codigo != ''){
                        arrayReport.push({fecha_inicial :  moment(this.fechaIni, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                        , fecha_final : moment(this.fechaFin, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                        , nombre : ''
                                        , original_Estimate : ''
                                        , actual_Costs : ''
                                        , proyecto : ''
                                        , odc_Codigo : ''
                                        , proveedor : ''
                                        , odc_fecha : ''
                                        , odc_SubTotal : ''
                                        , surtido : ''
                                        , factura : tn.factura_Codigo
                                        , moneda : tn.moneda
                                        , factura_Fecha : moment(tn.factura_Fecha, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                        , monto_Factura : tn.monto_Factura //formatCurrency(tn.monto_Factura, this.locale, '$')
                                      }) 

                      }
                      odc_Anterior = el.odc_Codigo
                    });

                  }

                  odc_Anterior = el.odc_Codigo
                  contadorNivel2++;
                });
              }
            }

            nombreAnterior = element.nombre
            actual_Cost_Anterior = element.actual_Costs
            original_Estimate_Anterior = element.original_Estimate
            contador++;
        });

        // --------------------------------------
        // Imprime ultimo registro de la consulta
        // --------------------------------------
        actual_Costs_Calculated = actual_Costs_Calculated + actual_Cost_Anterior;
        original_Estimate_Calculated = original_Estimate_Calculated + original_Estimate_Anterior;

        arrayReport.push({fecha_inicial :  moment(this.fechaIni, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                , fecha_final : moment(this.fechaFin, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                , nombre : nombreAnterior
                                , original_Estimate : original_Estimate_Calculated //formatCurrency(original_Estimate_Calculated, this.locale, '$')
                                , proyecto : ''
                                , actual_Costs : actual_Costs_Calculated //formatCurrency(actual_Costs_Calculated, this.locale, '$')
                                , odc_Codigo : ''
                                , proveedor : ''
                                , odc_fecha : ''
                                , odc_SubTotal : ''
                                , surtido : ''
                                , factura : ''
                                , moneda : ''
                                , factura_Fecha : ''
                                , monto_Factura : ''});

        // ---------------------
        // Para el segundo nivel proyecto final
        // ---------------------
        arraySegundoNivel.forEach(el => {
          if(el.nombre == nombreAnterior){
            arrayReport.push({fecha_inicial :  moment(this.fechaIni, 'YYYY-MM-DD').format('DD-MM-YYYY')
                            , fecha_final : moment(this.fechaFin, 'YYYY-MM-DD').format('DD-MM-YYYY')
                            , nombre : ''
                            , original_Estimate : ''
                            , actual_Costs : ''
                            , proyecto : el.proyecto_Codigo
                            , odc_Codigo : el.odc_Codigo
                            , proveedor : el.proveedor_Nombre
                            , odc_fecha : el.odc_Fecha
                            , odc_SubTotal : el.odc_Subtotal //formatCurrency(el.odc_Subtotal, this.locale, '$')
                            , surtido : (el.surtido == 1) ? 'SÍ' : 'NO'
                            , factura : ''
                            , moneda : ''
                            , factura_Fecha : ''
                            , monto_Factura : ''}) 

            // ---------------------
            // Para el tercer nivel
            // ---------------------                
            arrayTercerNivel.forEach(tn => {
              if(tn.odc_Codigo == odc_Anterior && tn.factura_Codigo != ''){
                arrayReport.push({fecha_inicial :  moment(this.fechaIni, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                , fecha_final : moment(this.fechaFin, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                , nombre : ''
                                , original_Estimate : ''
                                , actual_Costs : ''
                                , proyecto : ''
                                , odc_Codigo : ''
                                , proveedor : ''
                                , odc_fecha : ''
                                , odc_SubTotal : ''
                                , surtido : ''
                                , factura : tn.factura_Codigo
                                , moneda : tn.moneda
                                , factura_Fecha : moment(tn.factura_Fecha, 'YYYY-MM-DD').format('DD-MM-YYYY')
                                , monto_Factura : tn.monto_Factura //formatCurrency(tn.monto_Factura, this.locale, '$')
                              }) 

              }
              odc_Anterior = el.odc_Codigo
            });

          }

          odc_Anterior = el.odc_Codigo
          contadorNivel2++;
        });

        console.log('arreglo', arrayReport);


        this.descargarDetalleExcel(arrayReport);
      },
      error => console.log("error consulta proyectos",error)
    )

  }

  fechaInicial(event){
    console.log('fecha', moment(this.fechaIni, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  }

  fechaFinal(event){
    console.log('fecha', moment(this.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  }

  projectSelected(event){
    this.getCategoriasProyecto();
  }

  // ==================
  // SERVICIOS
  // ==================
  getProyectos(){

    // Obtiene proyectos
    this._projectService.getProjectAll().subscribe(
      res=> {

        this.datasourcePorjects = res;

        this.datasourcePorjects.push({proyecto_id: 999999,
          codigo_proyecto: "TODOS",
          nombre_proyecto: "",
          cliente_id: 0,
          nombre_cliente: "",
          presupuesto_proyecto: 0,
          fecha_inicial_proyecto: "2018-02-20T00:00:00",
          fecha_final_proyecto: "2018-02-20T00:00:00",
          responsable_proyecto: "",
          centro_de_costo_proyecto: "",
          nombre_centro_de_costo_proyecto: "",
          almacen_id: 3,
          nombre_almacen: "",
          ubicacion_almacen: "",
          destino_nombre: "",
          destino_direccion: "",
          destino_ciudad: "",
          destino_estado: "",
          destino_cp: 0,
          destino_requisitor: "",
          destino_telefono: 0});

        // Ordena los proyectos de mayor a menor
        this.datasourcePorjects.sort(function (a, b) {
          if (a.proyecto_id < b.proyecto_id) {
            return 1;
          }
          if (a.proyecto_id > b.proyecto_id) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

      },
      error => console.log("error consulta proyectos",error)
    )
  }

  getCategoriasProyecto(){
    // Obtiene categorias
    let proyecto_id_Local : number;

    proyecto_id_Local = (this.proyecto_id == 999999) ? 0 : this.proyecto_id; 
    this._projectCategoryservice.getProjectCateogryById(proyecto_id_Local).subscribe(
      res=> {
        this.datasourceCategories = res;

        console.log('categorias', res)

        this.datasourceCategories.push({activo : true
                              , almacenid : 1
                              , categoria_id : 0
                              , codigo_proyectocategoria : '0'
                              , fecha_inicial : '2023-01-01'
                              , nombre_almacen : 'Almacen 001'
                              , nombre_categoria : 'TODOS'
                              , nombre_proyecto : ''
                              , presupuesto : 0
                              , proyecto_id : this.proyecto_id
                              , proyectocategoria_id : 0
                              , responsable : ''})

        // Ordenado de arreglo de menor a mayor
        this.datasourceCategories = this.datasourceCategories.sort(function (a, b) {
          if (a.codigo_proyectocategoria > b.codigo_proyectocategoria) {
            return 1;
          }
          if (a.codigo_proyectocategoria < b.codigo_proyectocategoria) {
            return -1;
          }
          return 0;
        });

      },
      error => console.log("error consulta categorias",error)
    )
  }

// descargarExcel(){
  //   console.log('Descargar a excel');
  //   let dataSourceShowToExcel : any[] = [];
  //   let contador : number = 0
  //   let sumOriginal_Estimate : number = 0;
  //   let sumActual_Cost : number = 0;
  //   let sumProject_Added : number = 0;
  //   let sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION : number = 0;
  //   let sumPROJECTED_BALANCE_AT_COMPLETION : number = 0;

    
  //   console.log('DatasourceReporte', this.dataSourceShowX)

  //   this.dataSourceShowX.forEach(element => {

  //     dataSourceShowToExcel.push({
  //       '      ' : element.etiqueta
  //       , '    ' :''
  //       , Original_Estimate : formatCurrency(element.Original_Estimate, this.locale, '$')
  //       , '' :''
  //       , Actual_Cost : formatCurrency(element.Actual_Cost, this.locale, '$')
  //       , ' ' :''
  //       , Project_Added : formatCurrency(element.Project_Added, this.locale, '$')
  //       , '  ' :''
  //       , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(element.PROJECTED_ACTUAL_COSTS_AT_COMPLETION, this.locale, '$')
  //       , '   ' :''
  //       , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(element.PROJECTED_BALANCE_AT_COMPLETION, this.locale, '$')
  //     });
  //     contador++;

  //     sumOriginal_Estimate = sumOriginal_Estimate + element.Original_Estimate;
  //     sumActual_Cost = sumActual_Cost + element.Actual_Cost;
  //     sumProject_Added = sumProject_Added + element.Project_Added;
  //     sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION = sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION + element.PROJECTED_ACTUAL_COSTS_AT_COMPLETION;
  //     sumPROJECTED_BALANCE_AT_COMPLETION = sumPROJECTED_BALANCE_AT_COMPLETION + element.PROJECTED_BALANCE_AT_COMPLETION;
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : ''
  //     , '    ' :''
  //     , Original_Estimate : ''
  //     , '' :''
  //     , Actual_Cost : ''
  //     , ' ' :''
  //     , Project_Added : ''
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : ''
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : ''
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : 'Contract Totals'
  //     , '    ' :''
  //     , Original_Estimate : formatCurrency(sumOriginal_Estimate, this.locale, '$')
  //     , '' :''
  //     , Actual_Cost : formatCurrency(sumActual_Cost, this.locale, '$')
  //     , ' ' :''
  //     , Project_Added : formatCurrency(sumProject_Added, this.locale, '$')
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION, this.locale, '$')
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(sumPROJECTED_BALANCE_AT_COMPLETION, this.locale, '$')
  //   });
    
  //   dataSourceShowToExcel.push({
  //     '      ' : ''
  //     , '    ' :''
  //     , Original_Estimate : ''
  //     , '' :''
  //     , Actual_Cost : ''
  //     , ' ' :''
  //     , Project_Added : ''
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : ''
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : ''
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : 'Subcontractors - Base Contract'
  //     , '    ' :''
  //     , Original_Estimate : formatCurrency(0.00, this.locale, '$')
  //     , '' :''
  //     , Actual_Cost : formatCurrency(0.00, this.locale, '$')
  //     , ' ' :''
  //     , Project_Added : formatCurrency(0.00, this.locale, '$')
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(0.00, this.locale, '$')
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(0.00, this.locale, '$')
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : 'Subcontractor Change Order Costs'
  //     , '    ' :''
  //     , Original_Estimate : formatCurrency(0.00, this.locale, '$')
  //     , '' :''
  //     , Actual_Cost : formatCurrency(0.00, this.locale, '$')
  //     , ' ' :''
  //     , Project_Added : formatCurrency(0.00, this.locale, '$')
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(0.00, this.locale, '$')
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(0.00, this.locale, '$')
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : ''
  //     , '    ' :''
  //     , Original_Estimate : ''
  //     , '' :''
  //     , Actual_Cost : ''
  //     , ' ' :''
  //     , Project_Added : ''
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : ''
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : ''
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : 'PROJECTED TOTAL COSTS'
  //     , '    ' :''
  //     , Original_Estimate : ''
  //     , '' :''
  //     , Actual_Cost : ''
  //     , ' ' :''
  //     , Project_Added : ''
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION, this.locale, '$')
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(sumPROJECTED_BALANCE_AT_COMPLETION, this.locale, '$')
  //   });

  //   this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Reporte Maestro');  

  //   this.dataSourceShowX = [];
  // }

}

