<div fxLayout="row">
    <h4>Catálogo de proveedores</h4>
  </div>
  <br>
  <div mat-dialog-content-example [formGroup]="newProject">
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><br>Nombre</div>
        </div>
        <div fxFlex.gt-sm="25%" >
            <div>
              <mat-form-field>
              <input [type]="nombre" matInput class="form-control form-control-line control" formControlName="nombre" disabled >
            </mat-form-field>
            <span *ngIf="!newProject.get('nombre').valid && newProject.get('nombre').touched" class="help-block" style="color: red;"> El nombre es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="10%" >
          <div class="center" style="width: 100%;"><ng-container *ngIf="isloading == true"><mat-spinner color="primary"></mat-spinner></ng-container></div>
        </div>
        <div fxFlex.gt-sm="20%" >
          <div><br>Dirección</div>
        </div>
        <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <input [type]="direccion" matInput class="form-control form-control-line control" placeholder="" formControlName="direccion">
            </mat-form-field>
            <span *ngIf="!newProject.get('direccion').valid && newProject.get('direccion').touched" class="help-block" style="color: red;"> La dirección es requerida</span>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><br>RFC</div>
        </div>
        <div fxFlex.gt-sm="25%" >
            <div>
              <mat-form-field>
              <input [type]="rfc" matInput class="form-control form-control-line control" formControlName="rfc" disabled (change)="onChangeRFC($event)">
            </mat-form-field>
            <span *ngIf="!newProject.get('rfc').valid && newProject.get('rfc').touched" class="help-block" style="color: red;"> El RFC es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="10%" >
          <div></div>
        </div>
        <div fxFlex.gt-sm="20%" >
          <div><br>Contacto</div>
        </div>
        <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <input [type]="contacto" matInput class="form-control form-control-line control" placeholder="" formControlName="contacto" >
            </mat-form-field>
            <span *ngIf="!newProject.get('contacto').valid && newProject.get('contacto').touched" class="help-block" style="color: red;"> El contacto es requerido</span>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><br>Ciudad</div>
        </div>
        <div fxFlex.gt-sm="25%" >
            <div>
              <mat-form-field>
              <input [type]="ciudad" matInput class="form-control form-control-line control" formControlName="ciudad" disabled >
            </mat-form-field>
            <span *ngIf="!newProject.get('ciudad').valid && newProject.get('ciudad').touched" class="help-block" style="color: red;"> La ciudad es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="10%" >
          <div></div>
        </div>
        <div fxFlex.gt-sm="20%" >
          <div><br>Estado</div>
        </div>
        <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <input [type]="estado" matInput class="form-control form-control-line control" placeholder="" formControlName="estado">
            </mat-form-field>
            <span *ngIf="!newProject.get('estado').valid && newProject.get('estado').touched" class="help-block" style="color: red;"> El estado es requerido</span>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><br>REPSE</div>
        </div>
        <div fxFlex.gt-sm="25%" >
            <div><br>
              <mat-radio-group aria-label="Select an option" formControlName="repse">
                <mat-radio-button value="1">Sí</mat-radio-button>&nbsp;
                <mat-radio-button value="0">No</mat-radio-button>
              </mat-radio-group>
            <span *ngIf="!newProject.get('ciudad').valid && newProject.get('ciudad').touched" class="help-block" style="color: red;"> repse requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="10%" >
          <div></div>
        </div>
        <div fxFlex.gt-sm="20%" >
          <div><br></div>
        </div>
        <div fxFlex.gt-sm="25%" >
          <div>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><br>TIPO PROVEEDOR</div>
        </div>
        <div fxFlex.gt-sm="25%" >
            <div><br>
              <section class="example-section"> <!--[formGroup]="toppings"-->
                <p><mat-checkbox formControlName="servicioEspecializado" [checked]="this.dataEdit.servicioEspecializado">Especializado</mat-checkbox></p> <!--formControlName="pepperoni"-->
                <p><mat-checkbox formControlName="servicioGeneral">Servicios General</mat-checkbox></p><!--formControlName="extracheese"-->
                <p><mat-checkbox formControlName="productoDeMateriales">Producto de Materiales</mat-checkbox></p><!--formControlName="mushroom"-->
              </section>
            <span *ngIf="!newProject.get('ciudad').valid && newProject.get('ciudad').touched" class="help-block" style="color: red;"> repse requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="10%" >
          <div></div>
        </div>
        <div fxFlex.gt-sm="20%" >
          <div><br></div>
        </div>
        <div fxFlex.gt-sm="25%" >
          <div>
          </div>
        </div>
      </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div>

        </div>
    </div>
    <div fxFlex.gt-sm="20%" >
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><ng-container *ngIf="userProfile != 5"><button mat-raised-button color="accent" (click)="save(newProject, $event)">Guardar</button></ng-container></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><button mat-raised-button color="warn" (click)="cancel($event)">Cancelar</button></div>
    </div>
  </div>

  <br><br><br>
  <!-- INICIA CUERPO DE LA PÁGINA-->
<div class="col-12 col-md-12 p-0">
  <div class="card">
      <div class="card-body card-body-table">
          <mat-table [dataSource]="datasourcesupplier" matSort class="mat-elevation-z8"
              style="width: 95%;">

              
              <!-- <ng-container matColumnDef="id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>id </th>
                  <td mat-cell *matCellDef="let element" hidden> {{element.id}} </td>
              </ng-container>

              
              <ng-container matColumnDef="nombreProveedor">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30">Nombre </th>
                  <td mat-cell *matCellDef="let element"> {{element.nombreProveedor}} </td>
              </ng-container>
              
              <ng-container matColumnDef="rfcProveedor">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> rfc </th>
                  <td mat-cell *matCellDef="let element"> {{element.rfcProveedor}}
                  </td>
              </ng-container> -->

              <ng-container matColumnDef="mes">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> mes </th>
                  <td mat-cell *matCellDef="let element"> 
                      <ng-container *ngIf="element.mes == 1">Enero</ng-container>
                      <ng-container *ngIf="element.mes == 2">Febrero</ng-container>
                      <ng-container *ngIf="element.mes == 3">Marzo</ng-container>
                      <ng-container *ngIf="element.mes == 4">Abril</ng-container>
                      <ng-container *ngIf="element.mes == 5">Mayo</ng-container>
                      <ng-container *ngIf="element.mes == 6">Junio</ng-container>
                      <ng-container *ngIf="element.mes == 7">Julio</ng-container>
                      <ng-container *ngIf="element.mes == 8">Agosto</ng-container>
                      <ng-container *ngIf="element.mes == 9">Septiembre</ng-container>
                      <ng-container *ngIf="element.mes == 10">Octubre</ng-container>
                      <ng-container *ngIf="element.mes == 11">Noviembre</ng-container>
                      <ng-container *ngIf="element.mes == 12">Dicieembre</ng-container>
                  </td>
              </ng-container>

              <ng-container matColumnDef="anno">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> año </th>
                  <td mat-cell *matCellDef="let element"> {{element.anno}}
                  </td>
              </ng-container>

              <ng-container matColumnDef="fechaCaducidad">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> fecha de caducidad </th>
                  <td mat-cell *matCellDef="let element"> {{element.fechaCaducidad | date: 'dd-MM-yyyy'}}
                  </td>
              </ng-container>
              
              <ng-container matColumnDef="categoriaDocumento">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> cateogria del documento </th>
                  <td mat-cell *matCellDef="let element"> {{element.categoriaDocumento}}
                  </td>
              </ng-container>

              <ng-container matColumnDef="estatusDocumento">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> estatus </th>
                  <td mat-cell *matCellDef="let element">
                      <ng-container *ngIf="element.estatusDocumento == 'APROBADO'"><p style="color: green;">{{element.estatusDocumento}}</p></ng-container>
                      <ng-container *ngIf="element.estatusDocumento == 'VENCIDO'"><p style="color: red;">{{element.estatusDocumento}}</p></ng-container>
                      <ng-container *ngIf="element.estatusDocumento == 'NO CARGADO'"><p style="color: rgb(42, 81, 173);">{{element.estatusDocumento}}</p></ng-container>
                      <ng-container *ngIf="element.estatusDocumento == 'EN REVISIÓN'"><p style="color: rgb(221, 130, 11);">{{element.estatusDocumento}}</p></ng-container>
                  </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </mat-table>

      </div>
  </div>
</div>
  