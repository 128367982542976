import { Injectable } from '@angular/core';

import jwt_decode from "jwt-decode";
import { switchMapTo } from 'rxjs-compat/operator/switchMapTo';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string; 
}

  const MENUITEMS = [
    { state: 'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
    { state: 'catalog', name: 'Catálogos', type: 'link', icon: 'view_comfy' },
    { state: 'requisition', name: 'Exp Dir', type: 'link', icon: 'view_list' },
    { state: 'po', name: 'Ims Temp', type: 'link', icon: 'web' },
    { state: 'quotation', name: 'Descargas', type: 'link', icon: 'tab' },
    { state: 'userAccess', name: 'Cambio de clave', type: 'link', icon: 'tab' },
    
  ];

@Injectable()
export class MenuItems {
  getMenuitem(proveedor_id: string): Menu[] {

    // DECODIFICA
    let token = localStorage.getItem('token_access');    
    this.decodedSign = jwt_decode(token)["firma"] + '?alt=media&token='; 
    let decodeUser = jwt_decode(token)["username"];
    let decodeId = jwt_decode(token);
    
    this.usuarioId = decodeUser;

      return MENUITEMS;
  }

  decodedSign : any;
  usuarioId : any;


}
