import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { supplyservice } from '../../../services/supplier.service';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { forEach } from 'jszip';
import { identifierModuleUrl } from '@angular/compiler';

@Component({
  selector: 'app-suppliers-out-to-date',
  templateUrl: './suppliers-out-to-date.component.html',
  styleUrls: ['./suppliers-out-to-date.component.css']
})
export class SuppliersOutToDateComponent implements OnInit {

  optionsx: any[];
  filteredOptions: Observable<any[]>;
  proveedor_idw: any = '';
  formSupplier: FormGroup;
  proveedor_id = new FormControl('', [Validators.required]);

  constructor(
    private formBuilder: FormBuilder
    , private _supplyservice : supplyservice
    , private _excelService : ExcelServiceService
  ) { 
    this.formSupplier = this.formBuilder.group({
      proveedor_id: new FormControl('', [Validators.required]),
  });

  }

// =================
// DECLARACIONES
// =================
arrayYear : any = [];
arrayMonth : any = [];
year : number = 2020;
month :number = 1;
datasourcesupplier : any;


  ngOnInit(): void {
    this.FillCombos();
    this.getsupplierAll();
  }

  yearChange(event){
    console.log(event);
    console.log('selecciona año')

  }

  monthChange(event){
    console.log(event)
  }

  search(){
    this.getSupplierReport();
  }

  FillCombos(){

    // this.arrayYear.push({year_id:1900, year:'TODOS'});
    for(var i:number = 2020; i<= 2050;i++){
      this.arrayYear.push({year_id:i, year:i.toString()});
    }

    console.log(this.arrayYear);

    moment.locale('es');
    // this.arrayMonth.push({month_id:0, month: 'TODOS'});
    for(var j: number = 1;j<=12;j++){
      this.arrayMonth.push({month_id:j, month: moment(j, 'MM').format('MMMM')});
    }
  }

  // ================================
  // AUTO COMPLETE PROVEEDORES
  displayFn(element): string {
    this.proveedor_idw = element.proveedorid;    
    return element && element.nombre ? element.nombre : '';    
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.optionsx.filter((option) =>
      option.nombre.toLowerCase().includes(filterValue)
    );
  }

  descargarExcel(){

    console.warn('descarga de excel', this.datasourcesupplier);
    let arrayToExcel : any = [];

    this.datasourcesupplier.forEach(element => {
      arrayToExcel.push({id : element.id
                        , nombre_Proveedor : element.nombreProveedor
                        , RFC : element.rfc
                        , Comentarios : element.comentarios
                        , Mes : element.mes
                        , Año : element.anno
                        , Categoria_Documento_Id : element.categoriaDocumento_Id
                        , Fecha_Caducidad : element.fechaCaducidad
                        , Categoria_Documentos : element.categoriaDocumento
                        , Estatus_Documentos : element.estatusDocumento  
                        })
    });

    this._excelService.exportAsExcelFile(arrayToExcel, 'Documentos');  
  }

  // =================
  // SERVICIOS
  // =================

  getsupplierAll(){
    // Selecciona todos los proveedores
    this._supplyservice.getsupplyAll().subscribe(
      res=> {
        this.datasourcesupplier = res;

        let arrayProv : any = this.datasourcesupplier.filter(e => e.proveedorid == this.proveedor_id)
        this.proveedor_id.setValue(arrayProv[0])

        console.log('PROVEEDORES', this.datasourcesupplier);

        // ===========================
        // COMBO AUTOCOMPLETE
        this.optionsx= this.datasourcesupplier;

        this.filteredOptions = this.proveedor_id.valueChanges.pipe(
          startWith(''),
          map((value) => (typeof value === 'string' ? value : value?.nombre)),
          map((nombre) => (nombre ? this._filter(nombre) : this.optionsx.slice()))
        );
        // ===========================

      },
      error => console.log("error consulta categorias",error)
    )
  }

  getSupplierReport(){
  // Selecciona todos los proveedores
  let arrayProveedor:any;

  if(this.proveedor_id.value != undefined){
    arrayProveedor = this.proveedor_id.value;
    this.proveedor_idw = arrayProveedor["proveedorid"];
  }
  else
    this.proveedor_idw = 0;
    
  if(this.proveedor_idw == undefined)
  this.proveedor_idw = 0;

    this._supplyservice.getsupplyOutOfDate(this.proveedor_idw, this.year, this.month).subscribe(
      res=> {
        this.datasourcesupplier = res;

        this.descargarExcel();

        console.log('PROVEEDORES', res);

      },
      error => console.log("error consulta categorias",error)
    )
  }

}
