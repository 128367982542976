<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7" class="row" [formGroup]="newForm">
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>Año
                <ng-container>
                    <mat-select class="form-control"  formControlName="anio">
                        <mat-option *ngFor="let op of arrayYears" [value]="op.id" >
                            {{op.year}}
                        </mat-option>
                      </mat-select>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>Mes, Bimestre, Cuatrimestre
                <ng-container>
                      <mat-select class="form-control"  formControlName="mes">
                        <mat-option *ngFor="let op of arrayMonths" [value]="op.id" >
                            {{op.month}}
                        </mat-option>
                      </mat-select>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>
                <ng-container>
                    <button mat-raised-button color="accent" (click)="search(newForm, $event)">Buscar</button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>
                <ng-container>
                    <button mat-raised-button color="accent" (click)="descargarExcel()">Descargar a Excel</button>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- Table -->
    <div class="row">
        <div style="width: 200px;"></div>
        <div class="col">
            <div class="card shadow">
                <br>
                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                        <div class="card-body card-body-table">
                            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8" style="width: 80%;" #tabla1>

                                <ng-container matColumnDef="supplier_id" style="width: 300px;">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>supplier_id </th>
                                    <td mat-cell *matCellDef="let element" hidden> {{element.id}} </td>
                                </ng-container>

                                <ng-container matColumnDef="nombre" style="width: 300px;">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" >Nombre </th>
                                    <td mat-cell *matCellDef="let element" > {{element.nombre}} </td>
                                </ng-container>

                                <ng-container matColumnDef="rfc" style="width: 300px;">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" >RFC </th>
                                    <td mat-cell *matCellDef="let element" > {{element.rfc}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="periodicidad" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Periodicidad</th>
                                    <td mat-cell *matCellDef="let element" class="col300"> {{element.periodicidad}} </td>
                                </ng-container>

                                <ng-container matColumnDef="anio" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Año</th>
                                    <td mat-cell *matCellDef="let element" class="col300"> {{element.anio}} </td>
                                </ng-container>

                                <ng-container matColumnDef="mes" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Mes / Bimestre / Cuatrimestre</th>
                                    <td mat-cell *matCellDef="let element" class="col300"> {{element.mes}} </td>
                                </ng-container>

                                <ng-container matColumnDef="documento" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Documento</th>
                                    <td mat-cell *matCellDef="let element" class="col300"> {{element.documento}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="estatus" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Estatus </th>
                                    <td mat-cell *matCellDef="let element" class="col300">
                                        <ng-container *ngIf="element.estatus == 0"><p class="icon-cog-chocolate">POR REVISAR</p></ng-container>
                                        <ng-container *ngIf="element.estatus == 1"><p class="icon-cog-green">APROBADO</p></ng-container>
                                        <ng-container *ngIf="element.estatus == 2"><p class="icon-cog-red">RECHAZADO</p></ng-container>
                                        <ng-container *ngIf="element.estatus == 3"><p class="icon-cog-gray">NO APLICA</p></ng-container>
                                        <ng-container *ngIf="element.estatus == 4"><p class="icon-cog-gray">NO ENVIADA</p></ng-container>
                                    </td>
                                </ng-container>
                                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </mat-table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<br><br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <!-- <div><button mat-raised-button color="accent" (click)="save($event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div> -->
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 