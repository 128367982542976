import { CompileShallowModuleMetadata, IfStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { BREAKPOINT } from '@angular/flex-layout';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as e from 'cors';
import { decode, StringifyOptions } from 'querystring';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { employeservice } from '../../../../services/employe.service';
// import { RepseCommentsComponent } from '../../repse-comments/repse-comments.component';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee-capture-address',
  templateUrl: './employee-capture-address.component.html',
  styleUrls: ['./employee-capture-address.component.css']
})
export class EmployeeCaptureAddressComponent implements OnInit {

// =========================
// DECLARACIONES
// =========================
nombre : string;
cp: string;
vialidad : string;
numeroExterior : string;
numeroInterior : string;
colonia : string;
localidad : string;
credito2 : string;
municipio : string;
entidadFederativa : string;

employeeId : number = 0;
usuarioId : number = 0;
tipoImagenFile : any = [];
arrayDocumentosEmployee : any;
arrayDocumentosEmployeeOrigen : any;
dataSourceEmployee : any;
prestadorServicio : string;

nombreArchivo: string = " (archivo nuevo) ";
loading: boolean;
valido: boolean
errorFileUpload : boolean = true; // true = valido, false = invalido

// Variables de carga de archivos
urlLicencia : any;
urlCredito : any;
urlCredito2 : any;
urlRFC : any;
urlActaConstitutiva : any;
urlINE : any;
urlAltaImss : any;
urlAltaInfonavit : any;
urlAltaSAT : any;
urlEstadoCuenta : any;
urlEstadoFinanciero : any;
urlContrato : any;
urlRPP : any;
urledoCtaBancario : any;
urlComprobanteDom : any;

estadoVialidad : number;
estadoNumeroExterior : number;
estadoNumeroInterior : number;
estadocolonia : number;
estadolocalidad : number;
estadoLicencia : any;
estadoCredito : any;
estadoCredito2 : any;
estadoMunicipio : any;
estadoEntidadFederativa : any;
estadoComprobanteDom : any;
decodedSign : any;
unamePattern = "^[0-9]";

public newProject: FormGroup;

  constructor(
    public dialog: MatDialog
    , private _employeservice : employeservice
    , private formBuilder: FormBuilder
    , private _snackBar : MatSnackBar
    , private readonly _uploadFileService: UploadFileService
  ) { 
    this.newProject = this.formBuilder.group({
      nombre :  new FormControl('', [Validators.required]),
      cp :  new FormControl('', [Validators.required]),
      vialidad: new FormControl('', [Validators.required]),
      numeroExterior: new FormControl('', [Validators.required]),
      numeroInterior: new FormControl(''),
      colonia: new FormControl('', [Validators.required]),
      localidad: new FormControl('', [Validators.required]),
      licencia: new FormControl('', [Validators.required]),
      credito: new FormControl('', [Validators.required]),
      credito2: new FormControl('', [Validators.required]),
      municipio: new FormControl('', [Validators.required]),
      entidadFederativa: new FormControl('', [Validators.required])
    });
  }

// =========================
// PROCEDIMIENTOS
// =========================

  ngOnInit(): void {
    
    this.decode();
    
    this.getDocumentsAll(0);

    this.tipoImagenFile = [{id : 2001, categoria : 'licencia'}
    ,{id : 2002, categoria : 'credito'}
    ,{id : 2003, categoria : 'credito2'}
    ,{id : 2004, categoria : 'comprobanteDom'}
    ]

  }
  
  cancel(event){
  //  this.dialogRef.close();
  }

  save(form, event){

    let arrayToDb : any;
    let telefonoToDb : number;

    arrayToDb = ({ 
      empleadoId : this.employeeId
        , codigoPostal : form.controls["cp"].value
        , vialidad : form.controls["vialidad"].value
        , numeroExterior : form.controls["numeroExterior"].value
        , numeroInterior : form.controls["numeroInterior"].value
        , colonia : form.controls["colonia"].value
        , localidad : form.controls["localidad"].value
        , municipio : form.controls["municipio"].value
        , entidadFederativa : form.controls["entidadFederativa"].value

      });

     this.updateEmployee(arrayToDb);
    
  }

  addFile(concepto: any, event){
    console.log('SE CARGA', concepto);
  }

  addView(concepto: any, event ){
    console.log('VISUALIZA', concepto);
  }

  onFileChange(event, grupoArchivo: string, tipoArchivo : string){
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    let extencionArchivo : string = '';
    let fileToUpload : any;
    
    // this.UploadSavingsDataExcel = null;

    if (target.files.length !== 1) {
      throw new Error('No se pueden seleccionar multiples archivos');
    }

    this.nombreArchivo = (target.files.length > 0) ? target.files[0]["name"].substring(1,30) : " (archivo nuevo) ";

    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    extencionArchivo = target.files[0].name.substring(target.files[0].name.length - 5,target.files[0].name.length)

    this.UploadFiles(target.files[0], '1', tipoArchivo)
   
  }

  opencomments(documentShow : number, element, event){

    let arrayDocuemtnoEnviar : any;

    arrayDocuemtnoEnviar = this.arrayDocumentosEmployeeOrigen.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento == documentShow);

    // const dialogConfig = new MatDialogConfig();

    // dialogConfig.data = {
    //   id: 1,
    //   title: 'COMENTARIOS',
    //   arrayData : element,
    //   employeeId: this.employeeId,
    //   comentarios: arrayDocuemtnoEnviar[0]["comentarios"],
    //   estadoPantalla: 'Edit'
     
    // }
    // dialogConfig.width = '400px';
    // dialogConfig.height = '300px';
    // dialogConfig.disableClose = true;

    // const dialogRef = this.dialog.open(RepseCommentsComponent, dialogConfig);

    // dialogRef.afterClosed().subscribe(result => {
    // });
    // console.log('Muestra comentarios');

    this.showMessage(1, 'Comentario', 'info', arrayDocuemtnoEnviar[0]["comentarios"], 'Cerrar');

  }


// =========================
// UTILERIAS
// =========================
async UploadFiles(file: File, idProveedor: string, tipoArchivo: string) {

  if ((file.name.substring(file.name.length - 4, file.name.length).toUpperCase() == '.PNG')
      || (file.name.substring(file.name.length - 4, file.name.length).toUpperCase() == '.BMP')
      || (file.name.substring(file.name.length - 4, file.name.length).toUpperCase() == '.JPG')) {
  }
  else {
      this.valido = false;
      this.openSnackBar("Alguno de los archivos seleccionados no es una imagen, seleccionar un arhivo válido", 'error');
      this.loading = false;
      return;
  }

  const formData = new FormData();

  formData.append('File', file, file.name);
  formData.append('idProveedor', idProveedor);
  formData.append('tipoArchivo', tipoArchivo);

  try {
      this.errorFileUpload = true; // valido
      return await this._uploadFileService.postUploadFile(formData);
  } catch (error) {
      this.openSnackBar('El archivo no pudo ser cargado', 'error');
      this.errorFileUpload = false; // invalido
      this.loading = false;
      throw new Error
  }
}

openFile(documentShow : number){
  this.getDocumentsAll(documentShow);
}

openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {duration : 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: 'alert-snackbar'});
}

decode(){
  let token = localStorage.getItem('token_access');
  let decodeUser = jwt_decode(token)["usuario"];
  let decodeProveedorId = jwt_decode(token)["proveedor_id"];
  this.usuarioId = decodeUser;
  this.employeeId = decodeProveedorId

  this.getemployeeAll(decodeUser);
}

showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
  switch(tipoMensaje){
    case(1) : 
        Swal.fire({
          title: header,
          html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
          icon: icon,
          confirmButtonText: buttonCaption,
          customClass: {
              confirmButton: 'btn  btn-rounded btn-outline-warning'
          }
        })
      break;
    case(2) :
        Swal.fire({
          position: 'top-end',
          icon: icon,
          title: message,
          showConfirmButton: false,
          timer: 1500
        })
      break;
  }
}

// =========================
// SERVICIOS
// =========================
imagenes: any[] = [];
  cargarImagen(event: any, tipoImagen : string, grupoImagen : string, estado : any, categoriaImagen : any) {

    let archivos = event.target.files;
    let nombre = event.target.files.name;
    let arrayToDb : any;
    let year : number = 2022;
    let month : number = 1;

    for (let i = 0; i < archivos.length; i++) {

      let reader = new FileReader();
      reader.readAsDataURL(archivos[0]);
      reader.onloadend = () => {
        this.imagenes.push(reader.result);
        this._uploadFileService.subirImagenEmpleados(tipoImagen + '_' + event.target.files[0]["name"], reader.result, grupoImagen, this.employeeId, year , month).then(urlImagen => {

          let arrayCategoriaFiltrado = this.tipoImagenFile.filter(e => e.categoria == tipoImagen);
          let idDocumento : number = 0;

          arrayToDb = {
                        idDocumento : idDocumento
                        , idProveedor : this.employeeId
                        , idEmpleado : this.employeeId
                        , idFamiliar : 0
                        , categoriaDocumento : arrayCategoriaFiltrado[0]["id"]
                        , urlDocumento : urlImagen.toString()
                        , anno : 2022
                        , mes : 1
                        , estado : 0
                        , descripcion : ''};

          this._uploadFileService.postUploadDocumentsEmployeeToDb(arrayToDb).subscribe(
            res=> {
              console.log('DOCUMENTOS', res);
            },
            error => console.log("error al actualizar proveedores",error)
          );

          switch (tipoImagen){
            case('licencia'): this.urlLicencia = urlImagen.toString();
              break;
            case('credito'): this.urlCredito = urlImagen.toString();
              break;
            case('credito2'): this.urlCredito2 = urlImagen.toString();
              break;
          }
          
          this.showMessage(2, 'Exitoso', 'success', 'Se cargo exitosamente el archivo', 'Cerrar');

        });
      }
    }
  }

  insertFilesToDb(arrayToDb : any){
    // Inserta Archivos en base de datos
    this._employeservice.postDocument(arrayToDb).subscribe(
      res=> {
        this.openSnackBar('El registro se actualizó con éxito', '');  
      },
      error => console.log("error al insertar proveedores",error)
    )
  }

  getDocumentsAll(documentShow : number){
    let arrayDocumentos : any
    this._uploadFileService.getemployeeDocumentsAll().subscribe(
      res=> {
        arrayDocumentos = res;
        this.arrayDocumentosEmployee = null;

        if(documentShow == 0)
          this.arrayDocumentosEmployee = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId)
        else
          this.arrayDocumentosEmployee = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento == documentShow)

        if(documentShow == 0){
          this.arrayDocumentosEmployee.forEach(element => {
            switch(element.categoriaDocumento){
              case(2001): this.estadoLicencia = element.estado; break;
              case(2002): this.estadoCredito = element.estado; break;
              case(2003): this.estadoCredito2 = element.estado; break;
              case(2004): this.estadoComprobanteDom = element.estado; break;
            }
          });
        }

        if(this.arrayDocumentosEmployeeOrigen == undefined)
        this.arrayDocumentosEmployeeOrigen = this.arrayDocumentosEmployee;

        if(documentShow != 0){
          if(this.arrayDocumentosEmployee != undefined && this.arrayDocumentosEmployee.length > 0)
            window.open(this.arrayDocumentosEmployee[0]["urlDocumento"]);
          else
            this.openSnackBar('Documento no encontrado', '');
        }

      },
      error => console.log("error consulta regiones",error)
    )
  }

  getemployeeAll(rfc : string){

    this._employeservice.getemployeAll().subscribe(
      res=> {
        let arrayUserId : any[] = res.filter(e => e.rfc == rfc);

        if(arrayUserId.length > 0 ){

          this.employeeId = arrayUserId[0].id;

          // Empleados registrados
          this._employeservice.getemployeeDireccionById(arrayUserId[0].id).subscribe(
            res=> {
              this.dataSourceEmployee = new MatTableDataSource(res);

              this.newProject.controls["cp"].setValue(this.dataSourceEmployee.filteredData.codigoPostal);
              this.newProject.controls["vialidad"].setValue(this.dataSourceEmployee.filteredData.vialidad);
              this.newProject.controls["numeroExterior"].setValue(this.dataSourceEmployee.filteredData.numeroExterior);
              this.newProject.controls["numeroInterior"].setValue(this.dataSourceEmployee.filteredData.numeroInterior);
              this.newProject.controls["colonia"].setValue(this.dataSourceEmployee.filteredData.colonia);
              this.newProject.controls["localidad"].setValue(this.dataSourceEmployee.filteredData.localidad);
              this.newProject.controls["municipio"].setValue(this.dataSourceEmployee.filteredData.municipio);
              this.newProject.controls["entidadFederativa"].setValue(this.dataSourceEmployee.filteredData.entidadFederativa);
          },
          error => console.log("error consulta regiones",error)
        )
      }
    },
    error => console.log("error consulta regiones",error)
    )
  }

  updateEmployee(arrayToDb : any){
    // Actualizar empleados dirección
    this._employeservice.updateemployeeDirection(arrayToDb).subscribe(
      res=> {
        this.showMessage(2, 'Exitoso', 'success', 'Actualización de registro exitosa', 'Cerrar');
      },
      error => console.log("error consulta regiones",error)
    )
  }
}
