import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { employeModel } from 'src/app/models/employe.model';
import { employeservice } from 'src/app/services/employe.service';
import { SupplierDetailComponent } from 'src/app/components/supplier-detail/supplier-detail.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { EmployeeReviewDetailComponent } from '../../components/employee/employee-review-detail/employee-review-detail.component';
import jwt_decode from "jwt-decode";
import { interval, Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import * as moment from 'moment';
@Component({
  selector: 'app-employe-review',
  templateUrl: './employe-review.component.html',
  styleUrls: ['./employe-review.component.css']
})
export class EmployeReviewComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<employeModel>
decodeUserProfile : any;
subscription: Subscription;

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  displayedColumns = ['id', 'nombre', 'aPaterno', 'aMaterno', 'rfc', 'estatus', 'autorize', 'download'];
  interval : any;

  constructor(public dialog: MatDialog
    , private _excelService : ExcelServiceService
    , private _employeservice : employeservice) {
    }

  // =================
  // PROCEDIMIENTOS
  // =================
  ngOnInit(): void {
    // let header : string = '¡¡¡ALERTA!!!';
    // let icon: any
    // let message : string = 'Funcionalidad temporalmente suspendida, comuniquese con su proveedor de sistemas';
    // let buttonCaption: string = 'OK'
    
    // Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
    
    // var a = 1;
    // this.interval = setInterval(() => {
    //   Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
    // }, 5000);

    this.getemploye();
  }

  descargarExcel(){
    console.log('Descargar a excel');
    let dataSourceShowToExcel : any[] = [];

  this.dataSourceShow.filteredData.forEach(element => {
    dataSourceShowToExcel.push({
                              nombre : element.nombre
                              , direccion : element.direccion
                              , RFC : element.rfc
                              , estatus : element.estado
      })
    });

    this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Proveedores');  
  }

  newcustomer(evetn){
    console.log('Alta de proveedores');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'PROVEEDOR',
      arrayData : null,
      proveedorId: 0,
      estadoPantalla: 'New'
     
    }
    dialogConfig.width = '1800px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(SupplierDetailComponent , dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // window.location.reload();
    });
  }

  edit(element, event){
    // let header : string = '¡¡¡ALERTA!!!';
    // let icon: any
    // let message : string = 'Funcionalidad temporalmente suspendida, comuniquese con su proveedor de sistemas';
    // let buttonCaption: string = 'OK'
    
    // Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
    
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'PROVEEDOR',
      arrayData : element,
      employeeId: element.id,
      estadoPantalla: 'Edit'
     
    }
    dialogConfig.width = '1800px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(EmployeeReviewDetailComponent, dialogConfig);
    // const dialogRef = this.dialog.open(SupplierDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // window.location.reload();
    });
  }

  downloadInfo(element, event){
    
    let nombreArchivo : string = '';
    let arrayPersonales : any[] = [];
    let arrayDomicilio : any[] = [];
    let arrayFamiliares : any[] = [];
    let arrayEmpleoAnt : any[] = [];
    let arrayGenerales : any[] = [];
    this._employeservice.getemployeeById(element.id).subscribe(
      emp=> {
        arrayPersonales.push({
          Nombre : emp["nombre"]
          , Primer_Apellido : emp["aPaterno"]
          , Segundo_Apellido : emp["aMaterno"]
          , NSS : emp["nss"]
          , RFC : emp["rfc"]
          , CURP : emp["curp"]
          , Sexo : (emp["sexo"] == 1) ? 'Masculino' : 'Femenino' 
          , FechaNacimiento : moment(emp["fechaNacimiento"], 'YYYY-MM-DD').format('DD-MM-YYYY')
          , LugarNacimiento : emp["lugarNacimientoEstado"]
          , Nacionalidad : (emp["nacionalidad"] == 1) ? 'Mexicana' : 'Estadounidense'
          , NumeroLicencia : emp["numeroLicencia"]
          , Credito_Infonavit : emp["creditoInfonavit"]
          , Credito_Fonacot : emp["creditoFonacot"]
          , TelefonoFijo : emp["telefonoFijo"]
          , Celular : emp["celular"]
          , Correo : emp["correo"]
          , NivelEstudios : emp["nivelEstudios"]
          , Estudios_Actuales : emp["estudiosActuales"]
          , institucion : emp["institucion"]
          , NumeroEmpleado : emp["numeroEmpleado"]
          , Estado_Civil : (emp["estadoCivil"] == 0) ? 'Soltero' : 'Casado'
        })

        nombreArchivo = emp["nombre"] + '-' + emp["aPaterno"] + '-' + emp["aMaterno"] + '-Informacion';

        this._employeservice.getemployeeDireccionById(element.id).subscribe(
          empDomicilio=> {
    
            arrayDomicilio.push({
              Codigo_Postal : empDomicilio["codigoPostal"]
              , Vialidad : empDomicilio["vialidad"]
              , Numero_Exterior : empDomicilio["numeroExterior"]
              , Numero_Interior : empDomicilio["numeroInterior"]
              , Colonia : empDomicilio["colonia"]
              , Localidad : empDomicilio["localidad"]
              , Municipio : empDomicilio["municipio"]
              , Entidad_Federativa : empDomicilio["entidadFederativa"]
            })

            this._employeservice.getemployeeFammilyById(element.id).subscribe(
              empFamiliares=> {
        
                empFamiliares.forEach(element => {
                  arrayFamiliares.push({
                    Nombre : element.nombre
                    , Apellido_Paterno : element.aPaterno
                    , Apellido_Materno : element.aMaterno
                    , Fecha_De_Nacimiento : element.fechaNacimiento
                    , Parentesco : (element.parentesco == 0) ? 'Esposo' : 'Hijo'
                    , Sexo : (element.sexo == 1) ? 'Masculino' : 'Femenino'
                  })  
                });

                this._employeservice.getemployeeLastWorkById(element.id).subscribe(
                  empEmpledoAnt=> {

                    arrayEmpleoAnt.push({
                      Empresa : empEmpledoAnt["empresa"]
                      , Jefe_Directo : empEmpledoAnt["jefeDirecto"]
                      , Telefono : empEmpledoAnt["telefono"]
                      , Correo : empEmpledoAnt["correo"]
                      , Puesto : empEmpledoAnt["puesto"]
                      , Motivo_De_Separacion : empEmpledoAnt["motivoSeparacion"]
                    })
    
                    this._employeservice.getemployeeGeneralById(element.id).subscribe(
                      empGenerales=> {
    
                        arrayGenerales.push({
                          Ha_Sido_Afianzado : (empGenerales["afianzado"] == true) ? 'Sí' : 'No'
                          , Podria_Viajar : (empGenerales["puedeViajar"] == true) ? 'Sí' : 'No'
                          , Dispuesto_A_Cambiar_De_Residencia : (empGenerales["cambioResidencia"] == true) ? 'Sí' : 'No'
                          , Automovil_Propio : (empGenerales["automovil"] == true) ? 'Sí' : 'No'
                        })
        
                        this._excelService.exportAsExcelFileMultipleSheet(arrayPersonales, arrayDomicilio, arrayFamiliares, arrayEmpleoAnt, arrayGenerales, nombreArchivo);  
                
                      });
            
                  });
        
              });
    
          });

      });      

  }

  filtrar(event : Event){
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceShow.filter = filtro.trim().toLowerCase();
    console.log('filtro', filtro);
  }

  // =================
  // UTILERIAS
  // =================
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(0, this.totalSize);
    this.dataSourceShow = new MatTableDataSource(part);
    this.dataSourceShow.sort = this.sort;
    this.dataSourceShow.paginator = this.paginator;
  }

  decode(){
    let token = localStorage.getItem('token_access');
    this.decodeUserProfile = jwt_decode(token)["perfil_usuarioid"];

  }

  // =================
  // SERVICIOS
  // =================

  getemploye(){

    let arraySort: any;

    // Proyectos registrados
    this._employeservice.getemployeAll().subscribe(
      res=> {

        // Ordenado de arreglo
        arraySort = res.sort(function (a, b) {
          if (a.id < b.id) {
            return 1;
          }
          if (a.id > b.id) {
            return -1;
          }
          return 0;
        });
        
        this.dataSourceShow = new MatTableDataSource(arraySort);
        this.array = res;
        this.totalSize = this.array.length;
        
        this.iterator();
        this.dataSourceShow.sort = this.sort;
  
        this.decode();

      },
      error => console.log("error consulta regiones",error)
    )


  }

}
