import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { projectModel } from 'src/app/models/project.model';
import { projectservice } from '../../../services/projects/project.service';
import { categoryservice } from '../../../services/category/category.service';
import { MatTableDataSource } from '@angular/material/table';
import { projectCategoryservice } from 'src/app/services/projectCtegory/projectCateogry.service';
import { requisitionservice } from '../../../services/requisition/requisition.service';
import { requisitionModelDetail } from 'src/app/models/requisition.model.detail';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import Swal from 'sweetalert2';
import jwt_decode from "jwt-decode";
import { sendMailService } from '../../../services/sendMail.service';
import { RequisitionDocumentComponent } from '../requisition-document/requisition-document.component';
import { RequsitionSendMailComponent } from '../requsition-send-mail/requsition-send-mail.component';
@Component({
  selector: 'app-requisition-detail',
  templateUrl: './requisition-detail.component.html',
  styleUrls: ['./requisition-detail.component.css']
})
export class RequisitionDetailComponent implements OnInit {
  // ===================
  // DECLARACIONES
  // ===================

  // Para paginación
  public pageIndex:number = 0;
  public pageSize:number = 20;
  public currentPage = 0;
  public totalSize:number = 0;
  public array: any;

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  
  fecha:any = moment(new Date, 'DD-MM-YYYY hh:mm').format('DD-MM-YYYY');
  requisicion_id : any = '';
  categoria_id : any = '';
  proyecto_id : any = 0;
  requisicion_Numero : any = '';
  sku_Detalle : any = '';
  cantidad_Detalle : any = '';
  uom_Detalle : any = '';
  descripcion_Detalle : any = '';
  medida_Detalle : any = '';
  color_Detalle : any = '';
  linea_Detalle : any = 1;
  comentario : any = '';
  decodedSign : any;
  usuarioId : any;
  usuarioIdInt : any;
  profileId : any;
  estado : any;
  SendMailUsersDatasource : any;
  correosArray : any;
  arraySendMail: any;
  arraySendMailTarget: any;

  loadfile : any  = '';
  buscar : any = '';
  public nombreArchivo : any = 'selecciona archivo';
  UploadDataExcel : MatTableDataSource<requisitionModelDetail>;
  UploadDataExcelManual : MatTableDataSource<requisitionModelDetail>;
  dataExcel: any[];
  displayedColumns = ['SKU', 'cantidad', 'unidad_de_medida', 'descripcion', 'medida', 'color', 'otras_Especificaciones', 'linea'];
  displayedDocumentColumns = ['Descripcion', 'urlDocumento'];

  projectInfo : any;
  requisicionId : any = 0;
  estadoPantalla : string;
  newProject: FormGroup;
  datasourceCategories : any[] = [];
  datasourcePorjects : any[] = [];
  datasourceRequisition : any;
  documentsDatasource : any[] = [];
  SKU : string;
  cantidad : number;
  um : string;
  descripcion : string;
  medida : string
  colorDet : string;
  otras_especificaciones : string;
  linea : number

  fo : string
  cargo : string
  no_pym : string

  chkContratoOriginal : boolean = false;
  chkCodigoDeContrato : boolean = false;
  chkTMFO : boolean = false;
  chkEspecificarCargo : boolean = false;
  chkCargoAlContratista : boolean = false;
  activaContratoOriginal : boolean = false;
  activaCodigoDeContrato : boolean = false;
  activaEspecificarCargo : boolean = false;
  activaCargoAlContratista : boolean = false; 
  activaTMFO : boolean = false;
  NoODC : string = '';
  IdSolicitante : number = 0;
  TMFO: string = '';

  constructor(
    public dialogRef: MatDialogRef<projectModel>
    , private _projectService : projectservice
    , private _categoryService : categoryservice
    , private _projectCategoryservice : projectCategoryservice
    , @Inject(MAT_DIALOG_DATA) public data
    , public snackBar: MatSnackBar
    , private formBuilder: FormBuilder
    , private _snackBar : MatSnackBar
    , private _requisitionservice : requisitionservice
    , private _sendMailService : sendMailService
    , public dialog: MatDialog
  ) { 
    this.projectInfo = data.arrayData;
    this.requisicionId = data.requisicionId;
    this.estadoPantalla = data.estadoPantalla;

    this.newProject = this.formBuilder.group({
      proyecto_id : new FormControl('', [Validators.required]),
      categoria_id: new FormControl('', [Validators.required]),
      requisicion_id: new FormControl('', [Validators.required]),
      fecha: new FormControl('', [Validators.required]),
      requisicion_Numero : new FormControl('', [Validators.required]),
      sku_Detalle : new FormControl(''),
      cantidad_Detalle : new FormControl(''),
      uom_Detalle : new FormControl(''),
      descripcion_Detalle : new FormControl(''),
      medida_Detalle : new FormControl(''),
      color_Detalle : new FormControl(''),
      linea_Detalle : new FormControl(''),
      comentario : new FormControl(''),
      IdSolicitante : new FormControl('')
  });
  }

// ===============
// PROCEDIMIENTOS
// ===============

  ngOnInit(): void {

    console.log('DATOS INICIALES', this.projectInfo)
    this.documentsDatasource.push({Descripcion: 'Foto de pieza', urlDocumento: 'urlx'});

    this.decode();
    this.getProyectos();
    this.getEnabledCategories();

    if(this.requisicionId != 0){
      this.newProject.controls["categoria_id"].setValue(this.projectInfo["codigo_proyectocategoria"]);
      
        this.newProject.patchValue({
          proyecto_id : this.projectInfo["proyecto_id"],
          requisicion_id : '',
          requisicion_Numero : this.projectInfo["codigo"] ,
          categoria_id : '',
          fecha : moment(new Date),
          loadfile : '',
          noODC : this.projectInfo["noODC"],
          IdSolicitante : this.projectInfo["idSolicitante"]
      })
      this.requisicion_Numero = this.projectInfo["codigo"];
      this.proyecto_id = this.projectInfo["proyecto_id"];
      this.requisicion_id = '';
      this.categoria_id = this.projectInfo["proyectocategoria_id"];
      this.fecha = '';
      this.loadfile = '';
      this.comentario = this.projectInfo["comentario"];
      this.estado = this.projectInfo["estado"];
      // Checkboxs
      this.chkContratoOriginal = this.projectInfo["contratoOriginal"];
      this.chkTMFO = this.projectInfo["especificarCargo"];
      this.TMFO = this.projectInfo["tAndMDescription"];
      this.chkCargoAlContratista = this.projectInfo["cargoAlContratista"];
      this.NoODC = this.projectInfo["noODC"];
      this.IdSolicitante = this.projectInfo["idSolicitante"];

      this.getRequisitionDetail(this.requisicionId);
    }
  }

  decode(){
    let token = localStorage.getItem('token_access');
    this.decodedSign = jwt_decode(token)["firma"] + '?alt=media&token='; 
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeId = jwt_decode(token);
    this.profileId = jwt_decode(token)["perfil_usuarioid"]
    this.usuarioIdInt = jwt_decode(token)["usuarioid"];
    
    this.getMailSender();

    this.usuarioId = decodeUser;

    console.log(this.usuarioId)
  }

  openFile(documentShow : number){
    // this.yourMethodName();
    // this.getDocumentsAll(documentShow);
    // window.open(this.urlDireccion);
    console.log('Abre documento');
  }

  openFilepopup(requisicionId : number){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'DOCUMENTOS',
      // arrayData : element,
      requisicionInternaId: this.requisicionId,
      estadoPantalla: 'REQ'
     
    }
    
    dialogConfig.width = '650px';
    dialogConfig.height = '800px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(RequisitionDocumentComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // window.location.reload();
      // this.getsupplierDocuments();
    });
  }

  openSendmailpopup(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'MailToSend',
      // arrayData : element,
      requisicionInternaId: this.requisicionId,
      estadoPantalla: 'REQ',
      SendMailUsersDatasource: this.SendMailUsersDatasource
     
    }

    dialogConfig.width = '1200px';
    dialogConfig.height = '800px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(RequsitionSendMailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // window.location.reload();
      // this.getsupplierDocuments();
    });
  }

  onFileChange(event){
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    let extencionArchivo : string = '';
    let arrayExcel = [];
    let linea : number = 1;

    if(this.UploadDataExcel != undefined){
      if(this.UploadDataExcel.filteredData.length > 0){
        this.UploadDataExcel.filteredData.forEach(element => {
          arrayExcel.push({ 
            requisition_Id : 0,
            SKU : element.SKU
            , cantidad : element.cantidad
            , um : element.um
            , descripcion : element.descripcion
            , medida : element.medida
            , color : element.color
            , otras_especificaciones : element.otras_especificaciones
            , linea : linea
          })
          linea++;
        });
      }else{
        this.UploadDataExcel = null;
      }
    }else{
      this.UploadDataExcel = null;
    }
         
    if (target.files.length !== 1) {
      throw new Error('No se pueden seleccionar multiples archivos');
    }

    this.nombreArchivo = (target.files.length > 0) ? target.files[0]["name"].substring(1,30) : " (archivo nuevo) ";
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    extencionArchivo = target.files[0].name.substring(target.files[0].name.length - 5,target.files[0].name.length)
    if(extencionArchivo == '.xlsx' || extencionArchivo.substring(extencionArchivo.length - 4, extencionArchivo.length) == 'xls'){
      reader.onload = (e: any) => {
        /* create workbook */
  
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
  
        /* selected the first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data = XLSX.utils.sheet_to_json(ws);
        this.dataExcel = this.validate(data);
        this.dataExcel = data;

        let arrayErrores = [];
        let valido : boolean = true;

        // Validadores de campos
        let errorCantidad : boolean = false;
        let errorUoM : boolean = false;
        let errorDescripcion : boolean = false;
        let errorSKU : boolean = false;
        let DescripcionAnt : string = '';
        let Descripcion : string = '';

        DescripcionAnt = this.dataExcel[0]["DESCRIPCION"].toUpperCase();

        // Validamos que los crs del excel vs las existentes en el ctálogo
      let contador : number = 0;

      this.dataExcel.forEach(element => {
        // Reinicia valores
        valido = true;
        errorDescripcion = false;

        Descripcion = element.DESCRIPCION.toUpperCase()

        if(element.SKU == '') {valido = false; errorSKU = true}
        if(element.CANTIDAD_REQUERIDA == '') {valido = false; errorCantidad = true;}
        if(element.UNIDAD.toUpperCase() == '') {valido = false; errorUoM = true;}
        if(element.DESCRIPCION.toUpperCase() == '') {valido = false; errorDescripcion = true;}

        contador++;
        DescripcionAnt = Descripcion;

        if(valido == false){
          arrayErrores.push({ cantidad : element.CANTIDAD_REQUERIDA, 
            unidad_de_medida : element.UNIDAD, 
            descripcion : element.DESCRIPCION.toUpperCase()
           })
        }else{
          arrayExcel.push({ 
            requisition_Id : 0,
            SKU : element.SKU,
            cantidad : element.CANTIDAD_REQUERIDA, 
            um : element.UNIDAD,
            descripcion : element.DESCRIPCION,
            medida : element.MEDIDA,
            color : element.COLOR,
            otras_especificaciones : element.OTRAS_ESPECIFICACIONES,
            linea : linea++
           })
        }
      });

      if(arrayErrores.length > 0){
        this.openSnackBar('Los registros contienen datos incorrectos', '');
        this.dataExcel = null;
        return;
      }

      console.log('arreglo excel', arrayExcel);
      this.UploadDataExcel = new MatTableDataSource(arrayExcel);

      };
    }
    else{
      this.openSnackBar('Los registros contienen datos incorrectos', '');
      this.deleteUploadFile(event);
    }
  }

  validate(data : any)
  {
    // Valida que todos los datos esten completos
    return data
  }

  deleteUploadFile(event: any){
    this.nombreArchivo = " (archivo nuevo) ";
    this.UploadDataExcel = null;
  }

  proyectoSelected(){
    this.getCategories(this.newProject.controls["proyecto_id"].value);
  }

  solicitanteSelected(){
    console.log('Solicitante sender', this.newProject.controls["IdSolicitante"].value);
  }

  ActivaContratoOriginal(element : any, event : Event){
    this.activaContratoOriginal = event["checked"]
  }

  ActivaCodigoDeContrato(element : any, event : Event){
    this.activaCodigoDeContrato = event["checked"]
  }

  ActivaTMFO(element : any, event : Event){
    this.activaTMFO = event["checked"]
  }

  ActivaEspecificarCargo(element : any, event : Event){
    this.activaEspecificarCargo = event["checked"]
  }

  ActivaCargoAlContratista(element : any, event : Event){
    this.activaContratoOriginal = event["checked"]
  }

  categorySelected(){
    // Obtenemos requisiciones existentes y generamos nuevo numero para la requisición
    let proyectoCategoria_id : any = this.newProject.controls["categoria_id"].value
    let codigo_categoria : any = 0;
    let arrayProyectoCategoria : any;

    arrayProyectoCategoria = this.datasourceCategories.filter(e => e.proyectocategoria_id == proyectoCategoria_id);
    codigo_categoria = arrayProyectoCategoria[0]["codigo_proyectocategoria"]
    this.getrequisition(codigo_categoria);
  }

  validaCamposRequeridos() : boolean{
    let valido : boolean = true;
    valido = (this.newProject.get('proyecto_id').status == 'INVALID') ? false : valido;
    valido = (this.newProject.get("categoria_id").status == 'INVALID') ? false : valido;
    valido = (this.newProject.get("requisicion_Numero").status == 'INVALID') ? false : valido;

    return valido;
  }

  save(form, event){

    let arrayTodb : any;
    let arrayTodbHdr : any;

    if(this.estadoPantalla == 'new'){
      if(this.validaCamposRequeridos() == false){
        this.openSnackBar('debe capturar los campos requeridos', 'success');
        return;
      }
    }

    if(this.requisicionId == 0){
      arrayTodb = {
                  proyectocategoria_id : this.newProject.controls["categoria_id"].value,
                  codigo : this.newProject.controls["requisicion_Numero"].value,
                  fecha : moment(this.fecha, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                  contratoOriginal : (this.chkContratoOriginal == true) ? 1 : 0,
                  codigoDeContrato : (this.chkCodigoDeContrato == true) ? 1 : 0,
                  especificarCargo : (this.chkTMFO == true) ? 1 : 0,
                  cargoAlContratista : (this.chkCargoAlContratista == true) ? 1 : 0,
                  noODC : this.NoODC,
                  idSolicitante : this.newProject.controls["IdSolicitante"].value,
                  usuarioCrea : this.usuarioId,
                  tAndMDescription : this.TMFO
                };

      if(this.estadoPantalla == 'new'){
        // INSERTA REQUISICION HDR
        this.insertRequisition(arrayTodb);
      }
      // else{
      //   // ACTUALIZA REQUISICION HDR
      //   console.log('ACTUALIA PRIMER PASO');
      //   this.updateRequisition(arrayTodb);
      // }
      
    }
    else{
      arrayTodb = {proyecto_id : this.newProject.controls["requisicion_Numero"].value,
        proyectocategoria_id : this.newProject.controls["categoria_id"].value,
        requisicion_id : this.requisicion_id,
        fecha : moment(this.fecha, 'YYYY-MM-DD').format('YYYY-MM-DD')
      };

      arrayTodbHdr = {
        requisicioninterna_id: this.projectInfo["requisicioninterna_id"],
        fecha: this.projectInfo["fecha"],
        contratoOriginal: (this.chkContratoOriginal == true) ? 1 : 0,
        codigoDeContrato: (this.chkCodigoDeContrato == true) ? 1 : 0,
        especificarCargo: (this.chkTMFO == true) ? 1 : 0,
        cargoAlContratista: (this.chkCargoAlContratista == true) ? 1 : 0,
        noODC: this.NoODC,
        idSolicitante: this.newProject.controls["IdSolicitante"].value,
        usuarioCrea: this.usuarioId,
        tAndMDescription: this.TMFO,

        codigo: this.newProject.controls["requisicion_Numero"].value,
        codigo_proyectocategoria: "",
        proyectocategoria_id: this.projectInfo["proyectocategoria_id"],
        proyecto_id: this.projectInfo["proyecto_id"],
        codigo_proyecto: "",
        categoria_id: 0,
        codigo_categoria: "",
        fo: "",
        no_pym: "",
        cargo: "",
        estado: 0,
        comentario: "",
        nombreSolicitante: "",
      }

        // Actualiza registro EDICION
        this.updateRequisition(arrayTodb, arrayTodbHdr);
    }
    this.dialogRef.close();
  }

  add(form, event){
    
    let arrayExcel : any = [];
    let lineaReq : number = 1;

    if(this.UploadDataExcel != undefined){
      if(this.UploadDataExcel.filteredData.length > 0){
        this.UploadDataExcel.filteredData.forEach(element => {
          arrayExcel.push({ 
            requisition_Id : 0,
            SKU : element.SKU
            , cantidad : element.cantidad
            , um : element.um
            , descripcion : element.descripcion
            , medida : element.medida
            , color : element.color
            , otras_especificaciones : element.otras_especificaciones
            , requisicioninternadetalle_id : (element.requisicioninternadetalle_id != undefined) ? element.requisicioninternadetalle_id : 0
            , linea : element.linea
          })
        });
      }else{
        arrayExcel = [];
      }
    }else{
      arrayExcel = [];
    }

    
    if(this.UploadDataExcel != null){
      this.UploadDataExcel.filteredData.forEach(element => {
        lineaReq = element.linea + 1
      });
    }

    arrayExcel.push({ 
      requisition_Id : 0,
      SKU : this.newProject.controls["sku_Detalle"].value
      , cantidad : this.newProject.controls["cantidad_Detalle"].value
      , um : this.newProject.controls["uom_Detalle"].value
      , descripcion : this.newProject.controls["descripcion_Detalle"].value
      , medida : this.newProject.controls["medida_Detalle"].value
      , color : this.newProject.controls["color_Detalle"].value
      , otras_especificaciones : ''
      , requisicioninternadetalle_id : 0
      , linea : lineaReq
     })

    this.UploadDataExcel = null;
     this.UploadDataExcel = new MatTableDataSource(arrayExcel);

  }

  clean(event){
    this.UploadDataExcel = null;
  }

  fechaInicial(event){
    console.log('fecha', this.fecha);
  }

  cancel(event){
    this.dialogRef.close();
  }

  // ====================
  // UTILERIAS
  // ====================

  openSnackBar(message: string, action: string) {
    this._snackBar.open(message, action, {duration : 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: 'alert-snackbar'});
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(0, this.totalSize);
    this.UploadDataExcel = new MatTableDataSource(part);
    this.UploadDataExcel.sort = this.sort;
    this.UploadDataExcel.paginator = this.paginator;
  }

  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.UploadDataExcel.filter = filtro.trim().toLowerCase();
  }

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
      case(3) :
        Swal.fire({
          title: header,
          text: message,
          icon: icon,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: buttonCaption
        }).then((result) => {
          if (result.isConfirmed) {
            this.openFilepopup(this.requisicionId);
          }
        });
      break;
    }
  }

  // ====================
  // LLAMADAS A SERVICIOS
  // ====================

  getMailSender(){
    // Actualiza registro NUEVO
    this._sendMailService.getmailUserSender(this.usuarioIdInt, 'REQUISICION').subscribe(
      res=> {

        // Ordena alfabeticamente
        res.sort(function (a, b) {
          if (a.nombre > b.nombre) {
            return 1;
          }
          if (a.nombre < b.nombre) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });        

        this.SendMailUsersDatasource = res;

        console.log('usuarios de correos', this.SendMailUsersDatasource);

      },
      error => console.log("error consulta usuarios",error)
    )
  }

  getEnabledCategories(){
    // Actualiza registro NUEVO
    this._projectCategoryservice.getProjectCateogryById(1).subscribe(
      res=> {
        this.datasourceCategories = res;
        console.log('CATEGORIAS', res);
      },
      error => console.log("error consulta categorias",error)
    )
  }

  getProyectos(){
    let arrayProyecto : any;
    // Obtiene proyectos
    this._projectService.getProjectAll().subscribe(
      res=> {
        
        // Ordena los proyectos
        res.sort(function (a, b) {
          if (a.codigo_proyecto < b.codigo_proyecto) {
            return 1;
          }
          if (a.codigo_proyecto > b.codigo_proyecto) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        this.datasourcePorjects = res;
        console.log('PROYECTOS', res);
      },
      error => console.log("error consulta proyectos",error)
    )
  }

  getrequisition(codigo_categoria : any){

    let categoria_id : any = this.newProject.controls["categoria_id"].value
    let codigo_requisicion : any = 0;
    let arrayRequisition : any;
    let arrayCodigoCategoria : any;
    
    // Proyectos registrados
    this._requisitionservice.getRequisitionAll().subscribe(
      res=> {
        this.datasourceRequisition = new MatTableDataSource(res);

        if(this.datasourceRequisition.filteredData.length == 0){
          codigo_requisicion = codigo_categoria + '-1'; 
        }else{
          // Obtener requisicion en la que se mas alta y determinar cual sigue
          arrayRequisition = this.datasourceRequisition.filteredData.filter(e => e.proyectocategoria_id == categoria_id);
          if(arrayRequisition.length > 0){
            let cantidadRequisition = arrayRequisition.length
            codigo_requisicion = codigo_categoria + '-' + (cantidadRequisition + 1)
          }else{
            // obtener codigo_categoria
            arrayCodigoCategoria = 1;
            codigo_requisicion = codigo_categoria + '-1'; 
          }

        }

        // this.newProject.controls["requisicion_Numero"].setValue = codigo_categoria + codigo_requisicion;
        this.requisicion_Numero = codigo_requisicion;
        this.newProject.controls["requisicion_Numero"].setValue(codigo_requisicion);
      },
      error => console.log("error consulta regiones",error)
    )
  }

  getCategories(proyecto : any){
    // Obtiene categorias 
    this._projectCategoryservice.getProjectCateogryById(proyecto).subscribe(
      res=> {
        this.datasourceCategories = res;
        console.log('PROYECTOS - CATEGORIAS', res);
      },
      error => console.log("error consulta cateogorias",error)
    )
  }

  insertRequisition(arrayTodb : any){

    this._requisitionservice.insertRequisition(arrayTodb).subscribe(
      res=> {
        // INSERTA REQUISICIONES DET
        this.insertRequisitionDet(res);

        console.log('LOCALSTORAGE', localStorage.getItem('sendMailRequisition'));
        
        if(localStorage.getItem("sendMailRequisition").length > 2){
    
          JSON.parse(localStorage.getItem("sendMailRequisition")).forEach(element => {

            console.log('CORREOS', element["correos"]);

            this.arraySendMail = ({
              to: element["correos"]
              , action: 'Crear'
              , module: 'Requisición'
              , number_create: this.newProject.controls["requisicion_Numero"].value + ' ' + ' Elaborada por el usuario ' + this.usuarioId
              , template: 'Create'
            })
            console.log('this.correosArray', this.arraySendMail);

            console.log('requisicion numero', this.newProject.controls["requisicion_Numero"].value)

            this.sendMail(this.arraySendMail); 
          });
        }

        localStorage.setItem("sendMailRequisition", "");

      },
      error => console.log("error alta de proyectos",error)
    )
  }

  sendMail(arraySendMail: any){
    
    this._sendMailService.sendMail(arraySendMail).subscribe(
      res=>{
        console.log('Se envia correo electronico');
      }
    )
  }

  changeStatus(){

    let changeEstatusArray : any = ({
      "requisicioninterna_id": this.requisicionId,
      "estado": 0,
      "comentario": 'Modificado : ' + this.newProject.controls["comentario"].value
    });

    console.log('declinado', changeEstatusArray);

     // Actualiza registro
     this._requisitionservice.insertRequisitionCancel(changeEstatusArray).subscribe(
      res=> {
        this.showMessage(1, 'Alerta', 'success', 'Se registor correctamente el cambio de estatus', 'Cerrar');
      },
      error => console.log("error consulta usuarios",error)
    );
  }

  getRequisitionDetail(arrayTodb){

    let arrayRequsitionDetail : any;
    let arrayRequsitionToTable : any[] = [];

    this._requisitionservice.getRequisitionDetail(arrayTodb).subscribe(
      res=> {
        arrayRequsitionDetail = res;

        console.log('arrayTodb', arrayTodb);

        arrayRequsitionDetail.forEach(element => {
          arrayRequsitionToTable.push({requisition_Id: 0
                                , SKU : element.sku
                                , cantidad : element.cantidad
                                , um : element.unidad_medida
                                , descripcion : element.descripcion
                                , medida : element.medida
                                , requisicioninternadetalle_id : element.requisicioninternadetalle_id
                                , color : element.color
                                , otras_especificaciones : element.otras_especificaciones
                                , linea : element.linea})
        });

        // Ordena los proyectos
        arrayRequsitionToTable.sort(function (a, b) {
          if (a.linea > b.linea) {
            return 1;
          }
          if (a.linea < b.linea) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        this.UploadDataExcel = new MatTableDataSource(arrayRequsitionToTable);
      },
      error => console.log("error alta de proyectos",error)
    )
  }

  updateRequisition(arrayTodb : any, arrayTodbHdr : any){
    console.log('ACTUALIZA REQUISICION');

      this._requisitionservice.updateRequisitionHdr(arrayTodbHdr).subscribe(
        res=> {
          console.log('ACTUALIZA REQUISICIÓN HEADER', res);
          this.updateRequisitionDetalle(this.requisicionId);
        },
        error => console.log("error al insertar proyectos categorias",error)
      )
  }

  insertRequisitionDet(requisicionId : any){
    // Obtiene Requisicion Registrada
    let arrayToDb : any;

      this.UploadDataExcel.filteredData.forEach(element => {
        arrayToDb = {requisicioninternadetalle_id : element.requisicioninternadetalle_id
            , requisicioninterna_id : requisicionId
            , cantidad : element.cantidad
            , sku : (element.SKU != undefined) ? element.SKU : ''
            , codigo_requisicioninterna : ''
            , unidad_medida : element.um
            , descripcion : element.descripcion
            , existencia_almacen : 0
            , cantidad_comprar : element.cantidad
            , medida : (element.medida != undefined) ? element.medida : ''
            , color : (element.color != undefined) ? element.color : ''
            , otras_especificaciones : (element.otras_especificaciones != undefined) ? element.otras_especificaciones : ''
            , estado : true
            , cotizado : false
            , linea : element.linea
        }

        this.requisicionId = requisicionId;

        // Inserta Proyecto Categoria
        this._requisitionservice.insertRequisitionDetail(arrayToDb).subscribe(
          res=> {
            console.log('REQUISICIONES DETALLE', res);
            this.showMessage(3, 'Guardardo', 'warning', 'La requisición se guardo exitosamente, ¿Desea agregar documentos?', 'Yes, Agregar!');
            // this.dialogRef.close();
          },
          error => console.log("error al insertar proyectos categorias",error)
        )
        
        arrayToDb = null;

      });
  }

  updateRequisitionDetalle(requisicionId : any){
     // Obtiene Requisicion Registrada
     let arrayToDb : any;

     console.log('Detalle de requisicion', this.UploadDataExcel.filteredData)
 
       this.UploadDataExcel.filteredData.forEach(element => {

        if(element.requisicioninternadetalle_id != 0){
            
            // ==========
            // ACTUALIZAR
            // ==========
            arrayToDb = {
              requisicioninternadetalle_id : element.requisicioninternadetalle_id
                , requisicioninterna_id : requisicionId
                , cantidad : element.cantidad
                //  , sku : element.SKU
                //  , codigo_requisicioninterna : ''
                , unidad_medida : element.um
                , descripcion : element.descripcion
                //  , existencia_almacen : 0
                //  , cantidad_comprar : element.cantidad
                //  , medida : (element.medida != undefined) ? element.medida : ''
                //  , color : (element.color != undefined) ? element.color : ''
                //  , otras_especificaciones : (element.otras_especificaciones != undefined) ? element.otras_especificaciones : ''
                //  , estado : true
                //  , cotizado : false
            }

            // Inserta Proyecto Categoria
            this._requisitionservice.updateRequisitionDetail(arrayToDb).subscribe(
              res=> {
                this.showMessage(2, 'Guardardo', 'success', 'La requisición se guardo exitosamente', 'Cerrar');
                // this.dialogRef.close();
              },
              error => console.log("error al insertar proyectos categorias",error)
            )
            
            arrayToDb = null;
            
          }else{

            // ==========
            // INSERTAR
            // ==========
            arrayToDb = {requisicioninternadetalle_id : element.requisicioninternadetalle_id
              , requisicioninterna_id : requisicionId
              , cantidad : element.cantidad
              , sku : element.SKU
              , codigo_requisicioninterna : ''
              , unidad_medida : element.um
              , descripcion : element.descripcion
              , existencia_almacen : 0
              , cantidad_comprar : element.cantidad
              , medida : (element.medida != undefined) ? element.medida : ''
              , color : (element.color != undefined) ? element.color : ''
              , otras_especificaciones : (element.otras_especificaciones != undefined) ? element.otras_especificaciones : ''
              , estado : true
              , cotizado : false
              , linea : element.linea
            }

            // Inserta Proyecto Categoria
            this._requisitionservice.insertRequisitionDetail(arrayToDb).subscribe(
              res=> {
                console.log('REQUISICIONES DETALLE', res);
                this.showMessage(2, 'Guardardo', 'success', 'La requisición se guardo exitosamente', 'Cerrar');
                // this.dialogRef.close();
              },
              error => console.log("error al insertar proyectos categorias",error)
            )

          }
       });

       // ACTUALZA ESTADO
       this.changeStatus();

        if(localStorage.getItem("sendMailRequisition").length > 2){
    
          JSON.parse(localStorage.getItem("sendMailRequisition")).forEach(element => {

              this.arraySendMail = ({
                to: element["correos"]
                  , action: 'Actualizada'
                  , module: 'Requisición'
                  , number_create:this.newProject.controls["requisicion_Numero"].value + ' ' + ' Elaborada por el usuario ' + this.usuarioId
                  , template: 'Create'
              })
              console.log('this.correosArray', this.arraySendMail);

              console.log('requisicion numero', this.newProject.controls["requisicion_Numero"].value)

              this.sendMail(this.arraySendMail); 
          });
        }
  }

}
