import { CompileShallowModuleMetadata, IfStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { BREAKPOINT } from '@angular/flex-layout';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as e from 'cors';
import { decode } from 'querystring';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { employeservice } from '../../../../services/employe.service';
// import { RepseCommentsComponent } from '../../repse-comments/repse-comments.component';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee-capture-general',
  templateUrl: './employee-capture-general.component.html',
  styleUrls: ['./employee-capture-general.component.css']
})
export class EmployeeCaptureGeneralComponent implements OnInit {

// =========================
// DECLARACIONES
// =========================
empleadoId : string;
afianzado : string;
puedeViajar : string;
cambioResidencia : string;
automovil : string;
observaciones : string;
ProveedorId : number = 0;
usuarioId : number = 0;
tipoImagenFile : any = [];
arrayDocumentosProveedor : any;
arrayDocumentosProveedorOrigen : any;
dataSourceSupplier : any;
prestadorServicio : string;
employeeId : number = 0;
nombreCia : string;

nombreArchivo: string = " (archivo nuevo) ";
loading: boolean;
valido: boolean
errorFileUpload : boolean = true; // true = valido, false = invalido

public newProject: FormGroup;

  constructor(
    public dialog: MatDialog
    , private _employeservice : employeservice
    , private formBuilder: FormBuilder
    , private _snackBar : MatSnackBar
    , private readonly _uploadFileService: UploadFileService
  ) { 
    this.newProject = this.formBuilder.group({
      afianzado :  new FormControl('', [Validators.required]),
      puedeViajar: new FormControl('', [Validators.required]),
      cambioResidencia: new FormControl('', [Validators.required]),
      automovil: new FormControl('', [Validators.required]),
      observaciones: new FormControl(''),
      nombreCia: new FormControl('')
    });
  }

// =========================
// PROCEDIMIENTOS
// =========================

  ngOnInit(): void {
    
    this.decode();
        
  }
  
  cancel(event){
  //  this.dialogRef.close();
  }

  save(form, event){

    let arrayToDb : any;
    let telefonoToDb : number;

    arrayToDb = ({ 
      empleadoId : this.employeeId
        , afianzado : (form.controls["afianzado"].value != '0' ) ? true : false
        , puedeViajar : (form.controls["puedeViajar"].value != '0') ? true : false
        , cambioResidencia : (form.controls["cambioResidencia"].value != '0' ) ? true : false 
        , automovil : (form.controls["automovil"].value != '0' ) ? true : false
        , observaciones : (form.controls["observaciones"].value != null) ? form.controls["observaciones"].value : ''

      });

    this.updateEmployee(arrayToDb);
    
  }

  addFile(concepto: any, event){
    console.log('SE CARGA', concepto);
  }

  addView(concepto: any, event ){
    console.log('VISUALIZA', concepto);
  }

// =========================
// UTILERIAS
// =========================

openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {duration : 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: 'alert-snackbar'});
}

decode(){
  let token = localStorage.getItem('token_access');
  let decodeUser = jwt_decode(token)["usuario"];
  let decodeProveedorId = jwt_decode(token)["proveedor_id"];
  this.usuarioId = decodeUser;
  this.employeeId = decodeProveedorId

  this.getemployeeAll(decodeUser);
}

showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
  switch(tipoMensaje){
    case(1) : 
        Swal.fire({
          title: header,
          html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
          icon: icon,
          confirmButtonText: buttonCaption,
          customClass: {
              confirmButton: 'btn  btn-rounded btn-outline-warning'
          }
        })
      break;
    case(2) :
        Swal.fire({
          position: 'top-end',
          icon: icon,
          title: message,
          showConfirmButton: false,
          timer: 1500
        })
      break;
  }
}

opencomments(documentShow : number, element, event){

  let arrayDocuemtnoEnviar : any;

  arrayDocuemtnoEnviar = this.arrayDocumentosProveedorOrigen.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento == documentShow);

  this.showMessage(1, 'Comentario', 'info', arrayDocuemtnoEnviar[0]["comentarios"], 'Cerrar');

}

// =========================
// SERVICIOS
// =========================
getemployeeAll(rfc : string){

  this._employeservice.getemployeAll().subscribe(
    res=> {
      let arrayUserId : any[] = res.filter(e => e.rfc == rfc);

      if(arrayUserId.length > 0 ){
        this.employeeId = arrayUserId[0].id;


        // Proyectos registrados
        this._employeservice.getemployeeGeneralById(arrayUserId[0].id).subscribe(
          res=> {
            this.dataSourceSupplier = new MatTableDataSource(res);

            (this.dataSourceSupplier.filteredData.afianzado == 1) ? this.newProject.controls['afianzado'].setValue('1') : this.newProject.controls['afianzado'].setValue('0');
            (this.dataSourceSupplier.filteredData.puedeViajar == 1) ? this.newProject.controls['puedeViajar'].setValue('1') : this.newProject.controls['puedeViajar'].setValue('0');
            (this.dataSourceSupplier.filteredData.cambioResidencia == 1) ? this.newProject.controls['cambioResidencia'].setValue('1') : this.newProject.controls['cambioResidencia'].setValue('0');
            (this.dataSourceSupplier.filteredData.cambioResidencia == 1) ? this.newProject.controls['cambioResidencia'].setValue('1') : this.newProject.controls['cambioResidencia'].setValue('0');
            (this.dataSourceSupplier.filteredData.automovil == 1) ? this.newProject.controls['automovil'].setValue('1') : this.newProject.controls['automovil'].setValue('0');
            this.newProject.controls["observaciones"].setValue(this.dataSourceSupplier.filteredData.observaciones);

          },
          error => console.log("error consulta regiones",error)
        )
      }
    },
    error => console.log("error consulta regiones",error)
    )
  }

  updateEmployee(arrayToDb : any){
    // Empleados General
    this._employeservice.updateemployeeGeneral(arrayToDb).subscribe(
      res=> {
        this.showMessage(2, 'Exitoso', 'success', 'Actualización de registro exitosa', 'Cerrar');
      },
      error => console.log("error consulta regiones",error)
    )
  }
}
