<!-- INICIA CUERPO DE LA PÁGINA-->
<div class="col-12 col-md-12 p-0">
    <div class="card">
        <div class="card-body card-body-table">
            <mat-table [dataSource]="datasourcesupplier" matSort class="mat-elevation-z8"
                style="width: 95%;">

                
                <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>id </th>
                    <td mat-cell *matCellDef="let element" hidden> {{element.id}} </td>
                </ng-container>

                
                <ng-container matColumnDef="nombreProveedor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30">Nombre </th>
                    <td mat-cell *matCellDef="let element"> {{element.nombreProveedor}} </td>
                </ng-container>
                
                <ng-container matColumnDef="rfcProveedor">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> rfc </th>
                    <td mat-cell *matCellDef="let element"> {{element.rfcProveedor}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="mes">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> mes </th>
                    <td mat-cell *matCellDef="let element"> 
                        <ng-container *ngIf="element.mes == 1">Enero</ng-container>
                        <ng-container *ngIf="element.mes == 2">Febrero</ng-container>
                        <ng-container *ngIf="element.mes == 3">Marzo</ng-container>
                        <ng-container *ngIf="element.mes == 4">Abril</ng-container>
                        <ng-container *ngIf="element.mes == 5">Mayo</ng-container>
                        <ng-container *ngIf="element.mes == 6">Junio</ng-container>
                        <ng-container *ngIf="element.mes == 7">Julio</ng-container>
                        <ng-container *ngIf="element.mes == 8">Agosto</ng-container>
                        <ng-container *ngIf="element.mes == 9">Septiembre</ng-container>
                        <ng-container *ngIf="element.mes == 10">Octubre</ng-container>
                        <ng-container *ngIf="element.mes == 11">Noviembre</ng-container>
                        <ng-container *ngIf="element.mes == 12">Dicieembre</ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="anno">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> año </th>
                    <td mat-cell *matCellDef="let element"> {{element.anno}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="fechaCaducidad">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> fecha de caducidad </th>
                    <td mat-cell *matCellDef="let element"> {{element.fechaCaducidad | date: 'dd-MM-yyyy'}}
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="categoriaDocumento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> cateogria del documento </th>
                    <td mat-cell *matCellDef="let element"> {{element.categoriaDocumento}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="estatusDocumento">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> estatus </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="element.estatusDocumento == 'APROBADO'"><p style="color: green;">{{element.estatusDocumento}}</p></ng-container>
                        <ng-container *ngIf="element.estatusDocumento == 'VENCIDO'"><p style="color: red;">{{element.estatusDocumento}}</p></ng-container>
                        <ng-container *ngIf="element.estatusDocumento == 'NO CARGADO'"><p style="color: rgb(42, 81, 173);">{{element.estatusDocumento}}</p></ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>

        </div>
    </div>
</div>