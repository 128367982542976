<div mat-dialog-content-example [formGroup]="newProject">
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Nombre</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="nombre" matInput class="form-control form-control-line control"
                        formControlName="nombre" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('nombre').valid && newProject.get('nombre').touched" class="help-block"
                    style="color: red;"> El nombre es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Primer Apellido</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="aPaterno" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="aPaterno">
                </mat-form-field>
                <span *ngIf="!newProject.get('aPaterno').valid && newProject.get('aPaterno').touched"
                    class="help-block" style="color: red;"> El primer apellido es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Segundo Apellido</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="aMaterno" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="aMaterno">
                </mat-form-field>
                <span *ngIf="!newProject.get('aMaterno').valid && newProject.get('aMaterno').touched" class="help-block"
                    style="color: red;"> El segundo apellido es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>NSS</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="nss" matInput class="form-control form-control-line control" formControlName="nss" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('nss').valid && newProject.get('nss').touched" class="help-block"
                    style="color: red;"> El NSS es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoNSS == undefined"><input type="file" name="fuNSS" id="fuNSS" (change)="cargarImagen(newProject, $event, 'nss', 'personales', estadoNSS, 1001)" hidden><label for="fuNSS"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoNSS == 2"><input type="file" name="fuNSS" id="fuNSS" (change)="cargarImagen(newProject, $event, 'nss', 'personales', estadoNSS, 1001)" hidden><label for="fuNSS"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoNSS == undefined"><button class="button" (click)="openFile(1001)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoNSS == 0"><button class="button" (click)="openFile(1001)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoNSS == 1"><button class="button" (click)="openFile(1001)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoNSS == 2">
                    <button class="button" (click)="openFile(1001)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1001, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>RFC</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="rfc" matInput class="form-control form-control-line control" formControlName="rfc" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('rfc').valid && newProject.get('rfc').touched" class="help-block"
                    style="color: red;"> El RFC es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoRFC == undefined"><input type="file" name="fuRFC" id="fuRFC" (change)="cargarImagen(newProject, $event, 'rfc', 'personales', estadoRFC, 1002)" hidden><label for="fuRFC"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoRFC == 2"><input type="file" name="fuRFC" id="fuRFC" (change)="cargarImagen(newProject, $event, 'rfc', 'personales', estadoRFC, 1002)" hidden><label for="fuRFC"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoRFC == undefined"><button class="button" (click)="openFile(1002)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoRFC == 0"><button class="button" (click)="openFile(1002)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoRFC == 1"><button class="button" (click)="openFile(1002)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoRFC == 2">
                    <button class="button" (click)="openFile(1002)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1002)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>CURP</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="curp" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="curp">
                </mat-form-field>
                <span *ngIf="!newProject.get('curp').valid && newProject.get('curp').touched" class="help-block"
                    style="color: red;"> El CURP es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoCURP == undefined"><input type="file" name="fuCURP" id="fuCURP" (change)="cargarImagen(newProject, $event, 'curp', 'personales', estadoCURP, 1003)" hidden><label for="fuCURP"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoCURP == 2"><input type="file" name="fuCURP" id="fuCURP" (change)="cargarImagen(newProject, $event, 'curp', 'personales', estadoCURP, 1003)" hidden><label for="fuCURP"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoCURP == undefined"><button class="button" (click)="openFile(1003)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoCURP == 0"><button class="button" (click)="openFile(1003)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoCURP == 1"><button class="button" (click)="openFile(1003)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoCURP == 2">
                    <button class="button" (click)="openFile(1003)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1003)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Sexo</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <mat-select class="form-control" formControlName="sexo">
                        <mat-option value="1">Masculino</mat-option>
                        <mat-option value="2">Femenino</mat-option>
                    </mat-select>
                </mat-form-field>
                <span *ngIf="!newProject.get('sexo').valid && newProject.get('sexo').touched"
                    class="help-block" style="color: red;"> El genero es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Fecha de Nacimiento (dd/mm/yyyy)</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="fechaNacimiento" (dateChange)="fechaInicial($event)" class="control">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>

                <!-- <mat-form-field>
                    <mat-select class="form-control" formControlName="fechaNacimiento">
                        <mat-option value="1">Mexicana</mat-option>
                        <mat-option value="2">Estadounidense</mat-option>
                        <mat-option value="3">Español</mat-option>
                    </mat-select>
                </mat-form-field> -->
                <span *ngIf="!newProject.get('fechaNacimiento').valid && newProject.get('fechaNacimiento').touched"
                    class="help-block" style="color: red;"> La nacionalidad es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoactaDeNacimiento == undefined"><input type="file" name="fuactaDeNacimiento" id="fuactaDeNacimiento" (change)="cargarImagen(newProject, $event, 'actaDeNacimiento', 'personales', estadoactaDeNacimiento, 1004)" hidden><label for="fuactaDeNacimiento"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoactaDeNacimiento == 2"><input type="file" name="fuactaDeNacimiento" id="fuactaDeNacimiento" (change)="cargarImagen(newProject, $event, 'actaDeNacimiento', 'personales', estadoactaDeNacimiento, 1004)" hidden><label for="fuactaDeNacimiento"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoactaDeNacimiento == undefined"><button class="button" (click)="openFile(1004)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoactaDeNacimiento == 0"><button class="button" (click)="openFile(1004)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoactaDeNacimiento == 1"><button class="button" (click)="openFile(1004)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoactaDeNacimiento == 2">
                    <button class="button" (click)="openFile(1004)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1004)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Lugar de Nacimiento (Estado)</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="lugarNacimientoEstado" matInput class="form-control form-control-line control"
                        formControlName="lugarNacimientoEstado" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('lugarNacimientoEstado').valid && newProject.get('lugarNacimientoEstado').touched" class="help-block"
                    style="color: red;">El lugar de nacimiento es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Nacionalidad</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <mat-select class="form-control" formControlName="nacionalidad">
                        <mat-option value='1'>Mexicana</mat-option>
                        <mat-option value='2'>USA</mat-option>
                    </mat-select>
                </mat-form-field>
                <span *ngIf="!newProject.get('nacionalidad').valid && newProject.get('nacionalidad').touched"
                    class="help-block" style="color: red;"> La nacionalidad es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Numero de Licencia</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="numeroLicencia" matInput class="form-control form-control-line control"
                        formControlName="numeroLicencia" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('numeroLicencia').valid && newProject.get('numeroLicencia').touched"
                    class="help-block" style="color: red;"> El numero de licencia es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>¿Cuenta con crédito infonavit?</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <mat-select class="form-control" formControlName="creditoInfonavit">
                        <mat-option value="SI">Sí</mat-option>
                        <mat-option value="NO">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <span *ngIf="!newProject.get('creditoInfonavit').valid && newProject.get('creditoInfonavit').touched"
                    class="help-block" style="color: red;"> El crédito INFONAVIT es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>¿Cuenta con crédito fonacot?</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <mat-select class="form-control" formControlName="creditoFonacot">
                        <mat-option value="SI">Sí</mat-option>
                        <mat-option value="NO">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <span *ngIf="!newProject.get('creditoFonacot').valid && newProject.get('creditoFonacot').touched"
                    class="help-block" style="color: red;"> El crédito FONACOT es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Telefono Fijo</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="telefonoFijo" matInput class="form-control form-control-line control"
                        formControlName="telefonoFijo" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('telefonoFijo').valid && newProject.get('telefonoFijo').touched"
                    class="help-block" style="color: red;"> El crédito FONACOT es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Celular</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="celular" matInput class="form-control form-control-line control"
                        formControlName="celular" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('celular').valid && newProject.get('celular').touched"
                    class="help-block" style="color: red;"> El celular es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Correo Electrónico</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="correo" matInput class="form-control form-control-line control"
                        formControlName="correo" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('correo').valid && newProject.get('correo').touched"
                    class="help-block" style="color: red;"> El correo es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Escolaridad Terminada o Profesión</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="nivelEstudios" matInput class="form-control form-control-line control"
                        formControlName="nivelEstudios" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('nivelEstudios').valid && newProject.get('nivelEstudios').touched"
                    class="help-block" style="color: red;"> El nivel de estudios es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Estudio en la Actualidad</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <mat-select class="form-control" formControlName="estudiosActuales">
                        <mat-option value="SI">Sí</mat-option>
                        <mat-option value="NO">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <span *ngIf="!newProject.get('estudiosActuales').valid && newProject.get('estudiosActuales').touched"
                    class="help-block" style="color: rgb(74, 28, 28);"> Saber si esta estudiando es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoEstudio == undefined"><input type="file" name="fuEstudio" id="fuEstudio" (change)="cargarImagen(newProject, $event, 'estudio', 'personales', estadoEstudio, 1014)" hidden><label for="fuEstudio"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoEstudio == 2"><input type="file" name="fuEstudio" id="fuEstudio" (change)="cargarImagen(newProject, $event, 'estudio', 'personales', estadoEstudio, 1014)" hidden><label for="fuEstudio"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoEstudio == undefined"><button class="button" (click)="openFile(1014)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoEstudio == 0"><button class="button" (click)="openFile(1014)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoEstudio == 1"><button class="button" (click)="openFile(1014)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoEstudio == 2">
                    <button class="button" (click)="openFile(1014)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1014)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>INE</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoINE == undefined"><input type="file" name="fuINE" id="fuINE" (change)="cargarImagen(newProject, $event, 'ine', 'personales', estadoINE, 1005)" hidden><label for="fuINE"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoINE == 2"><input type="file" name="fuINE" id="fuINE" (change)="cargarImagen(newProject, $event, 'ine', 'personales', estadoINE, 1005)" hidden><label for="fuINE"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoINE == undefined"><button class="button" (click)="openFile(1005)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoINE == 0"><button class="button" (click)="openFile(1005)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoINE == 1"><button class="button" (click)="openFile(1005)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoINE == 2">
                    <button class="button" (click)="openFile(1005)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1005)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Institución
            </div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>
                <mat-form-field>
                <input [type]="institucion" matInput class="form-control form-control-line control" formControlName="institucion" >
            </mat-form-field>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Cuenta</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoCuenta == undefined"><input type="file" name="fuCuenta" id="fuCuenta" (change)="cargarImagen(newProject, $event, 'cuenta', 'personales', estadoCuenta, 1013)" hidden><label for="fuCuenta"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoCuenta == 2"><input type="file" name="fuCuenta" id="fuCuenta" (change)="cargarImagen(newProject, $event, 'cuenta', 'personales', estadoCuenta, 1013)" hidden><label for="fuCuenta"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoCuenta == undefined"><button class="button" (click)="openFile(1013)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoCuenta == 0"><button class="button" (click)="openFile(1013)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoCuenta == 1"><button class="button" (click)="openFile(1013)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoCuenta == 2">
                    <button class="button" (click)="openFile(1013)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1013)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>CLABE</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <!-- <mat-form-field>
                    <input [type]="clabe" matInput class="form-control form-control-line control"
                        formControlName="clabe" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('clabe').valid && newProject.get('clabe').touched"
                    class="help-block" style="color: red;"> La clabe es requerida</span> -->
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoCLABE == undefined"><input type="file" name="fuCLABE" id="fuCLABE" (change)="cargarImagen(newProject, $event, 'clabe', 'personales', estadoCLABE, 1006)" hidden><label for="fuCLABE"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoCLABE == 2"><input type="file" name="fuCLABE" id="fuCLABE" (change)="cargarImagen(newProject, $event, 'clabe', 'personales', estadoCLABE, 1006)" hidden><label for="fuCLABE"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoCLABE == undefined"><button class="button" (click)="openFile(1006)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoCLABE == 0"><button class="button" (click)="openFile(1006)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoCLABE == 1"><button class="button" (click)="openFile(1006)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoCLABE == 2">
                    <button class="button" (click)="openFile(1006)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1006)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Fotografía reciente</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadofotografia == undefined"><input type="file" name="fufotografia" id="fufotografia" (change)="cargarImagen(newProject, $event, 'fotografia', 'personales', estadofotografia, 1007)" hidden><label for="fufotografia"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadofotografia == 2"><input type="file" name="fufotografia" id="fufotografia" (change)="cargarImagen(newProject, $event, 'fotografia', 'personales', estadofotografia, 1007)" hidden><label for="fufotografia"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadofotografia == undefined"><button class="button" (click)="openFile(1007)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadofotografia == 0"><button class="button" (click)="openFile(1007)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadofotografia == 1"><button class="button" (click)="openFile(1007)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadofotografia == 2">
                    <button class="button" (click)="openFile(1007)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1007)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Carta de no antecedentes penales</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadocartaDeNoAntecedentes == undefined"><input type="file" name="fucartaDeNoAntecedentes" id="fucartaDeNoAntecedentes" (change)="cargarImagen(newProject, $event, 'cartaDeNoAntecedentes', 'personales', estadocartaDeNoAntecedentes, 1008)" hidden><label for="fucartaDeNoAntecedentes"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadocartaDeNoAntecedentes == 2"><input type="file" name="fucartaDeNoAntecedentes" id="fucartaDeNoAntecedentes" (change)="cargarImagen(newProject, $event, 'cartaDeNoAntecedentes', 'personales', estadocartaDeNoAntecedentes, 1008)" hidden><label for="fucartaDeNoAntecedentes"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadocartaDeNoAntecedentes == undefined"><button class="button" (click)="openFile(1008)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadocartaDeNoAntecedentes == 0"><button class="button" (click)="openFile(1008)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadocartaDeNoAntecedentes == 1"><button class="button" (click)="openFile(1008)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadocartaDeNoAntecedentes == 2">
                    <button class="button" (click)="openFile(1008)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1008)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Todo sobre la prevención del Covid-19</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadocertificadoCOVID == undefined"><input type="file" name="fucertificadoCOVID" id="fucertificadoCOVID" (change)="cargarImagen(newProject, $event, 'certificadoCOVID', 'personales', estadocertificadoCOVID, 1009)" hidden><label for="fucertificadoCOVID"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID == 2"><input type="file" name="fucertificadoCOVID" id="fucertificadoCOVID" (change)="cargarImagen(newProject, $event, 'certificadoCOVID', 'personales', estadocertificadoCOVID, 1009)" hidden><label for="fucertificadoCOVID"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadocertificadoCOVID == undefined"><button class="button" (click)="openFile(1009)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID == 0"><button class="button" (click)="openFile(1009)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID == 1"><button class="button" (click)="openFile(1009)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID == 2">
                    <button class="button" (click)="openFile(1009)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1009)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Estado de cuenta AFORE</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoestadoDeCuentaAFORE == undefined"><input type="file" name="fuestadoDeCuentaAFORE" id="fuestadoDeCuentaAFORE" (change)="cargarImagen(newProject, $event, 'estadoDeCuentaAFORE', 'personales', estadoestadoDeCuentaAFORE, 1010)" hidden><label for="fuestadoDeCuentaAFORE"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoestadoDeCuentaAFORE == 2"><input type="file" name="fuestadoDeCuentaAFORE" id="fuestadoDeCuentaAFORE" (change)="cargarImagen(newProject, $event, 'estadoDeCuentaAFORE', 'personales', estadoestadoDeCuentaAFORE, 1010)" hidden><label for="fuestadoDeCuentaAFORE"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoestadoDeCuentaAFORE == undefined"><button class="button" (click)="openFile(1010)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoestadoDeCuentaAFORE == 0"><button class="button" (click)="openFile(1010)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoestadoDeCuentaAFORE == 1"><button class="button" (click)="openFile(1010)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoestadoDeCuentaAFORE == 2">
                    <button class="button" (click)="openFile(1010)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1010)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Recomendaciones para un retorno seguro al trabajo ante Covid-19</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadocertificadoCOVID2 == undefined"><input type="file" name="fucertificadoCOVID2" id="fucertificadoCOVID2" (change)="cargarImagen(newProject, $event, 'certificadoCOVID2', 'personales', estadocertificadoCOVID2, 1011)" hidden><label for="fucertificadoCOVID2"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID2 == 2"><input type="file" name="fucertificadoCOVID2" id="fucertificadoCOVID2" (change)="cargarImagen(newProject, $event, 'certificadoCOVID2', 'personales', estadocertificadoCOVID2, 1011)" hidden><label for="fucertificadoCOVID2"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadocertificadoCOVID2 == undefined"><button class="button" (click)="openFile(1011)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID2 == 0"><button class="button" (click)="openFile(1011)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID2 == 1"><button class="button" (click)="openFile(1011)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID2 == 2">
                    <button class="button" (click)="openFile(1011)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1011)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Plan de acción para el hogar frente al Covid-19</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadocertificadoCOVID3 == undefined"><input type="file" name="fucertificadoCOVID3" id="fucertificadoCOVID3" (change)="cargarImagen(newProject, $event, 'certificadoCOVID3', 'personales', estadocertificadoCOVID3, 1012)" hidden><label for="fucertificadoCOVID3"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID3 == 2"><input type="file" name="fucertificadoCOVID3" id="fucertificadoCOVID3" (change)="cargarImagen(newProject, $event, 'certificadoCOVID3', 'personales', estadocertificadoCOVID3, 1012)" hidden><label for="fucertificadoCOVID3"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadocertificadoCOVID3 == undefined"><button class="button" (click)="openFile(1012)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID3 == 0"><button class="button" (click)="openFile(1012)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID3 == 1"><button class="button" (click)="openFile(1012)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadocertificadoCOVID3 == 2">
                    <button class="button" (click)="openFile(1012)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(1012)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

</div>

<br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="accent" (click)="save(newProject, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <!-- <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div> -->
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 
