import { Component, EventEmitter, LOCALE_ID, OnInit, Output, ViewChild, NgModule, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { reportMasterModel } from 'src/app/models/reportMaster.model';
import { reportMasterservice } from 'src/app/services/reportMaster.service';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { projectservice } from '../../../services/projects/project.service';
import { formatCurrency } from '@angular/common';
import * as moment from 'moment';
import { CompileShallowModuleMetadata, tokenName } from '@angular/compiler';
import { categoryservice } from '../../../services/category/category.service';
import { trackingservice } from '../../../services/tracking.service';
import { trackingModel } from 'src/app/models/tracking.model';

@Component({
  selector: 'app-binnacle-download',
  templateUrl: './binnacle-download.component.html',
  styleUrls: ['./binnacle-download.component.css']
})
export class BinnacleDownloadComponent implements OnInit {
// =================
// DECLARACIONES
// =================
curr = formatCurrency(10,this.locale, 'MXP');

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<reportMasterModel>
dataSourceShowX : any = [];
fecha:any = moment(new Date, 'DD-MM-YYYY hh:mm').format('DD-MM-YYYY');
fechaIni:any = moment(new Date, 'DD-MM-YYYY hh:mm').format('DD-MM-YYYY');
fechaFin:any = moment(new Date, 'DD-MM-YYYY hh:mm').format('DD-MM-YYYY');

  constructor(public dialog: MatDialog
          , private _excelService : ExcelServiceService
          , private _reportMasterservice : reportMasterservice
          , @Inject(LOCALE_ID) public locale: string
          , private _trackingservice : trackingservice) { }

  ngOnInit(): void {
   }

  search(){
    this.getReporteBinnacle();
  }

  cancel(event){}

  getReporteBinnacle(){

    let arrayReport : any[] = []; 
    let proyecto_Actual : string = '';

    // Obtiene reporte maestro
    this._trackingservice.gettrackingByProjectId(moment(this.fechaIni, 'YYYY-MM-DD').format('YYYY-MM-DD'), moment(this.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD')).subscribe(
      track=> {
        // arrayReport = track;

        console.log('log de transacciones', track)

        track.forEach(element => {
          arrayReport.push({fecha :  moment(element.dateOperation, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY hh:mm:ss')
          , modulo : element.module
          , operacion : element.operation
          , Usuario : element.nombreUsuario
         });
        });

        this._excelService.exportAsExcelFile(arrayReport, 'Reporte_Log_Movimientos');  
      },
      error => console.log("error consulta proyectos",error)
    )

  }

  fechaInicial(event){
    // console.log('fecha', moment(this.fechaIni, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  }

  fechaFinal(event){
    // console.log('fecha', moment(this.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD'));
  }

  // ==================
  // SERVICIOS
  // ==================
  
 

// descargarExcel(){
  //   console.log('Descargar a excel');
  //   let dataSourceShowToExcel : any[] = [];
  //   let contador : number = 0
  //   let sumOriginal_Estimate : number = 0;
  //   let sumActual_Cost : number = 0;
  //   let sumProject_Added : number = 0;
  //   let sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION : number = 0;
  //   let sumPROJECTED_BALANCE_AT_COMPLETION : number = 0;

    
  //   console.log('DatasourceReporte', this.dataSourceShowX)

  //   this.dataSourceShowX.forEach(element => {

  //     dataSourceShowToExcel.push({
  //       '      ' : element.etiqueta
  //       , '    ' :''
  //       , Original_Estimate : formatCurrency(element.Original_Estimate, this.locale, '$')
  //       , '' :''
  //       , Actual_Cost : formatCurrency(element.Actual_Cost, this.locale, '$')
  //       , ' ' :''
  //       , Project_Added : formatCurrency(element.Project_Added, this.locale, '$')
  //       , '  ' :''
  //       , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(element.PROJECTED_ACTUAL_COSTS_AT_COMPLETION, this.locale, '$')
  //       , '   ' :''
  //       , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(element.PROJECTED_BALANCE_AT_COMPLETION, this.locale, '$')
  //     });
  //     contador++;

  //     sumOriginal_Estimate = sumOriginal_Estimate + element.Original_Estimate;
  //     sumActual_Cost = sumActual_Cost + element.Actual_Cost;
  //     sumProject_Added = sumProject_Added + element.Project_Added;
  //     sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION = sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION + element.PROJECTED_ACTUAL_COSTS_AT_COMPLETION;
  //     sumPROJECTED_BALANCE_AT_COMPLETION = sumPROJECTED_BALANCE_AT_COMPLETION + element.PROJECTED_BALANCE_AT_COMPLETION;
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : ''
  //     , '    ' :''
  //     , Original_Estimate : ''
  //     , '' :''
  //     , Actual_Cost : ''
  //     , ' ' :''
  //     , Project_Added : ''
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : ''
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : ''
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : 'Contract Totals'
  //     , '    ' :''
  //     , Original_Estimate : formatCurrency(sumOriginal_Estimate, this.locale, '$')
  //     , '' :''
  //     , Actual_Cost : formatCurrency(sumActual_Cost, this.locale, '$')
  //     , ' ' :''
  //     , Project_Added : formatCurrency(sumProject_Added, this.locale, '$')
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION, this.locale, '$')
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(sumPROJECTED_BALANCE_AT_COMPLETION, this.locale, '$')
  //   });
    
  //   dataSourceShowToExcel.push({
  //     '      ' : ''
  //     , '    ' :''
  //     , Original_Estimate : ''
  //     , '' :''
  //     , Actual_Cost : ''
  //     , ' ' :''
  //     , Project_Added : ''
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : ''
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : ''
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : 'Subcontractors - Base Contract'
  //     , '    ' :''
  //     , Original_Estimate : formatCurrency(0.00, this.locale, '$')
  //     , '' :''
  //     , Actual_Cost : formatCurrency(0.00, this.locale, '$')
  //     , ' ' :''
  //     , Project_Added : formatCurrency(0.00, this.locale, '$')
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(0.00, this.locale, '$')
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(0.00, this.locale, '$')
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : 'Subcontractor Change Order Costs'
  //     , '    ' :''
  //     , Original_Estimate : formatCurrency(0.00, this.locale, '$')
  //     , '' :''
  //     , Actual_Cost : formatCurrency(0.00, this.locale, '$')
  //     , ' ' :''
  //     , Project_Added : formatCurrency(0.00, this.locale, '$')
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(0.00, this.locale, '$')
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(0.00, this.locale, '$')
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : ''
  //     , '    ' :''
  //     , Original_Estimate : ''
  //     , '' :''
  //     , Actual_Cost : ''
  //     , ' ' :''
  //     , Project_Added : ''
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : ''
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : ''
  //   });

  //   dataSourceShowToExcel.push({
  //     '      ' : 'PROJECTED TOTAL COSTS'
  //     , '    ' :''
  //     , Original_Estimate : ''
  //     , '' :''
  //     , Actual_Cost : ''
  //     , ' ' :''
  //     , Project_Added : ''
  //     , '  ' :''
  //     , PROJECTED_ACTUAL_COSTS_AT_COMPLETION : formatCurrency(sumPROJECTED_ACTUAL_COSTS_AT_COMPLETION, this.locale, '$')
  //     , '   ' :''
  //     , PROJECTED_BALANCE_AT_COMPLETION : formatCurrency(sumPROJECTED_BALANCE_AT_COMPLETION, this.locale, '$')
  //   });

  //   this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Reporte Maestro');  

  //   this.dataSourceShowX = [];
  // }

}

