import { Component, EventEmitter, LOCALE_ID, OnInit, Output, ViewChild, NgModule, Inject, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { reportMasterModel } from 'src/app/models/reportMaster.model';
import { reportMasterservice } from 'src/app/services/reportMaster.service';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { projectservice } from '../../../services/projects/project.service';
import { formatCurrency } from '@angular/common';
import { categoryservice } from '../../../services/category/category.service';
import { purchaseOrderservice } from '../../../services/PurchaseOrder.service';
import jsPDF from 'jspdf';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import jwt_decode from "jwt-decode";
import { trackingservice } from '../../../services/tracking.service';
import { UserService } from '../../../services/user.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { supplyservice } from '../../../services/supplier.service';
import { element } from 'protractor';
import { interval, Subscription } from 'rxjs';
import { projectCategoryservice } from 'src/app/services/projectCtegory/projectCateogry.service';
import * as JSZip from "jszip";
import { type } from 'os';
import * as FileSaver from 'file-saver';
// import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
@Component({
  selector: 'app-po-download',
  templateUrl: './po-download.component.html',
  styleUrls: ['./po-download.component.css'],
})
export class PoDownloadComponent implements OnInit {

  pdfTest1 = '';

// =================
// DECLARACIONES
// =================
curr = formatCurrency(10,this.locale, 'MXP');

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<reportMasterModel>
dataSourceShowX : any = [];
datasourcePorjects : any;
datasourceCategories : any[] = [];
datasourcesupplier : any;
arrayPOHdr : any;
arrayPODet : any[] = [];
proyecto_id : number;
categoria_id : number;
decodedSign : any;
usuarioId : any;
UserIdLogin : number;
datasourceUsers : any;
arrayUsers : any;
logoDataUrl : string;
logoDataCompras : string;
logoDataControlProy : string;
logoDataBlanco : string;
logoDataBlancoODC : string;
logoCCC: any;
interval : any;
arraytoPDF : any[] = [];
xpdf : any;
pdfBuffer : any;
contadorODC : number = 0;
contadorInternoODC : number = 0;
isloading : boolean = false;

public zip = new JSZip();
public pdf = this.zip.folder("ordenesDeCompra");

@ViewChild('pdfTable') pdfTable: ElementRef;
@ViewChild(MatSort,{static:true}) sort: MatSort;
@ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
@Output() filterChange = new EventEmitter();

pdfByteArrays = [];

  constructor(public dialog: MatDialog
    , private _excelService : ExcelServiceService
    , private _projectService : projectservice
    , private _reportMasterservice : reportMasterservice
    , @Inject(LOCALE_ID) public locale: string
    , private _categoryService : categoryservice
    , private _purchaseOrderservice : purchaseOrderservice
    , private _UserService : UserService
    , private _supplyservice : supplyservice
    , private _trackingservice : trackingservice
    , private _projectCategoryservice : projectCategoryservice) 
    { 
      // this.pdfByteArrays.push(this.pdfTest1);
    }

  ngOnInit(): void {
    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlanco.PNG').then(
      result => this.logoDataUrl = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaRoberto.PNG').then(
      result => this.logoDataCompras = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/imgControlProyectos.PNG').then(
      result => this.logoDataControlProy = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/background/logoCCC.jpg').then(
      result => this.logoCCC = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/imgBlanco.png' ).then(
      result => this.logoDataBlanco = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlancoOrdenesDeCompra.png' ).then(
      result => this.logoDataBlancoODC = result
    )
    
    this.getsupplierAll();
    this.getProyectos();
    this.decode();

    // this.downloadPdf();
    // this.downloadZip();
  }

  decode(){
    let token = localStorage.getItem('token_access');
    this.decodedSign = jwt_decode(token)["firma"] + '?alt=media&token='; 
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeId = jwt_decode(token);
    let usuariotoTrack = jwt_decode(token)["usuarioid"];

    this.usuarioId = decodeUser;

    let arrayTracking : any = ({module : 'Orden de compra', userId : usuariotoTrack, operation : 1, oldData : '', newData : 'Consulta listado de ordenes de compra', dateOperation : moment(new Date, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')})
    this.saveTracking(arrayTracking)

    this.getusers();
  }

  search(){
    this.getOrdenesDeCompra();
  }

  projectSelected(event){
    console.log('xxxxxx', this.proyecto_id)
    this.getCategoriasProyecto();
  }

  cancel(event){}

  getOrdenesDeCompra(){

    let categoria : number;
    this.contadorODC = 0;
    this.contadorInternoODC = 0;
    categoria = (this.categoria_id == undefined) ? 0 : this.categoria_id

    this.isloading = true;
    // Obtiene reporte maestro
    this._purchaseOrderservice.getPOByProjectCategory(this.proyecto_id, categoria).subscribe(
      res=> {
        this.arrayPOHdr = res;

        console.log('cantidad ordenes de compra', this.arrayPOHdr.length);

        let contador : number = 1;

        this.arrayPOHdr.forEach(element => {

          //if(contador <= 100)
          this.getPO_Det(element)
          // setTimeout( () => { this.printPDF(element); console.log('imprime pdf') }, 5000 );
        
          contador++;
        });

        console.log('pdfvalue', this.xpdf)
        // this.xpdf.forEach(element => {
        //   console.log('impresiones', element.pdf);
        //   //pdfMake.createPdf(documentDefinition).download(element.ordendecompra_codigo + '.pdf');
        //   // element.pdf.download(element.pdfNombre)
          
        // });

        // this.descargarExcel();
      },
      error => console.log("error consulta proyectos",error)
    )

  }

  // ==================
  // SERVICIOS
  // ==================
  getProyectos(){
    // Obtiene proyectos
    this._projectService.getProjectAll().subscribe(
      res=> {
        this.datasourcePorjects = res;
        console.log('PROYECTOS', res);
      },
      error => console.log("error consulta proyectos",error)
    )
  }

  datsourcePoDetail : any;

  getPO_Det(element){
    this._purchaseOrderservice.getPODetail(element.ordendecompra_id).subscribe(
      res=> {

        // Ordena los PO DETAIL
        res.sort(function (a, b) {
          if (a.linea > b.linea) {
            return 1;
          }
          if (a.linea < b.linea) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        this.datsourcePoDetail = res;
        this.downloadAsPDF(element);
  
      },
      error => console.log("error consulta proyectos",error)
    )
  }

  getsupplierAll(){
    // Selecciona todos los proveedores
    this._supplyservice.getsupplyAll().subscribe(
      res=> {
        this.datasourcesupplier = res;
      },
      error => console.log("error consulta categorias",error)
    )
  }

  getusers(){
    this._UserService.getUsersAll().subscribe(
      res=> {
        this.datasourceUsers = res;
        this.arrayUsers = this.datasourceUsers.filter(e => e.nombreUsuario == this.usuarioId)
        this.UserIdLogin = Number(this.arrayUsers[0]["usuarioId"].toString());


        switch(this.arrayUsers[0]["nombreUsuario"]){
      case('pablo'):  this.decodedSign = this.decodedSign + 'c5a8f192-5cb8-4025-8d30-31918abfa5be';
                      this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaPablo.PNG').then(
                        result => this.logoDataUrl = result
                      )
        break;
      case('alejandro_fuentes'): this.decodedSign = this.decodedSign + '36189034-32e5-4e28-b44c-43dec58e9999' 
                      this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaAlejandro.PNG').then(
                        result => this.logoDataUrl = result
                      )
        break;
      case('bernardo_tamez'): this.decodedSign = this.decodedSign + '611d133a-d14a-45ab-a26a-f6e0dd570636' 
                      this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBernardo.PNG').then(
                        result => this.logoDataUrl = result
                      )        
        break;
      case('fernando_chavez'): this.decodedSign = this.decodedSign + 'be146605-1624-48e9-b646-cf9dbfd4f7a8' 
                      this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaFernando.PNG').then(
                        result => this.logoDataUrl = result
                      )   
        break;
        case('lauro_araujo'): this.decodedSign = this.decodedSign + '79a31698-9239-4e8c-ba29-90b07692136a' // lauro_araujo
                    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaLauroAraujo.png').then(
                      result => this.logoDataCompras = result
                    )   
          break;
        case('marisela_jaime'): this.decodedSign = this.decodedSign + '5f8925a7-bd65-40cc-a11d-b61dad2a9fad' 
                this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaMariselaJaime.png').then(
                  result => this.logoDataCompras = result
                )   
         break;
      default: this.decodedSign = this.decodedSign + 'c5a8f192-5cb8-4025-8d30-31918abfa5be';
                    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlanco.PNG').then(
                      result => this.logoDataUrl = result
                    )
        break;
    }
      },
      error => console.log("error consulta categorias",error)
    )
  }

  saveTracking(arrayTracking : any){

    this._trackingservice.inserttracking(arrayTracking).subscribe(
      inserta=>{
          console.log('inserta log', inserta);
      },
      error => console.log("error consulta regiones",error)
    )
  }

  getImageDataUrlFromLocalPath1(localPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement('canvas');
        let img = new Image();
        img.onload = () => {
            canvas.height = img.height;
            canvas.width = img.width;
            canvas.getContext("2d").drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png'));
        }
        img.onerror = () => reject('Imagen no disponible')
        img.src = localPath;
    })

  }
  
  getCategoriasProyecto(){
    // Obtiene categorias
    this._projectCategoryservice.getProjectCateogryById(this.proyecto_id).subscribe(
      res=> {
        this.datasourceCategories = res;

        console.log('categorias', res)

        this.datasourceCategories.push({activo : true
                              , almacenid : 1
                              , categoria_id : 0
                              , codigo_proyectocategoria : '0'
                              , fecha_inicial : '2023-01-01'
                              , nombre_almacen : 'Almacen 001'
                              , nombre_categoria : 'TODOS'
                              , nombre_proyecto : ''
                              , presupuesto : 0
                              , proyecto_id : this.proyecto_id
                              , proyectocategoria_id : 0
                              , responsable : ''})

        // Ordenado de arreglo de menor a mayor
        this.datasourceCategories = this.datasourceCategories.sort(function (a, b) {
          if (a.codigo_proyectocategoria > b.codigo_proyectocategoria) {
            return 1;
          }
          if (a.codigo_proyectocategoria < b.codigo_proyectocategoria) {
            return -1;
          }
          return 0;
        });

      },
      error => console.log("error consulta categorias",error)
    )
  }
// ===================
// UTILERIAS
// ===================
showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
  switch(tipoMensaje){
    case(1) : 
        Swal.fire({
          title: header,
          html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
          icon: icon,
          confirmButtonText: buttonCaption,
          customClass: {
              confirmButton: 'btn  btn-rounded btn-outline-warning'
          }
        })
      break;
    case(2) :
        Swal.fire({
          position: 'top-end',
          icon: icon,
          title: message,
          showConfirmButton: false,
          timer: 1500
        })
      break;
  }
}

// ===================
// Print PDF
// ===================
public async downloadAsPDF(element) {

let subtotalPDF : number = Number(element.sub_total);
    let ivaPDF : number = Number(element.iva_moneda);
    let totalPDF : number = Number(Number(element.sub_total) + Number(element.iva_moneda));
    let isrRetencion125PDF : number = Number(element.isrRetencion125);
    let totalPDFLetra : string = (element.tipo_moneda == 'MXN') ? this.numeroALetras(totalPDF, 'PESOS MEXICANOS') : this.numeroALetras(totalPDF, 'DOLARES AMERICANOS');
    let ciudadEstado : string;
    let terminosYCondiciones : string = element.terminos_condiciones
    let descuentoPDF : number = Number(element.descuento_global);
    let retencionMonto : number = Number(element.retencion_monto);
    let fechaODC : any = element.ordendecompra_fecha;
    let retencionIVA2 : any = element.retencionIVA2;
    let retencionIVA3 : any = element.retencionIVA3;

    // ivaPDF = (retencionMonto == 0) ? ivaPDF : ivaPDF - retencionMonto;
    totalPDF = (retencionMonto == 0) ? Number(Number(element.sub_total) + Number(element.iva_moneda)) : Number(Number(element.sub_total) + Number(ivaPDF) - Number(retencionMonto));
    totalPDF = (isrRetencion125PDF == 0) ? Number(totalPDF) : Number(Number(totalPDF) - Number(isrRetencion125PDF));
    totalPDF = totalPDF - Number(element.retencionIVA2) - Number(element.retencionIVA3);
    const doc = new jsPDF();   
    const pdfTable = this.pdfTable.nativeElement;
    var html = htmlToPdfmake(pdfTable.innerHTML);

    let arrayProveedor = this.datasourcesupplier.filter(e => e.proveedorid == element.proveedor_id) 
    
    var headers = {
      0:{
          col_1:{ text: 'Cantidad', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 20 },
          col_2:{ text: 'Unidad', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 20 },
          col_3:{ text: 'Descripción', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 40 },
          col_4:{ text: 'Precio Unitario', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 10  },
          col_5:{ text: 'Importe', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 10  },
          // col_6:{ text: 'SKU', style: 'tableHeader',rowSpan: 2, alignment: 'center',margin: [0, 8, 0, 0], width: 20 }
      },
      1:{
          col_1:{ text: 'Header 1', style: 'tableHeader', alignment: 'center' },
          col_2:{ text: 'Header 2', style: 'tableHeader', alignment: 'center' }, 
          col_3:{ text: 'Header 3', style: 'tableHeader', alignment: 'center' },
          col_4:{ text: 'Citación', style: 'tableHeader', alignment: 'center' },
          col_5:{ text: 'Cumplimiento', style: 'tableHeader', alignment: 'center'},
          col_6:{ text: 'Cumplimiento', style: 'tableHeader', alignment: 'center'}
      }
    }

    let bodyx = [];
    var body = [];
    for (var key in headers){
        if (headers.hasOwnProperty(key)){
            var header = headers[key];
            var row = new Array();
            row.push( header.col_1 );
            row.push( header.col_2 );
            row.push( header.col_3 );
            row.push( header.col_4 );
            row.push( header.col_5 );
            // row.push( header.col_6 );
            body.push(row);
        }
    }

    if(retencionIVA2 != 0){
      this.datsourcePoDetail[0]["descripcion"] = this.datsourcePoDetail[0]["descripcion"] + '\nClave Prod. Serv. - 80121603 Derecho societario'
      + '\nImpuestos:'
              + '\nTraslados:'
              + '\n 002 IVA Base - ' + subtotalPDF + ' Tasa - 0.160000 Importe - $ ' + ivaPDF
              + '\nRetenciones:'
              + '\n 001 ISR Base - ' + subtotalPDF + ' Tasa - 0.100000 Importe - $ ' + retencionIVA2
              + '\n 002 IVA Base - ' + subtotalPDF + ' Tasa - 0.106700 Importe - $ ' + retencionIVA3
    }

    for (var key in this.datsourcePoDetail) {
        if (this.datsourcePoDetail.hasOwnProperty(key))
        {
            var data = this.datsourcePoDetail[key];
            var row = new Array();
            row.push( data.cantidad.toString() );
            row.push( data.unidad_medida.toString()  );
            row.push( data.descripcion.toString() + ' ' + data.medida.toString() + ' ' + data.color.toString() );
            row.push( formatter.format(data.precio_unitario).toString()  );
            row.push( formatter.format((data.precio_unitario * data.cantidad)).toString() );
            body.push(row);
            bodyx.push(row);            
        }
    }

    ciudadEstado = (element.destino_ciudad.length > 0 && element.destino_estado.length > 0) ? element.destino_ciudad + ' ' + element.destino_estado : ''

    const documentDefinition = {
      content: [
        {
          columns: [
            { 
              // image: this.getBase64ImageFromURL('https://firebasestorage.googleapis.com/v0/b/sap-comercial.appspot.com/o/firmas%2FFirmaPablo.PNG?alt=media&token=c5a8f192-5cb8-4025-8d30-31918abfa5be'),
              image: this.logoCCC,
              width: 100,
              height: 50,
            },
            {
              text: '', fontSize:8, width: 20
            },
            {
              text: 'COMMERCIAL CONTRACTING DE MEXICO, S DE RL DE CV      Carretera a Saltillo-Monterrey Km. 18                                       Parque Industrial Santa María 25903 Ramos Arízpe, Coahuila Telefono: (844) 866 9030 RFC: CCM-950330-P1A', fontSize:8, width: 230
            },
            {
              text: '', fontSize:8, width: 20
            },
            {
              text: ' ', fontSize:8 // ORDEN DE COMPRA No. 1791-SUB232
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Proveedor:', fontSize:8, bold:true
            },
            {
              text: '', fontSize:8, width: 20
            },
            {
              text: '', fontSize:8
            },
            {
              text: '', fontSize:8, width: 20
            },
            {
              text: 'Enviar a:', fontSize:8, bold:true
            }
          ]
        },
        {
          columns: [
            {
              text: 'Nombre: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (arrayProveedor.length > 0) ? arrayProveedor[0]["nombre"] : 'NA', fontSize:8, width: '*'
            },
            {
              text: 'Nombre: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.destino_nombre.length > 0) ? element.destino_nombre : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Dirección: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (arrayProveedor.length > 0) ? arrayProveedor[0]["direccion"] : 'NA', fontSize:8, width: '*'
            },
            {
              text: 'Dirección: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.destino_direccion.length > 0) ? element.destino_direccion : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'R.F.C. ', fontSize:8, bold:true, width: 90
            },
            {
              text: (arrayProveedor.length > 0) ? arrayProveedor[0]["rfc"] : 'NA', fontSize:8, width: '*'
            },
            {
              text: 'Cd./Edo: ', fontSize:8, bold:true, width: 90
            },
            {
              text: ciudadEstado, fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Cd./Edo: ', fontSize:8, bold:true, width: 90
            },
            {
              text: ((arrayProveedor.length > 0) ? arrayProveedor[0]["ciudad"] : 'NA') + ', ' + ((arrayProveedor.length > 0) ? arrayProveedor[0]["estado"] : ''), fontSize:8, width: '*'
            },
            {
              text: 'Requisitor: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.destino_requisitor.length > 0) ? element.destino_requisitor : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Contacto ', fontSize:8, bold:true, width: 90
            },
            {
              text: (arrayProveedor.length > 0) ? arrayProveedor[0]["contacto"] : 'NA', fontSize:8, width: '*'
            },
            {
              text: 'Teléfono: ', fontSize:8, bold:true, width: 90
            },
            {
              text: (element.destino_telefono.toString().length > 0 && element.destino_telefono.toString() != '0') ? element.destino_telefono : '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true, width: 90
            },
            {
              text: '', fontSize:8, width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Orden de compra ', fontSize:8, bold:true, width: 90
            },
            {
              text: element.ordendecompra_codigo, fontSize:8, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'FO ', fontSize:8, bold:true, width: 90
            },
            {
              text: element.fo, fontSize:8, width: '*'
            }
          ]
        },
        {
          columns: [
            {
              text: 'Proyecto ', fontSize:8, bold:true, width: 90
            },
            {
              text: element.ordendecompra_codigo.substring(0, element.ordendecompra_codigo.indexOf('-')), fontSize:8, width: '*'
            }
          ]
        },
        {
          style: 'tableHeader', fontSize:8, width:'500px',
          table: {
            body: [
              ['FECHA ODC', 'TERMINOS Y CONDICIONES                                                                                                                                                                            .'],
              [ moment(fechaODC, 'YYYY-MM-DD').format('DD/MMM/YYYY'), terminosYCondiciones]
            ]
          }
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          fontSize:8, width:'100%',
          table: {
            widths: [ '10%', '10%', '50%', '15%', '15%' ],
            headerRows: 2,
            // keepWithHeaderRows: 1,
            body: body
        }
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: totalPDFLetra, fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*' // CIEN MIL PESOS 00/100 MN
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Subtotal', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(subtotalPDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Descuento', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(descuentoPDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'IVA', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(ivaPDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Retención', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(retencionMonto) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 300
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Retención 1.25%', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(isrRetencion125PDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 300
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Retención IVA', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(retencionIVA2) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 300
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Retención ISR', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(retencionIVA3) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: '', fontSize:8, bold:true, width: 280
            },
            {
              text: '', fontSize:8, width: '*'
            },
            {
              text: '', fontSize:8, bold:true
            },
            {
              text: 'Total', fontSize:8, bold:true, width: '*'
            },
            {
              text: formatter.format(totalPDF) , fontSize:8, alignment: 'right', width:'*'
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, width: 20
            },
            { 
              image: this.logoDataBlanco,
              width: 50,
              height: 50,
            },
            {
              text: ' ', fontSize:8, width: 20
            },
            { 
              image: this.logoDataCompras,
              width: 100,
              height: 50,
            },
            {
              text: '', fontSize:8, width: 20
            },
            { 
              image: this.logoDataControlProy,
              width: 100,
              height: 50,
            },
            {
              text: '', fontSize:8, width: 20
            },
            { 
              // image: this.getBase64ImageFromURL('https://firebasestorage.googleapis.com/v0/b/sap-comercial.appspot.com/o/firmas%2FFirmaPablo.PNG?alt=media&token=c5a8f192-5cb8-4025-8d30-31918abfa5be'),
              image: this.logoDataUrl,
              // image: (element.estado == 1 || element.estado == 2) ? this.logoDataBlancoODC : this.logoDataUrl,
              width: 100,
              height: 50,
            }
          ]
        },
      
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
       
        {
          columns: [
            {
              text: '1. Datos de Facturación:', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  COMMERCIAL CONTRACTING DE MEXICO', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Régimen General (601)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Método de Pago PPD (Pago Parcial Diferido)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Forma de pago    99   (Por definir)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Uso del CFDI        G3 ( Gastos Generales)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '2. Entrega factura anexando O.C y soporte firmado de autorización y/o almacén:', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  (Incluyendo archivos XML y PDF)', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '3. Para el envió de facturas será:', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: '-  Facturas.ccm@cccnetwork.mx', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: ' 4. No se pagaran facturas que no cumplan con estos requisitos', fontSize:8, bold:true, width: 300
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
        {
          columns: [
            {
              text: ' ', fontSize:8, bold:true, width: 90
            }
          ]
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 11,
          color: 'black'
        },
        tableFooter: {
          bold: true,
          fontSize: 13,
          color: 'black',
          border: false
        }
      },
      defaultStyle: {
        // alignment: 'justify'
      }
    };

    // pdfMake.createPdf(documentDefinition).open();

    // this.arraytoPDF.push(documentDefinition);
      // pdfMake.createPdf(documentDefinition).download(element.ordendecompra_codigo + '.pdf');
    
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBase64((dataff) => {

      this.pdfTest1 = dataff;

      this.pdfByteArrays.push(this.pdfTest1);

      this.pdfByteArrays.forEach((value,i) => {
        this.pdf.file(element.ordendecompra_codigo + '.pdf',value, { base64: true });
      });

      this.contadorODC++;
      this.contadorInternoODC++;

      console.log('Contador va en: ', this.contadorODC);
      console.log('contador interno', this.contadorInternoODC);

      //  || this.contadorInternoODC == 80
      if(this.contadorODC == this.arrayPOHdr.length || this.contadorInternoODC == 80){
        this.zip.generateAsync({ type: "blob" }).then(function (content) {
          FileSaver.saveAs(content, "OrdenesDeCompra" + element.ordendecompra_codigo +".zip");
        });

        // Reinicializa arreglos
        this.zip = new JSZip();
        this.pdf = this.zip.folder("ordenesDeCompra");
        this.pdfByteArrays = [];
        this.contadorInternoODC = 0;

      }

      if(this.contadorODC == this.arrayPOHdr.length){
        this.isloading = false;
      }

    });

      // pdfMake.createPdf(documentDefinition).save(element.ordendecompra_codigo + '.pdf')
      // this.xpdf.push({pdfData : data, pdfNombre : element.ordendecompra_codigo + '.pdf'});
     
}

  // NUMEROS A LETRAS

  Unidades(num){

    switch(num)
    {
        case 1: return 'UN';
        case 2: return 'DOS';
        case 3: return 'TRES';
        case 4: return 'CUATRO';
        case 5: return 'CINCO';
        case 6: return 'SEIS';
        case 7: return 'SIETE';
        case 8: return 'OCHO';
        case 9: return 'NUEVE';
    }
  
    return '';
  }//Unidades()
  
  Decenas(num){
  
    let decena = Math.floor(num/10);
    let unidad = num - (decena * 10);
  
    switch(decena)
    {
        case 1:
            switch(unidad)
            {
                case 0: return 'DIEZ';
                case 1: return 'ONCE';
                case 2: return 'DOCE';
                case 3: return 'TRECE';
                case 4: return 'CATORCE';
                case 5: return 'QUINCE';
                default: return 'DIECI' + this.Unidades(unidad);
            }
        case 2:
            switch(unidad)
            {
                case 0: return 'VEINTE';
                default: return 'VEINTI' + this.Unidades(unidad);
            }
        case 3: return this.DecenasY('TREINTA', unidad);
        case 4: return this.DecenasY('CUARENTA', unidad);
        case 5: return this.DecenasY('CINCUENTA', unidad);
        case 6: return this.DecenasY('SESENTA', unidad);
        case 7: return this.DecenasY('SETENTA', unidad);
        case 8: return this.DecenasY('OCHENTA', unidad);
        case 9: return this.DecenasY('NOVENTA', unidad);
        case 0: return this.Unidades(unidad);
    }
  }//Unidades()
  
  DecenasY(strSin, numUnidades) {
    if (numUnidades > 0)
        return strSin + ' Y ' + this.Unidades(numUnidades)
  
    return strSin;
  }//DecenasY()
  
  Centenas(num) {
    let centenas = Math.floor(num / 100);
    let decenas = num - (centenas * 100);
  
    switch(centenas)
    {
        case 1:
            if (decenas > 0)
                return 'CIENTO ' + this.Decenas(decenas);
            return 'CIEN';
        case 2: return 'DOSCIENTOS ' + this.Decenas(decenas);
        case 3: return 'TRESCIENTOS ' + this.Decenas(decenas);
        case 4: return 'CUATROCIENTOS ' + this.Decenas(decenas);
        case 5: return 'QUINIENTOS ' + this.Decenas(decenas);
        case 6: return 'SEISCIENTOS ' + this.Decenas(decenas);
        case 7: return 'SETECIENTOS ' + this.Decenas(decenas);
        case 8: return 'OCHOCIENTOS ' + this.Decenas(decenas);
        case 9: return 'NOVECIENTOS ' + this.Decenas(decenas);
    }
  
    return this.Decenas(decenas);
  }//Centenas()
  
  Seccion(num, divisor, strSingular, strPlural) {
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)
  
    let letras = '';
  
    if (cientos > 0)
        if (cientos > 1)
            letras = this.Centenas(cientos) + ' ' + strPlural;
        else
            letras = strSingular;
  
    if (resto > 0)
        letras += '';
  
    return letras;
  }//Seccion()
  
  Miles(num) {
    let divisor = 1000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)
  
    let strMiles = this.Seccion(num, divisor, 'UN MIL', 'MIL');
    let strCentenas = this.Centenas(resto);
  
    if(strMiles == '')
        return strCentenas;
  
    return strMiles + ' ' + strCentenas;
  }//Miles()
  
  Millones(num) {
    let divisor = 1000000;
    let cientos = Math.floor(num / divisor)
    let resto = num - (cientos * divisor)
  
    let strMillones = this.Seccion(num, divisor, 'UN MILLON', 'MILLONES');
    let strMiles = this.Miles(resto);
  
    if(strMillones == '')
        return strMiles;
  
    return strMillones + ' ' + strMiles;
  }//Millones()
  
  numeroALetras(num, currency) {
    currency = currency || {};
    let data = {
        numero: num,
        enteros: Math.floor(num),
        centavos: (((Math.round(num * 100)) - (Math.floor(num) * 100))),
        letrasCentavos: '',
        letrasMonedaPlural: currency.plural || currency,//'PESOS', 'Dólares', 'Bolívares', 'etcs'
        letrasMonedaSingular: currency.singular || currency, //'PESO', 'Dólar', 'Bolivar', 'etc'
        letrasMonedaCentavoPlural: currency.centPlural || 'CENTAVOS',
        letrasMonedaCentavoSingular: currency.centSingular || 'CENTAVO'
    };
  
    if (data.centavos > 0) {
        let centavos = ''
        if (data.centavos == 1)
            centavos = this.Millones(data.centavos) + ' ' + data.letrasMonedaCentavoSingular;
        else
            centavos =  this.Millones(data.centavos) + ' ' + data.letrasMonedaCentavoPlural;
        data.letrasCentavos = 'CON ' + centavos
    };
  
    if(data.enteros == 0)
        return 'CERO ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
    if (data.enteros == 1)
        return this.Millones(data.enteros) + ' ' + data.letrasMonedaSingular + ' ' + data.letrasCentavos;
    else
        return this.Millones(data.enteros) + ' ' + data.letrasMonedaPlural + ' ' + data.letrasCentavos;
  }

}
