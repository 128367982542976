import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { documentRequisitionModel } from 'src/app/models/documentRequisition.model';
import { UploadFileService } from '../../../services/upload-file/upload-file.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatTableDataSource } from '@angular/material/table';
import { deserialize } from 'chartist';

@Component({
  selector: 'app-requsition-send-mail',
  templateUrl: './requsition-send-mail.component.html',
  styleUrls: ['./requsition-send-mail.component.css'
  ]
})
export class RequsitionSendMailComponent implements OnInit {

  projectInfo : any;
  requisicionId : any = 0;
  estadoPantalla : string;
  emailDatasource : any;
  emailDatasourceOriginal : any;
  emailDatasourcetarget : any;
  email : any = '';

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;

  displayedColumnsOrigin = ['nombre', 'correos', 'add'];
  displayedColumnsTarget = ['nombre', 'correos', 'delete'];

  public newProject: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<documentRequisitionModel>
    , @Inject(MAT_DIALOG_DATA) public data
    , private formBuilder: FormBuilder
  ) { 
    this.newProject = this.formBuilder.group({
      correos: new FormControl('', [Validators.required]),
      emailCapture: new FormControl('', [Validators.required])
    });
    this.projectInfo = data.arrayData;
    this.requisicionId = data.requisicionInternaId;
    this.estadoPantalla = data.estadoPantalla;
  }

  ngOnInit(): void {

    this.emailDatasource = new MatTableDataSource(this.data.SendMailUsersDatasource);
    this.emailDatasourceOriginal = new MatTableDataSource(this.data.SendMailUsersDatasource);

    let res2 : any;

    if(localStorage.getItem('sendMailRequisition') != undefined){
      if(localStorage.getItem('sendMailRequisition').length > 2){
        res2 = ([JSON.parse(localStorage.getItem('sendMailRequisition'))][0]);
      }
      else{
        res2 = [];
      }
    }else{
      res2 = [];
    }
    
    this.emailDatasourcetarget = new MatTableDataSource(res2);
    
    console.log(this.emailDatasource);
  }

  filtrar(event : Event){
    const filtro = (event.target as HTMLInputElement).value;
    this.emailDatasource.filter = filtro.trim().toLowerCase();

    this.emailDatasource = this.emailDatasourceOriginal;
  }

  addemailManual(){
    // Agrega a lista de correos seleccionados
    this.emailDatasourcetarget.filteredData.push({nombre: this.newProject.controls["emailCapture"].value, correos: this.newProject.controls["emailCapture"].value});
    this.emailDatasourcetarget = new MatTableDataSource(this.emailDatasourcetarget.filteredData);
  }

  cancel(){

    localStorage.setItem('sendMailRequisition', JSON.stringify(this.emailDatasourcetarget.filteredData));
    this.dialogRef.close();
  }

  addMail(nombre, correos, event){
  
    // Agrega a lista de correos seleccionados
    this.emailDatasourcetarget.filteredData.push({nombre: nombre, correos: correos});
    this.emailDatasourcetarget = new MatTableDataSource(this.emailDatasourcetarget.filteredData);

    // Elimina de lista inicial
    this.emailDatasource = new MatTableDataSource(this.emailDatasource.filteredData.filter(x => x.correos != correos));

  }

  deleteMail(nombre, correos){

    // Agrega a lista inicial
    this.emailDatasource.filteredData.push({nombre: nombre, correos: correos});
    this.emailDatasource = new MatTableDataSource(this.emailDatasource.filteredData);

    // Elimina de lista de correos seleccionados
    this.emailDatasourcetarget = new MatTableDataSource(this.emailDatasourcetarget.filteredData.filter(x => x.correos != correos));

  }

}
