<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card shadow">
                <div class="card-header border-0">
                    <table>
                        <tr>
                            <td style="width: 20%;"><h3 class="mb-0">Revisión de empleados</h3></td>
                            <td style="width: 60%;"></td>
                            <td style="width: 20%;">
                                <img src="{{imgFoto}}" style="width: 200px;height: 200px;">
                            </td>
                        </tr>
                    </table>
                </div>

                <!-- <form> -->

                <h6 class="heading-small text-muted mb-4"></h6>
                <hr class="my-4" />
                <!-- </form> -->
                <div class="card">
                    <table>
                        <tr>
                            <td class="col20"></td>
                            <td class="col70"></td>
                            <td class="col10"></td>
                            <td class="col10" hidden><button mat-raised-button color="primary"
                                (click)="descargarExcel()">Descargar</button></td>
                            <td class="col10"></td>
                        </tr>
                    </table>
                    <br>
                </div>

                <div fxLayout="row">
                    <h4>Empleados captura de documentación</h4>
                </div>
                <br>
                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Personales</span>
                        </ng-template>
                        <br><span class="tabsEncabezado">Datos Personales</span><br>
                        <div>
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 5%;"></td>
                                <td style="width: 70%;"><app-employee-capture-personal></app-employee-capture-personal></td>
                            </tr>
                        </table>
                        </div>
                    </mat-tab>
                
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Domicilio</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Datos domicilio </span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-employee-capture-address></app-employee-capture-address></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>
                
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Familiares</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Datos Familiares </span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-employee-capture-family></app-employee-capture-family></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>
                
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Empleo Anterior</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Datos del Empleo Anterior </span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-employee-capture-last-work></app-employee-capture-last-work></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Generales</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Datos Generales</span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-employee-capture-general></app-employee-capture-general></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Documentos</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Documentos</span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-employee-capture-documents></app-employee-capture-documents></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>

                </mat-tab-group>

            </div>
        </div>
    </div>
</div>
