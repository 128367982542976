import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { partModel } from 'src/app/models/part.model';
import { partservice } from 'src/app/services/part.service';
import { SupplierDetailComponent } from 'src/app/components/supplier-detail/supplier-detail.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
import jwt_decode from "jwt-decode";
import { Console } from 'console';
import { trackingservice } from '../../services/tracking.service';
import * as moment from 'moment';

@Component({
  selector: 'app-part-report',
  templateUrl: './part-report.component.html',
  styleUrls: ['./part-report.component.css']
})
export class PartReportComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
decodeUserProfile : any;
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<partModel>
interval : any;
isloading : boolean = false;
user : any = jwt_decode(localStorage.getItem('token_access'))["usuario"]

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  displayedColumns = ['cia_Id', 'part_Id', 'descr', 'uoM_Id', 'fracc_Id', 'categ_Id', 'marca', 'modelo', 'num_Serie'];

  constructor(public dialog: MatDialog
    , private _excelService : ExcelServiceService
    , private _partservice : partservice
    , private _trackingservice : trackingservice) { }

  // =================
  // PROCEDIMIENTOS
  // =================
  ngOnInit(): void {

    this.getcountry();
  }

  descargarExcel(){
    console.log('Descargar a excel');
    let dataSourceShowToExcel : any[] = [];

  this.dataSourceShow.filteredData.forEach(element => {
    console.log('estatus', element)
    dataSourceShowToExcel.push({
                          compañia: element.cia_Id
                          , parte: element.part_Id
                          , descripcion: element.descr
                          , unidad_de_medida: element.uoM_Id
                          , fraccion: element.fracc_Id
                          , categoria: element.categ_Id
                          , marca: element.marca
                          , modelo: element.modelo
                          , num_Serie: element.num_Serie
      })
    });

    this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Partes');  
  }

  filtrar(event : Event){
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceShow.filter = filtro.trim().toLowerCase();
    console.log('filtro', filtro);
  }

  // =================
  // UTILERIAS
  // =================
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(0, this.totalSize);
    this.dataSourceShow = new MatTableDataSource(part);
    this.dataSourceShow.sort = this.sort;
    this.dataSourceShow.paginator = this.paginator;
  }

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  decode(){
    let token = localStorage.getItem('token_access');
    this.decodeUserProfile = jwt_decode(token)["perfil_usuarioid"];
    let decodeUser = jwt_decode(token)["usuario"];
    let usuariotoTrack = jwt_decode(token)["usuarioid"];
  }

  // =================
  // SERVICIOS
  // =================

  getcountry(){

    let arraySort: any;
    this.isloading = true;
    // Proyectos registrados
    this._partservice.getpartAll().subscribe(
      res=> {
        console.log('Partes', res);
        this.isloading = false;
        // Ordenado de arreglo
        arraySort = res.sort(function (a, b) {
          if (a.descr < b.descr) {
            return 1;
          }
          if (a.descr > b.descr) {
            return -1;
          }
          return 0;
        });
        
        console.log('Codigo de estado', arraySort);
        this.dataSourceShow = new MatTableDataSource(arraySort);
        this.array = res;
        this.totalSize = this.array.length;
        
        this.iterator();
        this.dataSourceShow.sort = this.sort;
  
        this.decode();

      },
      error => console.log("error consulta estados",error)
    )
  }

}
