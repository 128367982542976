<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7" class="row" [formGroup]="newForm">
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>Año
                <ng-container>
                    <mat-select class="form-control"  formControlName="anio">
                        <mat-option value= "2020">2020</mat-option>
                        <mat-option value= "2021">2021</mat-option>
                        <mat-option value= "2022" selected="selected">2022</mat-option>
                        <mat-option value= "2023">2023</mat-option>
                        <mat-option value= "2024">2024</mat-option>
                        <mat-option value= "2025">2025</mat-option>
                        <mat-option value= "2026">2026</mat-option>
                        <mat-option value= "2027">2027</mat-option>
                        <mat-option value= "2028">2028</mat-option>
                        <mat-option value= "2029">2029</mat-option>
                        <mat-option value= "2030">2030</mat-option>
                        <mat-option value= "2031">2031</mat-option>
                      </mat-select>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>Mes
                <ng-container>
                      <mat-select class="form-control"  formControlName="mes">
                        <mat-option value= "1" selected="selected">Enero</mat-option>
                        <mat-option value= "2">Febrero</mat-option>
                        <mat-option value= "3">Marzo</mat-option>
                        <mat-option value= "4">Abril</mat-option>
                        <mat-option value= "5">Mayo</mat-option>
                        <mat-option value= "6">Junio</mat-option>
                        <mat-option value= "7">Julio</mat-option>
                        <mat-option value= "8">Agosto</mat-option>
                        <mat-option value= "9">Septiembre</mat-option>
                        <mat-option value= "10">Octubre</mat-option>
                        <mat-option value= "11">Noviembre</mat-option>
                        <mat-option value= "12">Diciembre</mat-option>
                      </mat-select>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>
                <ng-container>
                    <button mat-raised-button color="accent" (click)="search(newForm, $event)">Buscar</button>
                </ng-container>
            </div>
        </div>
    </div>
    <!-- Table -->
    <div class="row">
        <div style="width: 200px;"></div>
        <div class="col">
            <div class="card shadow">
                <br>
                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                        <div class="card-body card-body-table">
                            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8"
                                style="width: 80%;">

                                
                                <ng-container matColumnDef="supplier_id" style="width: 300px;">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>supplier_id </th>
                                    <td mat-cell *matCellDef="let element" hidden> {{element.id}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="documento" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Documento</th>
                                    <td mat-cell *matCellDef="let element" class="col300"> {{element.documento}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="estatus" class="col300">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Estatus </th>
                                    <td mat-cell *matCellDef="let element" class="col300">
                                        <ng-container *ngIf="element.estatus == 0">POR REVISAR</ng-container>
                                        <ng-container *ngIf="element.estatus == 1">APROBADO</ng-container>
                                        <ng-container *ngIf="element.estatus == 2">RECHAZADO</ng-container>
                                        <ng-container *ngIf="element.estatus == 3">NO APLICA</ng-container>
                                        <ng-container *ngIf="element.estatus == 4">NO ENVIADA</ng-container>
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="ver">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Ver </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 0" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-orange"></i> </button> </ng-container> 
                                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 1" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-green"></i> </button> </ng-container> 
                                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 2" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-red"></i> </button> </ng-container> 
                                        <ng-container><button *ngIf="element.aprobacion == true && (element.estatus == 2 || element.estatus == 1)" mat-button color="primary" (click)="reactive(element, $event)"><i class="fa fa-retweet fa-lg icon-cog-reload"></i> </button> </ng-container> 
                                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 4" mat-button color="primary"><i class="fa fa-eye fa-lg icon-cog-gray"></i> </button> </ng-container>  
                                        <!-- <button *ngIf="element.aprobacion == true" mat-button color="primary" (click)="review(element, $event)"><i class="fa fa-eye fa-lg icon-cog"></i> </button>  -->
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="revision">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Revisión </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <ng-container *ngIf="usuarioId != 'revisor'"><button *ngIf="element.aprobacion == true && element.estatus == 0" mat-button color="primary"(click)="review(element, $event)"><i class="fa fa-arrow-right fa-lg icon-cog-blue"></i> </button></ng-container>
                                        <ng-container *ngIf="usuarioId != 'revisor'"><button *ngIf="element.aprobacion == true && element.estatus == 2" mat-button color="primary"(click)="review(element, $event)"><i class="fa fa-arrow-right fa-lg icon-cog-red"></i> </button></ng-container>
                                        <!-- <button *ngIf="element.aprobacion == true" mat-button color="primary" (click)="see(element, $event)"><i class="fa fa-arrow-right fa-lg icon-cog-blue"></i> </button>  -->
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="descarga">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Descarga </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="element.aprobacion == true && element.estatus != 4">
                                            <a href="{{ element.url }}" src="{{ element.url }}" download target="_blank"><i class="fa fa-download fa-lg icon-cog-orange"></i></a>
                                        </ng-container> 
                                        
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </mat-table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<br><br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 