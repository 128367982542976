  <div fxLayout="row">
    <h4>Nueva Requisición</h4>
  </div>
  <br>
  <div mat-dialog-content-example [formGroup]="newProject">
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div *ngIf="requisicionId == 0"><br>Proyecto</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div *ngIf="requisicionId == 0">
          <mat-form-field>
            <mat-select class="form-control control" formControlName="proyecto_id" (selectionChange)="proyectoSelected()" >
              <mat-option *ngFor="let op of datasourcePorjects" [value]="op.proyecto_id" >
                {{op.codigo_proyecto}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span *ngIf="!newProject.get('proyecto_id').valid && newProject.get('proyecto_id').touched" class="help-block" style="color: red;"> seleccione un proyecto</span>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div *ngIf="requisicionId == 0"><br>Proyecto-Categoría</div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div *ngIf="requisicionId == 0">
              <mat-form-field>
                <mat-select class="form-control control" formControlName="categoria_id" (selectionChange)="categorySelected()">
                  <mat-option *ngFor="let opx of datasourceCategories" [value]="opx.proyectocategoria_id">
                    {{opx.codigo_proyectocategoria}}
                  </mat-option>
              </mat-select>
            </mat-form-field>
            <span *ngIf="!newProject.get('categoria_id').valid && newProject.get('categoria_id').touched" class="help-block" style="color: red;"> seleccione una categoría</span>
          </div>
          <div *ngIf="this.requisicionId != 0 && this.estado == 0" style="background-color: orange; color: white;">CREADA</div>
          <div *ngIf="this.requisicionId != 0 && this.estado == 1" style="background-color: red; color: white;">RECHAZADA</div>
          <div *ngIf="this.requisicionId != 0 && this.estado == 2" style="background-color: green; color: white;">APROBADA</div>
          <div *ngIf="this.requisicionId != 0">{{comentario}}</div>
          <div><br><br></div>
          <!-- Documentos -->
          <div>
            <button class="button" (click)="openFilepopup(105)"><i class="fa fa-eye fa-lg icon-cog"></i></button>&nbsp;&nbsp;&nbsp;
            <button class="button" (click)="openSendmailpopup()"><i class="fa fa-at fa-lg icon-cog-blue"></i></button>
          </div>
          <!-- Termina Documentos -->
      </div>
    </div>
  
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Requisición</div>
      </div>
      <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <ng-container><input [type]="requisicion_Numero" matInput class="form-control form-control-line control" formControlName="requisicion_Numero"></ng-container>
            </mat-form-field>
            <span *ngIf="!newProject.get('requisicion_Numero').valid && newProject.get('requisicion_Numero').touched" class="help-block" style="color: red;"> capture un número de Requisición</span>
          </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br><ng-container *ngIf="requisicionId == 0">Fecha</ng-container></div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field appearance="fill" *ngIf="requisicionId == 0">
            <mat-label>{{fecha}}</mat-label>  <!--| date: 'dd-MM-yyyy'-->
            <input matInput [matDatepicker]="picker" formControlName="fecha" (dateChange)="fechaInicial($event)" class="control" >
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker [startAt]="fecha" #picker></mat-datepicker>
          </mat-form-field></div>
      </div>
      
      <div>
      </div>
    </div>
    <div fxLayout="row" class="division" *ngIf="requisicionId == 0">
        <div fxFlex.gt-sm="20%">
            <div class="input-group mb-3">
              <input type="file" name="loadFile" id="loadFile" class="form-control form-control-line" placeholder="SELECCIONA ARCHIVO" style="display: none;" (change)="onFileChange($event)" onclick="this.value=null;">
              <label for="loadFile" style="margin: 0; text-align: left; width: 400px;" class="btn btn-outline-dark"><b style="background-color: orange;">{{nombreArchivo}}</b></label><br>
            </div>
        </div>
        <div fxFlex.gt-sm="20%">
        </div>
        <div fxFlex.gt-sm="20%">
            <div></div>
        </div>
    </div>
    <br>
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div class="input-group mb-3">
            <mat-form-field>Requisitor
              <mat-select class="form-control control" formControlName="IdSolicitante" (selectionChange)="solicitanteSelected()" > <!--[(ngModel)]="IdSolicitante"-->
                <mat-option *ngFor="let sm of SendMailUsersDatasource" [value]="sm.usuarioId" >
                  {{sm.nombre}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      </div>
      <div fxFlex.gt-sm="20%">
      </div>
      <div fxFlex.gt-sm="20%">
          <div></div>
      </div>
  </div>

    <br>
    <!--Tabla de captura-->
    <div fxLayout="row" class="division">
        
    </div>

    <!-- SECCION CAPTURA MANUAL -->
    <div fxLayout="row" class="division" *ngIf="this.requisicionId != 10 && this.estado != 10">
      <div fxFlex.gt-sm="15%">
          <div>
            <mat-form-field>
              <input [type]="sku_Detalle" matInput class="form-control form-control-line control" formControlName="sku_Detalle" placeholder="SKU">
            </mat-form-field>
          </div>
      </div>
      <div fxFlex.gt-sm="5%"></div>
      <div fxFlex.gt-sm="15%">
        <div>
          <mat-form-field>
            <input [type]="cantidad_Detalle" matInput class="form-control form-control-line control" formControlName="cantidad_Detalle" placeholder="Cantidad">
          </mat-form-field>
        </div>
      </div>
      <div fxFlex.gt-sm="5%"></div>
      <div fxFlex.gt-sm="15%">
        <div>
          <mat-form-field>
            <input [type]="uom_Detalle" matInput class="form-control form-control-line control" formControlName="uom_Detalle" placeholder="U de M">
          </mat-form-field>
        </div>
      </div>
    </div>
  <div fxLayout="row" class="division" *ngIf="this.requisicionId != 10 && this.estado != 10">
    <div fxFlex.gt-sm="100%">
      <div>
        <mat-form-field>
          <input [type]="descripcion_Detalle" matInput class="form-control form-control-line control" formControlName="descripcion_Detalle" placeholder="Descripción">
        </mat-form-field>
      </div>
    </div>
  </div>
<div fxLayout="row" class="division" *ngIf="this.requisicionId != 10 && this.estado != 10">
  <div fxFlex.gt-sm="15%">
    <div>
      <mat-form-field>
        <input [type]="medida_Detalle" matInput class="form-control form-control-line control" formControlName="medida_Detalle" placeholder="Medida">
      </mat-form-field>
    </div>
  </div>
  <div fxFlex.gt-sm="5%"></div>
  <div fxFlex.gt-sm="15%">
    <div>
      <mat-form-field>
        <input [type]="color_Detalle" matInput class="form-control form-control-line control" formControlName="color_Detalle" placeholder="Color">
      </mat-form-field>
    </div>
  </div>
  <div fxFlex.gt-sm="5%"></div>
  <div fxFlex.gt-sm="15%">
    <div>
      <mat-form-field>
        <input [type]="linea_Detalle" matInput class="form-control form-control-line control" formControlName="linea_Detalle" placeholder="Linea">
      </mat-form-field>
    </div>
  </div>
    
    <div fxFlex.gt-sm="20%" >
      <div>
        <ng-container><button mat-raised-button color="accent" (click)="add(newProject, $event)">Agregar</button></ng-container>
      </div>
    </div>
  </div>
  <br>
  <!-- TERMINA SECCION CAPTURA MANUAL -->
  </div>
  <!-- ESPECIDIFCAR CARGO -->
  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
      <div>
        <mat-checkbox color="warn"
          [checked]="chkContratoOriginal"
          (click)="$event.stopPropagation()"
          (change)="ActivaContratoOriginal(chkContratoOriginal, $event)"
          [(ngModel)]="chkContratoOriginal">
        </mat-checkbox> &nbsp; Contrato Original
      </div>
      <!-- <div>
        <mat-checkbox color="warn"
          [checked]="chkCodigoDeContrato"
          (click)="$event.stopPropagation()"
          (change)="ActivaCodigoDeContrato(chkCodigoDeContrato, $event)"
          [(ngModel)]="chkCodigoDeContrato">
        </mat-checkbox> &nbsp; Código de Contrato
      </div> -->
      <div>
        <mat-checkbox color="warn"
          [checked]="chkTMFO"
          (click)="$event.stopPropagation()"
          (change)="ActivaTMFO(chkTMFO, $event)"
          [(ngModel)]="chkTMFO">
        </mat-checkbox> &nbsp; T&M/FO/RFI/DCR
        &nbsp;&nbsp;&nbsp;
        <ng-container *ngIf="chkTMFO == true">
            <mat-form-field>
            <input type="TMFO" matInput class="form-control form-control-line control" placeholder="" [(ngModel)]="TMFO" placeholder="TMFO">
          </mat-form-field>
        </ng-container>
      </div>
      <!-- <div>
        <mat-checkbox color="warn"
          [checked]="chkEspecificarCargo"
          (click)="$event.stopPropagation()"
          (change)="ActivaEspecificarCargo(chkEspecificarCargo, $event)"
          [(ngModel)]="chkEspecificarCargo">
        </mat-checkbox> &nbsp; Especificar Cargo
      </div> -->
      <div>
        <mat-checkbox color="warn"
          [checked]="chkCargoAlContratista"
          (click)="$event.stopPropagation()"
          (change)="ActivaCargoAlContratista(chkCargoAlContratista, $event)"
          [(ngModel)]="chkCargoAlContratista">
        </mat-checkbox> &nbsp; Cargo Al Contratista
      </div>
      <div>
        <mat-form-field>
          <input [type]="NoODC" matInput class="form-control form-control-line control" [(ngModel)]="NoODC" placeholder="No O. C.">
        </mat-form-field>
      </div>
      <div>
        <!-- <mat-form-field>
          <input [type]="IdSolicitante" matInput class="form-control form-control-line control" [(ngModel)]="IdSolicitante" placeholder="Solicitante">
        </mat-form-field> -->
      </div>
      <div>
        <!-- <mat-form-field>Requisitor
          <mat-select class="form-control control" [(ngModel)]="IdSolicitante" (selectionChange)="solicitanteSelected()" >
            <mat-option *ngFor="let sm of SendMailUsersDatasource" [value]="sm.usuarioId" >
              {{sm.nombre}}
            </mat-option>
          </mat-select>
        </mat-form-field> -->
      </div>
    </div>
    <div fxFlex.gt-sm="20%">
    </div>
    <div fxFlex.gt-sm="20%">
    </div>
    <div fxFlex.gt-sm="20%">
    </div>
    <div fxFlex.gt-sm="20%">
    </div>
  </div>
  <br><br>
  <!-- TERMINA ESPECIDIFCAR CARGO -->

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div>
        <ng-container *ngIf='requisicionId == 0' >
          <button mat-raised-button color="accent" (click)="clean($event)">Limpiar</button>
        </ng-container>
      </div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div>
        <!-- <ng-container *ngIf="this.requisicionId != 0 || this.estado != 0"></ng-container> -->
        <ng-container > 
          <button mat-raised-button color="accent" (click)="save(newProject, $event)">Guardar</button>
        </ng-container>
        </div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div>
        <ng-container><button *ngIf='requisicionId == 0' mat-raised-button color="warn" (click)="cancel($event)">Cancelar</button></ng-container>
        <ng-container><button *ngIf='requisicionId != 0' mat-raised-button color="warn" (click)="cancel($event)">Regresar</button></ng-container>
      </div>
    </div>
  </div>

     <!-- INICIA CUERPO DE LA PÁGINA-->
     <div class="col-12 col-md-12 p-0">
      <div class="card">
        <div class="card-body card-body-table">
          <mat-table [dataSource]="UploadDataExcel" matSort class="mat-elevation-z8" style="width: 95%;">
              
            <!-- ID Column -->
            <ng-container matColumnDef="SKU">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> <span>SKU</span> </th>
              <td mat-cell *matCellDef="let element">
                <input type='SKU' [(ngModel)]="element.SKU" class="input-short"  class="form-control form-control-line control input-xshort">
              </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> <span>Cantidad</span> </th>
              <td mat-cell *matCellDef="let element">
                <input type='cantidad' [(ngModel)]="element.cantidad" class="form-control form-control-line control input-xshort">
              </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="unidad_de_medida">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" matInput> Unidad de Medida </th>
              <td mat-cell *matCellDef="let element"> 
                <input type='um' [(ngModel)]="element.um" class="form-control form-control-line control input-xshort">
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Descripción </th>
              <td mat-cell *matCellDef="let element"> 
                <input type='descripcion' [(ngModel)]="element.descripcion" class="form-control form-control-line control input-xlarge">
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="medida">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Medida </th>
              <td mat-cell *matCellDef="let element">  
                <input type='medida' [(ngModel)]="element.medida" class="input-short" class="form-control form-control-line control input-short">
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="color">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Color </th>
              <td mat-cell *matCellDef="let element">
                <input type='colorDet' [(ngModel)]="element.color" class="input-short" class="form-control form-control-line control input-short"> 
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="otras_Especificaciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Otras especificaciones </th>
              <td mat-cell *matCellDef="let element"> 
                <input type='otras_especificaciones' [(ngModel)]="element.otras_especificaciones" class="form-control form-control-line control input-short"> 
              </td>
            </ng-container>

            <ng-container matColumnDef="linea">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> linea </th>
              <td mat-cell *matCellDef="let element">
                <input type='linea' [(ngModel)]="element.linea" class="input-short" class="form-control form-control-line control input-short"> 
              </td>
            </ng-container>
            
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </mat-table>
        </div>
      </div>
    </div>
  