import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { trackingModel } from '../models/tracking.model';

@Injectable({
    providedIn: 'root'
})

export class trackingservice {

    constructor(protected http: HttpClient) { }
  
      gettrackingByProjectId(fechaIni : any, fechaFin : any) {
          const headers : HttpHeaders = new HttpHeaders()
              .set('Content-Type', 'application/json')
              .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
          return this.http.get<trackingModel[]>(environment.urlapi + environment.apiContextDrivers + 'tracking/rangofechas?dateOperationInit=' + fechaIni + '&dateOperationEnd=' + fechaFin, {headers}); //  + '/' + fechaFin
      }
  
      inserttracking(arrayToDb : any) {
        console.log('arreglo para tracking', arrayToDb);
          const headers : HttpHeaders = new HttpHeaders()
          .set('Content-Type', 'application/json')
          .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
      return this.http.post(environment.urlapi + environment.apiContextDrivers + 'tracking/registrar', arrayToDb, {headers});
      }
  }