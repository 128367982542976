<div class="row">
    <div style="width: 200px;"></div>
        <div class="col">
            <div class="card shadow">
                <br>
                <div class="col-12 col-md-12 p-0">
                    <div fxLayout="row" class="division">
                        <div fxFlex.gt-sm="10%"></div>
                        <div fxFlex.gt-sm="70%">
                            <div>Proyecto<br>
                                <mat-form-field>
                                    <mat-select class="form-control control" [(ngModel)]="proyecto_id" (selectionChange)="projectSelected($event)">
                                    <mat-option *ngFor="let op of datasourcePorjects" [value]="op.proyecto_id">
                                        {{op.codigo_proyecto + ' - ' + op.nombre_proyecto + ' - ' + op.nombre_cliente}}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex.gt-sm="5%"></div>
                        <div fxFlex.gt-sm="15%">
                        <div><br><br>
                        </div>
                    </div>
                </div>

                <div class="col-12 col-md-12 p-0">
                    <div fxLayout="row" class="division">
                        <div fxFlex.gt-sm="10%"></div>
                        <div fxFlex.gt-sm="30%">
                            <div>Categoría<br>
                                <mat-form-field>
                                    <mat-select class="form-control control" [(ngModel)]="categoria_id">
                                    <mat-option *ngFor="let cat of datasourceCategories" [value]="cat.proyectocategoria_id" >
                                        {{cat.nombre_categoria}}
                                    </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxFlex.gt-sm="5%"></div>
                        <div fxFlex.gt-sm="15%">
                        <div><br><br>
                            <td class="col10"><button mat-raised-button color="accent" (click)="ExcelReport()">Buscar</button></td>
                        </div>
                        <div><br><br>
                            <td class="col10"><button mat-raised-button color="accent" (click)="search()">Descargar</button></td>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<div id="pdfTable" #pdfTable>
    
</div>
<div class="center"><ng-container *ngIf="isloading == true"><mat-spinner color="primary"></mat-spinner></ng-container></div>
