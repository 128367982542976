<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
      <div class="header-body">
        <!-- Card stats -->
      </div>
    </div>
  </div>
  <!-- Page content -->
  <div class="container-fluid mt--7">
  
    <!-- Table -->
    <div class="row">
      <div class="col">
        <div class="card shadow">
          <div class="card-header border-0">
            <h3 class="mb-0">Requisiciones</h3>
          </div>
          
          <!-- <form> -->
            <h6 class="heading-small text-muted mb-4"></h6>
            <hr class="my-4" />
          <!-- </form> -->
          <div class="card">
            <table>
              <tr>
                <td class="col20"><input type="text" class="form-control form-control-line" placeholder="BUSCAR" (keyup)="filtrar($event)"></td>
                <td class="col10"></td>
                <td class="col20">
                  <mat-form-field>
                    <mat-select class="form-control control" [(ngModel)]="fEstatus" (selectionChange)="emitFilters($event, 'Estatus')">
                      <mat-option value= "0">CREADA</mat-option>
                      <mat-option value= "1">RECHAZADA</mat-option>
                      <mat-option value= "2" selected>APROBADA</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td class="col40"></td>
                <td class="col10"><button mat-raised-button color="primary" (click)="descargarExcel()"><i class="fa fa-arrow-down fa-lg icon-cog-wite"></i>&nbsp;&nbsp;Descargar</button></td>
                <td class="col10"></td>
                <td class="col10"></td>
              </tr>
            </table>
            <br>
          </div>

          <!-- INICIA CUERPO DE LA PÁGINA-->
          <div class="col-12 col-md-12 p-0">
            <div class="card">
              <div class="card-body card-body-table">
                <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8" style="width: 95%;">
                  
                  <!-- Codigo proyecto Column -->
                  <ng-container matColumnDef="codigo_proyectocategoria">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30"> Proyecto-Categoría </th>
                    <td mat-cell *matCellDef="let element" class="column"> {{element.codigo_proyectocategoria}} </td>
                  </ng-container>
                  
                  <!-- Codigo Column -->
                  <ng-container matColumnDef="codigo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> Requisición </th>
                    <td mat-cell *matCellDef="let element" class="column"> {{element.codigo}} </td>
                  </ng-container>

                  <!-- Fecha Column -->
                  <ng-container matColumnDef="fecha">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Fecha de Requisición </th>
                    <td mat-cell *matCellDef="let element" class="column"> {{element.fecha | date: ' dd-MM-yyyy'}} </td>
                  </ng-container>

                  <!-- Estatus Column -->
                  <ng-container matColumnDef="estado">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> Estatus </th>
                    <td mat-cell *matCellDef="let element" class="colbuttons">
                      <ng-container *ngIf="element.estado == 0">CREADA</ng-container>
                      <ng-container *ngIf="element.estado == 1">RECHAZADA</ng-container>
                      <ng-container *ngIf="element.estado == 2">APROBADA</ng-container>
                    </td>
                  </ng-container>

                  <!-- Editar Column -->
                  <ng-container matColumnDef="editar">
                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Editar </th>
                    <td mat-cell *matCellDef="let element" class="colbuttons"> <button mat-button color="primary" (click)="editRequisicion(element, $event)"><i class="fa fa-pencil fa-lg icon-cog-edit"></i></button> </td>
                  </ng-container>

                  <!-- Print Column -->
                  <ng-container matColumnDef="imprimir">
                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> PDF </th>
                    <td mat-cell *matCellDef="let element" class="colbuttons"> <button mat-button color="primary" (click)="printRequisicion(element, $event)"><i class="fa fa-print fa-lg icon-cog-print"></i></button> </td>
                  </ng-container>
                  
                  <!-- Comment Column -->
                  <ng-container matColumnDef="comentario">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Comentario </th>
                    <td mat-cell *matCellDef="let element" class="column"> {{element.comentario}} </td>
                  </ng-container>
                  
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </mat-table>
          
              </div>
              <mat-paginator [pageSizeOptions]="[20, 100, 150]" [showFirstLastButtons]="true" [pageSize]="pageSize" [length]="totalSize"  (page)="handlePage($event)" style="width: 95%;"></mat-paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>