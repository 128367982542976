import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { employeModel } from 'src/app/models/employe.model';
import { employeservice } from 'src/app/services/employe.service';
import { SupplierDetailComponent } from 'src/app/components/supplier-detail/supplier-detail.component';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { EmployeeAproveComponent } from '../../employee-aprove/employee-aprove.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
import * as moment from 'moment';

@Component({
  selector: 'app-employee-review-personal-data',
  templateUrl: './employee-review-personal-data.component.html',
  styleUrls: ['./employee-review-personal-data.component.css']
})
export class EmployeeReviewPersonalDataComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<employeModel>
employeeId : number;
pageInfo : any;
arraySupplierGlobal : any = [];
usuarioId : string;
dataSourceEmployee : any;
arrayEmployeeGlobal : any = [];

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<employeModel>
      , private _employeservice : employeservice
      , @Inject(MAT_DIALOG_DATA) public data
      , private formBuilder: FormBuilder
      , public dialog: MatDialog
      , private _UploadFileService : UploadFileService
    ) { 
      this.pageInfo = data.arrayData;
      this.newForm = this.formBuilder.group({
        
      })
    }

  displayedColumns = ['employee_id', 'documento', 'estatus', 'ver', 'revision'];

  public newForm: FormGroup;
  fileDownload : any;

  ngOnInit(): void {
    this.decode();
    this.employeeId = this.pageInfo.id;
    this.getemployeeDocuments();
  }

  cancel(){
    this.dialogRef.close();
  }

  descargarArchivos(){
  
    let posicion : number;
    let posicionInicial : number;

    this.fileDownload = ''
    this.dataSourceShow.filteredData.forEach(element => {

      let binaryData : any = []
      let dataType = 'png';
      let fileName = 'Nombre'
      let fileNameCompleto : string;
      let filePath : string;

      if(element.url != ''){

        posicionInicial = element.url.indexOf("6%2")
        posicion = element.url.indexOf("?")

        fileNameCompleto = element.url.substring(posicion, posicionInicial + 3)
        dataType = fileNameCompleto.substring(fileNameCompleto.indexOf(".") + 1, fileNameCompleto.length)
        fileName = fileNameCompleto.substring(0, fileNameCompleto.indexOf("."))

        // DESCARGAR ARCHIVO
        // ==================
        binaryData.push(element.url)
        filePath = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        
        let downloadLink = document.createElement('a');
        downloadLink.href = filePath;
        downloadLink.setAttribute('download', fileNameCompleto);
        downloadLink.setAttribute('src', fileNameCompleto);
        document.body.appendChild(downloadLink);
        downloadLink.click();

      }
    
    });

  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    this.usuarioId = decodeUser;
  
  }

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  // -------------
  // SERVICIOS
  // -------------

  getemployeeDocuments(){
    let arrayDocumentos : any;
    let arrayDocumentosFiltrados : any;

    // Empleado registrado
    this._employeservice.getemployeeById(this.employeeId).subscribe(
      emp=> {

        arrayDocumentos = emp;
        this.dataSourceEmployee = new MatTableDataSource(emp);

        // Lista de documentos
        this._UploadFileService.getemployeeDocumentsAll().subscribe(
          res=> {
            arrayDocumentos = res;

            arrayDocumentosFiltrados = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento > 1000 && e.categoriaDocumento < 2000);

            this.arrayEmployeeGlobal = [];
            this.arrayEmployeeGlobal.push({employee_id : 1, documento : 'Nombre: ' + this.dataSourceEmployee.filteredData.nombre, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Primer apellido: ' + this.dataSourceEmployee.filteredData.aPaterno , estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Segundo apellido: ' + this.dataSourceEmployee.filteredData.aMaterno, estatus : 0, aprobacion : false, url : '', idDocumento : 0})

            this.showDocument(arrayDocumentosFiltrados, 1001, 2022, 1, 'NSS: ' + this.dataSourceEmployee.filteredData.nss)
            this.showDocument(arrayDocumentosFiltrados, 1002, 2022, 1, 'RFC: ' + this.dataSourceEmployee.filteredData.rfc)
            this.showDocument(arrayDocumentosFiltrados, 1003, 2022, 1, 'CURP: ' + this.dataSourceEmployee.filteredData.curp)
            
            this.arrayEmployeeGlobal.push({employee_id : 1, documento : 'Sexo: ' + ((this.dataSourceEmployee.filteredData.sexo == 1) ? 'Masculino' : 'Femenino'), estatus : 0, aprobacion : false, url : '', idDocumento : 0})

            this.showDocument(arrayDocumentosFiltrados, 1004, 2022, 1, 'Fecha de nacimiento: ' + moment(this.dataSourceEmployee.filteredData.fechaNacimiento, 'YYYY-MM-DD').format('DD-MM-YYYY'))
            
            this.arrayEmployeeGlobal.push({employee_id : 1, documento : 'Lugar de nacimiento: ' + this.dataSourceEmployee.filteredData.lugarNacimientoEstado, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Nacionalidad: ' + ((this.dataSourceEmployee.filteredData.nacionalidad == 1) ? 'Mexicana' : 'Estadounidense'), estatus : 3, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Número de licencia: ' + this.dataSourceEmployee.filteredData.numeroLicencia, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : '¿Cuenta con crédito INFONAVIT?: ' + this.dataSourceEmployee.filteredData.creditoInfonavit, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : '¿Cuenta con crédito FONACOT?: ' + this.dataSourceEmployee.filteredData.creditoFonacot, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Teléfono fijo: ' + this.dataSourceEmployee.filteredData.telefonoFijo, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Celular: ' + this.dataSourceEmployee.filteredData.celular, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Correo electrónico: ' + this.dataSourceEmployee.filteredData.correo, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Esconalidad terminada o profesión: ' + this.dataSourceEmployee.filteredData.nivelEstudios, estatus : 0, aprobacion : false, url : '', idDocumento : 0}
            , {employee_id : 1, documento : 'Estudio en la actualidad: ' + this.dataSourceEmployee.filteredData.estudiosActuales, estatus : 0, aprobacion : false, url : '', idDocumento : 0})

            this.showDocument(arrayDocumentosFiltrados, 1014, 2022, 1, 'Comprobante de Estudios: ' + this.dataSourceEmployee.filteredData.institucion)
            this.showDocument(arrayDocumentosFiltrados, 1005, 2022, 1, 'INE: ') //  + this.dataSourceEmployee.filteredData.ine
            this.showDocument(arrayDocumentosFiltrados, 1013, 2022, 1, 'Cuenta: ')
            this.showDocument(arrayDocumentosFiltrados, 1006, 2022, 1, 'CLABE: ')
            this.showDocument(arrayDocumentosFiltrados, 1007, 2022, 1, 'Fotografía reciente: ')
            this.showDocument(arrayDocumentosFiltrados, 1008, 2022, 1, 'Carta de no antecedentes penales: ')
            this.showDocument(arrayDocumentosFiltrados, 1009, 2022, 1, 'Todo sobre la prevención del Covid-19: ')
            this.showDocument(arrayDocumentosFiltrados, 1011, 2022, 1, 'Recomendaciones para un retorno seguro al trabajo ante Covid-19: ')
            this.showDocument(arrayDocumentosFiltrados, 1012, 2022, 1, 'Plan de acción para el hogar frente al Covid-19: ')
            this.showDocument(arrayDocumentosFiltrados, 1010, 2022, 1, 'Estado de cuenta AFORE: ')

            this.dataSourceShow = new MatTableDataSource(this.arrayEmployeeGlobal);

          });
      }
    
    )}

    review(element, event){
      // AUTORIZA O RECHAZA
      const dialogConfig = new MatDialogConfig();
  

      dialogConfig.data = {
        id: 1,
        title: 'APROBAR/DENEGAR ARCHIVO',
        arrayData : element,
        employee_id: element.employee_id,
        idDocumento: element.idDocumento,
        estadoPantalla: 'Edit'
       
      }
      
      dialogConfig.width = '550px';
      dialogConfig.height = '300px';
      dialogConfig.disableClose = true;
  
      const dialogRef = this.dialog.open(EmployeeAproveComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(result => {
        // window.location.reload();
        this.getemployeeDocuments();
      });
    }
  
    showDocument(arrayDocumentosFiltrados : any, categoriaDocumento : number, anio: number, mes: number, titulo : string){
      let arraySupplier: any[] = [];
        let arrayDocumentos : any;
        let arrayDocumentoPorEmpleado : any;
        let urlShow : any = '';
        let estadoShow : number = 3;
        let idDocumentShow : number = 0;
      
      urlShow = '';
      estadoShow = 4;
      idDocumentShow = 0;
      arrayDocumentoPorEmpleado = arrayDocumentosFiltrados.find(e => e.categoriaDocumento == categoriaDocumento)

      if(arrayDocumentoPorEmpleado != undefined){ 
        urlShow = (arrayDocumentoPorEmpleado.urlDocumento.length > 0) ? arrayDocumentoPorEmpleado.urlDocumento : '';
        estadoShow = (arrayDocumentoPorEmpleado.estado != undefined) ? arrayDocumentoPorEmpleado.estado : 0;
        idDocumentShow = (arrayDocumentoPorEmpleado.idDocumento != undefined) ? arrayDocumentoPorEmpleado.idDocumento : 0;
      }
      this.arrayEmployeeGlobal.push({employee_id : this.employeeId, documento : titulo, estatus : estadoShow, aprobacion : true, url : urlShow, idDocumento : idDocumentShow})
    }

    reactive(element, event){
      let arrayToDb : any;
  
      arrayToDb = ({idDocumento: element.idDocumento
                  , estado: 0
                 , comentarios: '' })
  
      this.aproveRejectDocument(arrayToDb);
    }

    aproveRejectDocument(arrayToDb : any){
      // Inserta Archivos en base de datos
      this._UploadFileService.postDocumentosEmpleadosAprobarRechazar(arrayToDb).subscribe(
        res=> {
          console.log('APROBAR DOCUMENTO', res);
          this.getemployeeDocuments();
          this.showMessage(2, 'Guardardo', 'success', 'Se actualizo el registro correctamente', 'Cerrar');
        },
        error => console.log("error al aprobar el ocumento",error)
      )
    }

    view(element, event){
      window.open(element.url);
      // this.getImage();
    }

}
