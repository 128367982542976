<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card shadow">
                <div class="card-header border-0">
                    <h3 class="mb-0">Empleados</h3>
                </div>

                <!-- <form> -->
                <h6 class="heading-small text-muted mb-4"></h6>
                <hr class="my-4" />
                <!-- </form> -->
                <div class="card">
                    <table>
                        <tr>
                            <td class="col20"><input type="text" class="form-control form-control-line"
                                    placeholder="BUSCAR" (keyup)="filtrar($event)">
                            </td>
                            <td class="col70"></td>
                            <td class="col10"><button mat-raised-button color="primary"
                                    (click)="descargarExcel()">Descargar</button></td>
                            <td class="col10">
                                <ng-container *ngIf="decodeUserProfile != 5">
                                    <button mat-raised-button color="accent" (click)="newemployee($event)">Nuevo Empleado</button>
                                </ng-container>
                                </td>
                            <td class="col10"></td>
                        </tr>
                    </table>
                    <br>
                </div>

                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                        <div class="card-body card-body-table">
                            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8"
                                style="width: 95%;">

                                
                                <ng-container matColumnDef="id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" hidden>id </th>
                                    <td mat-cell *matCellDef="let element" hidden> {{element.id}} </td>
                                </ng-container>

                                <ng-container matColumnDef="numeroEmpleado">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20">Número de empleado </th>
                                    <td mat-cell *matCellDef="let element"> {{element.numeroEmpleado}} </td>
                                </ng-container>

                                <ng-container matColumnDef="nombre">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20">Nombre </th>
                                    <td mat-cell *matCellDef="let element"> {{element.nombre}} </td>
                                </ng-container>

                                <ng-container matColumnDef="aPaterno">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20">Ap Paterno </th>
                                    <td mat-cell *matCellDef="let element"> {{element.aPaterno}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="aMaterno">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Ap Materno</th>
                                    <td mat-cell *matCellDef="let element"> {{element.aMaterno}} </td>
                                </ng-container>

                                <ng-container matColumnDef="rfc">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> rfc </th>
                                    <td mat-cell *matCellDef="let element"> {{element.rfc}}
                                    </td>
                                </ng-container>

                                
                                <ng-container matColumnDef="estatus">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"><ng-container *ngIf="decodeUserProfile == 4 || decodeUserProfile == 1"> Estatus </ng-container></th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container  *ngIf="decodeUserProfile == 4 || decodeUserProfile == 1">
                                            <ng-container *ngIf="element.estatus == true"><p style="color: green;">ACTIVO</p></ng-container>
                                            <ng-container *ngIf="element.estatus == false"><p style="color: red;">INACTIVO</p></ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="edit">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons">
                                        <ng-container *ngIf="decodeUserProfile != 5">Editar</ng-container> 
                                        <ng-container *ngIf="decodeUserProfile == 5">Ver</ng-container> 
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <ng-container *ngIf="decodeUserProfile != 5"> <button mat-button color="primary" (click)="edit(element, $event)"><i class="fa fa-pencil fa-lg icon-cog-edit"></i></button> </ng-container>
                                        <ng-container *ngIf="decodeUserProfile == 5"> <button mat-button color="primary" (click)="edit(element, $event)"><i class="fa fa-eye fa-lg icon-cog-edit"></i></button> </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="users">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"><ng-container *ngIf="decodeUserProfile != 5">Usuario </ng-container>  </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <ng-container *ngIf="decodeUserProfile != 5"><button mat-button color="primary" (click)="users(element, $event)"><i class="fa fa-user fa-lg icon-cog-user"></i></button> </ng-container>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="activeInactive">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"><ng-container *ngIf="decodeUserProfile != 5 && (decodeUserProfile == 4 || decodeUserProfile == 1)"><ng-container *ngIf="decodeUserProfile != 5 && (decodeUserProfile == 4 || decodeUserProfile == 1)"> Activo/Inactivo </ng-container> </ng-container>  </th>
                                    <td mat-cell *matCellDef="let element"> 
                                        <ng-container *ngIf="decodeUserProfile != 5 && (decodeUserProfile == 4 || decodeUserProfile == 1)">
                                            <ng-container *ngIf="element.estatus == 1"><button mat-button color="primary" (click)="inactive(element, $event)"><i class="fa fa-check fa-lg icon-cog-check"></i></button></ng-container>
                                            <ng-container *ngIf="element.estatus == 0"><button mat-button color="primary" (click)="active(element, $event)"><i class="fa fa-ban fa-lg icon-cog-cancel"></i></button></ng-container>
                                        </ng-container>
                                    </td>
                                </ng-container>


                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </mat-table>

                        </div>
                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>