import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { supplierModel } from 'src/app/models/supplier.model';
import { supplyservice } from 'src/app/services/supplier.service';
import { SupplierDetailComponent } from 'src/app/components/supplier-detail/supplier-detail.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { SupplierUserComponent } from '../../components/supplier-user/supplier-user.component';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
import jwt_decode from "jwt-decode";
import { Console } from 'console';
import { trackingservice } from '../../services/tracking.service';
import * as moment from 'moment';

@Component({
  selector: 'app-supplier',
  templateUrl: './supplier.component.html',
  styleUrls: ['./supplier.component.css']
})

export class SupplierComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
decodeUserProfile : any;
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<supplierModel>
interval : any;
isloading : boolean = false;
user : any = jwt_decode(localStorage.getItem('token_access'))["usuario"]

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  displayedColumns = ['id', 'nombre', 'direccion', 'rfc', 'estatus', 'estatusVencimiento', 'autorizado', 'servicioEspecializado', 'servicioGeneral', 'productoDeMateriales', 'repse', 'edit', 'users', 'activeInactive','aprove']; //, 'delusers', 'deny' 

  constructor(public dialog: MatDialog
    , private _excelService : ExcelServiceService
    , private _supplyservice : supplyservice
    , private _UserService : UserService
    , private _trackingservice : trackingservice) { }

  // =================
  // PROCEDIMIENTOS
  // =================
  ngOnInit(): void {

    // this.interval = setInterval(() => {
    //   console.log("Se terminan los mensajes")
    //   clearInterval(this.interval);
    // }, 5000);

    this.getsupplier();
  }

  descargarExcel(){
    console.log('Descargar a excel');
    let dataSourceShowToExcel : any[] = [];

  this.dataSourceShow.filteredData.forEach(element => {
    console.log('estatus', element)
    dataSourceShowToExcel.push({
                              nombre : element.nombre
                              , direccion : element.direccion
                              , RFC : element.rfc
                              , estado : element.estado
                              , estatus : (element.estatus == 1) ? 'ACTIVO': 'INACTIVO' 
                              , autorizado : (element.autorizado == 1) ? 'AUTORIZADO': 'NO AUTORIZADO'
                              , repse : (element.repse == true) ? 'SÍ' : 'NO'  
                              , estatusVencimiento : element.estatusVencimiento
      })
    });

    this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Proveedores');  
  }

  newsupplier(event){
    console.log('Alta de proveedores');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'PROVEEDOR',
      arrayData : null,
      proveedorId: 0,
      estadoPantalla: 'New',
      userProfile : 1,
      servicioEspecializado : 0,
      servicioGeneral : 0,
      productoDeMateriales : 0,
      arraySupplier : this.dataSourceShow
     
    }
    dialogConfig.width = '1300px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(SupplierDetailComponent , dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('proveedores');
      window.location.reload();
    });
  }

  edit(element, event){
    console.log('Editar un proveedores', this.dataSourceShow);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'PROVEEDOR',
      arrayData : element,
      proveedorId: element.proveedorId,
      estadoPantalla: 'Edit',
      userProfile : this.decodeUserProfile,
      servicioEspecializado : element.servicioEspecializado,
      servicioGeneral : element.servicioGeneral,
      productoDeMateriales : element.productoDeMateriales,
      arraySupplier : this.dataSourceShow
     
    }
    dialogConfig.width = '1300px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(SupplierDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getsupplier();
      // window.location.reload();
    });
  }

  users(element, event){
    console.log('Editar un proveedores', element);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'USUARIOS',
      arrayData : element,
      proveedorId: element.proveedorId,
      estadoPantalla: 'Edit',
      userProfile : this.decodeUserProfile
     
    }
    dialogConfig.width = '500px';
    dialogConfig.height = '500px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(SupplierUserComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // window.location.reload();
    });
  }

  // delusers(element, event){
  //   let userId : number = 0
  //   console.log('Elimina usuario', element);

  //   this.getUser(element.rfc);

  // }

  filtrar(event : Event){
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceShow.filter = filtro.trim().toLowerCase();
    console.log('filtro', filtro);
  }

  aprove(element, event){
    let arrayToDb : any;

    arrayToDb = {
      proveedorid : element.proveedorid
        , estado : 1 
        , campo : 'Autorizado'
                }  
    console.log('APRUEBA', arrayToDb)

    this.updateSupplierStatus(arrayToDb);
  }

  deny(element, event){
    let arrayToDb : any;

    arrayToDb = {
      proveedorid : element.proveedorid
        , estado : 0
        , campo : 'Autorizado'
                }
    this.updateSupplierStatus(arrayToDb);
  }

  active(element, event){
    let arrayToDb : any;

    arrayToDb = {
      proveedorid : element.proveedorid
        , estado : 1 
        , campo : 'EsActivo'
                }  
    console.log('APRUEBA', arrayToDb)

    this.updateSupplierStatus(arrayToDb);
  }

  inactive(element, event){
    let arrayToDb : any;

    arrayToDb = {
      proveedorid : element.proveedorid
        , estado : 0
        , campo : 'EsActivo'
                }
    this.updateSupplierStatus(arrayToDb);
  }

  // =================
  // UTILERIAS
  // =================
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(0, this.totalSize);
    this.dataSourceShow = new MatTableDataSource(part);
    this.dataSourceShow.sort = this.sort;
    this.dataSourceShow.paginator = this.paginator;
  }

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  decode(){
    let token = localStorage.getItem('token_access');
    this.decodeUserProfile = jwt_decode(token)["perfil_usuarioid"];
    let decodeUser = jwt_decode(token)["usuario"];
    let usuariotoTrack = jwt_decode(token)["usuarioid"];

    let arrayTracking : any = ({module : 'Proveedores', userId : usuariotoTrack, operation : 1, oldData : '', newData : 'Consulta listado de proveedores', dateOperation : moment(new Date, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')})
    this.saveTracking(arrayTracking);
  }

  // =================
  // SERVICIOS
  // =================

  getsupplier(){

    let arraySort: any;
    this.isloading = true;
    // Proyectos registrados
    this._supplyservice.getsupplyAll().subscribe(
      res=> {
        console.log('Proveedores', res);
        this.isloading = false;
        // Ordenado de arreglo
        arraySort = res.sort(function (a, b) {
          if (a.supplier_id < b.supplier_id) {
            return 1;
          }
          if (a.supplier_id > b.supplier_id) {
            return -1;
          }
          return 0;
        });
        
        this.dataSourceShow = new MatTableDataSource(arraySort);
        this.array = res;
        this.totalSize = this.array.length;
        
        this.iterator();
        this.dataSourceShow.sort = this.sort;
  
        this.decode();

      },
      error => console.log("error consulta regiones",error)
    )
  }

    updateSupplierStatus(arrayToDb : any){

      // Inserta Proveedores
      this._supplyservice.putsupplyStatus(arrayToDb).subscribe(
        res=> {
          console.log('ACTIVO', res);

          this.showMessage(2, 'Comentario', 'success', 'Se actualizo el registro con exito', 'Cerrar');
          this.getsupplier();

        },
        error => console.log("error al actualizar proveedores",error)
      )
    }

    saveTracking(arrayTracking : any){

      this._trackingservice.inserttracking(arrayTracking).subscribe(
        inserta=>{
            console.log('inserta log', inserta);
        },
        error => console.log("error consulta regiones",error)
      )
    }
}
