import { Component, Inject, OnInit } from '@angular/core';


@Component({
  selector: 'app-repse-supplier-capture',
  templateUrl: './repse-supplier-capture.component.html',
  styleUrls: ['./repse-supplier-capture.component.css']
})
export class RepseSupplierCaptureComponent implements OnInit {


  ngOnInit(): void {
  }

  

}
