import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { supplierModel } from 'src/app/models/supplier.model';
import { supplyservice } from '../../services/supplier.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { ReturnStatement } from '@angular/compiler';

@Component({
  selector: 'app-supplier-detail',
  templateUrl: './supplier-detail.component.html',
  styleUrls: ['./supplier-detail.component.css']
})
export class SupplierDetailComponent implements OnInit {

// =========================
// DECLARACIONES
// =========================
nombre : string;
direccion : string;
rfc : string;
contacto : string;
ciudad : string;
estado : string;
supplierDataSource: any;
userProfile : any;
dataEdit : any;
projectInfo : any;
estadoPantalla : string;
servicioEspecializadoGlobal : any;
datasourcesupplier : any[] = [];
proveedorId : number;
isloading : boolean = false;

displayedColumns = ['mes', 'anno', 'fechaCaducidad', 'categoriaDocumento', 'estatusDocumento']; // 'id', 'nombreProveedor', 'rfcProveedor', 

public newProject: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<supplierModel>
    , private _supplyservice : supplyservice
    , private formBuilder: FormBuilder
    , @Inject(MAT_DIALOG_DATA) public data
    , private _snackBar : MatSnackBar
  ) {

    this.projectInfo = data.arrayData;
    this.estadoPantalla = data.estadoPantalla;

    this.newProject = this.formBuilder.group({
      nombre :  new FormControl('', [Validators.required]),
      direccion: new FormControl('', [Validators.required]),
      rfc: new FormControl('', [Validators.required]),
      contacto: new FormControl('', [Validators.required]),
      ciudad: new FormControl('', [Validators.required]),
      estado: new FormControl('', [Validators.required]),
      repse: new FormControl('', [Validators.required]),
      servicioEspecializado: new FormControl('', [Validators.required]),
      servicioGeneral: new FormControl('', [Validators.required]),
      productoDeMateriales: new FormControl('', [Validators.required]),
    });
   }

// =========================
// PROCEDIMIENTOS
// =========================

  ngOnInit(): void {

    this.userProfile = this.data.userProfile;
    this.dataEdit = this.data;
    this.servicioEspecializadoGlobal = this.dataEdit.servicioEspecializado;
    
    console.log('DATA***', this.dataEdit.arraySupplier);
    // let repse : string = '1';

    // repse = (this.projectInfo.repse == true) ? '1' : '0';

    // this.supplierDataSource = this.dataEdit.arraySupplier.filteredData;

    this.getSupplier();
    if(this.estadoPantalla == 'Edit'){
      this.proveedorId = this.data.arrayData.proveedorid;
      
      this.newProject.controls['nombre'].setValue(this.projectInfo['nombre']);
      this.newProject.controls['direccion'].setValue(this.projectInfo['direccion']);
      this.newProject.controls['ciudad'].setValue(this.projectInfo['ciudad']);
      this.newProject.controls['rfc'].setValue(this.projectInfo['rfc']);
      this.newProject.controls['contacto'].setValue(this.projectInfo.contacto);
      // this.newProject.controls['telefono'].setValue(this.projectInfo['telefono']);
      this.newProject.controls['estado'].setValue(this.projectInfo.estado);
      (this.projectInfo.repse == true) ? this.newProject.controls['repse'].setValue('1') : this.newProject.controls['repse'].setValue('0');
      (this.dataEdit.servicioEspecializado == true) ? this.newProject.controls['servicioEspecializado'].setValue(1) : this.newProject.controls['servicioEspecializado'].setValue(0);
      (this.dataEdit.servicioGeneral == true) ? this.newProject.controls['servicioGeneral'].setValue(1) : this.newProject.controls['servicioGeneral'].setValue(0);
      (this.dataEdit.productoDeMateriales == true) ? this.newProject.controls['productoDeMateriales'].setValue(1) : this.newProject.controls['productoDeMateriales'].setValue(0);

      this.getSupplierReport();

    }
  }

  cancel(event){
    this.dialogRef.close();
  }

  save(form, event){

    if(this.estadoPantalla != 'Edit'){
      console.log("Esta en proceso de guardado");
      if(this.supplierDataSource.filter(e => e.rfc.trim() == form.controls["rfc"].value.trim()).length > 0){
        this.openSnackBar('Este RFC ya esta registrado', '');
        return
      }
    }

    let arrayToDb : any;

    arrayToDb = ({ 
      proveedorid : (this.estadoPantalla == 'Edit') ? this.projectInfo.proveedorid : 0
        , nombre : form.controls["nombre"].value
        , direccion : form.controls["direccion"].value
        , rfc : form.controls["rfc"].value 
        , ciudad : form.controls["ciudad"].value
        , estado : form.controls["estado"].value
        , contacto : form.controls["contacto"].value
        , objetoSocial: ''
        , telefonoContacto : 0
        , correo : ''
        , tipoPersona : 1
        , prestadorServicio : 0
        , vigencia : '2050-01-01'
        , estatus : 1
        , repse : (form.controls['repse'].value == '1') ? true : false
        , servicioEspecializado : (form.controls['servicioEspecializado'].value == '1') ? true : false
        , servicioGeneral : (form.controls['servicioGeneral'].value == '1') ? true : false
        , productoDeMateriales : (form.controls['productoDeMateriales'].value == '1') ? true : false
      });

      if(this.estadoPantalla == 'Edit'){
        this.updateSupplier(arrayToDb);
      }else{
        this.insertSupplier(arrayToDb);
      }
    
  }

  onChangeRFC(event){

    console.log('supplier', this.supplierDataSource)
    console.log('RFC PANTALLA', this.newProject.controls["rfc"].value.trim());
    console.log('RFC DATASOURCE', this.supplierDataSource.filter(e => e.rfc.trim() == this.newProject.controls["rfc"].value.trim()));
    
    if(this.supplierDataSource.filter(e => e.rfc.trim() == this.newProject.controls["rfc"].value.trim()).length > 0){
      this.openSnackBar('Este RFC ya esta registrado', '');
    }
  }

// =========================
// UTILERIAS
// =========================
openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {duration : 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: 'alert-snackbar'});
}

// =========================
// SERVICIOS
// =========================

insertSupplier(arrayToDb : any){

  // Inserta Proveedores
  this._supplyservice.insertsupply(arrayToDb).subscribe(
    res=> {
      console.log('PROVEEDORES', res);
      this.openSnackBar('El registro se actualizó con éxito', '');  
      this.dialogRef.close();
    },
    error => console.log("error al insertar proveedores",error)
  )

}

updateSupplier(arrayToDb : any){

  // Inserta Proveedores
  console.log('HELP', arrayToDb);
  this._supplyservice.updatesupply(arrayToDb).subscribe(
    res=> {
      console.log('PROVEEDORES', res);
      this.dialogRef.close();
    },
    error => console.log("error al actualizar proveedores",error)
  )

}

getSupplier(){

  // Obtiene Proveedores
  this.isloading = true;
  this._supplyservice.getsupplyAll().subscribe(
    res=> {
      this.supplierDataSource = res;
      console.log('PROVEEDORES', this.supplierDataSource);
      this.isloading = false;
    },
    error => console.log("error al actualizar proveedores",error)
  )

}

getSupplierReport(){

  let fechaActual = moment(new Date, 'YYYY-MM-DD').format('YYYY-MM-DD');
  let fechaMesAnterior = moment(new Date, 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD');
  let year = Number(moment(fechaMesAnterior, "YYYY-MM-DD").format("YYYY"));
  let month = Number(moment(fechaMesAnterior, "YYYY-MM-DD").format("MM"));
  let arrayResulsetMesAnterior : any;
  let arrayResulsetMesActual : any;
  let arrayResulsetFinal : any[] =[];

  console.log(fechaActual);

  this._supplyservice.getsupplyOutOfDate(this.proveedorId, year, month).subscribe(
    res=> {
      arrayResulsetMesAnterior = res;

      console.log('CONSULTA 1', arrayResulsetMesAnterior);

      year = Number(moment(new Date, "YYYY-MM-DD").format("YYYY"));
      month = Number(moment(new Date, "YYYY-MM-DD").format("MM"));

      // Segunda consulta
      this._supplyservice.getsupplyOutOfDate(this.proveedorId, year, month).subscribe(
        res2=> {
          // this.datasourcesupplier.push(res2);
          arrayResulsetMesActual = res2;
  
          arrayResulsetMesAnterior.forEach(element => {
            arrayResulsetFinal.push({id: element.id
                                      , nombreProveedor: element.nombreProveedor
                                      , rfcProveedor:  element.rfcProveedor
                                      , comentarios: element.comentarios
                                      , mes: element.mes
                                      , anno: element.anno
                                      , categoriaDocumento_Id: element.categoriaDocumento_Id
                                      , fechaCaducidad: element.fechaCaducidad
                                      , categoriaDocumento: element.categoriaDocumento
                                      , estatusDocumento: element.estatusDocumento
                                    })
          });

          arrayResulsetMesActual.forEach(element => {
            arrayResulsetFinal.push({id: element.id
                                      , nombreProveedor: element.nombreProveedor
                                      , rfcProveedor:  element.rfcProveedor
                                      , comentarios: element.comentarios
                                      , mes: element.mes
                                      , anno: element.anno
                                      , categoriaDocumento_Id: element.categoriaDocumento_Id
                                      , fechaCaducidad: element.fechaCaducidad
                                      , categoriaDocumento: element.categoriaDocumento
                                      , estatusDocumento: element.estatusDocumento
                                    })
          });
          
          this.datasourcesupplier = arrayResulsetFinal;

          console.log('CONSULTA 2', arrayResulsetMesActual);

          console.log('RESULTADO FINAL', this.datasourcesupplier);
  
        },
        error => console.log("error consulta categorias",error)
      )


    },
    error => console.log("error consulta categorias",error)
  )
}


}
