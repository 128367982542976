import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { WorkBook, WorkSheet } from 'xlsx';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheethml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class ExcelServiceService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {

    const worksheet: WorkSheet = XLSX.utils.json_to_sheet(json);

    const workbook: WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);

  }

  public exportAsExcelFileMultipleSheet(jsonPersonales: any[], jsonDomicilio: any[], jsonFamiliares: any[], jsonEmpleoAnt: any[], jsonGenerales: any[], excelFileName: string): void {

    const worksheetPersonales: WorkSheet = XLSX.utils.json_to_sheet(jsonPersonales);
    const worksheetDomicilio: WorkSheet = XLSX.utils.json_to_sheet(jsonDomicilio);
    const worksheetFamiliares: WorkSheet = XLSX.utils.json_to_sheet(jsonFamiliares);
    const worksheetEmpleoAnt: WorkSheet = XLSX.utils.json_to_sheet(jsonEmpleoAnt);
    const worksheetGenerales: WorkSheet = XLSX.utils.json_to_sheet(jsonGenerales);

    const workbook: WorkBook = { Sheets: { 'Personales': worksheetPersonales
                                          , 'Domicilio': worksheetDomicilio
                                          , 'Familiares': worksheetFamiliares
                                          , 'EmpleoAnterior' : worksheetEmpleoAnt
                                          , 'Generales' : worksheetGenerales }
                                          , SheetNames: ['Personales', 'Domicilio', 'Familiares', 'EmpleoAnterior', 'Generales'] };

    // XLSX.utils.book_append_sheet(workbook, worksheetPersonales, "Hoja 1")
    // XLSX.utils.book_append_sheet(workbook, worksheetDomicilio, "Hoja 2")

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

    this.saveAsExcelFile(excelBuffer, excelFileName);

  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

    FileSaver.saveAs(data, `${fileName}_export_${new Date().getTime()}${EXCEL_EXTENSION}`);
  }

}
