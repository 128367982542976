     <div mat-dialog-content-example [formGroup]="newProject">
    
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Razón social</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="nombre" matInput class="form-control form-control-line control"
                        formControlName="nombre" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('nombre').valid && newProject.get('nombre').touched" class="help-block"
                    style="color: red;"> El nombre es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container>
                    <!-- <input type="file" accept=".png, .jpg,.jpeg" multiple (change)="cargarImagen($event)" id="inputFile">
                    <label for="inputFile" class="labelInputFile">
                        <img src="https://icons-for-free.com/iconfiles/png/512/cloud+upload+file+storage+upload+icon-1320190558968694328.png" alt="upload file" >
                    </label> -->
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Dirección</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="direccion" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="direccion">
                </mat-form-field>
                <span *ngIf="!newProject.get('direccion').valid && newProject.get('direccion').touched"
                    class="help-block" style="color: red;"> La dirección es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoDireccion == undefined"><input type="file" name="fuDireccion" id="fuDireccion" (change)="cargarImagen($event, 'direccion', 'generales', estadoDireccion, 101)" hidden><label for="fuDireccion"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoDireccion == 2"><input type="file" name="fuDireccion" id="fuDireccion" (change)="cargarImagen($event, 'direccion', 'generales', estadoDireccion, 101)" hidden><label for="fuDireccion"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoDireccion == undefined"><button class="button" (click)="openFile(101)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoDireccion == 0"><button class="button" (click)="openFile(101)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoDireccion == 1"><button class="button" (click)="openFile(101)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoDireccion == 2">
                    <button class="button" (click)="openFile(101)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(101, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>RFC</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="rfc" matInput class="form-control form-control-line control" formControlName="rfc" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('rfc').valid && newProject.get('rfc').touched" class="help-block"
                    style="color: red;"> El RFC es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoRFC == undefined"><input type="file" name="fuRFC" id="fuRFC" (change)="cargarImagen($event, 'rfc', 'generales', estadoRFC, 102)" hidden><label for="fuRFC"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoRFC == 2"><input type="file" name="fuRFC" id="fuRFC" (change)="cargarImagen($event, 'rfc', 'generales', estadoRFC, 102)" hidden><label for="fuRFC"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoRFC == undefined"><button class="button" (click)="openFile(102)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoRFC == 0"><button class="button" (click)="openFile(102)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoRFC == 1"><button class="button" (click)="openFile(102)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoRFC == 2">
                    <button class="button" (click)="openFile(102)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(102)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Contacto</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="contacto" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="contacto">
                </mat-form-field>
                <span *ngIf="!newProject.get('contacto').valid && newProject.get('contacto').touched" class="help-block"
                    style="color: red;"> El contacto es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Ciudad</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="ciudad" matInput class="form-control form-control-line control"
                        formControlName="ciudad" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('ciudad').valid && newProject.get('ciudad').touched" class="help-block"
                    style="color: red;"> La ciudad es requerida</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Estado</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="estado" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="estado">
                </mat-form-field>
                <span *ngIf="!newProject.get('estado').valid && newProject.get('estado').touched" class="help-block"
                    style="color: red;"> El estado es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Objeto Social</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="objetivoSocial" matInput class="form-control form-control-line control"
                        formControlName="objetivoSocial" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('objetivoSocial').valid && newProject.get('objetivoSocial').touched"
                    class="help-block" style="color: red;">El objetivo social es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Tipo Persona</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <mat-select class="form-control" formControlName="tipoPersona">
                        <mat-option value="1">Moral</mat-option>
                        <mat-option value="0">Física</mat-option>
                    </mat-select>
                </mat-form-field>
                <span *ngIf="!newProject.get('tipoPersona').valid && newProject.get('tipoPersona').touched"
                    class="help-block" style="color: red;"> El tipo persona es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Teléfono contacto</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="telefono" matInput class="form-control form-control-line control"
                        formControlName="telefono" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('telefono').valid && newProject.get('telefono').touched" class="help-block"
                    style="color: red;">El telefono es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Correo electrónico</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="email" matInput class="form-control form-control-line control"
                        formControlName="email" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('email').valid && newProject.get('email').touched"
                    class="help-block" style="color: red;"> El tipo persona es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Acta constitutiva</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoActaConstitutiva == undefined"><input type="file" name="fuActaConstitutiva" id="fuActaConstitutiva" (change)="cargarImagen($event, 'actaConstitutiva', 'generales', estadoActaConstitutiva, 103)" hidden><label for="fuActaConstitutiva"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoActaConstitutiva == 2"><input type="file" name="fuActaConstitutiva" id="fuActaConstitutiva" (change)="cargarImagen($event, 'actaConstitutiva', 'generales', estadoActaConstitutiva, 103)" hidden><label for="fuActaConstitutiva"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoActaConstitutiva == undefined"><button class="button" (click)="openFile(103)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoActaConstitutiva == 0"><button class="button" (click)="openFile(103)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoActaConstitutiva == 1"><button class="button" (click)="openFile(103)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoActaConstitutiva == 2">
                    <button class="button" (click)="openFile(103)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(103, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>INE</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoINE == undefined"><input type="file" name="fuINE" id="fuINE" (change)="cargarImagen($event, 'ine', 'generales', estadoINE, 104)" hidden><label for="fuINE"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoINE == 2"><input type="file" name="fuINE" id="fuINE" (change)="cargarImagen($event, 'ine', 'generales', estadoINE, 104)" hidden><label for="fuINE"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoINE == undefined"><button class="button" (click)="openFile(104)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoINE == 0"><button class="button" (click)="openFile(104)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoINE == 1"><button class="button" (click)="openFile(104)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoINE == 2">
                    <button class="button" (click)="openFile(104)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(104)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>                
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Alta IMSS</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoAltaImss == undefined"><input type="file" name="fuIMSS" id="fuIMSS" (change)="cargarImagen($event, 'imss', 'generales', estadoAltaImss, 105)" hidden><label for="fuIMSS"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoAltaImss == 2"><input type="file" name="fuIMSS" id="fuIMSS" (change)="cargarImagen($event, 'imss', 'generales', estadoAltaImss, 105)" hidden><label for="fuIMSS"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                
                <ng-container *ngIf="estadoAltaImss == undefined"><button class="button" (click)="openFile(105)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoAltaImss == 0"><button class="button" (click)="openFile(105)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoAltaImss == 1"><button class="button" (click)="openFile(105)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoAltaImss == 2">
                    <button class="button" (click)="openFile(105)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(105)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Alta Infonavit</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoAltaInfonavit == undefined"><input type="file" name="fuAltaInfonavit" id="fuAltaInfonavit" (change)="cargarImagen($event, 'altaInfonavit', 'generales', estadoAltaInfonavit, 106)" hidden><label for="fuAltaInfonavit"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoAltaInfonavit == 2"><input type="file" name="fuAltaInfonavit" id="fuAltaInfonavit" (change)="cargarImagen($event, 'altaInfonavit', 'generales', estadoAltaInfonavit, 106)" hidden><label for="fuAltaInfonavit"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoAltaInfonavit == undefined"><button class="button" (click)="openFile(106)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoAltaInfonavit == 0"><button class="button" (click)="openFile(106)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoAltaInfonavit == 1"><button class="button" (click)="openFile(106)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>    
                <ng-container *ngIf="estadoAltaInfonavit == 2">
                    <button class="button" (click)="openFile(106)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(106)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>    
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="250px">
            <div><br>Alta SAT</div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <!-- <input type="file" (change)="uploadFile($event)" /> -->
                <ng-container *ngIf="estadoAltaSAT == undefined"><input type="file" name="fuAltaSAT" id="fuAltaSAT" (change)="cargarImagen($event, 'altaSAT', 'generales', estadoAltaSAT, 107)" hidden><label for="fuAltaSAT"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoAltaSAT == 2"><input type="file" name="fuAltaSAT" id="fuAltaSAT" (change)="cargarImagen($event, 'altaSAT', 'generales', estadoAltaSAT, 107)" hidden><label for="fuAltaSAT"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoAltaSAT == undefined"><button class="button" (click)="openFile(107)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoAltaSAT == 0"><button class="button" (click)="openFile(107)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoAltaSAT == 1"><button class="button" (click)="openFile(107)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoAltaSAT == 2">
                    <button class="button" (click)="openFile(107)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(107)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div><br>Estado de cuenta</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoEstadoCuenta == undefined"><input type="file" name="fuEdoCta" id="fuEdoCta" (change)="cargarImagen($event, 'edoCTA', 'generales', estadoEstadoCuenta, 108)" hidden><label for="fuEdoCta"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoEstadoCuenta == 2"><input type="file" name="fuEdoCta" id="fuEdoCta" (change)="cargarImagen($event, 'edoCTA', 'generales', estadoEstadoCuenta, 108)" hidden><label for="fuEdoCta"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoEstadoCuenta == undefined"><button class="button" (click)="openFile(108)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoEstadoCuenta == 0"><button class="button" (click)="openFile(108)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoEstadoCuenta == 1"><button class="button" (click)="openFile(108)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoEstadoCuenta == 2">
                    <button class="button" (click)="openFile(108)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(108)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Estado financiero</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoEstadoFinanciero == undefined"><input type="file" name="fuEdoFinanciero" id="fuEdoFinanciero" (change)="cargarImagen($event, 'edoFinanciero', 'generales', estadoEstadoFinanciero, 109)" hidden><label for="fuEdoFinanciero"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoEstadoFinanciero == 2"><input type="file" name="fuEdoFinanciero" id="fuEdoFinanciero" (change)="cargarImagen($event, 'edoFinanciero', 'generales', estadoEstadoFinanciero, 109)" hidden><label for="fuEdoFinanciero"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                    
                <ng-container *ngIf="estadoEstadoFinanciero == undefined"><button class="button" (click)="openFile(109)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoEstadoFinanciero == 0"><button class="button" (click)="openFile(109)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoEstadoFinanciero == 1"><button class="button" (click)="openFile(109)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoEstadoFinanciero == 2">
                    <button class="button" (click)="openFile(109)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(109)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>¿Es usted prestador de servicios especializados?</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <mat-select class="form-control" formControlName="prestadorServicio">
                        <mat-option value="1">Sí</mat-option>
                        <mat-option value="0">No</mat-option>
                    </mat-select>
                </mat-form-field>
                <span *ngIf="!newProject.get('prestadorServicio').valid && newProject.get('prestadorServicio').touched"
                    class="help-block" style="color: rgb(74, 28, 28);"> El estado de cuenta bancario es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Contrato</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoContrato == undefined"><input type="file" name="fuContrato" id="fuContrato" (change)="cargarImagen($event, 'contrato', 'generales', estadoContrato, 110)" hidden><label for="fuContrato"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoContrato == 2"><input type="file" name="fuContrato" id="fuContrato" (change)="cargarImagen($event, 'contrato', 'generales', estadoContrato, 110)" hidden><label for="fuContrato"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                    
                <ng-container *ngIf="estadoContrato == undefined"><button class="button" (click)="openFile(110)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoContrato == 0"><button class="button" (click)="openFile(110)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoContrato == 1"><button class="button" (click)="openFile(110)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoContrato == 2">
                    <button class="button" (click)="openFile(110)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(110)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br>Repse del proveedor</div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container *ngIf="estadoRPP == undefined"><input type="file" name="fuRegPatProveedor" id="fuRegPatProveedor" (change)="cargarImagen($event, 'RegPatProveedor', 'generales', estadoRPP, 111)" hidden><label for="fuRegPatProveedor"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoRPP == 2"><input type="file" name="fuRegPatProveedor" id="fuRegPatProveedor" (change)="cargarImagen($event, 'RegPatProveedor', 'generales', estadoRPP, 111)" hidden><label for="fuRegPatProveedor"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                <ng-container *ngIf="estadoRPP == undefined"><button class="button" (click)="openFile(111)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoRPP == 0"><button class="button" (click)="openFile(111)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoRPP == 1"><button class="button" (click)="openFile(111)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoRPP == 2">
                    <button class="button" (click)="openFile(111)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(111)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>
</div>

<br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="accent" (click)="save(newProject, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 
