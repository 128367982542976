<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row"> <!--[formGroup]="newForm"-->
        <div style="width: 200px;"></div>
        <div class="col">
            <div class="card shadow">
                <br>
                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div fxLayout="row" class="division">
                    <div fxFlex.gt-sm="200px">
                        <div>Año
                            <ng-container>
                                <mat-select class="form-control"  [(ngModel)]="anio"> <!--formControlName="anio"-->
                                    <mat-option value= "2020">2020</mat-option>
                                    <mat-option value= "2021">2021</mat-option>
                                    <mat-option value= "2022" selected="selected">2022</mat-option>
                                    <mat-option value= "2023">2023</mat-option>
                                    <mat-option value= "2024">2024</mat-option>
                                    <mat-option value= "2025">2025</mat-option>
                                    <mat-option value= "2026">2026</mat-option>
                                    <mat-option value= "2027">2027</mat-option>
                                    <mat-option value= "2028">2028</mat-option>
                                    <mat-option value= "2029">2029</mat-option>
                                    <mat-option value= "2030">2030</mat-option>
                                    <mat-option value= "2031">2031</mat-option>
                                </mat-select>
                            </ng-container>
                        </div>
                    </div>
                    <div fxFlex.gt-sm="100px">
                        <div><br>
                            <ng-container>
                                <button mat-raised-button color="accent" (click)="search(newForm, $event)">Buscar</button>
                            </ng-container>
                        </div>
                    </div>
                </div>

                    <br>
                    <div class="row">
                        <div class="card">
                            <div class="card-body card-body-table">
                                <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8" style="width: 60%;">
                                    
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef>
                                            <div style="width: 100px; text-align: left; ">Requerido
                                        </div>
                                          </th>
                                          <div style="width: 100px;">
                                          <td mat-cell *matCellDef="let element; let i = index">
                                            <mat-checkbox color="warn"
                                                        [(ngModel)]="element.is_required"
                                                        (click)="$event.stopPropagation()"
                                                        (change)="ActivarDesactivar(element.activity_id, element.provider_id, element.is_required, element.year, element.month, $event)"
                                                        [checked]="selection.isSelected(element.is_required)">
                                            </mat-checkbox>
                                          </td>
                                        </div>
                                    </ng-container>
                                    
                                    <!-- activity_id -->
                                    <ng-container matColumnDef="activity_id" class="col300">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300" hidden> Actividad Id</th>
                                        <td mat-cell *matCellDef="let element" class="col300" hidden> {{element.activity_id}} </td>
                                    </ng-container>
                                    
                                    <!-- provider_id -->
                                    <ng-container matColumnDef="provider_id" class="col300">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300" hidden> provider_id </th>
                                        <td mat-cell *matCellDef="let element" class="col300" hidden> {{element.provider_id}} </td>
                                    </ng-container>
                                    
                                    <!-- year -->
                                    <ng-container matColumnDef="year">
                                        <th mat-header-cell *matHeaderCellDef class="colbuttons" style="width: 100px; text-align: left; "> Año </th>
                                        <td mat-cell *matCellDef="let element" class="col300"> {{element.year}} </td>
                                    </ng-container>
                                    
                                    <!-- month -->
                                    <ng-container matColumnDef="month">
                                        <th mat-header-cell *matHeaderCellDef class="colbuttons" style="width: 100px; text-align: left; "> Mes </th>
                                        <td mat-cell *matCellDef="let element" class="col300"> <!-- {{element.month}} -->
                                            <ng-container *ngIf="element.month == 1">Enero</ng-container>
                                            <ng-container *ngIf="element.month == 2">Febrero</ng-container>
                                            <ng-container *ngIf="element.month == 3">Marzo</ng-container>
                                            <ng-container *ngIf="element.month == 4">Abril</ng-container>
                                            <ng-container *ngIf="element.month == 5">Mayo</ng-container>
                                            <ng-container *ngIf="element.month == 6">Junio</ng-container>
                                            <ng-container *ngIf="element.month == 7">Julio</ng-container>
                                            <ng-container *ngIf="element.month == 8">Agosto</ng-container>
                                            <ng-container *ngIf="element.month == 9">Septiembre</ng-container>
                                            <ng-container *ngIf="element.month == 10">Octubre</ng-container>
                                            <ng-container *ngIf="element.month == 11">Noviembre</ng-container>
                                            <ng-container *ngIf="element.month == 12">Diciembre</ng-container>
                                        </td>
                                    </ng-container>

                                    <!-- Checkbox is_required -->
                                    <ng-container matColumnDef="is_required">
                                        <th mat-header-cell *matHeaderCellDef class="colbuttons" hidden> Requerido </th>
                                        <td mat-cell *matCellDef="let element" class="col300" hidden> {{element.is_required}} </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                </mat-table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<br><br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <!-- <div><button mat-raised-button color="accent" (click)="save($event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div> -->
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 