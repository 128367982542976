<div mat-dialog-content-example > <!--[formGroup]="frmEmployee"-->
    
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="300px">
            <div><br>
                <ng-container><b>Descripción</b><input type="descripcion" matInput class="form-control form-control-line" style="border-color: orange;" [(ngModel)]="txtDescripcion"></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div><br><br>&nbsp;&nbsp;
                <ng-container *ngIf="estadoEmployee == undefined"><input type="file" name="fudocument" id="fudocument" (change)="cargarImagen($event, 'documentos', 'documentos', estadoEmployee, 9001)" hidden><label for="fudocument"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                    <ng-container *ngIf="estadoEmployee == 2"><input type="file" name="fudocument" id="fudocument" (change)="cargarImagen($event, 'documentos', 'documentos', estadoEmployee, 9001)" hidden><label for="fudocument"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>

                    <!-- <ng-container *ngIf="estadoEmployee == undefined"><button class="button" (click)="openFile(2003)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                    <ng-container *ngIf="estadoEmployee == 0"><button class="button" (click)="openFile(2003)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                    <ng-container *ngIf="estadoEmployee == 1"><button class="button" (click)="openFile(2003)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                    <ng-container *ngIf="estadoEmployee == 2">
                        <button class="button" (click)="openFile(2003)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                        <button class="button" (click)="opencomments(2003)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                    </ng-container> -->
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="350px">
            
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>
    <br>
    <div class="card">
        <div class="card-body card-body-table">
            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8" style="width: 80%;">
               
                <ng-container matColumnDef="employee_id" style="width: 300px;">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>Id </th>
                    <td mat-cell *matCellDef="let element" hidden> {{element.employee_id}} </td>
                </ng-container>

                <ng-container matColumnDef="documento" class="col300">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col300"> Documento</th>
                    <td mat-cell *matCellDef="let element" class="col300"> {{element.documento}} </td>
                </ng-container>

                <ng-container matColumnDef="ver">
                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Ver </th>
                    <td mat-cell *matCellDef="let element">
                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 0" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-orange"></i> </button> </ng-container> 
                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 1" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-green"></i> </button> </ng-container> 
                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 2" mat-button color="primary" (click)="view(element, $event)"><i class="fa fa-eye fa-lg icon-cog-red"></i> </button> </ng-container> 
                        <ng-container><button *ngIf="element.aprobacion == true && (element.estatus == 2 || element.estatus == 1)" mat-button color="primary" (click)="deleteDocument(element, $event)"><i class="fa fa-retweet fa-lg icon-cog-reload"></i> </button> </ng-container> 
                        <ng-container><button *ngIf="element.aprobacion == true && element.estatus == 4" mat-button color="primary"><i class="fa fa-eye fa-lg icon-cog-gray"></i> </button> </ng-container> 
                    </td>
                </ng-container>
                
                <!-- <ng-container matColumnDef="eliminar">
                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Eliminar </th>
                    <td mat-cell *matCellDef="let element"> 
                        <ng-container *ngIf="usuarioId != 'revisor'"><button *ngIf="element.aprobacion == true && element.estatus == 0" mat-button color="primary"(click)="deleteDocument(element, $event)"><i class="fa fa-trash fa-lg icon-cog-red"></i> </button></ng-container>
                        <ng-container *ngIf="usuarioId != 'revisor'"><button *ngIf="element.aprobacion == true && element.estatus == 2" mat-button color="primary"(click)="deleteDocument(element, $event)"><i class="fa fa-trash fa-lg icon-cog-red"></i> </button></ng-container>
                    </td>
                </ng-container> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>

        </div>
    </div>


<br><br>
<div fxLayout="row" class="division" [formGroup]="frmEmployee">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="accent" (click)="save(frmEmployee, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
<div><button mat-raised-button color="warn" (click)="cancel()"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>        
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 
