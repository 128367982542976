import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { employeservice } from '../../../../services/employe.service';
import jwt_decode from "jwt-decode";
import { E } from '@angular/cdk/keycodes';

@Component({
  selector: 'app-employee-capture-documents',
  templateUrl: './employee-capture-documents.component.html',
  styleUrls: ['./employee-capture-documents.component.css']
})
export class EmployeeCaptureDocumentsComponent implements OnInit {

  documentShow : MatTableDataSource<any>;
  employeeId : string = '0';
  usuarioId : number = 0;
  id : number;
  tipoImagenFile : any = [];
  displayedColumns = ['categoria', 'url'];

  constructor(
    private readonly _uploadFileService: UploadFileService
    , private _employeservice : employeservice
  ) { }

  ngOnInit(): void {
    this.tipoImagenFile = [
      {id : 1001, categoria : 'nss'}
      ,{id : 1002, categoria : 'rfc'}
      ,{id : 1003, categoria : 'curp'}
      ,{id : 1004, categoria : 'actaDeNacimiento'}
      ,{id : 1005, categoria : 'ine'}
      ,{id : 1006, categoria : 'clabe'}
      ,{id : 1007, categoria : 'fotografia'}
      ,{id : 1008, categoria : 'cartaDeNoAntecedentes'}
      ,{id : 1009, categoria : 'certificadoCOVID'}
      ,{id : 1010, categoria : 'estadoDeCuentaAFORE'}
      ,{id : 1011, categoria : 'certificadoCOVID2'}
      ,{id : 1012, categoria : 'certificadoCOVID3'}
      ,{id : 1013, categoria : 'cuenta'}
      ,{id : 1014, categoria : 'estudio'}
      ,{id : 2001, categoria : 'licencia'}
      ,{id : 2002, categoria : 'credito'}
      ,{id : 2003, categoria : 'credito2'}
      ,{id : 2004, categoria : 'comprobanteDom'}
      ,{id : 3001, categoria : 'acta'}
    ]

    this.decode();
  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeProveedorId = jwt_decode(token)["proveedor_id"];
    this.usuarioId = decodeUser;
    this.employeeId = decodeProveedorId
  
    this.getemployeeAll(decodeUser);
    
  }

  getemployeeAll(rfc : string){
    this._employeservice.getemployeAll().subscribe(
      res=> {
        let arrayUserId : any[] = res.filter(e => e.rfc == rfc);
        let arrayEmployee : any;
        this.employeeId = arrayUserId[0].id

        this.id = arrayUserId[0].id;

        if(arrayUserId.length > 0 ){
          this._employeservice.getemployeeFammilyById(arrayUserId[0].id).subscribe(
            ex=> {
              arrayEmployee = new MatTableDataSource(ex);
              this.getemployeeDocuments(arrayEmployee.filteredData[0]["empleadoId"].toString());
            },
            error => console.log("error consulta regiones",error)
          )    
        }
      },
      error => console.log("error consulta regiones",error)
      )
  }

  getemployeeDocuments(empleadoId : string){
    let arrayEmployee : any;
    let arrayDocuments : any[] = [];
    
    this._uploadFileService.getemployeeDocumentsAll().subscribe(
      doc=> {
        arrayEmployee = doc.filter(e => e.idEmpleado == empleadoId);

        arrayEmployee.forEach(element => {

          arrayEmployee = '';
          if(element.categoriaDocumento != 9001){
            arrayEmployee = this.tipoImagenFile.find(e => e.id.toString() == element.categoriaDocumento.toString());
            arrayDocuments.push({id: arrayEmployee.id, categoria: arrayEmployee.categoria, url: element.urlDocumento});
          }
          else{
            arrayDocuments.push({id: 9001, categoria: element.descripcion , url: element.urlDocumento});
          }
          
        });
        
        this.documentShow = new MatTableDataSource(arrayDocuments);
        console.log('TIPO DOCUMENTO * * *', this.documentShow);
      },
      error => console.log("error consulta regiones",error)
    )
  }

  openFile(element : any){

    console.log('url', element['url']);
    window.open(element['url'], 'Download');

  }

}


