<div mat-dialog-content-example [formGroup]="newProject">
    <br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>Año
                <ng-container>
                    <mat-select class="form-control"  formControlName="anio"><!--(selectionChange)="emitFilters($event, 'Enviado')"  [(ngModel)]="almacen"-->
                        <mat-option value= "2020">2020</mat-option>
                        <mat-option value= "2021">2021</mat-option>
                        <mat-option value= "2022" selected="selected">2022</mat-option>
                        <mat-option value= "2023">2023</mat-option>
                        <mat-option value= "2024">2024</mat-option>
                        <mat-option value= "2025">2025</mat-option>
                        <mat-option value= "2026">2026</mat-option>
                        <mat-option value= "2027">2027</mat-option>
                        <mat-option value= "2028">2028</mat-option>
                        <mat-option value= "2029">2029</mat-option>
                        <mat-option value= "2030">2030</mat-option>
                        <mat-option value= "2031">2031</mat-option>
                      </mat-select>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>Mes
                <ng-container>
                      <mat-select class="form-control"  formControlName="mes"><!--(selectionChange)="emitFilters($event, 'Enviado')"  [(ngModel)]="almacen"-->
                        <mat-option value= "1" selected="selected">Enero</mat-option>
                        <mat-option value= "2">Febrero</mat-option>
                        <mat-option value= "3">Marzo</mat-option>
                        <mat-option value= "4">Abril</mat-option>
                        <mat-option value= "5">Mayo</mat-option>
                        <mat-option value= "6">Junio</mat-option>
                        <mat-option value= "7">Julio</mat-option>
                        <mat-option value= "8">Agosto</mat-option>
                        <mat-option value= "9">Septiembre</mat-option>
                        <mat-option value= "10">Octubre</mat-option>
                        <mat-option value= "11">Noviembre</mat-option>
                        <mat-option value= "12">Diciembre</mat-option>
                      </mat-select>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>
                <ng-container>
                    <button mat-raised-button color="accent" (click)="search(newProject, $event)">Buscar</button>
                </ng-container>
            </div>
        </div>
    </div>

    <br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div> Orden de compra CCM</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container><input type="file" name="urlOrdenCompraCCM" id="urlOrdenCompraCCM" (change)="cargarImagen(newProject, $event, 'ordenCompraCCM', 'papeleriaCCM')" hidden></ng-container>

                <ng-container *ngIf="estadoOrdenCompraCCM == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlOrdenCompraCCM"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoOrdenCompraCCM == 2"><label for="urlOrdenCompraCCM"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoOrdenCompraCCM == 4"><label for="urlOrdenCompraCCM"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoOrdenCompraCCM == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlOrdenCompraCCM)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoOrdenCompraCCM == 0"><button class="button" (click)="openFile(urlOrdenCompraCCM)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoOrdenCompraCCM == 1"><button class="button" (click)="openFile(urlOrdenCompraCCM)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoOrdenCompraCCM == 2">
                    <button class="button" (click)="openFile(urlOrdenCompraCCM)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(501, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoOrdenCompraCCM == 4"><button class="button" (click)="openFile(urlOrdenCompraCCM)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>                
            </div>
        </div>
    </div>
<br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div> Avance de obra CCM</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container><input type="file" name="urlAvanceDeObraCCM" id="urlAvanceDeObraCCM" (change)="cargarImagen(newProject, $event, 'avanceDeObraCCM', 'papeleriaCCM')" hidden></ng-container>
                
                <ng-container *ngIf="estadoAvanceDeObraCCM == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlAvanceDeObraCCM"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoAvanceDeObraCCM == 2"><label for="urlAvanceDeObraCCM"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoAvanceDeObraCCM == 4"><label for="urlAvanceDeObraCCM"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoAvanceDeObraCCM == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlAvanceDeObraCCM)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoAvanceDeObraCCM == 0"><button class="button" (click)="openFile(urlAvanceDeObraCCM)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoAvanceDeObraCCM == 1"><button class="button" (click)="openFile(urlAvanceDeObraCCM)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoAvanceDeObraCCM == 2">
                    <button class="button" (click)="openFile(urlAvanceDeObraCCM)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(502, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoAvanceDeObraCCM == 4"><button class="button" (click)="openFile(urlAvanceDeObraCCM)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </div>
        </div>
    </div>
    <br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div> Factura de pago CCM</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container><input type="file" name="urlFacturaDePagoCCM" id="urlFacturaDePagoCCM" (change)="cargarImagen(newProject, $event, 'facturaDePagoCCM', 'papeleriaCCM')" hidden></ng-container>
                
                <ng-container *ngIf="estadoFacturaDePagoCCM == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlFacturaDePagoCCM"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoFacturaDePagoCCM == 2"><label for="urlFacturaDePagoCCM"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoFacturaDePagoCCM == 4"><label for="urlFacturaDePagoCCM"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoFacturaDePagoCCM == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlFacturaDePagoCCM)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoFacturaDePagoCCM == 0"><button class="button" (click)="openFile(urlFacturaDePagoCCM)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoFacturaDePagoCCM == 1"><button class="button" (click)="openFile(urlFacturaDePagoCCM)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoFacturaDePagoCCM == 2">
                    <button class="button" (click)="openFile(urlFacturaDePagoCCM)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(503, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoFacturaDePagoCCM == 4"><button class="button" (click)="openFile(urlFacturaDePagoCCM)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </div>
        </div>
    </div>
    <br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div> Comprobante de pago CCM</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container><input type="file" name="urlComprobanteDePagoCCM" id="urlComprobanteDePagoCCM" (change)="cargarImagen(newProject, $event, 'comprobanteDePagoCCM', 'papeleriaCCM')" hidden></ng-container>

                <ng-container *ngIf="estadoComprobanteDePagoCCM == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlComprobanteDePagoCCM"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoComprobanteDePagoCCM == 2"><label for="urlComprobanteDePagoCCM"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoComprobanteDePagoCCM == 4"><label for="urlComprobanteDePagoCCM"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoComprobanteDePagoCCM == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlComprobanteDePagoCCM)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoComprobanteDePagoCCM == 0"><button class="button" (click)="openFile(urlComprobanteDePagoCCM)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoComprobanteDePagoCCM == 1"><button class="button" (click)="openFile(urlComprobanteDePagoCCM)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoComprobanteDePagoCCM == 2">
                    <button class="button" (click)="openFile(urlComprobanteDePagoCCM)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(504, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoComprobanteDePagoCCM == 4"><button class="button" (click)="openFile(urlComprobanteDePagoCCM)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </div>
        </div>
    </div>

</div>

