<div fxLayout="row">
    <h4>Catálogo de empleados</h4>
  </div>
  <br>
  <div mat-dialog-content-example [formGroup]="newProject">
    
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Numero Empleado</div>
      </div>
      <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
            <input [type]="numeroEmpleado" matInput class="form-control form-control-line control" formControlName="numeroEmpleado" disabled >
          </mat-form-field>
          <span *ngIf="!newProject.get('numeroEmpleado').valid && newProject.get('numeroEmpleado').touched" class="help-block" style="color: red;"> El número de empleado es requerido</span>
          </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br></div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
        </div>
      </div>
    </div>
    
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><br>Nombre</div>
        </div>
        <div fxFlex.gt-sm="25%" >
            <div>
              <mat-form-field>
              <input [type]="nombre" matInput class="form-control form-control-line control" formControlName="nombre" disabled >
            </mat-form-field>
            <span *ngIf="!newProject.get('nombre').valid && newProject.get('nombre').touched" class="help-block" style="color: red;"> El nombre es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="10%" >
          <div></div>
        </div>
        <div fxFlex.gt-sm="20%" >
          <div><br>Apellido Paterno</div>
        </div>
        <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <input [type]="appaterno" matInput class="form-control form-control-line control" placeholder="" formControlName="appaterno">
            </mat-form-field>
            <span *ngIf="!newProject.get('appaterno').valid && newProject.get('appaterno').touched" class="help-block" style="color: red;"> El apellido paterno es requerido</span>
          </div>
        </div>
      </div>

      <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><br>Apellido Materno</div>
        </div>
        <div fxFlex.gt-sm="25%" >
            <div>
              <mat-form-field>
              <input [type]="apmaterno" matInput class="form-control form-control-line control" formControlName="apmaterno">
            </mat-form-field>
            <span *ngIf="!newProject.get('apmaterno').valid && newProject.get('apmaterno').touched" class="help-block" style="color: red;"> El apellido materno es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="10%" >
          <div></div>
        </div>
        <div fxFlex.gt-sm="20%" >
          <div><br>RFC</div>
        </div>
        <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <input [type]="rfc" matInput class="form-control form-control-line control" placeholder="" formControlName="rfc" disabled (change)="onChangeRFC($event)">
            </mat-form-field>
            <span *ngIf="!newProject.get('rfc').valid && newProject.get('rfc').touched" class="help-block" style="color: red;"> El RFC es requerido</span>
          </div>
        </div>
      </div>
  </div>

  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><ng-container *ngIf="userProfile != 5"><button mat-raised-button color="accent" (click)="save(newProject, $event)">Guardar</button></ng-container></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div><button mat-raised-button color="warn" (click)="cancel($event)">Cancelar</button></div>
    </div>
  </div>
  