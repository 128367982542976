import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { employeModel } from 'src/app/models/employe.model';
import { employeservice } from 'src/app/services/employe.service';
import { SupplierDetailComponent } from 'src/app/components/supplier-detail/supplier-detail.component';
import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { EmployeeAproveComponent } from '../../employee-aprove/employee-aprove.component';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
import { Breakpoints } from '@angular/cdk/layout';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';

@Component({
  selector: 'app-employee-review-family-data',
  templateUrl: './employee-review-family-data.component.html',
  styleUrls: ['./employee-review-family-data.component.css']
})
export class EmployeeReviewFamilyDataComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<employeModel>
employeeId : number;
pageInfo : any;
arraySupplierGlobal : any = [];
usuarioId : string;
dataSourceEmployee : any;
arrayEmployeeGlobal : any = [];
id : number;

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<employeModel>
      , private _employeservice : employeservice
      , @Inject(MAT_DIALOG_DATA) public data
      , private formBuilder: FormBuilder
      , public dialog: MatDialog
      , private readonly _uploadFileService: UploadFileService
    ) { 
      this.pageInfo = data.arrayData;
      this.newForm = this.formBuilder.group({
        
      })
    }

  displayedColumns = ['employee_id', 'documento', 'estatus', 'ver', 'revision'];
  displayedColumnsFammily = ['id', 'url', 'nombre', 'aPaterno', 'aMaterno', 'fechaNacimiento', 'sexo', 'parentesco', 'ver', 'revision'];

  public newForm: FormGroup;
  fileDownload : any;

  ngOnInit(): void {
    this.decode();
    this.employeeId = this.pageInfo.id;
    this.getemployeeDocuments();
  }

  cancel(){
    this.dialogRef.close();
  }

  descargarArchivos(){
  
    let posicion : number;
    let posicionInicial : number;

    this.fileDownload = ''
    this.dataSourceShow.filteredData.forEach(element => {

      let binaryData : any = []
      let dataType = 'png';
      let fileName = 'Nombre'
      let fileNameCompleto : string;
      let filePath : string;

      if(element.url != ''){

        posicionInicial = element.url.indexOf("6%2")
        posicion = element.url.indexOf("?")

        fileNameCompleto = element.url.substring(posicion, posicionInicial + 3)
        dataType = fileNameCompleto.substring(fileNameCompleto.indexOf(".") + 1, fileNameCompleto.length)
        fileName = fileNameCompleto.substring(0, fileNameCompleto.indexOf("."))

        // DESCARGAR ARCHIVO
        // ==================
        binaryData.push(element.url)
        filePath = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        
        let downloadLink = document.createElement('a');
        downloadLink.href = filePath;
        downloadLink.setAttribute('download', fileNameCompleto);
        downloadLink.setAttribute('src', fileNameCompleto);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    
    });

  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    this.usuarioId = decodeUser;
    this.getemployeeAll(this.pageInfo.rfc);
  }

  review(element, event){
    // AUTORIZA O RECHAZA
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'APROBAR/DENEGAR ARCHIVO',
      arrayData : element,
      employee_id: element.employee_id,
      idDocumento: element.idDocumento,
      estadoPantalla: 'Edit'
     
    }
    
    dialogConfig.width = '550px';
    dialogConfig.height = '300px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(EmployeeAproveComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getemployeeDocuments();
    });
  }

  view(element, event){
    window.open(element.url);
  }

  // -------------
  // SERVICIOS
  // -------------
  getemployeeAll(rfc : string){
    this._employeservice.getemployeAll().subscribe(
      res=> {
        let arrayUserId : any[] = res.filter(e => e.rfc == rfc);
        let arrayEmployee : any;
        let estadoCivil : string;
        let arrayFammily : any;
        let arrayDetail : any = [];
        this.employeeId = arrayUserId[0].id

        this.id = arrayUserId[0].id; 

        switch(arrayUserId[0].estadoCivil.toString()){
          case ('0') : estadoCivil = 'CASADO'
            break;
          case ('1') : estadoCivil = 'SOLTERO'
            break;
          case ('2') : estadoCivil = 'DIVORCIADO'
            break;
        }

        this.arrayEmployeeGlobal = [];
        this.arrayEmployeeGlobal.push({employee_id : 1, documento : 'Estado civil: ' + estadoCivil, estatus : 0, aprobacion : false, url : '', idDocumento : 0})

        if(arrayUserId.length > 0 ){
          this._employeservice.getemployeeFammilyById(arrayUserId[0].id).subscribe(
            ex=> {
      
              arrayEmployee = new MatTableDataSource(ex);
              
              // Obtiene imagenes de familiares
              this._employeservice.getemployeeFammilyById(arrayUserId[0].id).subscribe(
                fam=> {
                  arrayFammily = fam;

                  this._uploadFileService.getemployeeDocumentsAll().subscribe(
                    doc=> {
                      let arrayDoc = doc;
                      console.warn('arrayDoc', arrayDoc)
                      fam.forEach(element => {
                        let x : any = arrayDoc.filter(e => e.idFamiliar == element.id)
                        
                        arrayDetail.push({
                          id : element.id
                          , nombre : element.nombre
                          , aPaterno : element.aPaterno
                          , aMaterno : element.aMaterno
                          , fechaNacimiento : element.fechaNacimiento
                          , sexo : element.sexo
                          , parentesco : element.parentesco
                          , url : (x.length > 0) ? x[0].urlDocumento : ''
                          , estatus : (x.length > 0) ? x[0].estado : 5
                        })
                      });
                      
                    this.dataSourceEmployee = new MatTableDataSource(arrayDetail);
                    console.warn('areglo de documentos', this.dataSourceEmployee)
                  },
                  error => console.log("error consulta regiones",error)
                )
              },
              error => console.log("error consulta regiones",error)
              )

              this.dataSourceEmployee = arrayEmployee;

              this.dataSourceShow = new MatTableDataSource(this.arrayEmployeeGlobal);
            },
            error => console.log("error consulta regiones",error)
          )    
        }
      },
      error => console.log("error consulta regiones",error)
      )
  }


  getemployeeDocuments(){
    // let arrayDocumentos : any;

    // // Empleado registrado
    // this._employeservice.getemployeeFammilyById(this.employeeId).subscribe(
    //   emp=> {
    //     arrayDocumentos = emp;
    //     this.dataSourceEmployee = new MatTableDataSource(emp);

    //     this.arrayEmployeeGlobal = [];
    //     this.arrayEmployeeGlobal.push({employee_id : 1, documento : 'Estado civil: ' + this.dataSourceEmployee.filteredData.nombre, estatus : 0, aprobacion : false, url : '', idDocumento : 0})

    //     this.dataSourceShow = new MatTableDataSource(this.arrayEmployeeGlobal);
    //         console.log('AQUI ESTA EL EMPLEADO', this.arrayEmployeeGlobal);
    //   }
    
    // )
  }

}
