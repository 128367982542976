<div mat-dialog-content-example [formGroup]="newProject">
    
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Nombre de la Compañía</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="empresa" matInput class="form-control form-control-line control"
                        formControlName="empresa" disabled>
                </mat-form-field>
                <span *ngIf="!newProject.get('empresa').valid && newProject.get('empresa').touched" class="help-block"
                    style="color: red;"> El nombre de la empresa es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Nombre de su Jefe Dirécto</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="jefeDirecto" matInput class="form-control form-control-line control" placeholder=""
                        formControlName="jefeDirecto">
                </mat-form-field>
                <span *ngIf="!newProject.get('jefeDirecto').valid && newProject.get('jefeDirecto').touched"
                    class="help-block" style="color: red;">El Jefe directo es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Teléfono</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="telefono" matInput class="form-control form-control-line control" placeholder="" formControlName="telefono">
                </mat-form-field>
                <span *ngIf="!newProject.get('telefono').valid && newProject.get('telefono').touched" class="help-block"
                    style="color: red;"> El teléfono es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Correo Electrónico</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="correo" matInput class="form-control form-control-line control" formControlName="correo">
                </mat-form-field>
                <span *ngIf="!newProject.get('correo').valid && newProject.get('correo').touched" class="help-block"
                    style="color: red;"> El correo es requerido</span>
            </div>
            <div fxFlex.gt-sm="90px">
                <div><br>
                </div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br>Puesto que Desempeñaba</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div>
                <mat-form-field>
                    <input [type]="puesto" matInput class="form-control form-control-line control" formControlName="puesto">
                </mat-form-field>
                <span *ngIf="!newProject.get('puesto').valid && newProject.get('puesto').touched" class="help-block"
                    style="color: red;"> El puesto es requerido</span>
            </div>
            <div fxFlex.gt-sm="90px">
                <div><br>
                </div>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>Motivo de su Separación</div>
        </div>
        <div fxFlex.gt-sm="400px">
            <div>
                <mat-form-field>
                    <input [type]="motivoSeparacion" matInput class="form-control form-control-line control" placeholder="" formControlName="motivoSeparacion">
                </mat-form-field>
                <span *ngIf="!newProject.get('motivoSeparacion').valid && newProject.get('motivoSeparacion').touched" class="help-block"
                    style="color: red;"> El motivo de la separacion es requerido</span>
            </div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
            </div>
        </div>
        <div fxFlex.gt-sm="50px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

<br><br>
<div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="accent" (click)="save(newProject, $event)"><i class="fa fa-check fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div><button mat-raised-button color="warn" (click)="cancel($event)"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
    <div fxFlex.gt-sm="20%">
        <div></div>
    </div>
</div> 
