<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
      <div class="header-body">
        <ng-container *ngIf="this.user == 'root'"></ng-container>
        <!-- Card stats -->
      </div>
    </div>
  </div>
  
  <!-- Page content -->
  <div class="container-fluid mt--7">
  
    <!-- Table -->
    <div class="row">
      <div class="col">
        <div class="card shadow">
          <div class="card-header border-0">
            <h3 class="mb-0">Ordenes de compra</h3>
            <!-- <h3><p style="color: red; background-color: greenyellow;">Es necesario que se comunique son su proveedor de sistemas</p></h3> -->
          </div>
          
          <!-- <form> -->    
            <h6 class="heading-small text-muted mb-4"></h6>
            <hr class="my-4" />
          <!-- </form> -->
          <div class="card">
            <table>
              <tr>
                <td class="col20"><input type="text" class="form-control form-control-line" placeholder="BUSCAR" (keyup)="filtrar($event)"></td>
                <td class="col10"></td>
                <td class="col20">
                  <mat-form-field>
                    <mat-select class="form-control control" [(ngModel)]="fEstatus" (selectionChange)="emitFilters($event, 'Estatus')">
                      <mat-option value= "1">POR AUTORIZAR</mat-option>
                      <!-- <mat-option value= "2">ORDEN DE COMPRA</mat-option> -->
                      <mat-option value= "3" selected>AUTORIZADO</mat-option>
                      <mat-option value= "4">CANCELADO</mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td class="col40"><div class="center" style="width: 100%;"><ng-container *ngIf="isloading == true"><mat-spinner color="primary"></mat-spinner></ng-container></div></td>
                <td class="col10"><button mat-raised-button color="primary" (click)="descargarExcel()"><i class="fa fa-arrow-down fa-lg icon-cog-wite"></i>&nbsp;&nbsp;Descargar</button></td>
                <td class="col10"><button *ngIf="profile == 12 || profile == 13" mat-raised-button color="accent" (click)="nuevaOrdenDeCompra($event)"><i class="fa fa-plus fa-lg icon-cog-wite"></i>&nbsp;&nbsp;Nueva Orde de Compra</button></td>
                <td class="col10"></td>
              </tr>
            </table>
            <br>
          </div>

          <!-- INICIA CUERPO DE LA PÁGINA-->
        <div class="col-12 col-md-12 p-0">
          <div class="card">
            <div class="card-body card-body-table">
              <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8" style="width: 95%;">
      
                <!-- Orden de compra Id -->
                <ng-container matColumnDef="ordendecompra_id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10" hidden> <span>Id</span> </th>
                  <td mat-cell *matCellDef="let element" class="column" hidden> {{element.ordendecompra_id}} </td>
                </ng-container>

                <!-- Codigo orden de compra -->
                <ng-container matColumnDef="ordendecompra_codigo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> <span>Orden de compra</span> </th>
                  <td mat-cell *matCellDef="let element" class="column"> {{element.ordendecompra_codigo}} </td>
                </ng-container>
                
                <!-- ID Column -->
                <ng-container matColumnDef="cotizacion_codigo">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> <span>Cotización</span> </th>
                  <td mat-cell *matCellDef="let element" class="column"> {{element.cotizacion_codigo}} </td>
                </ng-container>
                
                <!-- Name Column -->
                <ng-container matColumnDef="proveedor_nombre">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30"> Proveedor </th>
                  <td mat-cell *matCellDef="let element" class="column"> {{element.proveedor_nombre}} </td>
                </ng-container>
                
                <ng-container matColumnDef="ordendecompra_fecha">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> Fecha </th>
                  <td mat-cell *matCellDef="let element" class="column"> {{element.ordendecompra_fecha | date: ' dd-MM-yyyy'}} </td>
                </ng-container> 

                <!-- Email Column -->
                <ng-container matColumnDef="Estatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Estatus </th>
                  <td mat-cell *matCellDef="let element" class="col20"> 
                    <ng-container *ngIf="element.estado == 0"><b>POR AUTORIZAR</b></ng-container>
                    <ng-container *ngIf="element.estado == 1"><b>POR AUTORIZAR</b></ng-container>
                    <ng-container *ngIf="element.estado == 2">ORDEN DE COMPRA</ng-container>
                    <ng-container *ngIf="element.estado == 3"><p style="color: green;">AUTORIZADO</p></ng-container>
                    <ng-container *ngIf="element.estado == 4"><p style="color: red;">CANCELADO</p></ng-container>
                  </td>
                </ng-container>

                <!-- Editar -->
                <ng-container matColumnDef="editar">
                  <th mat-header-cell *matHeaderCellDef class="colbuttons"> Editar </th>
                  <td mat-cell *matCellDef="let element" class="colbuttons"> 
                    <ng-container *ngIf="profile == 12 || profile == 13"><button mat-button color="primary" (click)="editODC(element, $event)"><i class="fa fa-pencil fa-lg icon-cog-edit"></i></button></ng-container>
                    <ng-container *ngIf="profile == 7"><button mat-button color="primary"><i class="fa fa-pencil fa-lg icon-cog-disabled"></i></button></ng-container>
                  </td>
                </ng-container>

                <!-- Cancelar -->
                <ng-container matColumnDef="cancelar">
                  <th mat-header-cell *matHeaderCellDef class="colbuttons"> Cancelar </th>
                  <td mat-cell *matCellDef="let element" class="colbuttons"> 
                    <ng-container *ngIf="profile == 12 || profile == 13"><button mat-button color="primary" (click)="cancelaODC(element, $event)"><i class="fa fa-ban fa-lg icon-cog-red"></i></button></ng-container>
                    <ng-container *ngIf="profile == 7"><button mat-button color="primary" ><i class="fa fa-ban fa-lg icon-cog-disabled"></i></button></ng-container>
                    </td>
                </ng-container>

                <!-- Autorizar -->
                <ng-container matColumnDef="autorizar">
                  <th mat-header-cell *matHeaderCellDef class="colbuttons"> Autorizar </th>
                  <td mat-cell *matCellDef="let element" class="colbuttons"> 
                    <ng-container *ngIf="profile == 12 || profile == 13"><button mat-button color="primary" (click)="aproveODC(element, $event)"><i class="fa fa-check fa-lg icon-cog-check"></i></button> </ng-container>
                    <ng-container *ngIf="profile == 7"><button mat-button color="primary"><i class="fa fa-check fa-lg icon-cog-disabled"></i></button> </ng-container>
                  </td>
                </ng-container>

                <!-- PDF -->
                <ng-container matColumnDef="pdf">
                  <th mat-header-cell *matHeaderCellDef class="colbuttons"> PDF </th>
                  <td mat-cell *matCellDef="let element" class="colbuttons"> <button mat-button color="primary" (click)="printPDF(element, $event)"><i class="fa fa-file fa-lg icon-cog-print"></i></button> </td>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </mat-table>
        
            </div>
            <mat-paginator [pageSizeOptions]="[20, 100, 150]" [showFirstLastButtons]="true" [pageSize]="pageSize" [length]="totalSize"  (page)="handlePage($event)" style="width: 95%;"></mat-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" hidden>
  <div id="pdfTable" #pdfTable>
    
  </div>
</div>

