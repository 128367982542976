<p>Reporte General</p>

    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tabsEncabezado">Perfil</span>
            </ng-template>
            <br><span class="tabsEncabezado"></span><br>
            <app-user-key-access></app-user-key-access>
        </mat-tab>
    
        <ng-container *ngIf="this.profile == '12'">
            <mat-tab>
                <ng-template mat-tab-label *ngIf="this.usuarioId != 'roberto_guzman'">
                    <span class="tabsEncabezado">Usuarios</span>
                </ng-template> 
                <br>
                    <span class="tabsEncabezado">Mantenimiento usuarios </span>
                <br>
                <app-user-manage-list></app-user-manage-list>
            </mat-tab>
        </ng-container>
    </mat-tab-group>



