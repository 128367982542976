import { CompileShallowModuleMetadata, IfStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { BREAKPOINT } from '@angular/flex-layout';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as e from 'cors';
import { decode } from 'querystring';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { employeservice } from '../../../../services/employe.service';
// import { RepseCommentsComponent } from '../../repse-comments/repse-comments.component';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';

@Component({
  selector: 'app-employee-capture-last-work',
  templateUrl: './employee-capture-last-work.component.html',
  styleUrls: ['./employee-capture-last-work.component.css']
})
export class EmployeeCaptureLastWorkComponent implements OnInit {

// =========================
// DECLARACIONES
// =========================
empresa : string;
jefeDirecto : string;
telefono : string;
correo : string;
puesto : string;
motivoSeparacion : string;
employeeId : number = 0;

usuarioId : number = 0;
tipoImagenFile : any = [];
arrayDocumentosProveedor : any;
arrayDocumentosProveedorOrigen : any;
dataSourceEmployee : any;
prestadorServicio : string;

nombreArchivo: string = " (archivo nuevo) ";
loading: boolean;
valido: boolean
errorFileUpload : boolean = true; // true = valido, false = invalido

public newProject: FormGroup;

  constructor(
    public dialog: MatDialog
    , private _employeservice : employeservice
    , private formBuilder: FormBuilder
    , private _snackBar : MatSnackBar
    , private readonly _uploadFileService: UploadFileService
  ) { 
    this.newProject = this.formBuilder.group({
      empresa :  new FormControl('', [Validators.required]),
      jefeDirecto: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required]),
      puesto: new FormControl('', [Validators.required]),
      motivoSeparacion: new FormControl('', [Validators.required])
    });
  }

// =========================
// PROCEDIMIENTOS
// =========================

  ngOnInit(): void {
    
    this.decode();
  }
  
  cancel(event){
  //  this.dialogRef.close();
  }

  save(form, event){

    let arrayToDb : any;

    arrayToDb = ({ 
      empleadoId : this.employeeId
        , empresa : form.controls["empresa"].value
        , jefeDirecto : form.controls["jefeDirecto"].value
        , telefono : Number(form.controls["telefono"].value) 
        , correo : form.controls["correo"].value
        , puesto : form.controls["puesto"].value
        , motivoSeparacion : form.controls["motivoSeparacion"].value

      });

    this.updateEmployee(arrayToDb);
    
  }

  addFile(concepto: any, event){
    console.log('SE CARGA', concepto);
  }

  addView(concepto: any, event ){
    console.log('VISUALIZA', concepto);
  }

  onFileChange(event, grupoArchivo: string, tipoArchivo : string){
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(event.target);
    let extencionArchivo : string = '';
    let fileToUpload : any;
    
    // this.UploadSavingsDataExcel = null;

    if (target.files.length !== 1) {
      throw new Error('No se pueden seleccionar multiples archivos');
    }

    this.nombreArchivo = (target.files.length > 0) ? target.files[0]["name"].substring(1,30) : " (archivo nuevo) ";

    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    extencionArchivo = target.files[0].name.substring(target.files[0].name.length - 5,target.files[0].name.length)

    this.UploadFiles(target.files[0], '1', tipoArchivo)
   
  }

// =========================
// UTILERIAS
// =========================
async UploadFiles(file: File, idProveedor: string, tipoArchivo: string) {

  if ((file.name.substring(file.name.length - 4, file.name.length).toUpperCase() == '.PNG')
      || (file.name.substring(file.name.length - 4, file.name.length).toUpperCase() == '.BMP')
      || (file.name.substring(file.name.length - 4, file.name.length).toUpperCase() == '.JPG')) {
  }
  else {
      this.valido = false;
      this.openSnackBar("Alguno de los archivos seleccionados no es una imagen, seleccionar un arhivo válido", 'error');
      this.loading = false;
      return;
  }

  const formData = new FormData();

  formData.append('File', file, file.name);
  formData.append('idProveedor', idProveedor);
  formData.append('tipoArchivo', tipoArchivo);

  try {
      this.errorFileUpload = true; // valido
      return await this._uploadFileService.postUploadFile(formData);
  } catch (error) {
      this.openSnackBar('El archivo no pudo ser cargado', 'error');
      this.errorFileUpload = false; // invalido
      this.loading = false;
      throw new Error
  }
}

openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {duration : 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: 'alert-snackbar'});
}

decode(){
  let token = localStorage.getItem('token_access');
  let decodeUser = jwt_decode(token)["usuario"];
  let decodeProveedorId = jwt_decode(token)["proveedor_id"];
  this.usuarioId = decodeUser;
  this.employeeId = decodeProveedorId

  this.getemployeeAll(decodeUser);
}

showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
  switch(tipoMensaje){
    case(1) : 
        Swal.fire({
          title: header,
          html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
          icon: icon,
          confirmButtonText: buttonCaption,
          customClass: {
              confirmButton: 'btn  btn-rounded btn-outline-warning'
          }
        })
      break;
    case(2) :
        Swal.fire({
          position: 'top-end',
          icon: icon,
          title: message,
          showConfirmButton: false,
          timer: 1500
        })
      break;
  }
}


opencomments(documentShow : number, element, event){

  let arrayDocuemtnoEnviar : any;

  arrayDocuemtnoEnviar = this.arrayDocumentosProveedorOrigen.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento == documentShow);

  this.showMessage(1, 'Comentario', 'info', arrayDocuemtnoEnviar[0]["comentarios"], 'Cerrar');

}

// =========================
// SERVICIOS
// =========================
  getemployeeAll(rfc : string){

    this._employeservice.getemployeAll().subscribe(
      res=> {
        let arrayUserId : any[] = res.filter(e => e.rfc == rfc);

        if(arrayUserId.length > 0 ){
          this.employeeId = arrayUserId[0].id;

        // Proyectos registrados
        this._employeservice.getemployeeLastWorkById(arrayUserId[0].id).subscribe(
          res=> {
            this.dataSourceEmployee = new MatTableDataSource(res);

            this.newProject.controls["empresa"].setValue(this.dataSourceEmployee.filteredData.empresa);
            this.newProject.controls["jefeDirecto"].setValue(this.dataSourceEmployee.filteredData.jefeDirecto);
            this.newProject.controls["telefono"].setValue(this.dataSourceEmployee.filteredData.telefono);
            this.newProject.controls["correo"].setValue(this.dataSourceEmployee.filteredData.correo);
            this.newProject.controls["puesto"].setValue(this.dataSourceEmployee.filteredData.puesto);
            this.newProject.controls["motivoSeparacion"].setValue(this.dataSourceEmployee.filteredData.motivoSeparacion);
            
          },
          error => console.log("error consulta regiones",error)
        )
      }
    },
    error => console.log("error consulta regiones",error)
    )
  }

  updateEmployee(arrayToDb : any){
    // Proyectos registrados
    this._employeservice.updateemployeeLastWork(arrayToDb).subscribe(
      res=> {
        console.log('Proveedores', res);
        this.showMessage(2, 'Exitoso', 'success', 'Actualización de registro exitosa', 'Cerrar');
      },
      error => console.log("error consulta regiones",error)
    )
  }
}
