import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { mailUserSender } from '../models/mail.model';
    
@Injectable({
    providedIn: 'root'
})

export class sendMailService {

    constructor(protected http: HttpClient) { }

    sendMail(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.post(environment.urlapi+environment.apiContextDrivers+'EmailNotifications/sendNotifications',arrayToDb, {headers});
    }

    getmailUserSender(usuarioIdInt : any, modulo : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<mailUserSender[]>(environment.urlapi+environment.apiContextDrivers+'EmailNotifications/usuarios/notificar/lista?userId=' + usuarioIdInt + '&modulo=' + modulo, {headers});
    }
}