import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { supplyservice } from '../../services/supplier.service';
import jwt_decode from "jwt-decode";
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { dateInputsHaveChanged } from '@angular/material/datepicker/datepicker-input-base';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';

@Component({
  selector: 'app-supplier-defeated',
  templateUrl: './supplier-defeated.component.html',
  styleUrls: ['./supplier-defeated.component.css']
})
export class SupplierDefeatedComponent implements OnInit {

  // =================
  // DECLARACIONES
  // =================
  dataSourceShow : MatTableDataSource<any>;
  datasourcesupplier : any[] = [];
  proveedorId : number;
  usuarioId : any;

  displayedColumns = ['id', 'nombreProveedor', 'rfcProveedor', 'mes', 'anno', 'fechaCaducidad', 'categoriaDocumento', 'estatusDocumento'];

  constructor(
    private _supplyservice : supplyservice
  ) { }

  // =================
  // PROCEDIMIENTOS
  // =================
  ngOnInit(): void {
    this.decode();
  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeProveedorId = jwt_decode(token)["proveedor_id"];
    this.usuarioId = decodeUser;
    this.proveedorId = decodeProveedorId;
    this.getSupplierReport();
  }

  // =================
  // SERVICIOS
  // =================
  getSupplierReport(){

    let fechaActual = moment(new Date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    let fechaMesAnterior = moment(new Date, 'YYYY-MM-DD').subtract(1, 'months').format('YYYY-MM-DD');
    let year = Number(moment(fechaMesAnterior, "YYYY-MM-DD").format("YYYY"));
    let month = Number(moment(fechaMesAnterior, "YYYY-MM-DD").format("MM"));
    let arrayResulsetMesAnterior : any;
    let arrayResulsetMesActual : any;
    let arrayResulsetFinal : any[] =[];

    console.log(fechaActual);

    this._supplyservice.getsupplyOutOfDate(this.proveedorId, year, month).subscribe(
      res=> {
        arrayResulsetMesAnterior = res;

        console.log('CONSULTA 1', arrayResulsetMesAnterior);

        year = Number(moment(new Date, "YYYY-MM-DD").format("YYYY"));
        month = Number(moment(new Date, "YYYY-MM-DD").format("MM"));

        // Segunda consulta
        this._supplyservice.getsupplyOutOfDate(this.proveedorId, year, month).subscribe(
          res2=> {
            // this.datasourcesupplier.push(res2);
            arrayResulsetMesActual = res2;
    
            arrayResulsetMesAnterior.forEach(element => {
              arrayResulsetFinal.push({id: element.id
                                        , nombreProveedor: element.nombreProveedor
                                        , rfcProveedor:  element.rfcProveedor
                                        , comentarios: element.comentarios
                                        , mes: element.mes
                                        , anno: element.anno
                                        , categoriaDocumento_Id: element.categoriaDocumento_Id
                                        , fechaCaducidad: element.fechaCaducidad
                                        , categoriaDocumento: element.categoriaDocumento
                                        , estatusDocumento: element.estatusDocumento
                                      })
            });

            arrayResulsetMesActual.forEach(element => {
              arrayResulsetFinal.push({id: element.id
                                        , nombreProveedor: element.nombreProveedor
                                        , rfcProveedor:  element.rfcProveedor
                                        , comentarios: element.comentarios
                                        , mes: element.mes
                                        , anno: element.anno
                                        , categoriaDocumento_Id: element.categoriaDocumento_Id
                                        , fechaCaducidad: element.fechaCaducidad
                                        , categoriaDocumento: element.categoriaDocumento
                                        , estatusDocumento: element.estatusDocumento
                                      })
            });
            
            this.datasourcesupplier = arrayResulsetFinal;

            console.log('CONSULTA 2', arrayResulsetMesActual);

            console.log('RESULTADO FINAL', this.datasourcesupplier);
    
          },
          error => console.log("error consulta categorias",error)
        )


      },
      error => console.log("error consulta categorias",error)
    )
  }

}


