import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { codigoDeCostosModel } from 'src/app/models/codigo-de-costos.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class codigoDeCostosService {

  constructor(protected http: HttpClient) { }

    getCodigoDeCostosAll() {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<codigoDeCostosModel[]>(environment.urlapi + environment.apiContextDrivers + 'InternalRequisition/lista/codigoscostos', {headers});
    }
}