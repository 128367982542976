<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card shadow">
                <div class="card-header border-0">
                    <h3 class="mb-0">Revisión de proveedores</h3>
                </div>

                <!-- <form> -->

                <h6 class="heading-small text-muted mb-4"></h6>
                <hr class="my-4" />
                <!-- </form> -->
                <div class="card">
                    <table>
                        <tr>
                            <td class="col20"></td>
                            <td class="col70"></td>
                            <td class="col10"></td>
                            <td class="col10" hidden><button mat-raised-button color="primary"
                                (click)="descargarExcel()">Descargar</button></td>
                            <td class="col10"></td>
                        </tr>
                    </table>
                    <br>
                </div>

                <div fxLayout="row">
                    <h4>REPSE Captura de proveedores</h4>
                </div>
                <br>
                <mat-tab-group>
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Generales</span>
                        </ng-template>
                        <br><span class="tabsEncabezado">Datos Generales</span><br>
                        <div>
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 5%;"></td>
                                <td style="width: 70%;"><app-repse-review-general></app-repse-review-general></td>
                            </tr>
                        </table>
                        </div>
                    </mat-tab>
                
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Mensuales</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Datos Mensuales </span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-repse-review-mensual></app-repse-review-mensual></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>
                
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Bimestrales</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Datos Bimestrales </span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-repse-review-bimestral></app-repse-review-bimestral></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>
                
                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Cuatrimestrales</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Datos Cuatrimestrales </span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-repse-review-cuatrimestral></app-repse-review-cuatrimestral></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Papelerias CCM</span>
                        </ng-template> 
                        <br><span class="tabsEncabezado">Papelerias CCM</span><br>
                        <div>
                            <table style="width: 100%;">
                                <tr>
                                    <td style="width: 5%;"></td>
                                    <td style="width: 70%;"><app-repse-review-ccm></app-repse-review-ccm></td>
                                </tr>
                            </table>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Meses Válidos</span>
                        </ng-template>
                        <br><span class="tabsEncabezado">Meses Válidos</span><br>
                        <div>
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 5%;"></td>
                                <td style="width: 70%;"><app-repse-supplier-review-validos></app-repse-supplier-review-validos></td>
                            </tr>
                        </table>
                        </div>
                    </mat-tab>

                    <mat-tab>
                        <ng-template mat-tab-label>
                            <span class="tabsEncabezado">Reporte Ejecutivo</span>
                        </ng-template>
                        <br><span class="tabsEncabezado">Reporte Ejecutivo</span><br>
                        <div>
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 5%;"></td>
                                <td style="width: 70%;"><app-repse-report></app-repse-report></td>
                            </tr>
                        </table>
                        </div>
                    </mat-tab>
                </mat-tab-group>

            </div>
        </div>
    </div>
</div>
