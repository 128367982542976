import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { poModel } from 'src/app/models/po.model';
import { purchaseOrderservice } from 'src/app/services/PurchaseOrder.service';
import { PoDetailComponent } from 'src/app/components/po-detail/po-detail.component';
import jsPDF from 'jspdf';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import htmlToPdfmake from 'html-to-pdfmake';
import jwt_decode from "jwt-decode";
import { supplyservice } from '../../../services/supplier.service';
import { quotationservice  } from 'src/app/services/quotation/quotation.service';
import { UserService } from '../../../services/user.service';
import Swal from 'sweetalert2';
import { trackingservice } from '../../../services/tracking.service';
import { poPdfPrints } from '../../../components/pdfModule/po-pdf-print';
import { ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { BreakPoint } from '@angular/flex-layout';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['./po-list.component.css']
})
export class PoListComponent implements OnInit {
  // =====================
  // DECLARACIONES
  // =====================

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<poModel>
dataSourceShowOriginal : MatTableDataSource<poModel>

  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  displayedColumns = ['ordendecompra_id', 'ordendecompra_codigo', 'cotizacion_codigo', 'proveedor_nombre', 'ordendecompra_fecha', 'Estatus', 'editar', 'cancelar', 'autorizar', 'pdf'];
  
  buscar:any;
  logoDataUrl : string;
  logoDataCompras : string;
  logoDataControlProy : string;
  logoDataBlanco : string;
  logoDataBlancoODC : string;
  logoCCC: any;
  logoDataGerencia: any;
  decodedSign : any;
  usuarioId : any;
  datasourcesupplier : any;
  datasourcePoveedores : any[] = [];
  datasourceCotizaciones : any;
  datasourceCotizacionesDetalle : any;
  UserIdLogin : number;
  datasourceUsers : any;
  fEstatus : any = '3'
  fEstatusGerencia : any = '0'
  ISRRetencion125Monto : number = 0;
  profile : any = jwt_decode(localStorage.getItem('token_access'))["perfil_usuarioid"];
  user : any = jwt_decode(localStorage.getItem('token_access'))["usuario"]
  isloading : boolean = false;
  NoODC : string = '';

  constructor(public dialog: MatDialog
          , private _excelService : ExcelServiceService
          , private _purchaseOrderService : purchaseOrderservice
          , private _supplyservice : supplyservice
          , private _quotationservice : quotationservice
          , private _UserService : UserService
          , private _trackingservice : trackingservice
          , private _pdfPrints : poPdfPrints) { }


  // =====================
  // PROCEDIMIENTOS
  // =====================

  ngOnInit(): void {

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlanco.PNG').then(
      result => this.logoDataUrl = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaRoberto.PNG').then(
      result => this.logoDataCompras = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/imgFirmaEnBlanco.jpg').then(
      result => this.logoDataControlProy = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/background/logoCCC.jpg').then(
      result => this.logoCCC = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/imgBlanco.png' ).then(
      result => this.logoDataBlanco = result
    )

    this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaBlancoOrdenesDeCompra.png' ).then(
      result => this.logoDataBlancoODC = result
    )

    // this.getImageDataUrlFromLocalPath1('../../../assets/images/Signs/FirmaAlbertoRamirez.png' ).then(
    //   result => this.logoDataGerencia = result
    // )

    this.getsupplierAll();
    this.getProveedores();
    this.getCotizacionesAll();
    this.getPO_Hdr();
    this.decode();
  }

  descargarExcel(){
    let dataSourceShowToExcel : any[] = [];

  this.dataSourceShow.filteredData.forEach(element => {
    dataSourceShowToExcel.push({proyecto_id : element.proyecto_id
                              , categoria : element.codigo_proyectocategoria
                              , requisicion : element.requisicioninterna_id
                              , Fecha_Requisicion : moment(element.Fecha_Requisicion, 'YYYY-MM-DD').format('DD-MM-YYYY')
                              , estatus : element.estado
                              , estatusGerencia : element.estatusGerencia
      })
    });

    this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Requisicones');  
  }

  nuevaOrdenDeCompra(event){
    
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'ORDEN DE COMPRA',
      arrayData : null,
      requisicionId: 1,
      estadoPantalla : 'new'
     
    }
    dialogConfig.width = '1500px';
    dialogConfig.height = '9000px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(PoDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPO_Hdr();
    });
  }

  editODC(element, event){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'ORDEN DE COMPRA',
      arrayData : element,
      requisicionId: 1,
      estadoPantalla : 'edition'
     
    }
    dialogConfig.width = '1500px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(PoDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPO_Hdr();
    });
  }

  aproveODC(element, event){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'ORDEN DE COMPRA',
      arrayData : element,
      requisicionId: 1,
      estadoPantalla : 'aprove',
     
    }
    dialogConfig.width = '1500px';
    dialogConfig.height = '9000px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(PoDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPO_Hdr();
    });
  }

  cancelaODC(element, event : Event){
    this.updateODCStatus(element, 4);
  }

  printPDF(element, event){
    this._pdfPrints.pdfPurchaseOrderPrintOnInit(element);
  }

  // =====================
  // UTILERIAS
  // =====================

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceShow.filter = filtro.trim().toLowerCase();
  }

  emitFilters(event, filtro) {
    this.dataSourceShow = this.dataSourceShowOriginal;
    let filtroEstatus : number = 3;
    let arrayFiltroData : MatTableDataSource<poModel> = null;

    console.log('eventeventevent', event)

    if(filtro === 'Estatus'){
      filtroEstatus = (filtroEstatus == undefined) ? 3 : event.value; 
      filtroEstatus = (event.value == undefined) ? 3 : filtroEstatus;
    }

    if(filtro === 'estatusGerencia'){
      filtroEstatus = 2;
      filtroEstatus = (filtroEstatus == undefined) ? 2 : event.value; 
      filtroEstatus = (event.value == undefined) ? 2 : filtroEstatus;
    }

    if(filtro === 'Estatus'){
      arrayFiltroData = new MatTableDataSource(this.dataSourceShow.filteredData.filter(elemento => (
        (elemento.estado == filtroEstatus ) || (filtroEstatus == 99)
        )));
    }
    else{
      arrayFiltroData = new MatTableDataSource(this.dataSourceShow.filteredData.filter(elemento => (
        (elemento.estatusGerencia == filtroEstatus ) || (filtroEstatus == 99)
        )));
    }

    this.dataSourceShow = new MatTableDataSource(arrayFiltroData.filteredData);

    this.array = this.dataSourceShow.filteredData;
    this.totalSize = this.dataSourceShow.filteredData.length;
    this.iterator();
    this.dataSourceShow.sort = this.sort;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(0, this.totalSize);
    this.dataSourceShow = new MatTableDataSource(part);
    this.dataSourceShow.sort = this.sort;
    this.dataSourceShow.paginator = this.paginator;
  }

  // =====================
  // CONSILTA DE SERVICIOS
  // =====================

  getCotizacionesAll(){
    this._quotationservice.getQuotationAll().subscribe(
      res=> {
        this.datasourceCotizaciones = res;
  
      },
      error => console.log("error consulta proyectos",error)
    )
  }

  getcotizacionesDetail(cotizacion_id : any, element){
    this._quotationservice.getQuotationDetail(cotizacion_id).subscribe(
      res=> {
        this.datasourceCotizacionesDetalle = res;
        // this.downloadAsPDF(element);
  
      },
      error => console.log("error consulta proyectos",error)
    )
  }
  getProveedores(){

    let arrayProvider : any [];

    arrayProvider = [];
    arrayProvider.push({proveedor_id : 1, proveedor_nombre : 'CABLES MONTERREY SA DE CV'},
                    {proveedor_id : 2, proveedor_nombre : 'VENTA DE CABLEADO SA'}
                    )

    this.datasourcePoveedores = arrayProvider;

  }


  getPO_Hdr(){

    let arraySort: any;
    this.isloading = true;
    // Proyectos registrados
    this._purchaseOrderService.getPOAll().subscribe(
      res=> {
        console.log('PurchaseOrder', res);
        this.isloading = false;
        // Ordenado de arreglo
        arraySort = res.sort(function (a, b) {
          if (a.ordendecompra_id < b.ordendecompra_id) {
            return 1;
          }
          if (a.ordendecompra_id > b.ordendecompra_id) {
            return -1;
          }
          return 0;
        });

        this.dataSourceShowOriginal = new MatTableDataSource(arraySort);
        this.emitFilters(3, 'Estatus')
        
        console.log('originaloriginaloriginal', this.dataSourceShowOriginal)
      },
      error => console.log("error consulta regiones",error)
    )
  }

  cancelODCToDB(element){
    this._purchaseOrderService.putPOCancel(element.ordendecompra_id).subscribe(
      res=> {
        console.log('Se inserto con éxito', res);
        
      },
      error => console.log("error alta de proyectos",error)
    )
  }

  updateODCStatus(element, statusToDb){
    let arrayToDb : any;

    arrayToDb = ({ordendecompra_id : element.ordendecompra_id, estatus : statusToDb, usuario : this.UserIdLogin})

    this._purchaseOrderService.putPOCancel(element.ordendecompra_id).subscribe(
      res=> {
        console.log('Se inserto con éxito', res);
        
        //INSERTA EN BITACORA
        this.insertODCStatusBitacora(element.ordendecompra_id);

        this.getPO_Hdr();
      },
      error => console.log("error alta de proyectos",error)
    )
  }

  insertODCStatusBitacora(po_id : any){
    let arrayToDb : any;

    arrayToDb = ({ordendecompra_id : po_id , estatus : 4, usuario : this.UserIdLogin})

    this._purchaseOrderService.insertPOStatus(arrayToDb).subscribe(
      res=> {
        console.log('Se inserto con éxito', res);
      },
      error => console.log("error alta de proyectos",error)
    )
  }

  getsupplierAll(){
    // Selecciona todos los proveedores
    this._supplyservice.getsupplyAll().subscribe(
      res=> {
        this.datasourcesupplier = res;
      },
      error => console.log("error consulta categorias",error)
    )
  }

  saveTracking(arrayTracking : any){

    this._trackingservice.inserttracking(arrayTracking).subscribe(
      inserta=>{
          console.log('inserta log', inserta);
      },
      error => console.log("error consulta regiones",error)
    )
  }

  // ====================
  // GENERA REPORTE PDF
  // ====================

  getImageDataUrlFromLocalPath1(localPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
        let canvas = document.createElement('canvas');
        let img = new Image();
        img.onload = () => {
            canvas.height = img.height;
            canvas.width = img.width;
            canvas.getContext("2d").drawImage(img, 0, 0);
            resolve(canvas.toDataURL('image/png'));
        }
        img.onerror = () => reject('Imagen no disponible')
        img.src = localPath;
    })

  }

  decode(){
    let token = localStorage.getItem('token_access');
    this.decodedSign = jwt_decode(token)["firma"] + '?alt=media&token='; 
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeId = jwt_decode(token);
    let usuariotoTrack = jwt_decode(token)["usuarioid"];

    this.usuarioId = decodeUser;

    let arrayTracking : any = ({module : 'Orden de compra', userId : usuariotoTrack, operation : 1, oldData : '', newData : 'Consulta listado de ordenes de compra', dateOperation : moment(new Date, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')})
    this.saveTracking(arrayTracking)

    this.getusers();

  }

  datsourcePoDetail : any;

  getPO_Det(element){
    this._purchaseOrderService.getPODetail(element.ordendecompra_id).subscribe(
      res=> {
        console.log('ORDEN DE COMPRA DETALLE', res);

        // Ordena los PO DETAIL
        res.sort(function (a, b) {
          if (a.linea > b.linea) {
            return 1;
          }
          if (a.linea < b.linea) {
            return -1;
          }
          // a must be equal to b
          return 0;
        });

        this.datsourcePoDetail = res;
        // this.downloadAsPDF(element);
  
      },
      error => console.log("error consulta proyectos",error)
    )
  }

  getusers(){
    let arrayUsers : any;
    
    this._UserService.getUsersAll().subscribe(
      res=> {
        this.datasourceUsers = res;
        arrayUsers = this.datasourceUsers.filter(e => e.nombreUsuario == this.usuarioId)
        this.UserIdLogin = Number(arrayUsers[0]["usuarioId"].toString());
      },
      error => console.log("error consulta categorias",error)
    )
  }
  
}
