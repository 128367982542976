import { MediaMatcher } from '@angular/cdk/layout';
import {ChangeDetectorRef, Component,OnDestroy,AfterViewInit} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';
import jwt_decode from "jwt-decode";
import { ponotinvoiceservice } from '../../services/po-not-invoice.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: []
})
export class FullComponent implements OnDestroy, AfterViewInit {
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;
  userName : any;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public _ponotinvoiceservice : ponotinvoiceservice
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
  ngAfterViewInit() {}

  ngOnInit() : void{
   this.decode();
  }

  decode(){

    let token = localStorage.getItem('token_access');
  
    let decodeUser = jwt_decode(token)["username"];
    
    this.userName = decodeUser;

    console.log('DECODEusuario', this.userName)

  }

  getAlert(){
    // let QtyArray :any[] = [];

    // QtyArray.push({QtyPONotInvoice : 2166});

    // this._ponotinvoiceservice.getpoNotInvoiceQty().subscribe(
    //   res=> {
    //     this.QtyPONotInvoice = res[0]["qtyPONotInvoice"];        
    //   },
    //   error => console.log("error consulta regiones",error)
    // )

  }
}
