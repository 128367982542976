<p>Reporte General</p>

    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tabsEncabezado">Generales</span>
            </ng-template>
            <br><span class="tabsEncabezado">Datos Generales</span><br>
            <app-report-master-gen></app-report-master-gen>
        </mat-tab>
    
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tabsEncabezado">Detallado</span>
            </ng-template> 
            <br><span class="tabsEncabezado">Reporte Maestro Detallado </span><br>
            <app-report-master-detail></app-report-master-detail>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tabsEncabezado">Orden de compra</span>
            </ng-template> 
            <br><span class="tabsEncabezado">Descarga de ordenes de compra </span><br>
            <app-po-download></app-po-download>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tabsEncabezado">Orden de compra Sin factura</span>
            </ng-template> 
            <br><span class="tabsEncabezado">Descarga de ordenes de compra Sin Factura </span><br>
            <app-po-not-invoice-report></app-po-not-invoice-report>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tabsEncabezado">Log</span>
            </ng-template> 
            <br><span class="tabsEncabezado">Consulta de log de actividades </span><br>
            <app-binnacle-download></app-binnacle-download>
        </mat-tab>
    </mat-tab-group>



