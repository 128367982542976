<p>{{this.requisicionId}}</p>
<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
        </div>
    </div>
</div>
<div mat-dialog-content-example [formGroup]="newProject">
    <div fxLayout="row" class="division">
         <div fxFlex.gt-sm="100%" *ngIf="estadoPantalla == 'REQ'">
             <div><textarea type="[comentarios]" placeholder="Comentarios" formControlName="comentarios" style="height: 100px; width: 80%;"></textarea> </div>
         </div>
     </div>
     <br>
     <div fxLayout="row" class="division">
         <div fxFlex.gt-sm="20%">
             <div>
                <ng-container *ngIf="estadoPantalla == 'REQ'"><input type="file" name="fuDocumento" id="fuDocumento" (change)="cargarDocumento($event)" hidden><label for="fuDocumento"><i class="fa fa-arrow-up fa-lg icon-cog-blue">[SELECCIONA]</i></label></ng-container>
            </div>
         </div>
         <div fxFlex.gt-sm="20%">
             <div></div>
         </div>
         <div fxFlex.gt-sm="20%">
             <div><button mat-raised-button color="warn" (click)="cancel()"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
         </div>
         <div fxFlex.gt-sm="20%">
             <div></div>
         </div>
         <div fxFlex.gt-sm="20%">
             <div></div>
         </div>
     </div>
     <br><br>
     <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="80%">
            <div>
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                      <div class="card-body card-body-table">
                        <mat-table [dataSource]="requisitionDatasource" matSort class="mat-elevation-z8" style="width: 95%;">
                          
                            <ng-container matColumnDef="id" hidden>
                                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30"> Descripción </th>
                                <td mat-cell *matCellDef="let element" class="column"> {{element.descripcion}} </td>
                            </ng-container>

                          <ng-container matColumnDef="descripcion">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30"> Descripción </th>
                            <td mat-cell *matCellDef="let element" class="column"> {{element.descripcion}} </td>
                          </ng-container>
                          
                          <ng-container matColumnDef="urlDocumento">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> URL </th>
                            <td mat-cell *matCellDef="let element" class="column"> 
                                <ng-container><button class="button" (click)="openFile(element.urlDocumento)"><i class="fa fa-eye fa-lg icon-cog-orangeRed"></i></button></ng-container>&nbsp;&nbsp;
                                <ng-container *ngIf="estadoPantalla == 'REQ'"><button class="button" (click)="deleteFile(element.id)"><i class="fa fa-trash fa-lg icon-cog-red"></i></button></ng-container>
                            </td>
                          </ng-container>
                          
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </mat-table>
                  
                      </div>
                      <!-- <mat-paginator [pageSizeOptions]="[20, 100, 150]" [showFirstLastButtons]="true" [pageSize]="pageSize" [length]="totalSize"  (page)="handlePage($event)" style="width: 95%;"></mat-paginator> -->
                    </div>
                  </div>
            </div>
        </div>
        <div fxFlex.gt-sm="20%">
            <div></div>
        </div>
    </div>
 </div>
