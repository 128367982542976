import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { uomModel } from 'src/app/models/uom.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})


export class uomservice {

    constructor(protected http: HttpClient) { }
  
      getuomAll() {
          const headers : HttpHeaders = new HttpHeaders()
              .set('Content-Type', 'application/json')
            //   .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
              
          return this.http.get<uomModel[]>(environment.urlapi + environment.apiContextDrivers + 'Uom', {headers});
      }
  
  }