import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { requisitionModel } from 'src/app/models/requisition.model';
import { requisitionservice } from 'src/app/services/requisition/requisition.service';
import { RequisitionDetailComponent } from 'src/app/components/requisitions/requisition-detail/requisition-detail.component';
import * as moment from 'moment';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { trackingservice } from '../../../services/tracking.service';
import jwt_decode from "jwt-decode";
import { pdfPrints } from '../../../components/pdfModule/pdf-print';

@Component({
  selector: 'app-requisition-list',
  templateUrl: './requisition-list.component.html',
  styleUrls: ['./requisition-list.component.css']
})

export class RequisitionListComponent implements OnInit {
// =================
// DECLARACIONES
// =================

// Para paginación
fEstatus : any = '0';
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<requisitionModel>;
dataSourceShowOriginal : MatTableDataSource<requisitionModel>;
user : any = jwt_decode(localStorage.getItem('token_access'))["usuario"];
usuarioId: string = '';

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  displayedColumns = ['codigo_proyectocategoria', 'codigo', 'fecha', 'estado', 'editar', 'imprimir', 'comentario'];
  
  constructor(public dialog: MatDialog
          , private _excelService : ExcelServiceService
          , private _requisitionService : requisitionservice
          , private _trackingservice : trackingservice
          , private _pdfPrints : pdfPrints) { }


  // =================
  // PROCEDIMIENTOS
  // =================

  ngOnInit(): void {

console.log('suaurioxxx', this.user)

    this.getrequisition();
    this.decode();
  }

  decode(){
    let token = localStorage.getItem('token_access');
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeProveedorId = jwt_decode(token)["proveedor_id"];
    let usuariotoTrack = jwt_decode(token)["usuarioid"];
    
    this.usuarioId = usuariotoTrack;
    let arrayTracking : any = ({module : 'Requisiciones', userId : usuariotoTrack, operation : 1, oldData : '', newData : 'Consulta listado de requisiciones', dateOperation : moment(new Date, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')})
    this.saveTracking(arrayTracking)
  }

  descargarExcel(){
    let dataSourceShowToExcel : any[] = [];

  this.dataSourceShow.filteredData.forEach(element => {
    dataSourceShowToExcel.push({
                              // proyecto_id : element.proyecto_id
                              categoria : element.codigo_proyectocategoria
                              , requisicion : element.requisicioninterna_id
                              , Fecha_Requisicion : moment(element.Fecha_Requisicion, 'YYYY-MM-DD').format('DD-MM-YYYY')
                              , estatus : element.estado
      })
    });

    this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Requisicones');  
  }

  nuevaRequisicion(evetn){
    
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'REQUISICIONES',
      arrayData : null,
      requisicionId: 0,
      estadoPantalla : 'new'
     
    }
    dialogConfig.width = '1300px';
    dialogConfig.height = '1200px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(RequisitionDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getrequisition();
    });
  }

  editRequisicion(element){
    
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'REQUISICIONES',
      arrayData : element,
      requisicionId: element.requisicioninterna_id,
      estadoPantalla : 'edit'
     
    }
    dialogConfig.width = '1200px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(RequisitionDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getrequisition();
    });
  }

  printRequisicion(element){
    
    console.log('PDF element', element)
    this._pdfPrints.pdfRequisitionPrintOnInit(element);
  
  }


  filtrar(event : Event){
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceShow.filter = filtro.trim().toLowerCase();
  }

  // =================
  // UTILERIAS
  // =================

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(0, this.totalSize);
    this.dataSourceShow = new MatTableDataSource(part);
    this.dataSourceShow.sort = this.sort;
    this.dataSourceShow.paginator = this.paginator;
  }

  emitFilters(event, filtro) {
    this.dataSourceShow = this.dataSourceShowOriginal;
    let filtroEstatus : number = 0;
    let arrayFiltroData : MatTableDataSource<requisitionModel> = null;

    if(filtro === 'Estatus'){
      filtroEstatus = (filtroEstatus == undefined) ? 0 : event.value;
      filtroEstatus = (event.value == undefined) ? 0 : filtroEstatus;
    }

    arrayFiltroData = new MatTableDataSource(this.dataSourceShow.filteredData.filter(elemento => (
                                                                                  (elemento.estado == filtroEstatus ) || (filtroEstatus == 99)
                                                                                   )));

    this.dataSourceShow = new MatTableDataSource(arrayFiltroData.filteredData);

    this.array = this.dataSourceShow.filteredData;
    this.totalSize = this.dataSourceShow.filteredData.length;
    this.iterator();
    this.dataSourceShow.sort = this.sort;
  }

  // =================
  // SERVICIOS
  // =================

  getrequisition(){
    
    let arraySort: any;
    let arraySortFiltered: any;

    // Proyectos registrados
    this._requisitionService.getRequisitionAll().subscribe(
      res=> {
        console.log('PRINT REQUSITION', res)      
        // Ordenado de arreglo
        arraySort = res.sort(function (a, b) {
          if (a.requisicioninterna_id < b.requisicioninterna_id) {
            return 1;
          }
          if (a.requisicioninterna_id > b.requisicioninterna_id) {
            return -1;
          }
          return 0;
        });

        // this.dataSourceShowOriginal = new MatTableDataSource(arraySort);
        // this.dataSourceShowOriginal.filteredData = arraySort.filter(e => e.usuarioCrea == this.user || this.user == 'root')        

        arraySortFiltered = arraySort.filter(e => e.usuarioCrea == this.user || this.user == 'root');

        this.dataSourceShowOriginal = new MatTableDataSource(arraySortFiltered);

        console.log('requisiciones 3', this.dataSourceShowOriginal);

        this.emitFilters(0, 'Estatus')
        
      },
      error => console.log("error consulta regiones",error)
    )
  }

  saveTracking(arrayTracking : any){

    this._trackingservice.inserttracking(arrayTracking).subscribe(
      inserta=>{
          console.log('inserta log', inserta);
      },
      error => console.log("error consulta regiones",error)
    )
  }

}
