<p>Reporte General</p>
    <mat-tab-group>
        <mat-tab>
            <ng-template mat-tab-label>
                <span class="tabsEncabezado">Proveedores Vencidos</span>
            </ng-template> 
            <br><span class="tabsEncabezado">Proveedores vencidos </span><br>
            <app-suppliers-out-to-date></app-suppliers-out-to-date>
        </mat-tab>
    </mat-tab-group>
