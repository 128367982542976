<br><br>
<div class="col-12 col-md-12 p-0">
    <div class="card">
      <div class="card-body card-body-table">
        <mat-table [dataSource]="this.documentShow" matSort class="mat-elevation-z8" style="width: 95%;" #tabla1>
            
          <!-- ID Column -->
          <!-- <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> <span>Id</span> </th>
            <td mat-cell *matCellDef="let element">{{element.id}}
            </td>
          </ng-container> -->

          <!-- ID Column -->
          <ng-container matColumnDef="categoria">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> <span>categoria</span> </th>
            <td mat-cell *matCellDef="let element">{{element.categoria}}
            </td>
          </ng-container>

          <!-- ID Column -->
          <ng-container matColumnDef="url">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" style="width: 300px;"> <span>url</span> </th>
            <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="estadoEstudio == undefined"><button class="button" (click)="openFile(element)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </td>
          </ng-container>
          
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </mat-table>
      </div>
    </div>