<div fxLayout="row">
    <h4>Nueva Orden de Compra</h4>
  </div>
  <br>
  <div mat-dialog-content-example [formGroup]="newProject">

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="20%">
            <div><br>requisición</div>
        </div>
        <div fxFlex.gt-sm="25%" >
          <div><br>
            {{this.requisicionCodigo}}
          </div>
        </div>
        <div fxFlex.gt-sm="10%" >
          <div>
          </div>
        </div>
        <div fxFlex.gt-sm="20%" >
          <div><br>
        </div>
        </div>
        <div fxFlex.gt-sm="25%" >
              <div><br>
                <div *ngIf="this.requisicionId != 0 && this.estado == 0" style="background-color: orange; color: white;">CREADA</div>
                <div *ngIf="this.requisicionId != 0 && this.estado == 1" style="background-color: red; color: white;">RECHAZADA</div>
                <div *ngIf="this.requisicionId != 0 && this.estado == 2" style="background-color: green; color: white;">APROBADA</div>
                <div *ngIf="this.requisicionId != 0"><textarea style="width: 100%;" formControlName="comentario">{{comentario}}</textarea>
                <button mat-raised-button color="accent" (click)="decline()">Rechazar</button> </div>
                <div>
                  <button class="button" (click)="openFilepopup(105)"><i class="fa fa-eye fa-lg icon-cog"></i></button>&nbsp;&nbsp;&nbsp;
                  <button class="button" (click)="openSendmailpopup()"><i class="fa fa-at fa-lg icon-cog-blue"></i></button>
                </div>
            </div>
        </div>
      </div>


    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Cotización</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div><br>
            {{this.cotizacion_Numero}}
         
          <div class="center" style="width: 100%;"><ng-container *ngIf="loading == true"><mat-spinner color="primary"></mat-spinner></ng-container></div>
          
          <span *ngIf="!newProject.get('cotizacion_id').valid && newProject.get('cotizacion_id').touched" class="help-block" style="color: red;"> seleccione una cotización</span>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div>
        </div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>FO</div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div><br>
              <mat-form-field>
                <input [type]="codigo_requisicioninterna" matInput class="form-control form-control-line control" formControlName="codigo_requisicioninterna" (blur)="onBlurFO($event)">
            </mat-form-field>
          </div>
      </div>
    </div>
  
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Orden de compra</div>
      </div>
      <div fxFlex.gt-sm="25%" >
          <div>
            <mat-form-field>
              <input [type]="odc_Numero" matInput class="form-control form-control-line control" formControlName="odc_Numero"> 
            </mat-form-field>
            <span *ngIf="!newProject.get('odc_Numero').valid && newProject.get('odc_Numero').touched" class="help-block" style="color: red;"> seleccione un número de órden de compra</span>
          </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div>
        </div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>Proveedor</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>

          <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Provedor</mat-label>
            <input
              type="text"
              matInput
              [formControl]="proveedor_id"
              [matAutocomplete]="auto"/>

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let op of filteredOptions | async" [value]="op" (selectionChange)="selectsupplier($event)">
                <ng-container *ngIf="op.autorizado == true"> <p style="color: navy;">{{op.nombre}}</p></ng-container>
                <ng-container *ngIf="op.autorizado == false"> <p style="color: red;">{{op.nombre}}</p></ng-container>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <span *ngIf="!newProject.get('proveedor_id').valid && newProject.get('proveedor_id').touched" class="help-block" style="color: red;"> seleccione un proveedor</span>
        </div>
      </div>
    </div>
    
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>IVA</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <mat-select class="form-control control" formControlName="iva" (selectionChange)="ivaSelected(newProject, $event)">
              <mat-option value= "16" selected="selected">16%</mat-option>
              <mat-option value= "10">10%</mat-option>
              <mat-option value= "0">0</mat-option>
            </mat-select>
          </mat-form-field>
          <span *ngIf="!newProject.get('iva').valid && newProject.get('iva').touched" class="help-block" style="color: red;"> seleccione un iva</span>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
      </div>
      <div fxFlex.gt-sm="20%" >
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
              <p style="color:red; font-size: small;">LOS PROVEEDORES EN COLOR ROJO NO SON ELEGIBLES</p>
              <button mat-raised-button color="accent" (click)="addSupplier(newProject, $event)"> + </button>
              <button mat-raised-button color="accent" (click)="reloadSupplier(newProject, $event)"> o </button>
          </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Moneda</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <mat-select class="form-control control" formControlName="moneda" > 
              <mat-option value= "MXN" selected="selected">MXN</mat-option>
              <mat-option value= "USD">USD</mat-option>
            </mat-select>
          </mat-form-field>
          <span *ngIf="!newProject.get('moneda').valid && newProject.get('moneda').touched" class="help-block" style="color: red;"> seleccione una moneda</span>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>
          Descuento global
        </div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
            <mat-form-field>
              <input [type]="descuentoGlobal" matInput class="form-control form-control-line control" formControlName="descuentoGlobal" placeHplder="Descuento global" (change)="modificaDescuento($event)">
            </mat-form-field>
          </div>
      </div>
    </div>

    <!-- ENVIAR A -->
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Nombre Destino</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <input [type]="enviaANombre" matInput class="form-control form-control-line control" formControlName="enviaANombre" placeholder="Nombre Destino">
          </mat-form-field>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div>
          <br>Dirección destino
        </div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
            <mat-form-field>
              <input [type]="enviaADireccion" matInput class="form-control form-control-line control" formControlName="enviaADireccion" placeholder="Dirección Destino">
            </mat-form-field>
            </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Ciudad destino</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <input [type]="enviaACd" matInput class="form-control form-control-line control" formControlName="enviaACd" placeholder="Ciudad destino">
          </mat-form-field>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div>
          <br>Estado destino
        </div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
            <mat-form-field>
              <input [type]="enviaAEstado" matInput class="form-control form-control-line control" formControlName="enviaAEstado" placeholder="Estado destino">
            </mat-form-field>
            </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>CP destino</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <input [type]="destinoCP" matInput class="form-control form-control-line control" formControlName="destinoCP" placeholder="cp destino">
          </mat-form-field>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>Teléfono destino
        </div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
              <mat-form-field>
                <input [type]="enviaATelefono" matInput class="form-control form-control-line control" formControlName="enviaATelefono" placeholder="Teléfono">
              </mat-form-field>
            </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Project Manager</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <input [type]="enviaARequisitor" matInput class="form-control form-control-line control" formControlName="enviaARequisitor" placeholder="Requisitor">
        </mat-form-field>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>Terminos y condiciones
        </div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
              <mat-form-field>
                <input [type]="terminoYCondiciones" matInput class="form-control form-control-line control" formControlName="terminoYCondiciones" placeholder="Términos y condiciones">
              </mat-form-field>
            </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br>Retencion IVA2</div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
          <mat-form-field>
            <input [type]="retencionIVA2" matInput class="form-control form-control-line control" formControlName="retencionIVA2" placeholder="Retencion IVA 2">
        </mat-form-field>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>TRetencion IVA 3
        </div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
              <mat-form-field>
                <input [type]="retencionIVA3" matInput class="form-control form-control-line control" formControlName="retencionIVA3" placeholder="Retencion IVA 3">
              </mat-form-field>
            </div>
      </div>
    </div>
  </div>
  
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br></div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div><br>
          <mat-checkbox color="warn"
            [checked]="chkTIM"
            (click)="$event.stopPropagation()"
            (change)="ActivaTIM(chkTIM, $event)"
            [(ngModel)]="chkTIM">
        </mat-checkbox> &nbsp; TIM
        <br> 
          <mat-checkbox color="warn"
            [checked]="chkRetencion"
            (click)="$event.stopPropagation()"
            (change)="ActivaRetencion(chkRetencion, $event)"
            [(ngModel)]="chkRetencion">
        </mat-checkbox> &nbsp; Retención 4% 
        <br> 
          <mat-checkbox color="warn"
            [checked]="chkISR125"
            (click)="$event.stopPropagation()"
            (change)="ActivaISRRetencion125(chkISR125, $event)"
            [(ngModel)]="chkISR125">
        </mat-checkbox> &nbsp; Retención 1.25% 
        <br>
        <mat-checkbox color="warn" disabled
          [checked]="chkContratoOriginal"
          (click)="$event.stopPropagation()"
          (change)="ActivaContratoOriginal(chkContratoOriginal, $event)"
          [(ngModel)]="chkContratoOriginal">
        </mat-checkbox> &nbsp; Contrato Original
        <!-- <br>
        <mat-checkbox color="warn" disabled
          [checked]="chkCodigoDeContrato"
          (click)="$event.stopPropagation()"
          (change)="ActivaCodigoDeContrato(chkCodigoDeContrato, $event)"
          [(ngModel)]="chkCodigoDeContrato">
        </mat-checkbox> &nbsp; Código de Contrato -->
        <br>
        <mat-checkbox color="warn" disabled
          [checked]="chkTMFO"
          (click)="$event.stopPropagation()"
          (change)="ActivaTMFO(chkTMFO, $event)"
          [(ngModel)]="chkTMFO">
        </mat-checkbox> &nbsp; T&M/FO/RFI/DCR
        <!-- <br>
          <mat-checkbox color="warn" disabled
            [checked]="chkEspecificarCargo"
            (click)="$event.stopPropagation()"
            (change)="ActivaEspecificarCargo(chkEspecificarCargo, $event)"
            [(ngModel)]="chkEspecificarCargo">
          </mat-checkbox> &nbsp; Especificar Cargo -->
        <br>
          <mat-checkbox color="warn" disabled
            [checked]="chkCargoAlContratista"
            (click)="$event.stopPropagation()"
            (change)="ActivaCargoAlContratista(chkCargoAlContratista, $event)"
            [(ngModel)]="chkCargoAlContratista">
          </mat-checkbox> &nbsp; Cargo Al Contratista
          <br>
        <mat-form-field>
          <input [type]="NoODC" matInput class="form-control form-control-line control" [(ngModel)]="NoODC" placeholder="No O. C." disabled>
        </mat-form-field>
          <br>
          <mat-form-field>
            <input [type]="IdSolicitante" matInput class="form-control form-control-line control" [(ngModel)]="IdSolicitante" placeholder="Solicitante" disabled>
          </mat-form-field>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div>
        </div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>
          
        </div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
              
            </div>
      </div>
    </div>

    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div><br></div>
      </div>
      <div fxFlex.gt-sm="25%" >
        <div>
        </div>
      </div>
      <div fxFlex.gt-sm="10%" >
        <div>
        </div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><br>
          
        </div>
      </div>
      <div fxFlex.gt-sm="25%" >
            <div>
          
            </div>
      </div>
    </div>

    <!--ESTABA SUBTOTAL IVA TOTAL -->

    <!--Tabla de captura-->
    <div fxLayout="row" class="division">
        
    </div>

    <p style="font-weight: bold;">Detalle orden de compra</p>


  <div fxLayout="row" class="division">
    <div fxFlex.gt-sm="20%">
      <div class="input-group mb-3" *ngIf="estadoPantalla != 'aprove'">
        <!-- <input type="file" name="loadFile" id="loadFile" class="form-control form-control-line" placeholder="SELECCIONA ARCHIVO" style="display: none;" (change)="onFileChange($event)" onclick="this.value=null;">
        <label for="loadFile" style="margin: 0; text-align: left; width: 400px;" class="btn btn-outline-dark"><b style="background-color: orange;">{{nombreArchivo}}</b></label><br> -->
      </div>  
      
    </div>
    <div fxFlex.gt-sm="20%" >
      <div></div>  
    </div>
    <div fxFlex.gt-sm="20%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div></div>
    </div>
    <div fxFlex.gt-sm="20%" >
      <div></div>
    </div>
  </div>
  <br>
    <div fxLayout="row" class="division">
      <div fxFlex.gt-sm="20%">
          <div></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div *ngIf="estadoPantalla == 'aprove'"><button mat-raised-button color="accent" (click)="downloadAsPDF()">PDF</button></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div *ngIf="estadoPantalla == 'aprove'"><button mat-raised-button color="accent" (click)="Authoriza(newProject, $event)">Autorizar</button></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><button mat-raised-button color="accent" (click)="save(newProject, $event)">Guardar</button></div>
      </div>
      <div fxFlex.gt-sm="20%" >
        <div><button mat-raised-button color="warn" (click)="cancel($event)">Cancelar</button></div>
      </div>
    </div>
    <br>
     <!-- INICIA CUERPO DE LA PÁGINA-->
     <div class="col-12 col-md-12 p-0">
      <div class="card">
        <div class="card-body card-body-table" id="AllTable" #AllTable>
          <mat-table [dataSource]="datasourceCotizacionesDetalle" matSort class="mat-elevation-z8" style="width: 95%;" #tabla1>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <div style="width: 100%; text-align: left; vertical-align: middle;">
                        ACTIVAR
                </div>
                  </th>
                  <div style="width: 50px;">
                  <td mat-cell *matCellDef="let element; let i = index">
                    <mat-checkbox color="warn"
                                [checked]="element.activo"
                                (click)="$event.stopPropagation()"
                                (change)="ActivarDesactivar(element.activo, $event)"
                                [(ngModel)]="element.activo"
                                >
                    </mat-checkbox>
                  </td>
                </div>
            </ng-container>
              
          <!-- ID Column -->
          <ng-container matColumnDef="detalle_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> <span>detalle_id</span> </th>
            <td mat-cell *matCellDef="let element"> {{element.requisicioninternadetalle_id}} </td>
          </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="SKU">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> <span>SKU</span> </th>
              <td mat-cell *matCellDef="let element"> {{element.SKU}} </td>
            </ng-container>

            <!-- ID Column -->
            <ng-container matColumnDef="cantidad">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> <span>Cantidad</span> </th>
              <td mat-cell *matCellDef="let element"> {{element.cantidad}} </td>
            </ng-container>
            
            <!-- Name Column -->
            <ng-container matColumnDef="unidad_de_medida">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Unidad de Medida </th>
              <td mat-cell *matCellDef="let element"> {{element.um}} </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="descripcion">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20wrap"> Descripción </th>
              <td mat-cell *matCellDef="let element" class="col20wrap">
                <textarea rows="5" cols="20" wrap="soft" (keyup)="checkEnterKey($event, 'descripcion')" (blur)="onBlurMethod($event)" [(ngModel)]="element.descripcion" >{{element.descripcion}}</textarea>
              </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="medida">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Medida </th>
              <td mat-cell *matCellDef="let element"> {{element.medida}} </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="color">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Color </th>
              <td mat-cell *matCellDef="let element"> {{element.color}} </td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="otras_Especificaciones">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Otras especificaciones </th>
              <td mat-cell *matCellDef="let element"> {{element.otras_especificaciones}} </td>
            </ng-container>

            <!-- cantidad_Ordenar Column -->
            <ng-container matColumnDef="cantidad_Ordenar">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Cantidad seleccionada </th>
                <td mat-cell *matCellDef="let element"> 
                    <input type='cantidad_Ordenar' [(ngModel)]="element.cantidad" (keyup)="checkEnterKey($event, 'presupuesto')" (blur)="onBlurMethod($event)">
                </td>
              </ng-container>

            <!-- precio column -->
            <ng-container matColumnDef="precio_unitario">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Precio Unitario</th>
              <td mat-cell *matCellDef="let element"> 
                  <input type='precio_unitario' [(ngModel)]="element.precio_unitario" (keyup)="checkEnterKey($event, 'precio_unitario')" (blur)="onBlurMethod($event)"> 
              </td>
            </ng-container>

            <!-- descuento column -->
            <ng-container matColumnDef="" >
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20" hidden> Descuento </th>
              <td mat-cell *matCellDef="let element" hidden> 
                  <input type='descuento' [(ngModel)]="element.descuento" (keyup)="checkEnterKey($event, 'descuento')" (blur)="onBlurMethod($event)">
              </td>
            </ng-container>

            <!-- importe column -->
            <ng-container matColumnDef="descuento">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Importe</th>
              <td mat-cell *matCellDef="let element"> {{element.precio_unitario * element.cantidad | currency}}
              </td>
            </ng-container>
            <!-- <ng-container matColumnDef="importe">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Importe </th>
              <td mat-cell *matCellDef="let element"> {{importe | currency}}
              </td>
            </ng-container> -->

            <!-- linea column -->
            <ng-container matColumnDef="linea">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Linea </th>
              <td mat-cell *matCellDef="let element" style="text-align: center;"> {{element.linea}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </mat-table>
    
        </div>
        <!-- <mat-paginator [pageSizeOptions]="[20, 100, 150]" [showFirstLastButtons]="true" [pageSize]="pageSize" [length]="totalSize"  (page)="handlePage($event)" style="width: 95%;"></mat-paginator> -->
        
        <!--SUBTOTAL IVA TOTAL-->
        <br>
        <div fxLayout="row" class="division">
          <div fxFlex.gt-sm="60%">
            <!-- Subtotal: {{subtotal | currency}} -->
          </div>
          <div fxFlex.gt-sm="20%">
            <!-- IVA: {{ivaSubtotal | currency}} -->
          </div>
          <div fxFlex.gt-sm="20%">
            Subtotal: {{subtotal | currency}}
          </div>
        </div>

        <div fxLayout="row" class="division">
          <div fxFlex.gt-sm="60%">
          </div>
          <div fxFlex.gt-sm="20%">
          </div>
          <div fxFlex.gt-sm="20%">
            Descuento: {{descuentoGlobal | currency}}
          </div>
        </div>

        <div fxLayout="row" class="division">
          <div fxFlex.gt-sm="60%">
          </div>
          <div fxFlex.gt-sm="20%">
          </div>
          <div fxFlex.gt-sm="20%">
            IVA: {{ivaSubtotal | currency}}
          </div>
        </div>

        <div fxLayout="row" class="division">
          <div fxFlex.gt-sm="60%">
          </div>
          <div fxFlex.gt-sm="20%">
          </div>
          <div fxFlex.gt-sm="20%">
            Retención 4%: {{retencionMonto | currency}}
          </div>
        </div>

        <div fxLayout="row" class="division">
          <div fxFlex.gt-sm="60%">
          </div>
          <div fxFlex.gt-sm="20%">
          </div>
          <div fxFlex.gt-sm="20%">
            Retención 1.25%: {{ISRRetencion125Monto | currency}}
          </div>
        </div>

        <div fxLayout="row" class="division">
          <div fxFlex.gt-sm="60%">
          </div>
          <div fxFlex.gt-sm="20%">
          </div>
          <div fxFlex.gt-sm="20%">
              Total: {{total | currency}}
          </div>
        </div>
      </div>

      <!-- REPORTE DE ORDEN DE COMPRA -->

      <div class="container" hidden>
        <div id="pdfTable" #pdfTable>
          <!-- <h4>COMERCIAL CONTINENTAL SA DE CV</h4> -->

          <table width="100%">
            <tr>
              <td width="20%">LOGO</td>
              <td width="60%">COMERCIAL SA DE CV</td>
              <td width="20%">Orden de compra</td>
            </tr>
          </table>

          <table  id="contentToConvert" width="100%" border="0">
            <tr>
              <td style="width: 300px;">LOGO</td><!--LOGO-->
              <td style="width: 1000px;">COMERCIAL DE MEXICO</td><!--LOGO-->
              <td style="width: 300px;">ORDEN DE COMPRA</td><!--ORDEN DE COMPRA-->
            </tr>
            <tr>
              <td style="width: 300px;">LOGO</td><!--LOGO-->
              <td style="width: 1000px;">COMERCIAL DE MEXICO</td><!--LOGO-->
              <td style="width: 300px;">ORDEN DE COMPRA</td><!--ORDEN DE COMPRA-->
            </tr>
            <tr>
              <td style="width: 300px;">LOGO</td><!--LOGO-->
              <td style="width: 1000px;">COMERCIAL DE MEXICO</td><!--LOGO-->
              <td style="width: 300px;">ORDEN DE COMPRA</td><!--ORDEN DE COMPRA-->
            </tr>
          </table>


          <div class="card-body card-body-table" id="AllTable" #AllTable>
            <mat-table [dataSource]="datasourceCotizacionesDetalle" matSort class="mat-elevation-z8" style="width: 95%;">
  
              <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>
                      <div style="width: 100%; text-align: left; vertical-align: middle;">
                          ACTIVAR
                  </div>
                    </th>
                    <div style="width: 50px;">
                    <td mat-cell *matCellDef="let element; let i = index">
                      <mat-checkbox color="warn"
                                  [checked]="element.activo"
                                  (click)="$event.stopPropagation()"
                                  (change)="ActivarDesactivar(element.activo, $event)"
                                  [(ngModel)]="element.activo"
                                  >
                      </mat-checkbox>
                    </td>
                  </div>
              </ng-container>
                
              <ng-container matColumnDef="detalle_id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> <span>detalle_id</span> </th>
                <td mat-cell *matCellDef="let element"> {{element.sku}} </td>
              </ng-container>

              <!-- ID Column -->
              <ng-container matColumnDef="SKU">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> <span>SKU</span> </th>
                <td mat-cell *matCellDef="let element"> {{element.SKU}} </td>
              </ng-container>
  
              <!-- ID Column -->
              <ng-container matColumnDef="cantidad">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> <span>Cantidad</span> </th>
                <td mat-cell *matCellDef="let element"> {{element.cantidad}} </td>
              </ng-container>
              
              <!-- Name Column -->
              <ng-container matColumnDef="unidad_de_medida">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Unidad de Medida </th>
                <td mat-cell *matCellDef="let element"> {{element.um}} </td>
              </ng-container>
  
              <!-- Email Column -->
              <ng-container matColumnDef="descripcion">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Descripción </th>
                <td mat-cell *matCellDef="let element"> {{element.descripcion}} </td>
              </ng-container>
  
              <!-- Email Column -->
              <ng-container matColumnDef="medida">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Medida </th>
                <td mat-cell *matCellDef="let element"> {{element.medida}} </td>
              </ng-container>
  
              <!-- Email Column -->
              <ng-container matColumnDef="color">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Color </th>
                <td mat-cell *matCellDef="let element"> {{element.color}} </td>
              </ng-container>
  
              <!-- Email Column -->
              <ng-container matColumnDef="otras_Especificaciones">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Otras especificaciones </th>
                <td mat-cell *matCellDef="let element"> {{element.otras_especificaciones}} </td>
              </ng-container>
  
              <!-- cantidad_Ordenar Column -->
              <ng-container matColumnDef="cantidad_Ordenar">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Cantidad seleccionada </th>
                  <td mat-cell *matCellDef="let element"> 
                      <input type='cantidad_Ordenar' [(ngModel)]="element.cantidad" (keyup)="checkEnterKey($event, 'presupuesto')" (blur)="onBlurMethod($event)" class="form-control form-control-line control input-short">
                  </td>
                </ng-container>
  
              <!-- precio column -->
              <ng-container matColumnDef="precio_unitario">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Precio Unitario</th>
                <td mat-cell *matCellDef="let element"> 
                    <input type='precio_unitario' [(ngModel)]="element.precio_unitario" (keyup)="checkEnterKey($event, 'precio_unitario')" (blur)="onBlurMethod($event)" class="form-control form-control-line control input-short"> 
                </td>
              </ng-container>
  
              <!-- descuento column -->
              <ng-container matColumnDef="descuento">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Descuento </th>
                <td mat-cell *matCellDef="let element"> 
                    <input type='descuento' [(ngModel)]="element.descuento" (keyup)="checkEnterKey($event, 'descuento')" (blur)="onBlurMethod($event)" class="form-control form-control-line control input-short">
                </td>
              </ng-container>

              <!-- descuento column -->
              <ng-container matColumnDef="linea">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> linea </th>
                <td mat-cell *matCellDef="let element"> 
                    <input type='linea' [(ngModel)]="element.linea" (keyup)="checkEnterKey($event, 'linea')" (blur)="onBlurMethod($event)" class="form-control form-control-line control input-short">
                </td>
              </ng-container>

  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </mat-table>
      
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="loading">
      <app-loader-global></app-loader-global>
  </div>