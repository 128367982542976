import { CompileShallowModuleMetadata, IfStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { BREAKPOINT } from '@angular/flex-layout';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import * as e from 'cors';
import { decode } from 'querystring';
import { UploadFileService } from 'src/app/services/upload-file/upload-file.service';
import { employeservice } from '../../../../services/employe.service';
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2';
import { Console } from 'console';
import * as moment from 'moment';
import { dateInputsHaveChanged } from '@angular/material/datepicker/datepicker-input-base';

@Component({
  selector: 'app-employee-capture-personal',
  templateUrl: './employee-capture-personal.component.html',
  styleUrls: ['./employee-capture-personal.component.css']
})
export class EmployeeCapturePersonalComponent implements OnInit {

// =========================
// DECLARACIONES
// =========================
nombre : string;
aPaterno : string;
aMaterno : string;
nss : string;
rfc : string;
curp : string;
sexo : string;
fechaNacimiento : string;
lugarNacimientoEstado : string;
nacionalidad : string;
numeroLicencia : string;
creditoInfonavit : string;
creditoFonacot : string;
telefonoFijo : string;
celular : string;
correo : string;
nivelEstudios : string;
estudiosActuales : string;
clabe : string;
institucion : string;
employeeId : number = 0;
usuarioId : number = 0;

estadoNSS : any;
estadoRFC : any;
estadoCURP : any;
estadoactaDeNacimiento : any;
estadoINE : any;
estadoCLABE : any;
estadofotografia : any;
estadocertificadoCOVID : any;
estadocertificadoCOVID2 : any;
estadocertificadoCOVID3 : any;
estadoestadoDeCuentaAFORE : any;
estadocartaDeNoAntecedentes : any;
estadoCuenta : any;
estadoEstudio : any;

// Variables de carga de archivos
urlNSS : any;
urlRFC : any;
urlCURP : any;
urlactaDeNacimiento : any;
urlINE : any;
urlCLABE : any;
urlfotografia : any;
urlcertificadoCOVID : any;
urlcertificadoCOVID2 : any;
urlcertificadoCOVID3 : any;
urlestadoDeCuentaAFORE : any;
urlcartaDeNoAntecedentes : any;
urlCuenta : any;
urlEstudio : any;
numeroEmpleado : any;

tipoImagenFile : any = [];
dataSourceEmployee : any;
arrayDocumentosEmployee : any;
arrayDocumentosEmployeeOrigen : any;

public newProject: FormGroup;

  constructor(
    public dialog: MatDialog
    , private _employeservice : employeservice
    , private formBuilder: FormBuilder
    , private _snackBar : MatSnackBar
    , private readonly _uploadFileService: UploadFileService
  ) { 
    this.newProject = this.formBuilder.group({
      nombre :  new FormControl('', [Validators.required]),
      aPaterno: new FormControl('', [Validators.required]),
      aMaterno: new FormControl('', [Validators.required]),
      nss: new FormControl('', [Validators.required]),
      rfc: new FormControl('', [Validators.required]),
      curp: new FormControl('', [Validators.required]),
      sexo: new FormControl('', [Validators.required]),
      fechaNacimiento: new FormControl('', [Validators.required]),
      lugarNacimientoEstado: new FormControl('', [Validators.required]),
      nacionalidad: new FormControl('', [Validators.required]),
      numeroLicencia: new FormControl('', [Validators.required]),
      creditoInfonavit: new FormControl('', [Validators.required]),
      creditoFonacot: new FormControl('', [Validators.required]),
      telefonoFijo: new FormControl('', [Validators.required]),
      celular: new FormControl('', [Validators.required]),
      correo: new FormControl('', [Validators.required]),
      nivelEstudios: new FormControl('', [Validators.required]),
      estudiosActuales: new FormControl('', [Validators.required]),
      clabe: new FormControl('', [Validators.required]),
      institucion: new FormControl('', [Validators.required])
    });
  }

// =========================
// PROCEDIMIENTOS
// =========================

  ngOnInit(): void {
    this.decode();

    this.getDocumentsAll(0);

    this.tipoImagenFile = [
      {id : 1001, categoria : 'nss'}
    ,{id : 1002, categoria : 'rfc'}
    ,{id : 1003, categoria : 'curp'}
    ,{id : 1004, categoria : 'actaDeNacimiento'}
    ,{id : 1005, categoria : 'ine'}
    ,{id : 1006, categoria : 'clabe'}
    ,{id : 1007, categoria : 'fotografia'}
    ,{id : 1008, categoria : 'cartaDeNoAntecedentes'}
    ,{id : 1009, categoria : 'certificadoCOVID'}
    ,{id : 1010, categoria : 'estadoDeCuentaAFORE'}
    ,{id : 1011, categoria : 'certificadoCOVID2'}
    ,{id : 1012, categoria : 'certificadoCOVID3'}
    ,{id : 1013, categoria : 'cuenta'}
    ,{id : 1014, categoria : 'estudio'}
    ]

  }
  
  cancel(event){
  //  this.dialogRef.close();
  }

  save(form, event){

    let arrayToDb : any;

    arrayToDb = ({ id : this.employeeId
        , numeroEmpleado : this.numeroEmpleado
        , nombre : form.controls["nombre"].value
        , aPaterno : form.controls["aPaterno"].value
        , aMaterno : form.controls["aMaterno"].value
        , estadoCivil : 0
        , nss : form.controls["nss"].value 
        , rfc : form.controls["rfc"].value 
        , curp : form.controls["curp"].value
        , sexo : form.controls["sexo"].value 
        , fechaNacimiento : form.controls["fechaNacimiento"].value
        , lugarNacimientoEstado : form.controls["lugarNacimientoEstado"].value
        , nacionalidad : Number(form.controls["nacionalidad"].value)
        , numeroLicencia : form.controls["numeroLicencia"].value
        , creditoInfonavit : form.controls["creditoInfonavit"].value
        , creditoFonacot : form.controls["creditoFonacot"].value
        , telefonoFijo : form.controls["telefonoFijo"].value
        , celular : form.controls["celular"].value
        , correo : form.controls["correo"].value
        , nivelEstudios : form.controls["nivelEstudios"].value
        , estudiosActuales : form.controls["estudiosActuales"].value
        , institucion : form.controls["institucion"].value
        , estatus : 1

      });

console.warn('asasasasa', arrayToDb);

      this.updateEmployee(arrayToDb);
    
  }

  addFile(concepto: any, event){
    console.log('SE CARGA', concepto);
  }

  addView(concepto: any, event ){
    console.log('VISUALIZA', concepto);
  }

  fechaInicial(event){

  }

// =========================
// UTILERIAS
// =========================

openFile(documentShow : number){
  this.getDocumentsAll(documentShow);
  // console.log('url', url);
  // window.open(url);
}

opencomments(documentShow : number, element, event){

  let arrayDocuemtnoEnviar : any;

  arrayDocuemtnoEnviar = this.arrayDocumentosEmployeeOrigen.filter(e => e.idEmpleado == this.employeeId 
                                                                  && e.categoriaDocumento == documentShow);

  this.showMessage(1, 'Comentario', 'info', arrayDocuemtnoEnviar[0]["comentarios"], 'Cerrar');

}

openSnackBar(message: string, action: string) {
  this._snackBar.open(message, action, {duration : 3000, horizontalPosition: "center", verticalPosition: "top", panelClass: 'alert-snackbar'});
}

decode(){
  let token = localStorage.getItem('token_access');
  let decodeUser = jwt_decode(token)["usuario"];
  let decodeProveedorId = jwt_decode(token)["proveedor_id"];
  this.usuarioId = decodeUser;
  this.employeeId = decodeProveedorId;
  this.getemployeeAll(decodeUser);
}

showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
  switch(tipoMensaje){
    case(1) : 
        Swal.fire({
          title: header,
          html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
          icon: icon,
          confirmButtonText: buttonCaption,
          customClass: {
              confirmButton: 'btn  btn-rounded btn-outline-warning'
          }
        })
      break;
    case(2) :
        Swal.fire({
          position: 'top-end',
          icon: icon,
          title: message,
          showConfirmButton: false,
          timer: 1500
        })
      break;
  }
}

// =========================
// SERVICIOS
// =========================
imagenes: any[] = [];
  cargarImagen(form, event: any, tipoImagen : string, grupoImagen : string, estado : any, categoriaImagen : any) {

    let archivos = event.target.files;
    let nombre = event.target.files.name;
    let arrayToDb : any;
    let year : number = 2022;
    let month : number = 1;

    for (let i = 0; i < archivos.length; i++) {

      let reader = new FileReader();
      reader.readAsDataURL(archivos[0]);
      reader.onloadend = () => {
        this.imagenes.push(reader.result); 

        this._uploadFileService.subirImagenEmpleados(tipoImagen + '_' + event.target.files[0]["name"], reader.result, grupoImagen, this.employeeId, year , month).then(urlImagen => {

          let arrayCategoriaFiltrado = this.tipoImagenFile.filter(e => e.categoria == tipoImagen);
          let idDocumento : number = 0;

          arrayToDb = {
                        idDocumento : idDocumento
                        , idProveedor : this.employeeId
                        , idEmpleado : this.employeeId
                        , idFamiliar : 0
                        , categoriaDocumento : arrayCategoriaFiltrado[0]["id"]
                        , urlDocumento : urlImagen.toString()
                        , anno : 2022
                        , mes : 1
                        , estado : 0
                        , descripcion : ''};

          this._uploadFileService.postUploadDocumentsEmployeeToDb(arrayToDb).subscribe(
            res=> {
              console.log('DOCUMENTOS', res);
            },
            error => console.log("error al actualizar proveedores",error)
          );

          switch (tipoImagen){
            case('nss'): this.urlNSS = urlImagen.toString();
              break;
            case('rfc'): this.urlRFC = urlImagen.toString();
              break;
            case('curp'): this.urlCURP = urlImagen.toString();
              break;
            case('actaDeNacimiento'): this.urlactaDeNacimiento = urlImagen.toString();
              break;
            case('ine'): this.urlINE = urlImagen.toString();
              break;
            case('clabe'): this.urlCLABE = urlImagen.toString();
              break;
            case('fotografia'): this.urlfotografia = urlImagen.toString();
              break;
            case('cartaDeNoAntecedentes'): this.urlcartaDeNoAntecedentes = urlImagen.toString();
              break;
            case('certificadoCOVID'): this.urlcertificadoCOVID = urlImagen.toString();
              break;
            case('estadoDeCuentaAFORE'): this.urlestadoDeCuentaAFORE = urlImagen.toString();
              break;
            case('certificadoCOVID2'): this.urlcertificadoCOVID2 = urlImagen.toString();
              break;
            case('certificadoCOVID3'): this.urlcertificadoCOVID3 = urlImagen.toString();
              break;
            case('cuenta'): this.urlCuenta = urlImagen.toString();
              break;
            case('estudio'): this.urlEstudio = urlImagen.toString();
              break;
          }
        
          this.showMessage(2, 'Exitoso', 'success', 'Se cargo exitosamente el archivo', 'Cerrar');

          // this.getDocumentsAll();

          // this.openSnackBar('Se cargo exitosamente el archivo', '');

          // guarda en base de datos
          // arrayToDb.push({id : 1, })
          // this.insertFilesToDb(arrayToDb);

        });
      }
    }
  }

// =========================
// SERVICIOS
// =========================
getDocumentsAll(documentShow : number){
  let arrayDocumentos : any;
    let anio : number = 0;
    let mes : number = 0;
                    
    this._uploadFileService.getemployeeDocumentsAll().subscribe(
      res=> {

        arrayDocumentos = res;

        this.arrayDocumentosEmployee = null;

        if(documentShow == 0)
        this.arrayDocumentosEmployee = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId); //  && e.categoriaDocumento > 1000 && e.categoriaDocumento < 2000
        else
          this.arrayDocumentosEmployee = arrayDocumentos.filter(e => e.idEmpleado == this.employeeId && e.categoriaDocumento == documentShow)

        if(documentShow == 0){
        this.arrayDocumentosEmployee.forEach(element => {
          switch(element.categoriaDocumento){
            case(1001): this.estadoNSS = element.estado; break;
            case(1002): this.estadoRFC = element.estado; break;
            case(1003): this.estadoCURP = element.estado; break;
            case(1004): this.estadoactaDeNacimiento = element.estado; break;
            case(1005): this.estadoINE = element.estado; break;
            case(1006): this.estadoCLABE = element.estado; break;
            case(1007): this.estadofotografia = element.estado; break;
            case(1008): this.estadocartaDeNoAntecedentes = element.estado; break;
            case(1009): this.estadocertificadoCOVID = element.estado; break;
            case(1010): this.estadoestadoDeCuentaAFORE = element.estado; break;
            case(1011): this.estadocertificadoCOVID2 = element.estado; break;
            case(1012): this.estadocertificadoCOVID3 = element.estado; break;
            case(1013): this.estadoCuenta = element.estado; break;
            case(1014): this.estadoEstudio = element.estado; break;
          }
        });
      }

      if(this.arrayDocumentosEmployeeOrigen == undefined)
        this.arrayDocumentosEmployeeOrigen = this.arrayDocumentosEmployee;

        if(documentShow != 0){
          if(this.arrayDocumentosEmployee != undefined && this.arrayDocumentosEmployee.length > 0)
            window.open(this.arrayDocumentosEmployee[0]["urlDocumento"]);
          else
            this.openSnackBar('Documento no encontrado', '');
        }
      },
      error => console.log("error consulta regiones",error)
    )
}

  getemployeeAll(rfc : string){

    let arrayDocumentos : any;
    let arrayDocumentosFiltrados : any;
    let anio : number = 0;
    let mes : number = 0;

    // Empleados registrados
    this._employeservice.getemployeAll().subscribe(
      res=> {
        let arrayUserId : any[] = res.filter(e => e.rfc == rfc);

        if(arrayUserId.length > 0 ){

          this.employeeId = arrayUserId[0].id;
          this.numeroEmpleado = arrayUserId[0].numeroEmpleado;

          // Empleados registrados
          this._employeservice.getemployeeById(arrayUserId[0].id).subscribe(
            res=> {
              this.dataSourceEmployee = new MatTableDataSource(res);
              arrayDocumentos = res;

              this.newProject.controls["nombre"].setValue(this.dataSourceEmployee.filteredData.nombre);
              this.newProject.controls["aPaterno"].setValue(this.dataSourceEmployee.filteredData.aPaterno);
              this.newProject.controls["aMaterno"].setValue(this.dataSourceEmployee.filteredData.aMaterno);
              this.newProject.controls["nss"].setValue(this.dataSourceEmployee.filteredData.nss);
              this.newProject.controls["rfc"].setValue(this.dataSourceEmployee.filteredData.rfc);
              this.newProject.controls["curp"].setValue(this.dataSourceEmployee.filteredData.curp);
              this.newProject.controls["sexo"].setValue(this.dataSourceEmployee.filteredData.sexo.toString());
              this.newProject.controls["fechaNacimiento"].setValue(this.dataSourceEmployee.filteredData.fechaNacimiento);
              this.newProject.controls["lugarNacimientoEstado"].setValue(this.dataSourceEmployee.filteredData.lugarNacimientoEstado);
              this.newProject.controls["nacionalidad"].setValue(this.dataSourceEmployee.filteredData.nacionalidad.toString());
              // this.newProject.controls["nacionalidad"].setValue('1');
              this.newProject.controls["numeroLicencia"].setValue(this.dataSourceEmployee.filteredData.numeroLicencia);
              this.newProject.controls["creditoInfonavit"].setValue(this.dataSourceEmployee.filteredData.creditoInfonavit.toString());
              this.newProject.controls["creditoFonacot"].setValue(this.dataSourceEmployee.filteredData.creditoFonacot);
              this.newProject.controls["telefonoFijo"].setValue(this.dataSourceEmployee.filteredData.telefonoFijo);
              this.newProject.controls["celular"].setValue(this.dataSourceEmployee.filteredData.celular.toString());
              this.newProject.controls["correo"].setValue(this.dataSourceEmployee.filteredData.correo.toString());
              this.newProject.controls["nivelEstudios"].setValue(this.dataSourceEmployee.filteredData.nivelEstudios);
              this.newProject.controls["estudiosActuales"].setValue(this.dataSourceEmployee.filteredData.estudiosActuales); 
              this.newProject.controls["institucion"].setValue(this.dataSourceEmployee.filteredData.institucion);

            },
            error => console.log("error consulta regiones",error)
          )
        }
  },
  error => console.log("error consulta regiones",error)
)

  }

  // showDocument(arrayDocumentosFiltrados : any, categoriaDocumento : number, anio: number, mes: number){
  //   let arraySupplier: any[] = [];
  //   let arrayDocumentos : any;
  //   let arrayDocumentoPorProveedor : any;
  //   let urlShow : any = '';
  //   let estadoShow : number = 3;
  //   let idDocumentShow : number = 0;
  
  //   urlShow = '';
  //   estadoShow = 4;
  //   idDocumentShow = 0;
  //   arrayDocumentoPorProveedor = arrayDocumentosFiltrados.find(e => e.categoriaDocumento == categoriaDocumento)
  //   if(arrayDocumentoPorProveedor != undefined){ 
  //     urlShow = (arrayDocumentoPorProveedor.urlDocumento.length > 0) ? arrayDocumentoPorProveedor.urlDocumento : '';
  //     estadoShow = (arrayDocumentoPorProveedor.estado != undefined) ? arrayDocumentoPorProveedor.estado : 0;
  //     idDocumentShow = (arrayDocumentoPorProveedor.idDocumento != undefined) ? arrayDocumentoPorProveedor.idDocumento : 0;
  //   }
    
  //   switch(categoriaDocumento){
  //     case(1001): this.estadoNSS = estadoShow; this.urlNSS = urlShow; break;
  //     case(1002): this.estadoRFC = estadoShow; this.urlRFC = urlShow; break;
  //     case(1003): this.estadoCURP = estadoShow; this.urlCURP = urlShow; break;
  //     case(1004): this.estadoactaDeNacimiento = estadoShow; this.urlactaDeNacimiento = urlShow; break;
  //     case(1005): this.estadoINE = estadoShow; this.urlINE = urlShow; break;
  //     case(1006): this.estadoCLABE = estadoShow; this.urlCLABE = urlShow; break;
  //     case(1007): this.estadofotografia = estadoShow; this.urlfotografia = urlShow; break;
  //     case(1008): this.estadocartaDeNoAntecedentes = estadoShow; this.urlcartaDeNoAntecedentes = urlShow; break;
  //     case(1009): this.estadocertificadoCOVID = estadoShow; this.urlcertificadoCOVID = urlShow; break;
  //     case(1010): this.estadoestadoDeCuentaAFORE = estadoShow; this.urlestadoDeCuentaAFORE = urlShow; break;
  //   }

  // }

  updateEmployee(arrayToDb : any){
    // Proyectos registrados
    this._employeservice.updateemployee(arrayToDb).subscribe(
      res=> {
        this.showMessage(2, 'Exitoso', 'success', 'Actualización de registro exitosa', 'Cerrar');
      },
      error => console.log("error consulta regiones",error)
    )
  }
}
