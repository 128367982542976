import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { poModel } from 'src/app/models/po.model';
import { purchaseOrderservice } from 'src/app/services/PurchaseOrder.service';
import { PoDetailComponent } from 'src/app/components/po-detail/po-detail.component';
import { InvoiceDetailCaptureComponent } from 'src/app/components/invoice/invoice-detail-capture/invoice-detail-capture.component';
import jwt_decode from "jwt-decode";
import { supplyservice } from '../../../services/supplier.service';
import { quotationservice  } from 'src/app/services/quotation/quotation.service';
import { UserService } from '../../../services/user.service';
import Swal from 'sweetalert2';
import { trackingservice } from '../../../services/tracking.service';
import { ponotinvoiceservice } from '../../../services/po-not-invoice.service';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

@Component({
  selector: 'app-po-not-invoice',
  templateUrl: './po-not-invoice.component.html',
  styleUrls: ['./po-not-invoice.component.css']
})
export class PoNotInvoiceComponent implements OnInit {

  // =====================
  // DECLARACIONES
  // =====================

// Para paginación
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<any>
dataSourceShowOriginal : MatTableDataSource<poModel>

  @ViewChild('pdfTable') pdfTable: ElementRef;
  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  displayedColumns = ['ordendecompra_id', 'ordendecompra_codigo', 'ordendecompra_fecha', 'proveedor_nombre', 'Estatus', 'Cancelar', 'Captura'];
  
  buscar:any;
  decodedSign : any;
  usuarioId : any;
  datasourcesupplier : any;
  datasourcePoveedores : any[] = [];
  datasourceCotizaciones : any;
  datasourceCotizacionesDetalle : any;
  UserIdLogin : number;
  datasourceUsers : any;
  fEstatus : any = '3'
  ISRRetencion125Monto : number = 0;
  profile : any = jwt_decode(localStorage.getItem('token_access'))["perfil_usuarioid"];
  isloading : boolean = false;

  constructor(public dialog: MatDialog
          , private _excelService : ExcelServiceService
          , private _purchaseOrderService : purchaseOrderservice
          , private _supplyservice : supplyservice
          , private _quotationservice : quotationservice
          , private _UserService : UserService
          , private _trackingservice : trackingservice
          , public _ponotinvoiceservice : ponotinvoiceservice) { }


  // =====================
  // PROCEDIMIENTOS
  // =====================

  ngOnInit(): void {

    this.getPO_NotInvoice();
    this.decode();
  }

  descargarExcel(){
    let dataSourceShowToExcel : any[] = [];

    this.dataSourceShow.filteredData.forEach(element => {
    dataSourceShowToExcel.push({                                
                                  odc_Id : element.ordendecompra_id
                                , odc_codigo : element.ordendecompra_codigo
                                , proveedor : element.proveedor_nombre
                                , fecha : element.ordendecompra_fecha
                                // , monto : element.total_odc
                                // , moneda : element.moneda
                                // , estatus : element.estatus
      })
    });

    this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Requisicones');  
  }

  nuevaOrdenDeCompra(event){
    
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'ORDEN DE COMPRA',
      arrayData : null,
      requisicionId: 1,
      estadoPantalla : 'new'
     
    }
    dialogConfig.width = '1500px';
    dialogConfig.height = '9000px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(PoDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPO_Hdr();
    });
  }

  editODC(element, event){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'ORDEN DE COMPRA',
      arrayData : element,
      requisicionId: 1,
      estadoPantalla : 'edition'
     
    }
    dialogConfig.width = '1500px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(PoDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPO_Hdr();
    });
  }

  aproveODC(element, event){

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'ORDEN DE COMPRA',
      arrayData : element,
      requisicionId: 1,
      estadoPantalla : 'aprove',
     
    }
    dialogConfig.width = '1500px';
    dialogConfig.height = '9000px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(PoDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getPO_Hdr();
    });
  }

  cancelaODC(element, event : Event){
    this.updateODCStatus(element, 4);
  }

  CapturaFactura(element, event){

    let arrayBilling : any;
    arrayBilling = {estatus : 4
                    , moneda : 'MXN'
                    , odc_codigo : element.ordendecompra_codigo
                    , odc_id : element.ordendecompra_id
                    , proveedor : element.proveedor_nombre
                    , total_odc : element.total
                  };

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: element.ordendecompra_id,
      proyecto_id : 38, //this.pageInfo.proyecto_id,
      title: 'FACTURAS',
      arrayData : arrayBilling,
      proveedorId: element.proveedor_id,
      estadoPantalla: 'New'
     
    }
    dialogConfig.width = '1500px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(InvoiceDetailCaptureComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // this.getPO_NotInvoice();
    });
  }

  // =====================
  // UTILERIAS
  // =====================

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceShow.filter = filtro.trim().toLowerCase();
  }

  emitFilters(event, filtro) {
    this.dataSourceShow = this.dataSourceShowOriginal;
    let filtroEstatus : number = 3;
    let arrayFiltroData : MatTableDataSource<poModel> = null;

    if(filtro === 'Estatus'){
      filtroEstatus = (filtroEstatus == undefined) ? 3 : event.value; 
      filtroEstatus = (event.value == undefined) ? 3 : filtroEstatus;
    }

    arrayFiltroData = new MatTableDataSource(this.dataSourceShow.filteredData.filter(elemento => (
                                                                                  (elemento.estado == filtroEstatus ) || (filtroEstatus == 99)
                                                                                   )));

    this.dataSourceShow = new MatTableDataSource(arrayFiltroData.filteredData);

    this.array = this.dataSourceShow.filteredData;
    this.totalSize = this.dataSourceShow.filteredData.length;
    this.iterator();
    this.dataSourceShow.sort = this.sort;
  }

  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(0, this.totalSize);
    this.dataSourceShow = new MatTableDataSource(part);
    this.dataSourceShow.sort = this.sort;
    this.dataSourceShow.paginator = this.paginator;
  }

  // =====================
  // CONSILTA DE SERVICIOS
  // =====================
  getPO_Hdr(){

    let arraySort: any;
    this.isloading = true;
    // Proyectos registrados
    this._purchaseOrderService.getPOAll().subscribe(
      res=> {
        console.log('PurchaseOrder', res);
        this.isloading = false;
        // Ordenado de arreglo
        arraySort = res.sort(function (a, b) {
          if (a.ordendecompra_id < b.ordendecompra_id) {
            return 1;
          }
          if (a.ordendecompra_id > b.ordendecompra_id) {
            return -1;
          }
          return 0;
        });
        
        this.dataSourceShowOriginal = new MatTableDataSource(arraySort);
        this.emitFilters(3, 'Estatus')
        
      },
      error => console.log("error consulta regiones",error)
    )
  }

  updateODCStatus(element, statusToDb){
    let arrayToDb : any;

    arrayToDb = ({ordendecompra_id : element.ordendecompra_id, estatus : statusToDb, usuario : this.UserIdLogin})

    this._purchaseOrderService.putPOCancel(element.ordendecompra_id).subscribe(
      res=> {
        console.log('Se inserto con éxito', res);
        
        //INSERTA EN BITACORA
        this.insertODCStatusBitacora(element.ordendecompra_id);

        this.getPO_Hdr();
      },
      error => console.log("error alta de proyectos",error)
    )
  }

  insertODCStatusBitacora(po_id : any){
    let arrayToDb : any;

    arrayToDb = ({ordendecompra_id : po_id , estatus : 4, usuario : this.UserIdLogin})

    this._purchaseOrderService.insertPOStatus(arrayToDb).subscribe(
      res=> {
        console.log('Se inserto con éxito', res);
      },
      error => console.log("error alta de proyectos",error)
    )
  }

  getPO_NotInvoice(){

    let arraySort: any;
    this.isloading = true;

    // Ordenes de compra sin factura
    this._ponotinvoiceservice.getpoNotInvoiceAll(0,0,'1900-01-01').subscribe(
      res=> {
            this.isloading = false;
            // Ordenado de arreglo
            arraySort = res.sort(function (a, b) {
              if (a.ordendecompra_id < b.ordendecompra_id) {
                return 1;
              }
              if (a.ordendecompra_id > b.ordendecompra_id) {
                return -1;
              }
              return 0;
            });
            
        console.log('PurcaseOrder Not Invoice', arraySort);

        this.dataSourceShow = new MatTableDataSource(arraySort);
        
      },
      error => console.log("error consulta regiones",error)
    )
  }

  cancelODCToDB(element){
    this._purchaseOrderService.putPOCancel(element.ordendecompra_id).subscribe(
      res=> {
        console.log('Se inserto con éxito', res);
        
      },
      error => console.log("error alta de proyectos",error)
    )
  }


  saveTracking(arrayTracking : any){

    this._trackingservice.inserttracking(arrayTracking).subscribe(
      inserta=>{
          console.log('inserta log', inserta);
      },
      error => console.log("error consulta regiones",error)
    )
  }

  // ====================
  // GENERA REPORTE PDF
  // ====================

  decode(){
    let token = localStorage.getItem('token_access');
    this.decodedSign = jwt_decode(token)["firma"] + '?alt=media&token='; 
    let decodeUser = jwt_decode(token)["usuario"];
    let decodeId = jwt_decode(token);
    let usuariotoTrack = jwt_decode(token)["usuarioid"];

    this.usuarioId = decodeUser;

    let arrayTracking : any = ({module : 'Orden de compra', userId : usuariotoTrack, operation : 1, oldData : '', newData : 'Consulta listado de ordenes de compra', dateOperation : moment(new Date, 'YYYY-MM-DD hh:mm:ss').format('YYYY-MM-DD hh:mm:ss')})
    this.saveTracking(arrayTracking)

    this.getusers();

  }

   datsourcePoDetail : any;

  getusers(){
    let arrayUsers : any;
    
    this._UserService.getUsersAll().subscribe(
      res=> {
        this.datasourceUsers = res;
        arrayUsers = this.datasourceUsers.filter(e => e.nombreUsuario == this.usuarioId)
        this.UserIdLogin = Number(arrayUsers[0]["usuarioId"].toString());
      },
      error => console.log("error consulta categorias",error)
    )
  }

}
