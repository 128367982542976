import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { employeModel } from 'src/app/models/employe.model';
import { employeservice } from 'src/app/services/employe.service';
import { EmployeeDetailComponent } from 'src/app/components/employee-detail/employee-detail.component';
import { MatDialogConfig } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { ExcelServiceService } from 'src/app/helpers/excel-service.service';
import { EmployeeUserComponent } from '../../components/employee/employee-user/employee-user.component';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.css']
})
export class EmployeeComponent implements OnInit {

// =================
// DECLARACIONES
// =================

// Para paginación
decodeUserProfile : any;
public pageIndex:number = 0;
public pageSize:number = 20;
public currentPage = 0;
public totalSize:number = 0;
public array: any;
dataSourceShow : MatTableDataSource<employeModel>

  @ViewChild(MatSort,{static:true}) sort: MatSort;
  @ViewChild(MatPaginator,{static:true}) paginator: MatPaginator;
  @Output() filterChange = new EventEmitter();

  displayedColumns = ['id', 'numeroEmpleado', 'nombre', 'aPaterno', 'aMaterno', 'rfc', 'estatus', 'edit', 'users', 'activeInactive'];

  constructor(public dialog: MatDialog
    , private _excelService : ExcelServiceService
    , private _employeservice : employeservice
    , private _UserService : UserService) { }

  // =================
  // PROCEDIMIENTOS
  // =================
  ngOnInit(): void {
    // let header : string = '¡¡¡ALERTA!!!';
    // let icon: any
    // let message : string = 'Funcionalidad temporalmente suspendida, comuniquese con su proveedor de sistemas';
    // let buttonCaption: string = 'OK'
    
    // Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
    this.getemployee();
  }

  descargarExcel(){
    console.log('Descargar a excel');
    let dataSourceShowToExcel : any[] = [];

  this.dataSourceShow.filteredData.forEach(element => {
    console.log('estatus', element)
    dataSourceShowToExcel.push({
                              nombre : element.nombre
                              , direccion : element.direccion
                              , RFC : element.rfc
                              , estado : element.estado
                              , estatus : (element.estatus == 1) ? 'ACTIVO': 'INACTIVO' 
                              , autorizado : (element.autorizado == 1) ? 'AUTORIZADO': 'NO AUTORIZADO'
      })
    });

    this._excelService.exportAsExcelFile(dataSourceShowToExcel, 'Proveedores');  
  }

  newemployee(evetn){
    // let header : string = '¡¡¡ALERTA!!!';
    // let icon: any
    // let message : string = 'Funcionalidad temporalmente suspendida, comuniquese con su proveedor de sistemas';
    // let buttonCaption: string = 'OK'
    
    // Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
    // return;
    console.log('Alta de proveedores');

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'PROVEEDOR',
      arrayData : null,
      employeeId: 0,
      estadoPantalla: 'New',
      userProfile : 1
     
    }
    dialogConfig.width = '1300px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(EmployeeDetailComponent , dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      console.log('proveedores');
      window.location.reload();
    });
  }

  edit(element, event){
    // let header : string = '¡¡¡ALERTA!!!';
    // let icon: any
    // let message : string = 'Funcionalidad temporalmente suspendida, comuniquese con su proveedor de sistemas';
    // let buttonCaption: string = 'OK'
    
    // Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
    // return;

    console.log('Editar un empleados', element);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'EMPLEADOS',
      arrayData : element,
      employeeId: element.id,
      estadoPantalla: 'Edit',
      userProfile : this.decodeUserProfile
     
    }
    dialogConfig.width = '1300px';
    dialogConfig.height = '900px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(EmployeeDetailComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.getemployee();
      // window.location.reload();
    });
  }

  users(element, event){
    console.log('Editar un usuario de empleado', element);

    const dialogConfig = new MatDialogConfig();

    dialogConfig.data = {
      id: 1,
      title: 'USUARIOS',
      arrayData : element,
      proveedorId: element.proveedorId,
      estadoPantalla: 'Edit',
      userProfile : this.decodeUserProfile
     
    }
    dialogConfig.width = '500px';
    dialogConfig.height = '500px';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(EmployeeUserComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      // window.location.reload();
    });
  }

  filtrar(event : Event){
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceShow.filter = filtro.trim().toLowerCase();
    console.log('filtro', filtro);
  }

  active(element, event){
    // let header : string = '¡¡¡ALERTA!!!';
    // let icon: any
    // let message : string = 'Funcionalidad temporalmente suspendida, comuniquese con su proveedor de sistemas';
    // let buttonCaption: string = 'OK'
    
    // Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
    // return;

    let arrayToDb : any;

    arrayToDb = {
      id : element.id
        , estatus : 1
                }  
    console.log('APRUEBA', arrayToDb)

    this.updateEmployeeStatus(arrayToDb);
  }

  inactive(element, event){
    // let header : string = '¡¡¡ALERTA!!!';
    // let icon: any
    // let message : string = 'Funcionalidad temporalmente suspendida, comuniquese con su proveedor de sistemas';
    // let buttonCaption: string = 'OK'
    
    // Swal.fire({
    //   title: header,
    //   html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
    //   icon: icon,
    //   confirmButtonText: buttonCaption,
    //   customClass: {
    //       confirmButton: 'btn  btn-rounded btn-outline-warning'
    //   }
    // })
    // return;
    let arrayToDb : any;

    arrayToDb = {
      id : element.id
        , estatus : 0
                }
    this.updateEmployeeStatus(arrayToDb);
  }

  // =================
  // UTILERIAS
  // =================
  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    const part = this.array.slice(0, this.totalSize);
    this.dataSourceShow = new MatTableDataSource(part);
    this.dataSourceShow.sort = this.sort;
    this.dataSourceShow.paginator = this.paginator;
  }

  showMessage(tipoMensaje : number, header: string, icon: any, message : string, buttonCaption: string){
  
    switch(tipoMensaje){
      case(1) : 
          Swal.fire({
            title: header,
            html: '<p style="text-transform: capitalize;"></p>' + '<p><b>' + message + '</b></p>' + '<p style="text-transform: capitalize;"></p>',
            icon: icon,
            confirmButtonText: buttonCaption,
            customClass: {
                confirmButton: 'btn  btn-rounded btn-outline-warning'
            }
          })
        break;
      case(2) :
          Swal.fire({
            position: 'top-end',
            icon: icon,
            title: message,
            showConfirmButton: false,
            timer: 1500
          })
        break;
    }
  }

  decode(){
    let token = localStorage.getItem('token_access');
    this.decodeUserProfile = jwt_decode(token)["perfil_usuarioid"];

  }

  // =================
  // SERVICIOS
  // =================

  getemployee(){

    let arraySort: any;

    // Proyectos registrados
    this._employeservice.getemployeAll().subscribe(
      res=> {

        // Ordenado de arreglo
        arraySort = res.sort(function (a, b) {
          if (a.id < b.id) {
            return 1;
          }
          if (a.id > b.id) {
            return -1;
          }
          return 0;
        });
        
        this.dataSourceShow = new MatTableDataSource(arraySort);
        this.array = res;
        this.totalSize = this.array.length;
        
        this.iterator();
        this.dataSourceShow.sort = this.sort;
  
        this.decode();

      },
      error => console.log("error consulta regiones",error)
    )
  }

    updateEmployeeStatus(arrayToDb : any){

      console.log('ACTIVO', arrayToDb);
      
      // Actualiza estatus
      this._employeservice.putemployeeStatus(arrayToDb).subscribe(
        res=> {
          console.log('ACTIVO', res);

          this.showMessage(2, 'Comentario', 'success', 'Se actualizo el registro con exito', 'Cerrar');
          this.getemployee();

        },
        error => console.log("error al actualizar proveedores",error)
      )
    
    }
}
