<div mat-dialog-content-example [formGroup]="newProject">
    <br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>Año
                <ng-container>
                    <mat-select class="form-control"  formControlName="anio"><!--(selectionChange)="emitFilters($event, 'Enviado')"  [(ngModel)]="almacen"-->
                        <mat-option value= "2020">2020</mat-option>
                        <mat-option value= "2021">2021</mat-option>
                        <mat-option value= "2022" selected="selected">2022</mat-option>
                        <mat-option value= "2023">2023</mat-option>
                        <mat-option value= "2024">2024</mat-option>
                        <mat-option value= "2025">2025</mat-option>
                        <mat-option value= "2026">2026</mat-option>
                        <mat-option value= "2027">2027</mat-option>
                        <mat-option value= "2028">2028</mat-option>
                        <mat-option value= "2029">2029</mat-option>
                        <mat-option value= "2030">2030</mat-option>
                        <mat-option value= "2031">2031</mat-option>
                      </mat-select>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="200px">
            <div>Mes
                <ng-container>
                      <mat-select class="form-control"  formControlName="mes"><!--(selectionChange)="emitFilters($event, 'Enviado')"  [(ngModel)]="almacen"-->
                        <mat-option value= "1" selected="selected">Enero</mat-option>
                        <mat-option value= "2">Febrero</mat-option>
                        <mat-option value= "3">Marzo</mat-option>
                        <mat-option value= "4">Abril</mat-option>
                        <mat-option value= "5">Mayo</mat-option>
                        <mat-option value= "6">Junio</mat-option>
                        <mat-option value= "7">Julio</mat-option>
                        <mat-option value= "8">Agosto</mat-option>
                        <mat-option value= "9">Septiembre</mat-option>
                        <mat-option value= "10">Octubre</mat-option>
                        <mat-option value= "11">Noviembre</mat-option>
                        <mat-option value= "12">Diciembre</mat-option>
                      </mat-select>
                </ng-container>
            </div>
        </div>
        <div fxFlex.gt-sm="100px">
            <div><br>
                <ng-container>
                    <button mat-raised-button color="accent" (click)="search(newProject, $event)">Buscar</button>
                </ng-container>
            </div>
        </div>
    </div>
<br>    
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div>Listado trabajadores Excel o PDF</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container><input type="file" name="fuListadoTrabajadores" id="fuListadoTrabajadores" (change)="cargarImagen(newProject, $event, 'listadoTabajadores', 'mensuales')" hidden></ng-container>
                
                <ng-container *ngIf="estadoListadoTrabajadores == undefined && anioBandera != 0 && mesBandera != 0"><label for="fuListadoTrabajadores"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoListadoTrabajadores == 2"><label for="fuListadoTrabajadores"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoListadoTrabajadores == 4"><label for="fuListadoTrabajadores"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                

                <ng-container *ngIf="estadoListadoTrabajadores == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlListadoTrabajadores)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoListadoTrabajadores == 0"><button class="button" (click)="openFile(urlListadoTrabajadores)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoListadoTrabajadores == 1"><button class="button" (click)="openFile(urlListadoTrabajadores)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoListadoTrabajadores == 2">
                    <button class="button" (click)="openFile(urlListadoTrabajadores)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(201, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoListadoTrabajadores == 4"><button class="button" (click)="openFile(urlListadoTrabajadores)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div><br>CFDI mensual de trabajadores</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <br><ng-container><input type="file" name="fuCFDI" id="fuCFDI" (change)="cargarImagen(newProject, $event, 'CFDI', 'mensuales')" hidden></ng-container>
                
                    <ng-container *ngIf="estadoCFDI == undefined && anioBandera != 0 && mesBandera != 0"><label for="fuCFDI"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                    <ng-container *ngIf="estadoCFDI == 2"><label for="fuCFDI"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                    <ng-container *ngIf="estadoCFDI == 4"><label for="fuCFDI"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                    
                    <ng-container *ngIf="estadoCFDI == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlCFDI)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                    <ng-container *ngIf="estadoCFDI == 0"><button class="button" (click)="openFile(urlCFDI)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                    <ng-container *ngIf="estadoCFDI == 1"><button class="button" (click)="openFile(urlCFDI)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                    <ng-container *ngIf="estadoCFDI == 2">
                        <button class="button" (click)="openFile(urlCFDI)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                        <button class="button" (click)="opencomments(202, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                    </ng-container>
                    <ng-container *ngIf="estadoCFDI == 4"><button class="button" (click)="openFile(urlCFDI)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </div> 
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="300px">
            <br><div class="titles"><br>Pago IMSS mensual</div>
        </div>
        <div fxFlex.gt-sm="300px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div>
                <ng-container></ng-container>
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div><br>Comprobante de pago del Banco en PDF</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <br><ng-container><input type="file" name="urlComprobanteBanco" id="urlComprobanteBanco" (change)="cargarImagen(newProject, $event, 'comprobanteBanco', 'mensuales')" hidden></ng-container>
                    <ng-container *ngIf="estadoComprobanteBanco == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                    <ng-container *ngIf="estadoComprobanteBanco == 2"><label for="urlComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                    <ng-container *ngIf="estadoComprobanteBanco == 4"><label for="urlComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                    <ng-container *ngIf="estadoComprobanteBanco == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                    <ng-container *ngIf="estadoComprobanteBanco == 0"><button class="button" (click)="openFile(urlComprobanteBanco)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                    <ng-container *ngIf="estadoComprobanteBanco == 1"><button class="button" (click)="openFile(urlComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                    <ng-container *ngIf="estadoComprobanteBanco == 2">
                        <button class="button" (click)="openFile(urlComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                        <button class="button" (click)="opencomments(203, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                    </ng-container>
                    <ng-container *ngIf="estadoComprobanteBanco == 4"><button class="button" (click)="openFile(urlComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </div> 
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div><br>SIPARE en pdf</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container><input type="file" name="urlSIPARE" id="urlSIPARE" (change)="cargarImagen(newProject, $event, 'SIPARE', 'mensuales')" hidden></ng-container>
                <ng-container *ngIf="estadoSIPARE == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlSIPARE"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoSIPARE == 2"><label for="urlSIPARE"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoSIPARE == 4"><label for="urlSIPARE"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoSIPARE == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlSIPARE)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoSIPARE == 0"><button class="button" (click)="openFile(urlSIPARE)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoSIPARE == 1"><button class="button" (click)="openFile(urlSIPARE)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoSIPARE == 2">
                    <button class="button" (click)="openFile(urlSIPARE)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(204, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoSIPARE == 4"><button class="button" (click)="openFile(urlSIPARE)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </div> 
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div><br>Cedula mensual SUA en PDF (trabajadores)</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <div><br>
                    <ng-container><input type="file" name="urlSUA" id="urlSUA" (change)="cargarImagen(newProject, $event, 'SUA', 'mensuales')" hidden></ng-container>
                    <ng-container *ngIf="estadoSUA == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlSUA"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                    <ng-container *ngIf="estadoSUA == 2"><label for="urlSUA"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                    <ng-container *ngIf="estadoSUA == 4"><label for="urlSUA"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                    <ng-container *ngIf="estadoSUA == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlSUA)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                    <ng-container *ngIf="estadoSUA == 0"><button class="button" (click)="openFile(urlSUA)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                    <ng-container *ngIf="estadoSUA == 1"><button class="button" (click)="openFile(urlSUA)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                    <ng-container *ngIf="estadoSUA == 2">
                        <button class="button" (click)="openFile(urlSUA)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                        <button class="button" (click)="opencomments(205, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                    </ng-container>
                    <ng-container *ngIf="estadoSUA == 4"><button class="button" (click)="openFile(urlSUA)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                    
                </div> 
            </div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div class="titles"><br>Pago SAT (ISR)</div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div><br>Comprobante de pago del Banco en PDF (ISR)</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container><input type="file" name="urlISRComprobanteBanco" id="urlISRComprobanteBanco" (change)="cargarImagen(newProject, $event, 'ISRComprobanteBanco', 'mensuales')" hidden></ng-container>
                <ng-container *ngIf="estadoISRComprobanteBanco == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlISRComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoISRComprobanteBanco == 2"><label for="urlISRComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoISRComprobanteBanco == 4"><label for="urlISRComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoISRComprobanteBanco == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlISRComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoISRComprobanteBanco == 0"><button class="button" (click)="openFile(urlISRComprobanteBanco)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoISRComprobanteBanco == 1"><button class="button" (click)="openFile(urlISRComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoISRComprobanteBanco == 2">
                    <button class="button" (click)="openFile(urlISRComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(206, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoISRComprobanteBanco == 4"><button class="button" (click)="openFile(urlISRComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
            </div> 
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div><br>Declaración de ISR</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container><input type="file" name="urlISRDeclaracion" id="urlISRDeclaracion" (change)="cargarImagen(newProject, $event, 'ISRDeclaracion', 'mensuales')" hidden></ng-container>
                <ng-container *ngIf="estadoISRDeclaracion == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlISRDeclaracion"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoISRDeclaracion == 2"><label for="urlISRDeclaracion"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoISRDeclaracion == 4"><label for="urlISRDeclaracion"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoISRDeclaracion == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlISRDeclaracion)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoISRDeclaracion == 0"><button class="button" (click)="openFile(urlISRDeclaracion)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoISRDeclaracion == 1"><button class="button" (click)="openFile(urlISRDeclaracion)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoISRDeclaracion == 2">
                    <button class="button" (click)="openFile(urlISRDeclaracion)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(207, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoISRDeclaracion == 4"><button class="button" (click)="openFile(urlISRDeclaracion)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                
            </div> 
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div class="titles"><br>Pago SAT (IVA)</div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div></div>
        </div>
        <div fxFlex.gt-sm="70px">
            <div></div>
        </div>
    </div>

    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div><br>Declaración de IVA</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container><input type="file" name="urlIVADeclaracion" id="urlIVADeclaracion" (change)="cargarImagen(newProject, $event, 'IVADeclaracion', 'mensuales')" hidden></ng-container>
                <ng-container *ngIf="estadoIVADeclaracion == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlIVADeclaracion"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoIVADeclaracion == 2"><label for="urlIVADeclaracion"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoIVADeclaracion == 4"><label for="urlIVADeclaracion"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                
                <ng-container *ngIf="estadoIVADeclaracion == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlIVADeclaracion)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoIVADeclaracion == 0"><button class="button" (click)="openFile(urlIVADeclaracion)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoIVADeclaracion == 1"><button class="button" (click)="openFile(urlIVADeclaracion)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoIVADeclaracion == 2">
                    <button class="button" (click)="openFile(urlIVADeclaracion)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(209, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoIVADeclaracion == 4"><button class="button" (click)="openFile(urlIVADeclaracion)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                
            </div> 
        </div>
    </div>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div><br>Comprobante de pago del Banco PDF (IVA)</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div><br>
                <ng-container><input type="file" name="urlIVAComprobanteBanco" id="urlIVAComprobanteBanco" (change)="cargarImagen(newProject, $event, 'IVAComprobanteBanco', 'mensuales')" hidden></ng-container>
                <ng-container *ngIf="estadoIVAComprobanteBanco == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlIVAComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoIVAComprobanteBanco == 2"><label for="urlIVAComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoIVAComprobanteBanco == 4"><label for="urlIVAComprobanteBanco"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoIVAComprobanteBanco == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlIVAComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoIVAComprobanteBanco == 0"><button class="button" (click)="openFile(urlIVAComprobanteBanco)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoIVAComprobanteBanco == 1"><button class="button" (click)="openFile(urlIVAComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoIVAComprobanteBanco == 2">
                    <button class="button" (click)="openFile(urlIVAComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(210, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoIVAComprobanteBanco == 4"><button class="button" (click)="openFile(urlIVAComprobanteBanco)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                
            </div> 
        </div>
    </div>
    <br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div>Opinión Positiva IMSS  (Un meses atrás)</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container><input type="file" name="urlOpinionPositivaIMSS" id="urlOpinionPositivaIMSS" (change)="cargarImagen(newProject, $event, 'opinionPositivaIMSS', 'mensuales')" hidden></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaIMSS == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlOpinionPositivaIMSS"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaIMSS == 2"><label for="urlOpinionPositivaIMSS"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaIMSS == 4"><label for="urlOpinionPositivaIMSS"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>

                <ng-container *ngIf="estadoOpinionPositivaIMSS == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlOpinionPositivaIMSS)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaIMSS == 0"><button class="button" (click)="openFile(urlOpinionPositivaIMSS)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaIMSS == 1"><button class="button" (click)="openFile(urlOpinionPositivaIMSS)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaIMSS == 2">
                    <button class="button" (click)="openFile(urlOpinionPositivaIMSS)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button>
                    <button class="button" (click)="opencomments(211, element, $event)"><i class="fa fa-list fa-lg icon-cog-lightblue"></i></button>
                </ng-container>
                <ng-container *ngIf="estadoOpinionPositivaIMSS == 4"><button class="button" (click)="openFile(urlOpinionPositivaIMSS)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                
            </div> 
        </div>
    </div>
    <br>
    <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="150px">
            <div><br></div>
        </div>
        <div fxFlex.gt-sm="500px">
            <div>Opinion Positiva SAT (Un meses atrás)</div>
        </div>
        <div fxFlex.gt-sm="90px">
            <div>
                <ng-container><input type="file" name="urlOpinionPositivaSAT" id="urlOpinionPositivaSAT" (change)="cargarImagen(newProject, $event, 'opinionPositivaSAT', 'mensuales')" hidden></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaSAT == undefined && anioBandera != 0 && mesBandera != 0"><label for="urlOpinionPositivaSAT"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaSAT == 2"><label for="urlOpinionPositivaSAT"><i class="fa fa-arrow-up fa-lg icon-cog-red"></i></label></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaSAT == 4"><label for="urlOpinionPositivaSAT"><i class="fa fa-arrow-up fa-lg icon-cog-blue"></i></label></ng-container>
                
                <ng-container *ngIf="estadoOpinionPositivaSAT == undefined && anioBandera != 0 && mesBandera != 0"><button class="button" (click)="openFile(urlOpinionPositivaSAT)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaSAT == 0"><button class="button" (click)="openFile(urlOpinionPositivaSAT)"><i class="fa fa-lock fa-lg icon-cog-chocolate"></i></button></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaSAT == 1"><button class="button" (click)="openFile(urlOpinionPositivaSAT)"><i class="fa fa-eye fa-lg icon-cog-green"></i></button></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaSAT == 2"><button class="button" (click)="openFile(urlOpinionPositivaSAT)"><i class="fa fa-eye fa-lg icon-cog-red"></i></button></ng-container>
                <ng-container *ngIf="estadoOpinionPositivaSAT == 4"><button class="button" (click)="openFile(urlOpinionPositivaSAT)"><i class="fa fa-eye fa-lg icon-cog-orange"></i></button></ng-container>
                
            </div>
        </div>
    </div>
</div>

