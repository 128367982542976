import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { employeeUsersModel } from 'src/app/models/employee-user.model';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class employeeusersservice {

  constructor(protected http: HttpClient) { }

    getemployeeUsersAll() {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.get<employeeUsersModel[]>(environment.urlapi + environment.apiContextDrivers + 'Provider/lista', {headers});
    }

    insertemployeeUser(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.post(environment.urlapi+environment.apiContextDrivers+'User/registrar',arrayToDb, {headers});
    }

    updateemployeeUser(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'User/actualizar', arrayToDb, {headers});
    }

    putemployeeStatus(arrayToDb : any) {
        const headers : HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .append('Authorization', 'Bearer ' + localStorage.getItem('token_access'));
        return this.http.put(environment.urlapi + environment.apiContextDrivers + 'Provider/activoinactivo', arrayToDb, {headers});
    }
}