import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import { AuthComponent } from  '../../src/app/components/auth/auth.component';
import { AuthGuard } from './guards/auth.guards';
import { LoginComponent } from './pages/login/login.component';
import { RepseSupplierReviewComponent } from './pages/repse-supplier-review/repse-supplier-review/repse-supplier-review.component';

import { AppComponent }   from './app.component';
import { BrowserModule  } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      {
        path: '',
        redirectTo: '/auth/login',
        pathMatch: 'full'
      },
      {
        path: 'auth',
          loadChildren: () => import('./pages/authentication.module').then(module => module.AuthenticationModule)
          // loadChildren: () => import('./pages/login/login.module').then(module => module.LoginModule)
      }
    ]
  },
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'catalog',
        loadChildren: () => import('./pages/catalogs-list/catalog-list.module').then(m => m.CatalogListModule)
      },
      {
        path: 'requisition',
        loadChildren: () => import('./pages/requisition/requisition-list/requisition.module').then(m => m.RequisitionModule)
      },
      {
        path: 'quotation',
        loadChildren: () => import('./pages/purchaseOrder/po-list/po.module').then(m => m.PoModule)
      },
      {
        path: 'po',
        loadChildren: () => import('./pages/purchaseOrder/po-list/po.module').then(m => m.PoModule)
      },
      {
        path: 'reportMaster',
        loadChildren: () => import('./pages/report-master/report-master/report-master.module').then(m => m.ReportMasterModule)
      },
      {
        path: 'userAccess',
        // loadChildren: () => import('./pages/user-key-access/user-key-access.module').then(m=> m.UserKeyAccessModule)
        loadChildren: () => import('./pages/user-key-access/user-manage/user-manage.module').then(m=> m.UserManageModule)
      },
      {
          path: 'login',
          component: LoginComponent,
      }
    ],
    canActivate: [AuthGuard]
  },
  
  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {useHash: true})
  ],
exports: [RouterModule]
})

// @NgModule({
//   declarations: [AppComponent],
//   imports: [BrowserModule],
//   providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
//   bootstrap: [AppComponent],
// })

// export class AppModule {}
export class AppRoutingModule { }


