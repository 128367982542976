<p>{{this.requisicionId}}</p>
<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
        </div>
    </div>
</div>
<div mat-dialog-content-example [formGroup]="newProject">
     <div fxLayout="row" class="division">
         <div fxFlex.gt-sm="20%">
             <div>
                <input type="text" class="form-control form-control-line" placeholder="BUSCAR" (keyup)="filtrar($event)">
            </div>
         </div>
         <div fxFlex.gt-sm="20%">
             <div></div>
         </div>
         <div fxFlex.gt-sm="20%">
             <div><button mat-raised-button color="warn" (click)="cancel()"><i class="fa fa-arrow-left fa-lg icon-cog-wite"></i></button></div>
         </div>
         <div fxFlex.gt-sm="20%">
             <div>
                <mat-form-field>
                    <input type="email" matInput class="form-control form-control-line control" placeholder="" formControlName="emailCapture" placeholder="correo">
                </mat-form-field>
             </div>
         </div>
         <div fxFlex.gt-sm="20%">
             <div><button mat-raised-button color="primary" (click)="addemailManual()"><i class="fa fa-plus fa-lg icon-cog-wite"></i></button></div>
         </div>
     </div>
     <br><br>
     <div fxLayout="row" class="division">
        <div fxFlex.gt-sm="100%">
            <div>
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                      <div class="card-body card-body-table" style="width: 100%;">
                        <table style="width: 100%;">
                            <tr>
                                <td style="width: 50%;">
                                    <mat-table [dataSource]="emailDatasource" matSort class="mat-elevation-z8" style="width: 95%;">
                          
                                        <ng-container matColumnDef="nombre" hidden>
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col40"> Nombre </th>
                                            <td mat-cell *matCellDef="let element" class="column"> {{element.nombre}} </td>
                                        </ng-container>
            
                                      <ng-container matColumnDef="correos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col40"> email </th>
                                        <td mat-cell *matCellDef="let element" class="column"> {{element.correos}} </td>
                                      </ng-container>
                                      
                                      <ng-container matColumnDef="add">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> Agregar </th>
                                        <td mat-cell *matCellDef="let element" class="column"> 
                                            <ng-container><button class="button" (click)="addMail(element.nombre, element.correos, $event)"><i class="fa fa-arrow-right fa-lg icon-cog-blue"></i></button></ng-container>&nbsp;&nbsp;
                                        </td>
                                      </ng-container>
                                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsOrigin"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsOrigin;"></tr>
                                    </mat-table>
                                </td>
                                <td style="width: 50%;">
                                    <mat-table [dataSource]="emailDatasourcetarget" matSort class="mat-elevation-z8" style="width: 95%;">
                          
                                        <ng-container matColumnDef="nombre" hidden>
                                            <th mat-header-cell *matHeaderCellDef mat-sort-header class="col40"> Nombre </th>
                                            <td mat-cell *matCellDef="let element" class="column"> {{element.nombre}} </td>
                                        </ng-container>
            
                                      <ng-container matColumnDef="correos">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col40"> email </th>
                                        <td mat-cell *matCellDef="let element" class="column"> {{element.correos}} </td>
                                      </ng-container>
                                      
                                      <ng-container matColumnDef="delete">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> Eliminar </th>
                                        <td mat-cell *matCellDef="let element" class="column"> 
                                            <ng-container><button class="button" (click)="deleteMail(element.nombre, element.correos)"><i class="fa fa-trash fa-lg icon-cog-red"></i></button></ng-container>&nbsp;&nbsp;
                                        </td>
                                      </ng-container>
                                      
                                      <tr mat-header-row *matHeaderRowDef="displayedColumnsTarget"></tr>
                                      <tr mat-row *matRowDef="let row; columns: displayedColumnsTarget;"></tr>
                                    </mat-table>
                                </td>
                            </tr>
                        </table>
                        
                      </div>
                    </div>
                    <br><br><br><br>
                    <div class="card">
                        <div class="card-body card-body-table">
                            
                        </div>
                      </div>


                </div>
            </div>
        </div>
    </div>
 </div>
