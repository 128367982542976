<div class="header bg-gradient-danger pb-8 pt-5 pt-md-8">
    <div class="container-fluid">
        <div class="header-body">
            <!-- Card stats -->
        </div>
    </div>
</div>
<!-- Page content -->
<div class="container-fluid mt--7">

    <!-- Table -->
    <div class="row">
        <div class="col">
            <div class="card shadow">
                <div class="card-header border-0">
                    <h3 class="mb-0">Facturas</h3>
                </div>

                <!-- <form> -->

                <h6 class="heading-small text-muted mb-4"></h6>
                <hr class="my-4" />
                <!-- </form> -->
                <div class="card">
                    <table>
                        <tr>
                            <td class="col20"><input type="text" class="form-control form-control-line"
                                    placeholder="BUSCAR" (keyup)="filtrar($event)">
                            </td>
                            <td class="col70"></td>
                            <td class="col10"><button mat-raised-button color="primary"
                                    (click)="descargarExcel()">Descargar</button></td>
                            <td class="col10"><button mat-raised-button color="accent"
                                    (click)="newsupplier($event)">Nueva factura</button></td>
                            <td class="col10"></td>
                        </tr>
                    </table>
                    <br>
                </div>

                <!-- INICIA CUERPO DE LA PÁGINA-->
                <div class="col-12 col-md-12 p-0">
                    <div class="card">
                        <div class="card-body card-body-table">
                            <mat-table [dataSource]="dataSourceShow" matSort class="mat-elevation-z8"
                                style="width: 95%;">
                                
                                <ng-container matColumnDef="proyecto_id">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" hidden>proyecto_id </th>
                                    <td mat-cell *matCellDef="let element" hidden> {{element.proyecto_id}} </td>
                                </ng-container>

                                <ng-container matColumnDef="proyecto">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30" >Proyecto </th>
                                    <td mat-cell *matCellDef="let element" > {{element.proyecto}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="cliente">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col30">Cliente </th>
                                    <td mat-cell *matCellDef="let element"> {{element.cliente}} </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="total_odc_mxn">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> OC (MXN)</th>
                                    <td mat-cell *matCellDef="let element"> {{element.total_odc_mxn | currency}} </td>
                                </ng-container>

                                
                                <ng-container matColumnDef="total_odc_usd">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col10"> OC (USD) </th>
                                    <td mat-cell *matCellDef="let element"> {{element.total_odc_usd | currency}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="total_factura_mxn">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Facturas (MXN) </th>
                                    <td mat-cell *matCellDef="let element">{{element.total_factura_mxn | currency}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="total_factura_usd">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Facturas (USD) </th>
                                    <td mat-cell *matCellDef="let element">{{element.total_factura_usd | currency}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="presupuesto">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="col20"> Presupuesto </th>
                                    <td mat-cell *matCellDef="let element">{{element.presupuesto | currency}}
                                    </td>
                                </ng-container>
                                
                                <ng-container matColumnDef="detail">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Detalle </th>
                                    <td mat-cell *matCellDef="let element"> <button mat-button color="primary"
                                            (click)="edit(element, $event)">Detalle</button> </td>
                                </ng-container>

                                <ng-container matColumnDef="invoice">
                                    <th mat-header-cell *matHeaderCellDef class="colbuttons"> Facturas </th>
                                    <td mat-cell *matCellDef="let element"> <button mat-button color="primary"
                                            (click)="invoiceModify(element, $event)">Modificar</button> </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </mat-table>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>